import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { FormSectionWrapper } from "../";
import { NameFields, Drivers } from "../../fieldGroups";
import { Section } from "../../../../components";

class DriversLicenseInfoUpdate extends PureComponent {
  render() {
    return (
      <FormSectionWrapper {...this.props}>
        <Section size="1-2" min="md">
          <NameFields {...this.props} />
        </Section>
        <Drivers {...this.props} />
      </FormSectionWrapper>
    );
  }
}

DriversLicenseInfoUpdate.propTypes = {
  form: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired
};

export default DriversLicenseInfoUpdate;
