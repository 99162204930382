import React, { PureComponent } from "react";
import { Field } from "redux-form";
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";

import { Checkbox, Grid, Col, Section } from "../../../../components";
import { messages } from "../../../../constants";
import {Txt} from "../../../../elements";

class EvoCard extends PureComponent {

  render() {
    const { intl } = this.props;
    return (
      <div>
        <Section size="2-3" min="md">
          <Grid>
            <Col>
              <Field
                component={Checkbox}
                name="evoCard"
                label={intl.formatMessage(messages.evoCard.evoCard)}
              />
              <Txt theme={["note"]}>
                <small>
                  {intl.formatMessage(messages.evoCard.evoCardNote1, {
                    link: <a href="mailto:info@evo.ca">info@evo.ca</a>
                  })}
                </small>
              </Txt>
              <Txt theme={["note"]}>
                <small>{intl.formatMessage(messages.evoCard.evoCardNote2)}</small>
              </Txt>
            </Col>
          </Grid>
        </Section>
      </div>
    );
  }
}

EvoCard.propTypes = {
  intl: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  section: PropTypes.string.isRequired,
};

export default injectIntl(EvoCard);
