import React from "react";
import ReactResponsive from "react-responsive";
import PropTypes from "prop-types";

const Responsive = props => {
  const { device, children } = props;
  if (!children) return null;

  const mobileSizeLimit = 767;

  switch (device) {
    case "Mobile":
      return <ReactResponsive maxWidth={mobileSizeLimit}>{children}</ReactResponsive>;
    case "Default":
    default:
      return <ReactResponsive minWidth={mobileSizeLimit + 1}>{children}</ReactResponsive>;
  }
};

Responsive.defaultProps = {
  device: "Default",
  children: null
};

Responsive.propTypes = {
  device: PropTypes.oneOf(["Default", "Mobile"]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string
  ])
};

export default Responsive;
