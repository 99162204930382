import React from "react";
import PropTypes from "prop-types";
// import { Link } from 'react-router-dom';
import Svg from "react-inlinesvg";
import cn from "classnames";
import "./logo-caa.scss";

// add logo images here
import bcaa from "./logo-bcaa.svg";

// map to regions
const regions = {
  bcaa: {
    logo: bcaa,
    url: "https://www.bcaa.com/",
    title: "BCAA"
  }
};

const LogoCAA = props => {
  const { region, theme } = props;
  const logoCSS = cn("logo-caa", theme ? theme.map(css => `logo-caa--${css}`).join(" ") : "");
  return (
    <a href={regions[region].url} className={logoCSS} target="caa" title={regions[region].title}>
      <Svg src={regions[region].logo} />
    </a>
  );
};

LogoCAA.propTypes = {
  theme: PropTypes.array,
  region: PropTypes.string
};

LogoCAA.defaultProps = {
  theme: [],
  region: "bcaa"
};

export default LogoCAA;
