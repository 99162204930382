import React, { PureComponent } from "react";
import { compose } from "redux";
import { reduxForm, formValueSelector } from "redux-form";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import { getBilling, getPromoCodeStatus } from "../../../selectors";
import { PromoCode } from "../fieldGroups";
import { constants } from "../../../constants";
import "./index.scss";

const settings = {
  form: constants.FORM.applyPromoCodeForm,
  destroyOnUnmount: true,
  keepDirtyOnReinitialize: true
};

class ApplyPromoCodeForm extends PureComponent {
  render() {
    const { handleSubmit, intl, promoCode, submitting } = this.props;
    const promoCodeValue = this.getValue("promo");
    return (
      <form data-testid="container-applyPromoCodeForm" onSubmit={handleSubmit}>
        <PromoCode
          promoCode={promoCode}
          intl={intl}
          handleClickBtn={handleSubmit}
          btnDisabled={!promoCodeValue}
          submitting={submitting}
        />
      </form>
    );
  }

  // function to get the selected field value
  getValue = name => {
    const { state } = this.props;
    const selector = formValueSelector(settings.form);
    return selector(state, name);
  };
}

ApplyPromoCodeForm.defaultProps = {
  state: {},
  promoCode: {},
  submitting: false
};

ApplyPromoCodeForm.propTypes = {
  intl: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  state: PropTypes.object,
  promoCode: PropTypes.object,
  submitting: PropTypes.bool
};

const mapStateToProps = state => ({
  state,
  billing: getBilling(state),
  promoCode: getPromoCodeStatus(state)
});

const enhancer = compose(
  injectIntl,
  connect(mapStateToProps),
  reduxForm(settings)
);

export default enhancer(ApplyPromoCodeForm);
