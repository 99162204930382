import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import get from "lodash/get";
import cn from "classnames";

import "./pages.scss";
import { getJsonFromUrl } from "../../util";
import * as actions from "../../actions";
import * as bannerActions from "../../actions/banner";
import { Banner } from "../../components";

// all pages must use this wrapper
class PagesContainer extends PureComponent {
  constructor(props) {
    super(props);

    const { persistBanner } = getJsonFromUrl(get(props, "location.search", ""));
    if (persistBanner !== "true") {
      props.banner.resetAlerts();
    }
  }

  componentDidMount() {
    const { theme, sub, actions } = this.props;
    actions.setPageTheme({ theme });
    actions.setPageSubNav({ sub });
  }

  // ID main is used for accessibility button "Skip to main content"
  render() {
    const { alerts, banner, children, classNames } = this.props;

    const containerCSS = cn(
      "main",
      classNames.length ? classNames.map(className => `main--${className}`).join(" ") : ""
    );

    return (
      <Fragment>
        <Banner alerts={alerts} handleClickDismissIcon={banner.dismissAlert} />
        <div className={containerCSS}>
          <label htmlFor="nav-checkbox" role="button" />
          {children}
        </div>
      </Fragment>
    );
  }
}
PagesContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
  theme: PropTypes.string,
  classNames: PropTypes.arrayOf(PropTypes.string),
  sub: PropTypes.array,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  banner: PropTypes.objectOf(PropTypes.func).isRequired,
  alerts: PropTypes.arrayOf(
    PropTypes.shape({
      level: PropTypes.oneOf(["success", "warning", "error"]),
      message: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    })
  )
};

PagesContainer.defaultProps = {
  classNames: []
};

const mapStateToProps = ({ banner }) => ({ alerts: banner.alerts });
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
  banner: bindActionCreators(bannerActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PagesContainer);
