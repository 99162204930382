import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
// import { injectIntl } from 'react-intl';
import DOMPurify from "dompurify";
import Parser from "html-react-parser";
import "./checkbox.scss";

const CheckboxWithoutRedux = props => {
  const { className, theme, label, checked, name, id, disabled, onChange, required } = props;
  // sanitize the content
  const sanitized = typeof label === "string" ? Parser(DOMPurify.sanitize(label)) : label;
  const inputCSS = cn(
    "checkbox",
    className,
    theme ? theme.map(css => `checkbox--${css}`).join(" ") : ""
  );

  return (
    <fieldset className={inputCSS} role="group">
      <input
        type="checkbox"
        checked={checked}
        name={name}
        id={id || name}
        disabled={disabled}
        onChange={onChange}
      />
      <label htmlFor={id || name}>
        {sanitized}
        {required ? " *" : ""}
      </label>
    </fieldset>
  );
};

CheckboxWithoutRedux.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.array,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool
};

CheckboxWithoutRedux.defaultProps = {};

export default CheckboxWithoutRedux;
