import { defineMessages } from "react-intl";

const namespace = "title";
const title = defineMessages({
  Home: {
    id: `${namespace}.Home`,
    description: `Landing page title`,
    defaultMessage: `Login to your Evo account`
  },
  Registration: {
    id: `${namespace}.Registration`,
    description: `Header Greeting`,
    defaultMessage: `Registration`
  },
  Preferences: {
    id: `${namespace}.Preferences`,
    description: `Preferences title in Account Settings`,
    defaultMessage: `Preferences`
  },
  Subscriptions: {
    id: `${namespace}.Subscriptions`,
    description: "Subscriptions title in Account Settings",
    defaultMessage: "Subscriptions"
  },
  MultiCity: {
    id: `${namespace}.MultiCity`,
    description: "MultiCity dropdown title",
    defaultMessage: "Which city are you using Evo in?"
  },
  HomeAddress: {
    id: `${namespace}.HomeAddress`,
    description: `Home Address title on Registration page`,
    defaultMessage: `Home Address`
  },
  BillingAddress: {
    id: `${namespace}.BillingAddress`,
    description: `Billing Address title on Registration page`,
    defaultMessage: `Billing Address`
  },
  Address: {
    id: `${namespace}.Address`,
    description: `Address title on Registration page`,
    defaultMessage: `Address`
  },
  MailingAddress: {
    id: `${namespace}.MailingAddress`,
    description: `Mailing Address title on Registration page`,
    defaultMessage: `Mailing Address`
  },
  ContactInfoTitle: {
    id: `${namespace}.ContactInfoTitle`,
    description: `Contact Info Title in Registration flow`,
    defaultMessage: `Your contact info`
  },
  DriversLicenseInfoTitle: {
    id: `${namespace}.DriversLicenseInfoTitle`,
    description: `Drivers Licence Info Title in Registration flow`,
    defaultMessage: `Add your driver's licence`
  },
  DriversLicenseInfoConfirmDetailsTitle: {
    id: `${namespace}.DriversLicenseInfoConfirmDetailsTitle`,
    description: `Drivers Licence Info Confirm Details Title in Registration flow`,
    defaultMessage: `Confirm your driver's licence`
  },
  EbikePersonalInfoConfirmDetailsTitle: {
    id: `${namespace}.EbikePersonalInfoConfirmDetailsTitle`,
    description: `EBike Personal Info Confirm Details Title in Registration flow`,
    defaultMessage: `Your Personal information`
  },
  IdentityVerificationInfoTitle: {
    id: `${namespace}.IdentityVerificationInfoTitle`,
    description: `Identity Verification Info Title in Registration flow`,
    defaultMessage: `Take a selfie`
  },
  PromosAndSavingsInfoTitle: {
    id: `${namespace}.PromosAndSavingsInfoTitle`,
    description: "Promos & Savings Info Title in Registration flow",
    defaultMessage: "Promos & savings"
  },
  PaymentInfoTitle: {
    id: `${namespace}.PaymentInfoTitle`,
    description: "Payment Info Title in Registration flow",
    defaultMessage: "Payment"
  },
  DrivingRecordInfoTitle: {
    id: `${namespace}.DrivingRecordTitle`,
    description: "Driving Record Info Title in Registration flow",
    defaultMessage: "Request Driving Record"
  },
  ContactDetailsProgressTitle: {
    id: `${namespace}.ContactDetailsProgressTitle`,
    description: `Contact Details Title title in Progress Bar for Registration Flow`,
    defaultMessage: `Contact Details`
  },
  DriversLicenseProgressTitle: {
    id: `${namespace}.DriversLicenseProgressTitle`,
    description: `Driver's Licence Info title in Progress Bar for Registration Flow`,
    defaultMessage: `Driver's Licence Info`
  },
  IdentityVerificationProgressTitle: {
    id: `${namespace}.IdentityVerificationProgressTitle`,
    description: `Identity Verification title in Progress Bar for Registration Flow`,
    defaultMessage: `Identity Verification`
  },
  PromosAndSavingsProgressTitle: {
    id: `${namespace}.PromosAndSavingsProgressTitle`,
    description: `Promos and Savings title in Progress Bar for Registration Flow`,
    defaultMessage: `Promos & Savings`
  },
  PaymentProgressTitle: {
    id: `${namespace}.PaymentProgressTitle`,
    description: `Payment title in Progress Bar for Registration Flow`,
    defaultMessage: `Payment`
  },
  CreditCardInfoProgressTitle: {
    id: `${namespace}.CreditCardInfoProgressTitle`,
    description: `Credit Card Info in Progress Bar for Registration Flow`,
    defaultMessage: `Credit Card Info`
  },
  DrivingRecordProgressTitle: {
    id: `${namespace}.DrivingRecordProgressTitle`,
    description: `Driving Record title in Progress Bar for Registration Flow`,
    defaultMessage: `Driving Record`
  },
  ConfirmationProgressTitle: {
    id: `${namespace}.ConfirmationProgressTitle`,
    description: `Confirmation title in Progress Bar for Registration Flow`,
    defaultMessage: `Confirmation`
  },
  IdentityVerificationInfo: {
    id: `${namespace}.IdentityVerificationInfo`,
    description: "Identity Verification",
    defaultMessage: "Identity Verification"
  },
  PersonalInfo: {
    id: `${namespace}.PersonalInfo`,
    description: `Personal Info title on Registration page`,
    defaultMessage: `Personal Info`
  },
  DriversLicenseInfo: {
    id: `${namespace}.DriversLicenseInfo`,
    description: `Driver's Licence Info title on Registration page`,
    defaultMessage: `Driver's Licence Info`
  },
  PaymentInfo: {
    id: `${namespace}.PaymentInfo`,
    description: `Payment Info title on Registration page`,
    defaultMessage: `Payment Info`
  },
  RequestDrivingRecord: {
    id: `${namespace}.RequestDrivingRecord`,
    description: `Request Driving Record title on Registration page`,
    defaultMessage: `Request Driving Record`
  },
  EbikeConfrimation: {
    id: `${namespace}.EbikeConfrimation`,
    description: `Ebike Confirmation page title on Registration page`,
    defaultMessage: `You're ready to roll`
  },
  WhatYouPay: {
    id: `${namespace}.WhatYouPay`,
    description: `Payment Summary title on Registration page`,
    defaultMessage: `What you'll pay`
  },
  WhatYouPaySub: {
    id: `${namespace}.WhatYouPaySub`,
    description: `Payment Summary description on Registration page`,
    defaultMessage: `You won’t be charged for Evo Car Share until your driving record is approved`
  },
  EnterPromoCode: {
    id: `${namespace}.EnterPromoCode`,
    description: `Promo Code title on Registration page`,
    defaultMessage: `Enter your promo code`
  },
  LoginInfo: {
    id: `${namespace}.LoginInfo`,
    description: `Login Info title on Account page`,
    defaultMessage: `Login Info`
  },
  CreatedBy: {
    id: `${namespace}.CreatedBy`,
    description: `Title for Created By BCAA logo`,
    defaultMessage: `Created By`
  },

  AccountDetails: {
    id: `${namespace}.AccountDetails`,
    description: "Account Details tab on Accoung page",
    defaultMessage: "My Account"
  },
  MyMinutes: {
    id: `${namespace}.MyMinutes`,
    description: "My Minutes tab on Accoung page",
    defaultMessage: "My Minutes"
  },
  Billing: {
    id: `${namespace}.Billing`,
    description: "Billing tab on Accoung page",
    defaultMessage: "Billing"
  },
  Statements: {
    id: `${namespace}.Statements`,
    description: "Statements tab on Accoung page",
    defaultMessage: "Statements"
  },
  newPasswordBelow: {
    id: `${namespace}.newPasswordBelow`,
    description: "Title for new password input after a reset",
    defaultMessage: "Enter Your New Password Below:"
  },
  successfulPasswordReset: {
    id: `${namespace}.successfulPasswordReset`,
    description: "Message saying password reset was a success",
    defaultMessage: "You have successfully reset your password"
  },
  loginWithNewPassword: {
    id: `${namespace}.loginWithNewPassword`,
    description: "Title letting the user know they can login with their new password",
    defaultMessage: "You can now login to your account using new password!"
  },
  Date: {
    id: `${namespace}.Date`,
    description: "Date tab in data table",
    defaultMessage: "Date"
  },
  billingTime: {
    id: `${namespace}.billingTime`,
    description: "Time tab in data table",
    defaultMessage: "Billing Time"
  },
  cardType: {
    id: `${namespace}.cardType`,
    description: "Card Type tab in data table",
    defaultMessage: "Card Type"
  },
  cardNumber: {
    id: `${namespace}.cardNumber`,
    description: "Card Number tab in data table",
    defaultMessage: "Card Number"
  },
  Status: {
    id: `${namespace}.Status`,
    description: "Status tab in data table",
    defaultMessage: "Status"
  },
  Posted: {
    id: `${namespace}.Posted`,
    description: "Posted tab in data table",
    defaultMessage: "Posted"
  },
  Type: {
    id: `${namespace}.Type`,
    description: "Type tab in data table",
    defaultMessage: "Type"
  },
  Description: {
    id: `${namespace}.Description`,
    description: "Description tab in data table",
    defaultMessage: "Description"
  },
  Trip: {
    id: `${namespace}.Trip`,
    description: "Trip tab in data table",
    defaultMessage: "Trip"
  },
  Amount: {
    id: `${namespace}.Amount`,
    description: "Amount tab in data table",
    defaultMessage: "Amount"
  },
  AmountCharged: {
    id: `${namespace}.AmountCharged`,
    description: "Amount tab in data table",
    defaultMessage: "Amount Charged"
  },
  availableFreeMin: {
    id: `${namespace}.availableFreeMin`,
    description: "availableFreeMin tab in data table",
    defaultMessage: "Available"
  },
  totalFreeMin: {
    id: `${namespace}.totalFreeMin`,
    description: "totalFreeMin tab in data table",
    defaultMessage: "Total"
  },
  totalAvailable: {
    id: `${namespace}.totalAvailable`,
    description: "totalAvailable tab in data table",
    defaultMessage: "Total Available"
  },
  availableMinutes: {
    id: `${namespace}.availableMinutes`,
    description: "availableMinutes tab in data table",
    defaultMessage: "Available Minutes"
  },
  regionalMinutes: {
    id: `${namespace}.regionalMinutes`,
    description: "regionalMinutes tab in data table",
    defaultMessage: "Regional Minutes"
  },
  regionalMinutesSub: {
    id: `${namespace}.regionalMinutesSub`,
    description: "regionalMinutes subtitle",
    defaultMessage: "Your minutes by regional availability. Different regions may share minutes."
  },
  Car: {
    id: `${namespace}.Car`,
    description: "Car tab in data table",
    defaultMessage: "Car"
  },
  TripStart: {
    id: `${namespace}.TripStart`,
    description: "Trip Start tab in data table",
    defaultMessage: "Trip Start"
  },
  TripEnd: {
    id: `${namespace}.TripEnd`,
    description: "Trip End tab in data table",
    defaultMessage: "Trip End"
  },
  Start: {
    id: `${namespace}.Start`,
    description: "Card Start tab in data table",
    defaultMessage: "Start"
  },
  End: {
    id: `${namespace}.End`,
    description: "Card End tab in data table",
    defaultMessage: "End"
  },
  From: {
    id: `${namespace}.From`,
    description: "From tab in data table",
    defaultMessage: "From"
  },
  To: {
    id: `${namespace}.To`,
    description: "To tab in data table",
    defaultMessage: "To"
  },
  Distance: {
    id: `${namespace}.Distance`,
    description: "Distance tab in data table",
    defaultMessage: "Distance"
  },
  Duration: {
    id: `${namespace}.Duration`,
    description: "Duration tab in data table",
    defaultMessage: "Duration"
  },
  Details: {
    id: `${namespace}.Details`,
    description: "Details in data table",
    defaultMessage: "Details"
  },
  ShowActivityFor: {
    id: `${namespace}.ShowActivityFor`,
    description: "ShowActivityFor range title",
    defaultMessage: "Show Activity For"
  },
  NoChargeForCurrentMonth: {
    id: `${namespace}.NoChargeForCurrentMonth`,
    description: "NoChargeForCurrentMonth null state message",
    defaultMessage: "There are no charges for the selected period."
  },
  NoChargeToday: {
    id: `${namespace}.NoChargeToday`,
    description: "NoChargeToday null state message",
    defaultMessage: "You currently have no charges for today"
  },
  NoStatements: {
    id: `${namespace}.NoStatements`,
    description: "null state message on statements page",
    defaultMessage: "You currently have no statements."
  },
  location: {
    id: `${namespace}.location`,
    description: "Location table header",
    defaultMessage: "Location"
  },
  locations: {
    id: `${namespace}.locations`,
    description: "Location(s) table header",
    defaultMessage: "Location(s)"
  },
  applied: {
    id: `${namespace}.applied`,
    description: "Applied table header",
    defaultMessage: "Applied"
  },
  expires: {
    id: `${namespace}.expires`,
    description: "Expires table header",
    defaultMessage: "Expires"
  },
  type: {
    id: `${namespace}.type`,
    description: "Type table header",
    defaultMessage: "Type"
  },
  noFreeMinutes: {
    id: `${namespace}.noFreeMinutes`,
    description: "Show no free minutes message",
    defaultMessage:
      "You currently have no free minutes. Follow us on social or catch our street team out and about to score yourself some."
  },
  NoTripToday: {
    id: `${namespace}.NoTripToday`,
    description: "NoTripToday null state message",
    defaultMessage: "No trips have been taken."
  },
  NoAdjustmentsAndOtherCharges: {
    id: `${namespace}.NoAdjustmentsAndOtherCharges`,
    description: "NoAdjustmentsAndOtherCharges null state message",
    defaultMessage: "You currently have no adjustments or other charges. "
  },
  email: {
    id: `${namespace}.email`,
    description: "Email title",
    defaultMessage: "Email"
  },
  emailAddress: {
    id: `${namespace}.emailAddress`,
    description: "Email address title",
    defaultMessage: "Email Address"
  },
  emailSub: {
    id: `${namespace}.emailSub`,
    description: "Email sub-title",
    defaultMessage:
      "If you change your email, you will be asked to log back in on all devices for security."
  },
  promoCodeTitle: {
    id: `${namespace}.promoCodeTitle`,
    description: "Promo code title",
    defaultMessage: "Have a promo code? Enter it here.{br}{br}"
  },
  referAFriendTitleEvo: {
    id: `${namespace}.referAFriendTitle`,
    description: "Refer a friend title",
    defaultMessage:
      "{bold} If you have a refer-a-friend promo code, skip this step and enter it on your My Credits page after registering (before you submit your driver’s abstract)."
  },
  referAFriendTitleEvolve: {
    id: `${namespace}.referAFriendTitle`,
    description: "Refer a friend title",
    defaultMessage:
      "{bold} If you have a refer-a-friend promo code, skip this step and enter it on your My Credits page after registering."
  },
  businessNoteTitle: {
    id: `${namespace}.businessNoteTitle`,
    description: "Business Note title",
    defaultMessage: "{message}{br}{br}"
  },
  promoCodeSubtitle: {
    id: `${namespace}.promoCodeSubtitle`,
    description: "Promo Code sub title",
    defaultMessage: "Enter it here."
  },
  charges: {
    id: `${namespace}.charges`,
    description: "charges",
    defaultMessage: "Charges"
  },
  discounts: {
    id: `${namespace}.discounts`,
    description: "discounts",
    defaultMessage: "Discounts"
  },
  taxes: {
    id: `${namespace}.taxes`,
    description: "taxes",
    defaultMessage: "Taxes"
  },
  total: {
    id: `${namespace}.total`,
    description: "total",
    defaultMessage: "Total"
  },
  freeMinutes: {
    id: `${namespace}.freeMinutes`,
    description: "freeMinutes",
    defaultMessage: "Free minutes"
  },
  evoApp: {
    id: `${namespace}.evoApp`,
    description: "download evo app title",
    defaultMessage: "Evo App"
  },
  evoAppContent: {
    id: `${namespace}.evoAppContent`,
    description: "download evo app content",
    defaultMessage: "To find a car, open or download the Evo App"
  },
  evoStalled: {
    id: `${namespace}.evoStalled`,
    description: "404 page title",
    defaultMessage: "Evo's Stalled."
  },
  pageNotFound: {
    id: `${namespace}.pageNotFound`,
    description: "404 page sub title",
    defaultMessage: "The page you’re looking for can’t be found."
  },
  aboutBCAA: {
    id: `${namespace}.aboutBCAA`,
    description: "About BCAA title",
    defaultMessage: "About BCAA"
  },
  mediaOption: {
    id: `${namespace}.mediaOption`,
    description: "Media Option title",
    defaultMessage: "Media Option"
  },
  password: {
    id: `${namespace}.password`,
    description: "Password Title",
    defaultMessage: "Password"
  },
  oldPassword: {
    id: `${namespace}.oldPassword`,
    description: "Old Password Title",
    defaultMessage: "Old Password"
  },
  newPassword: {
    id: `${namespace}.newPassword`,
    description: "New Password Title",
    defaultMessage: "New Password"
  },
  confirmPassword: {
    id: `${namespace}.confirmPassword`,
    description: "Confirm Password Title",
    defaultMessage: "Confirm Password"
  },
  directionsAndInformation: {
    id: `${namespace}.directionsAndInformation`,
    description: "Directions & Information Title",
    defaultMessage: "Directions & Information"
  },
  cars: {
    id: `${namespace}.cars`,
    description: "Cars filter title",
    defaultMessage: "Cars"
  },
  parking: {
    id: `${namespace}.parking`,
    description: "Parking filter title",
    defaultMessage: "Parking"
  },
  homezone: {
    id: `${namespace}.homezone`,
    description: "Homezone filter title",
    defaultMessage: "Homezone"
  },
  filterBy: {
    id: `${namespace}.filterBy`,
    description: "Homezone filter title",
    defaultMessage: "Filter By:"
  },
  Month: {
    id: `${namespace}.Month`,
    description: "Month",
    defaultMessage: "Month"
  },
  Download: {
    id: `${namespace}.Download`,
    description: "Download",
    defaultMessage: "Download"
  },
  NoChargeEvolve: {
    id: `${namespace}.NoChargeEvolve`,
    description: "Message for there is no charge for Evolve",
    defaultMessage:
      "No surprises here! Review everything that’ll hit your card once you’re approved. Evo has no caps, no monthly fees, and good times are always included."
  },
  EvoEvolveIcon: {
    id: `${namespace}.EvoEvolveIcon`,
    description: "Let's hit the road",
    defaultMessage: "Let's hit the road"
  }
});

export default title;
