import { defineMessages } from "react-intl";

const namespace = "subscriptions";
const subscriptions = defineMessages({
  freeminutesEmail: {
    id: `${namespace}.freeminutesEmail`,
    description: "Free minutes subscription toggle",
    defaultMessage: "I want info on free minutes, special offers, events and Evo news by email"
  },
  freeminutesSms: {
    id: `${namespace}.freeminutesSms`,
    description: "Free minutes subscription toggle",
    defaultMessage: "I want info on free minutes, special offers, events and Evo news by SMS"
  },
  promotionsEmail: {
    id: `${namespace}.promotionsEmail`,
    description: "Promotions subscription toggle",
    defaultMessage: "I want info on promotions from BCAA by email"
  },
  disclaimer: {
    id: `${namespace}.disclaimer`,
    description: "Disclaimer text at bottom of page",
    defaultMessage:
      "*10% savings applies to driving rates within your club region and excludes all service, registration and annual car share operator fees. Must be a valid BCAA Member to qualify for discount."
  },
  vancouver: {
    id: `${namespace}.city.vancouver`,
    description: "City option label",
    defaultMessage: "Vancouver"
  },
  montreal: {
    id: `${namespace}.city.montreal`,
    description: "City option label",
    defaultMessage: "Montreal"
  },
  toronto: {
    id: `${namespace}.city.toronto`,
    description: "City option label",
    defaultMessage: "Toronto"
  },
  quebec: {
    id: `${namespace}.city.quebec`,
    description: "City option label",
    defaultMessage: "Quebec"
  },
  ontario: {
    id: `${namespace}.city.ontario`,
    description: "City option label",
    defaultMessage: "Ontario"
  }
});

export default subscriptions;
