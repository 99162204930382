import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import moment from "moment-timezone";
import InfiniteScroll from "react-infinite-scroller";
import sortBy from "lodash/sortBy";
import get from "lodash/get";

import { messages, constants } from "../../constants";
import * as selectors from "../../selectors";
import { Section, Grid, Col, InfoBlock, DataTable, Datepicker } from "../../components";
import { Title, Txt, Button } from "../../elements";
import { formatCurrency, mapPaymentStatus, formatDate as customFormatDate } from "../../util";
import { fetchPayments, resetPayments } from "../../actions/billing";
import { getJsonFromUrl } from "../../util";
import { loginWithToken } from "../../actions/auth";
import { getCustomerMembership } from "../../actions/user";
import "./index.scss";
import {reduxForm, Field, formValueSelector} from "redux-form";
import {validationBuilder, validations} from "../../containers/form";
const rules = {};

class BillingLanding extends PureComponent {
  state = {
    from: moment()
      .subtract(30, "days")
      .toDate(),
    to: moment().toDate(),
    currentPage: 1,
    isLoadingPayments: false,
    isMembershipLoaded: false
  };

  _isMounted = false;

  ruleDateFrom = [];
  ruleDateTo = [];

  componentDidMount() {
    this._isMounted = true;
    this.props.initialize({from:this.state.from, to:this.state.to})
    this.generateValidationRulesFromProps();
    const { loginWithToken, getCustomerMembership } = this.props;
    try {
      const queryObj = getJsonFromUrl(get(this.props, "location.search", ""));
      const { idToken, accessToken } = queryObj;
      if (idToken && accessToken) {
        loginWithToken({ idToken, accessToken });
      }
    } catch (error) {}
    getCustomerMembership().then(() => {
      this.setState({ isMembershipLoaded: true });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateFromDate = from => {
    const value = this.getValue(from);
    this.setState({ from:value });
  };

  updateToDate = to => {
    const value = this.getValue(to);
    this.setState({ to:value });
  };

  handleClickApplyPromoCode = () => {
    this.props.resetPayments();
    this.setState({ currentPage: 1 });
  };

  render() {
    const { isLoadingPayments, isMembershipLoaded } = this.state;
    const { intl, balance, payments, totalPage, user, valid } = this.props;

    let totalNonMemberSavings = 0;
    let totalMemberSavings = 0;

    const processPaymentObj = paymentArray =>
      paymentArray &&
      paymentArray.map(
        ({
          paymentId,
          timestamp,
          timeZone,
          status,
          amount,
          CAAIneligibleDiscountAmount,
          CAAMemberDiscountAmount,
          ...rest
        }) => {
          const date = timestamp ? moment.tz(timestamp, timeZone).format("MMM D, YYYY") : "-";
          const time = timestamp ? moment.tz(timestamp, timeZone).format("h:mm a z") : "pending";
          // TODO Localize

          if (!isNaN(CAAIneligibleDiscountAmount))
            totalNonMemberSavings += CAAIneligibleDiscountAmount;
          if (!isNaN(CAAMemberDiscountAmount)) totalMemberSavings += CAAMemberDiscountAmount;

          return {
            time,
            date,
            timestamp,
            amount: <b>{formatCurrency(amount)}</b>,
            status: mapPaymentStatus(status, intl),
            CAAIneligibleDiscountAmount,
            CAAMemberDiscountAmount,
            ...rest,
            details: (
              // pending payment does not have paymentId, thus assign it will a special link
              <Link
                to={`${constants.ROUTE_ACCOUNT_BILLING}/${paymentId || "pending"}`}
                className="details-block"
              >
                {intl.formatMessage(messages.title.Details)}
              </Link>
            )
          };
        }
      );

    // payments has property of `timestamp` instead of `time` and `date. We first need to process this data.
    const paymentsArray = processPaymentObj(payments);

    // Table related objects
    const columnNames = [
      { key: "date", title: intl.formatMessage(messages.title.Date) },
      { key: "time", title: intl.formatMessage(messages.title.billingTime) },
      { key: "cardType", title: intl.formatMessage(messages.title.cardType) },
      { key: "maskedCardNumber", title: intl.formatMessage(messages.title.cardNumber) },
      { key: "status", title: intl.formatMessage(messages.title.Status) },
      { key: "amount", title: intl.formatMessage(messages.title.Amount) },
      { key: "details", title: "" }
    ];

    const displayKeys = [
      "date",
      "time",
      "cardType",
      "maskedCardNumber",
      "status",
      "amount",
      "details"
    ];

    if (!balance) {
      return null;
    }

    return (
      <div>
        <Section>
          <Grid>
            <Col>
              <Title priority={1} type={["strong"]} className="h2">
                {intl.formatMessage(messages.header.billing)}
              </Title>
            </Col>
          </Grid>
          <div className="block-info__wrapper">
            <InfoBlock
              title={intl.formatMessage(messages.billing.outstandingBalance)}
              infoBody={formatCurrency(balance.outstandingBalance)}
              theme={["mobile-1-2", balance.outstandingBalance > 0 ? "warning" : "normal"]}
            />
            <InfoBlock
              title={intl.formatMessage(messages.billing.todaysCharges)}
              infoBody={formatCurrency(balance.currentUnbilledCharges)}
              theme={["mobile-1-2", "normal"]}
            />
            <InfoBlock
              title={intl.formatMessage(messages.billing.totalBalanceOwing)}
              infoBody={formatCurrency(balance.currentUnbilledCharges + balance.outstandingBalance)}
              theme={["normal"]}
            />
          </div>
        </Section>
        <Section>
          <Grid>
            <Col>
              <Title priority={1} type={["strong"]} className="h2">
                {intl.formatMessage(messages.header.todayTotal)}
              </Title>
              <Txt>{intl.formatMessage(messages.header.todayTotalSub)}</Txt>
              <DataTable
                columnNames={columnNames}
                collaspableColumns={["cardType", "time", "maskedCardNumber"]}
                displayKeys={displayKeys}
                data={paymentsArray.filter(
                  ({ timestamp, timeZone }) =>
                    !moment.tz(timestamp, timeZone).isBefore(moment().startOf("day")) || !timestamp
                )}
                singleRow
                nullStateTitle={intl.formatMessage(messages.title.NoChargeToday)}
              />
            </Col>
          </Grid>
        </Section>

        <Section>
          <Grid>
            <Col>
              <Title priority={1} type={["strong"]} className="h2">
                {intl.formatMessage(messages.header.PreviousPostings)}
              </Title>
              <Txt>
                <p>{intl.formatMessage(messages.header.PreviousPostingsSub)}</p>
              </Txt>
               <Grid theme={["small-div"]}>
                <Col size="1-4" min="sm">
                  <Txt>
                    <b>{`${intl.formatMessage(messages.title.ShowActivityFor)}:`}</b>
                  </Txt>
                </Col>
                <Col size="1-4" min="sm">

                    <Field

                      label={intl.formatMessage(messages.billing.fromDate)}
                      name="from"
                      locale={intl.locale}
                      validate={this.ruleDateFrom}
                      component={Datepicker}
                      initialMonth={ moment().subtract(30, "days").toDate()}
                      onPostChange={this.updateFromDate('from')}
                      disabledDays={{
                        before: moment('Jan 1, 2014').toDate(),
                        after: this.state.to
                      }}
                      className={'billingDate'}
                      dataTestId={'datepicker-container-from'}
                    />
                </Col>
                <Col size="1-4" min="sm">
                    <Field
                      label={intl.formatMessage(messages.billing.toDate)}
                      name="to"
                      component={Datepicker}
                      initialMonth={moment().toDate()}
                      validate={this.ruleDateTo}
                      onPostChange={this.updateToDate('to')}
                      locale={intl.locale}
                      disabledDays={{
                        before: this.state.from,
                        after: moment().toDate()
                      }}
                      className={'billingDate'}

                      dataTestId={'datepicker-container-to'}
                    />

                </Col>
                <Col size="1-4" min="sm">
                  <Button
                    loading={isLoadingPayments}
                    type="submit"
                    theme={["cta"]}
                    className="btn__date-range"
                    onClick={this.handleClickApplyPromoCode}
                    disabled={!valid}
                  >
                    {intl.formatMessage(messages.button.apply)}
                  </Button>
                </Col>
              </Grid>

              {isMembershipLoaded && (
                <Txt theme={["bold"]}>
                  {get(user, "membership.memberIsActive")
                    ? intl.formatMessage(messages.billing.totalMemberSavings, {
                        amount: formatCurrency(Math.abs(totalMemberSavings)).toString()
                      })
                    : intl.formatMessage(messages.billing.totalNonMemberSavings, {
                      a: (...chunks) => `<a href="https://www.bcaa.com/membership/join" target="_blank">${chunks.join('')}</a>`,
                      amount: formatCurrency(Math.abs(totalNonMemberSavings)).toString()
                    })
                  }
                </Txt>
              )}

              <InfiniteScroll
                threshold={100}
                loadMore={this.onLoadRange}
                hasMore={
                  (!totalPage && this.state.currentPage === 1) ||
                  this.state.currentPage <= totalPage
                }
              >
                <DataTable
                  columnNames={columnNames}
                  collaspableColumns={["cardType", "time", "maskedCardNumber"]}
                  displayKeys={displayKeys}
                  data={sortBy(paymentsArray, "timestamp")
                    .reverse()
                    .filter(({ timestamp, timeZone }) =>
                      moment.tz(timestamp, timeZone).isBefore(moment().startOf("day"))
                    )}
                  nullStateTitle={intl.formatMessage(messages.title.NoChargeForCurrentMonth)}
                />
              </InfiniteScroll>
            </Col>
          </Grid>
        </Section>
      </div>
    );
  }
  /**
   *
   * Gets the selected field value
   * @memberof Drivers
   * @param {string} name
   * @returns {object}
   */
  getValue = name => {
    const { state, form } = this.props;
    const selector = formValueSelector(form);
    return selector(state, `${name}`);
  };

  onLoadRange = () => {
    const { from, to, currentPage } = this.state;
    const loadPage = currentPage;

    const fromDate = from !== 'Invalid date' ? from : moment().toDate();
    const toDate = to !== 'Invalid date' ? to: moment().toDate();

    this.setState({ isLoadingPayments: true, currentPage: currentPage + 1 }, async () => {
      try {
        await this.props.fetchPayments({
          from: customFormatDate(fromDate),
          to: customFormatDate(toDate),
          page: loadPage
        });
      } catch (error) {
        console.warn(error);
      }
      // preventing setting state to unmounted component
      if (this._isMounted) {
        this.setState({ isLoadingPayments: false });
      }
    });
  };


  generateValidationRulesFromProps = () => {
    const props = this.props;

    Object.keys(validations).forEach(key => {
      rules[key] = spec => (value, allValues) => validationBuilder(props, validations[key], spec)(value, allValues);
    });

    const { intl } = props;

    this.ruleDateFrom = [
      rules.isValidDate(intl.formatMessage(messages.error.invalidDate)),
      rules.isNotBeforeDate(intl.formatMessage(messages.error.isNotBeforeDate)),
      rules.isAfterDate(moment('Jan 1, 2014').toDate()),
      rules.isBeforeDate(moment().toDate())

    ];
    this.ruleDateTo = [
      rules.isValidDate(intl.formatMessage(messages.error.invalidDate)),
      rules.isNotAfterDate(intl.formatMessage(messages.error.isNotAfterDate)),
      rules.isAfterDate(moment('Jan 1, 2014').toDate()),
      rules.isBeforeDate(moment().toDate())
    ];
  };
}

BillingLanding.defaultProps = {
  payments: [],
  balance: {
    lastPaymentDate: null,
    lastPaymentAmount: 0,
    outstandingBalance: 0,
    currentUnbilledCharges: 0
  },
  currentPage: 1
};

BillingLanding.propTypes = {
  intl: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  balance: PropTypes.object,
  totalPage: PropTypes.number,
  payments: PropTypes.arrayOf(PropTypes.object),
  fetchPayments: PropTypes.func.isRequired,
  resetPayments: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  state: state,
  user: selectors.getUser(state),
  balance: selectors.getBilling(state).balance,
  payments: selectors.getBilling(state).payments.results,
  totalPage: selectors.getBilling(state).payments.totalPages,
  billing: selectors.getBilling(state)
});

const mapDispatchToProps = dispatch => ({
  fetchPayments: bindActionCreators(fetchPayments, dispatch),
  resetPayments: bindActionCreators(resetPayments, dispatch),
  loginWithToken: bindActionCreators(loginWithToken, dispatch),
  getCustomerMembership: bindActionCreators(getCustomerMembership, dispatch)
});

export const IntlBillingLanding = injectIntl(BillingLanding);

const formSettings = {
  form: constants.FORM.SearchBillingPaymentsForm,
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true
};

const enhancer = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm(formSettings)
);
export default enhancer(BillingLanding);
