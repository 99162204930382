import React from "react";
import { IntlProvider } from "react-intl";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import moment from "moment";

import localeData from "../constants/data";

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/locale-data/en'); // Add locale data for de
}

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/locale-data/fr'); // Add locale data for de
}

const LocaleRoot = ({ children, locale }) => {
  const messages = localeData[locale.lang] || localeData.en;
  // set moment localization
  moment.locale(locale.lang);

  return (
    <IntlProvider locale={locale.lang} messages={messages}>
      {children}
    </IntlProvider>
  );
};

LocaleRoot.propTypes = {
  locale: PropTypes.shape({
    lang: PropTypes.string
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string
  ])
};

const mapStateToProps = ({ locale }) => ({ locale });

export default connect(mapStateToProps)(LocaleRoot);
