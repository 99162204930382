import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import  {DatePickerInput} from 'rc-datepicker';

import cn from "classnames";
import "rc-datepicker/lib/style.css"
import { Txt } from "../../elements";
import "./datepicker.scss";
import moment from 'moment-timezone'

class Datepicker extends PureComponent {
  static propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    disabledDays: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.instanceOf(Date))
    ]),
    locale: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    initialMonth: PropTypes.instanceOf(Date),
    className: PropTypes.string,
    dataTestId: PropTypes.string
  };

  static defaultProps = {
    disabled: false,
    disabledDays: [],
    locale: "en",
    label: "",
    required: false,
    initialMonth: undefined,
    className: '',
    dataTestId: 'datepicker-input'
  };

  datepickerInput = React.createRef();

  render() {
    const {
      input,
      meta,
      disabled,
      disabledDays,
      locale,
      label,
      required,
      initialMonth,
      className,
      dataTestId
    } = this.props;
    const { value, name, onChange } = input;
    const { touched, error, warning, valid, invalid, active } = meta;
    const txt = `${label} (MM/DD/YYYY)${required ? " *" : ""}`;
    let errorTheme = ["small"];
    errorTheme.push("error");

    const inputCSS = cn(`input datepicker${className ? ' ' +className : ''}`, {
      "is-valid": touched && valid,
      "is-invalid": touched && invalid,
      "is-warning": touched && warning,
      "is-focused": active
    });

    return (
      <fieldset data-testid="component-datepicker" className={inputCSS} >
        <div className="input-field" data-testid={dataTestId}>
          <DatePickerInput
            ref={this.datepickerInput}
            id={name}
            locale={locale}
            disabled={disabled}
            onChange={onChange}
            displayFormat='MM/DD/YYYY'
            validationFormat='MM/DD/YYYY'
            placeholder={txt}
            className = {value === 'Invalid date' || !(moment(value).isValid())  ? 'placeholder-shown': null}
            iconClassName='fa fa-calendar'
            value={value === 'Invalid date'? null : value}
            startDate={initialMonth}
            minDate={disabledDays.before}
            maxDate={disabledDays.after}
            name={name}
            onBlur={this.handleBlur}
            showOnInputClick={true}
          />
          <label htmlFor={name}  className = {value === 'Invalid date' || !(moment(value).isValid()) ? 'placeholder-shown': null}>
            {txt}
          </label>
          {touched && error && (
            <Txt theme={errorTheme}>
            <small>{error}</small>
            </Txt>
            )}
        </div>
      </fieldset>
    );
  }

  handleBlur = () => {
    // calling it manully to avoid onBlur triggering onChange
    this.props.input.onBlur();
  };
}

export default Datepicker;
