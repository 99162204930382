import { createStore, compose, applyMiddleware } from "redux";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
// 'routerMiddleware': the new way of storing route changes with redux middleware since rrV4.
import { routerMiddleware } from "connected-react-router";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createRootReducer from "../reducers";

const persistConfig = {
  key: "persistor",
  blacklist: ["router", "banner", "adyen", "moneris", "form", "flow"],
  storage
};
const history = createBrowserHistory();
const persistedReducer = persistReducer(persistConfig, createRootReducer(history));

// Prepare middleware list

const middlewares = [
  // thunk middleware can also accept an extra argument to be passed to each thunk action
  // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
  thunk,
  routerMiddleware(history)
];

if (process.env.NODE_ENV !== "production") {
  // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
  middlewares.push(reduxImmutableStateInvariant());
}

function configureStoreProd(initialState) {
  return createStore(persistedReducer, initialState, compose(applyMiddleware(...middlewares)));
}

function configureStoreDev(initialState) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("../reducers", () => {
      store.replaceReducer(createRootReducer(history));
    });
  }

  return store;
}

// Group of export variables
const configureStore =
  process.env.NODE_ENV === "production" ? configureStoreProd : configureStoreDev;
const store = configureStore();

// Persist Store
const persistWrapper = store => persistStore(store);
const persistor = persistWrapper(store);

export { store, persistor, history };
