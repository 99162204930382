import { defineMessages } from "react-intl";

const namespace = `evoCard`;
const evoCard = defineMessages({
  evoCard: {
    id: `${namespace}.evoCard`,
    description: "Evo Card field",
    defaultMessage: "Send me an Evo Membership card"
  },
  evoCardNote1: {
    id: `${namespace}.evoCardNote1`,
    description: "Note under Evo Card Checkbox field",
    defaultMessage:
      "If you need to access cars without the app, you can request an Evo Membership card by emailing us at {link}"
  },
  evoCardNote2: {
    id: `${namespace}.evoCardNote2`,
    description: "Note under Evo Card Checkbox field",
    defaultMessage:
      "As part of our sustainability efforts, Evo Membership cards are only sent on request."
  }
});

export default evoCard;
