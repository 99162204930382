import { defineMessages } from "react-intl";

const namespace = `billing`;
const billing = defineMessages({
  outstandingBalance: {
    id: `${namespace}.outstandingBalance`,
    description: "Outstanding Info Block Title",
    defaultMessage: "Outstanding Balance"
  },
  outstandingBalanceDescription: {
    id: `${namespace}.outstandingBalanceDescription`,
    description: "Outstanding balance description",
    defaultMessage: "Amount carried from {prevDate}. Credit card verification failed."
  },
  refund: {
    id: `${namespace}.refund`,
    description: "Other charges refund type",
    defaultMessage: "Refund"
  },
  adjustment: {
    id: `${namespace}.adjustment`,
    description: "Other charges adjustment type",
    defaultMessage: "Adjustment"
  },
  todaysCharges: {
    id: `${namespace}.todaysCharges`,
    description: "Today's Charges Info Block Title",
    defaultMessage: "Today's Charges"
  },
  totalBalanceOwing: {
    id: `${namespace}.totalBalanceOwing`,
    description: "Total Balance Owing Info Block Title",
    defaultMessage: "Total Balance Owing"
  },
  detailSummaryDescription: {
    id: `${namespace}.detailSummaryDescription`,
    description: "Description in billing landing page",
    defaultMessage:
      "This is the summary for your selected day. View trip details and other charges below."
  },
  totalCostSummaryDescription: {
    id: `${namespace}.totalCostSummaryDescription`,
    description: "Description in billing details page",
    defaultMessage: "Total Cost of Your Trips"
  },
  totalCostSummaryDescriptionTooltip: {
    id: `${namespace}.totalCostSummaryDescriptionTooltip`,
    description: "Description tooltip in billing details page",
    defaultMessage: "For more billing details, click on the ‘details’ icon next to each trip"
  },
  adjustmentAndOtherChargesDescription: {
    id: `${namespace}.adjustmentAndOtherChargesDescription`,
    description: "Description in billing details page",
    defaultMessage: "Adjustments and Other Charges"
  },
  adjustmentAndOtherChargesDescriptionTooltip: {
    id: `${namespace}.adjustmentAndOtherChargesDescriptionTooltip`,
    description: "Description tooltip in billing details page",
    defaultMessage: "These are your refunds or other fees charged to your account on this day."
  },
  pending: {
    id: `${namespace}.pending`,
    description: "payment is pending",
    defaultMessage: "Pending"
  },
  approved: {
    id: `${namespace}.approved`,
    description: "payment is approved",
    defaultMessage: "Approved"
  },
  failed: {
    id: `${namespace}.failed`,
    description: "payment is failed",
    defaultMessage: "Failed"
  },
  totalMemberSavings: {
    id: `${namespace}.totalMemberSavings`,
    defaultMessage: "Just for being a BCAA Member, you saved {amount} in this period."
  },
  totalNonMemberSavings: {
    id: `${namespace}.totalNonMemberSavings`,
    defaultMessage:
      "BCAA Members save 10%. You could have saved {amount} in this period. Click <a>here</a> to sign up as a BCAA Member. "
  },
  fromDate:{
    id: `${namespace}.fromDate`,
    defaultMessage: "From"
  },
  toDate:{
    id: `${namespace}.toDate`,
    defaultMessage: "To"
  }
});

export default billing;
