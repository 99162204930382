import { messages } from "../../constants";

const fn = (intl) => [
  { value: "AB", label: intl.formatMessage(messages.provinces.AB) },
  { value: "BC", label: intl.formatMessage(messages.provinces.BC) },
  { value: "MB", label: intl.formatMessage(messages.provinces.MB) },
  { value: "NB", label: intl.formatMessage(messages.provinces.NB) },
  { value: "NL", label: intl.formatMessage(messages.provinces.NL) },
  { value: "NS", label: intl.formatMessage(messages.provinces.NS) },
  { value: "ON", label: intl.formatMessage(messages.provinces.ON) },
  { value: "PE", label: intl.formatMessage(messages.provinces.PE) },
  { value: "QC", label: intl.formatMessage(messages.provinces.QC) },
  { value: "SK", label: intl.formatMessage(messages.provinces.SK) },
  { value: "NT", label: intl.formatMessage(messages.provinces.NT) },
  { value: "YT", label: intl.formatMessage(messages.provinces.YT) },
  { value: "NU", label: intl.formatMessage(messages.provinces.NU) }
];

export default fn;
