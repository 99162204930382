import React from "react";
import { PagesContainer } from "../../containers";
import { NewRegistration } from "../../screens";

const Registration = props => {
  return (
    <PagesContainer {...props}>
      <NewRegistration {...props} />
    </PagesContainer>
  );
};

Registration.defaultProps = {};

export default Registration;
