// Set up your root reducer here...
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as formReducer } from "redux-form";

// import register from './register';
import banner from "./banner";
import billing from "./billing";
import statement from "./statement";
import flow from "./flow";
import registration from "./registration";
import forgotPassword from "./forgotPassword";
import locale from "./locale";
import adyen from "./adyen";
import moneris from "./moneris";
import page from "./page";
import regions from "./regions";
import settings from "./settings";
import user from "./user";
import map from "./map";

const fn = (history) =>
  combineReducers({
    router: connectRouter(history),
    banner,
    billing,
    statement,
    flow,
    registration,
    forgotPassword,
    form: formReducer,
    locale,
    adyen,
    moneris,
    page,
    regions,
    settings,
    user,
    map
  });

export default fn;
