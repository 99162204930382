import { messages } from "../../constants";
import moment from "moment";

/**
 * validationBuilder - build a validation function,
 * which returns dynamic/localized error message if value is invalid
 *
 * @param {object} config - Must have react intl prop for localizing error message
 * @param {object} rule - A rule that validates value
 * @param {spec} string - Extra string added to error message
 * @return {func} validation function
 */
export const validationBuilder = (config, rule, extra, overrideErrorMessage=false) => (value, allValues, props) => {
  const spec = extra || "";
  const invalid = rule.validation(value, spec, allValues);
  const intl = config.intl;
  if(overrideErrorMessage){
    return invalid ? spec : null;
  }
  return invalid ? intl.formatMessage(rule.message, {spec}) : null;
};

export const required = {
  validation: value => !value,
  message: messages.error.required
};

export const requiredConfirmEmail = {
  validation: value => !value,
  message: messages.error.confirmEmail
};

export const requiredRadio = {
  validation: value => !value,
  message: messages.error.requiredRadio
};

export const names = {
  validation: (value) => {
    // if name is undefined or empty string
    if ( !value || (value && value === "")) {
      return false;
    }
    // otherwise match name to regular expression or display error message
    else {
      return !value.match(/^[a-z ,.'-]+$/i);
    }
  }, message: messages.error.invalidFormat
};

export const pronounNames = {
  validation: (value) => {
    // if name is undefined or empty string
    if ( !value || (value && value === "")) {
      return false;
    }
    // otherwise match name to regular expression or display error message
    else {
      return !value.match(/^[a-z ,.'-/]+$/i);
    }
  }, message: messages.error.invalidPronounFormat
};

export const alphanumeric = {
  validation: value => value ? !value.match(/^[a-zA-Z0-9]+$/) : value, message: messages.error.alphanumeric
};

export const email = {
  validation: value =>
    value
      ? !value.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
      : !value, // eslint-disable-line
  message: messages.error.email
};

export const emailsMatch = {
  validation: (value, x, allValues) => {
    if (value !== allValues.personalInfo.emailAddress)
      return true;
    return false;
  },
  message: messages.error.confirmEmail
};

export const password = {
  // return true when there is error
  validation: value =>
    shouldHaveSpecialChar.validation(value) ||
    shouldHaveNumber.validation(value) ||
    shouldHaveUpper.validation(value) ||
    shouldHaveLower.validation(value) ||
    shouldHave8Chars.validation(value) ||
    shouldNotHaveSpaces.validation(value),
  message: messages.error.password
};

export const phone = {
  // validation: value => value && (!parse(value).country || !parse(value).phone),
  validation: value => (value ? !value.match(/^\+?[0-9]*[0-9 ]{8,14}$/) : !value),
  message: messages.error.phone
};

export const shouldHaveSpecialChar = {
  validation: value => (value ? !value.match(/[\W_]/) : !value),
  message: messages.error.shouldHaveSpecialChar
};

export const shouldHaveNumber = {
  validation: value => (value ? !value.match(/[\d]/) : !value),
  message: messages.error.shouldHaveNumber
};

export const shouldHaveUpper = {
  validation: value => (value ? !value.match(/[A-Z]/) : !value),
  message: messages.error.shouldHaveUpper
};

export const shouldHaveLower = {
  validation: value => (value ? !value.match(/[a-z]/) : !value),
  message: messages.error.shouldHaveLower
};

export const shouldHave3Numbers = {
  validation: value => (value ? value.length < 3 : !value),
  message: messages.error.shouldHave3Numbers
};

export const shouldHave8Chars = {
  validation: value => (value ? value.length < 8 : !value),
  message: messages.error.shouldHave8Chars
};

export const shouldHave13Numbers = {
  validation: value => (value ? value.replace(/\s/g,'').length < 13 : !value),
  message: messages.error.shouldHave13Numbers
};

export const shouldNotHaveSpaces = {
  validation: value => (value ? value.match(/\s/g): !value),
  message: messages.error.shouldNotHaveSpaces
};

export const numberOnly = {
  validation: value => (value ? !`${value}`.match(/^[\d]+$/) : !value),
  message: messages.error.numberOnly
};

export const yearFormat = {
  validation: value => (value ? !`${value}`.match(/^[\d]{4}$/) : !value),
  message: messages.error.yearFormat
};

export const monthYearRange = {
  validation: value => {
    if(value && value.length < 4){
      return value.length < 4;
    }
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    const month =  parseInt(value.substring(0,2));
    const year = parseInt(value.substring(2, 4));
   const currentCCYear = currentYear.toString().substr(-2);
    const futureDate = new Date(currentYear+10, currentMonth, currentDay);
   const futureYear = futureDate.getFullYear().toString().substr(-2);
    return (month < 1 || month > 12) || (year < currentCCYear  || year > futureYear);
    },
  message: messages.error.monthYearFormat
};

//@TODO: check the allowed range
export const yearRange = {
  validation: value => (value ? value < 1920 || value > new Date().getFullYear() : !value),
  message: messages.error.yearRange
};

export const isOverAge = {
  validation: (value, spec) => {
    const age = moment().diff(moment(value).startOf("date").add("1", "hour"), 'years');
    return !((age - spec) >= 0);
  },
  message: messages.error.isOverAge
};

export const isValidDate = {
  validation: value => {
    return value ? isNaN((new Date(value)).getTime()) : !value;
  },
  message: messages.error.invalidDate
};

export const isFuture = {
  validation: value => {
    return value ? new Date(value) < new Date().getTime() : !value;
  },
  message: messages.error.isFuture
};

// Canadian Postal Code (use Zip Code for US validation)
export const postalCode = {
  validation: (value) =>
    value
      ? !value.match(/^(?!.*[DFIOQUdfioqu])[A-VXYa-vxy][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$/)
      : false,
  message: messages.error.postalCode
};

export const zipCode = {
  validation: (value) => (value ? !value.match(/^[0-9]{5}(?:-[0-9]{4})?$/) : false),
  message: messages.error.zipCode
};

export const memberNumber = {
  validation: value => (value ? !value.match(/^(\d{16})$/) : !value),
  message: messages.error.memberNumber
};


export const isBeforeDate = {
  validation: (value, spec) => {
    const isBefore = moment(value).isBefore(spec);
    return !isBefore;
  },
  message: messages.error.isBeforeDate
};

export const isAfterDate = {
  validation: (value, spec) => {
    const isAfter = moment(value).isAfter(spec);
    return !isAfter;
  },
  message: messages.error.isAfterDate
};

//Custom to Account Billing Search

export const isNotAfterDate = {
  validation: (value, spec, allValues) => {
    const isAfter = moment(value).isAfter(allValues.from);
    return !isAfter;
  },
  message: messages.error.isNotAfterDate
};

export const isNotBeforeDate = {
  validation: (value, spec, allValues) => {
    const isBefore = moment(value).isBefore(allValues.to);
    return !isBefore;
  },
  message: messages.error.isNotBeforeDate
};


