import { requestGenerator, checkStatus, parseJson, getUserId } from "./customer";

export const fetchParkings = () => {
  const url = `${process.env.REACT_APP_PROPEL_SERVICE_URL}/fleet-service/parking/spots`;

  return requestGenerator(url, "GET").then(checkStatus).then(parseJson);
};

export const fetchHomezone = () => {
  const url = `${process.env.REACT_APP_PROPEL_SERVICE_URL}/fleet-service/member/homezones`;

  return requestGenerator(url, "GET").then(checkStatus).then(parseJson);
};

export const fetchCars = async () => {
  const url = `${process.env.REACT_APP_PROPEL_SERVICE_URL}/vehicle-service/member/reservation/vehicle/available`;

  return requestGenerator(url, "GET").then(checkStatus).then(parseJson);
};

export const fetchReservation = async () => {
  const userId = await getUserId();
  const url = `${process.env.REACT_APP_PROPEL_SERVICE_URL}/vehicle-service/member/reservation?customerId=${userId}`;
  return requestGenerator(url, "GET").then((response) => {
    const { status } = response;
    if (status === 404) {
      return null;
    } else {
      return parseJson(checkStatus(response));
    }
  });
};

export const bookACar = async (vin) => {
  const customerId = await getUserId();
  const url = `${process.env.REACT_APP_PROPEL_SERVICE_URL}/vehicle-service/member/reservation/`;

  return requestGenerator(url, "POST", { customerId, vin }).then(checkStatus);
};

export const cancelCarBooking = async (vin) => {
  const reservation = await fetchReservation();
  const url = `${process.env.REACT_APP_PROPEL_SERVICE_URL}/vehicle-service/member/reservation/${reservation.reservationId}`;

  return requestGenerator(url, "DELETE").then(checkStatus);
};
