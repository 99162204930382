/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from "react";
import { formValueSelector } from "redux-form";
import { injectIntl } from "react-intl";

import PropTypes from "prop-types";

import { FormSectionWrapper } from "../";

import {messages} from "../../../../constants";
import DriversLicenseRequirements from "../../fieldGroups/driversLicenseRequirements/driversLicenseRequirements";

import selfieIcon from "../../../../assets/img/selfieIcon_small.png";
import {bindActionCreators} from "redux";
import {flow} from "../../../../actions";
import {connect} from "react-redux";
import CaptureImageWrapper from "../../fieldGroups/imageCapture/captureImageWrapper";

class IdentityVerificationSelfie extends PureComponent {
  // function to get the selected field value
  getValue = name => {
    const { state, form } = this.props;
    const selector = formValueSelector(form);
    return selector(state, name);
  };



  render() {
      const { intl, handleSelfieValidate } = this.props;
    return (

        <FormSectionWrapper {...this.props}>
          <CaptureImageWrapper
            {...this.props}
            upperImageCaption={intl.formatMessage(messages.imageCapture.selfie)}
            orientation={intl.formatMessage(messages.imageCapture.portrait)}
            icon={selfieIcon}
            handleValidate={handleSelfieValidate}
            isSelfie={true}
            isBack={false}
          />
          <DriversLicenseRequirements {...this.props}/>
        </FormSectionWrapper>

    );
  }
}

IdentityVerificationSelfie.propTypes = {
  intl: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  actions: PropTypes.objectOf(PropTypes.func),
  handleSelfieValidate: PropTypes.func.isRequired
};


const mapDispatchToProps = (dispatch) => ({
  handleSelfieValidate: bindActionCreators(flow.handleSelfie, dispatch)
});

export default injectIntl(connect(null,mapDispatchToProps)(IdentityVerificationSelfie));
