import React, { PureComponent } from "react";
import { Field, formValueSelector } from "redux-form";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Input, Dropdown, DependOn, Grid, Col, Section } from "../../../../components";
import { messages } from "../../../../constants";
import { validationBuilder, validations, normalizers, formatters } from "../..";
import { compose } from "redux";

let rules = {};
class Address extends PureComponent {
  constructor(props) {
    super(props);

    // for field level validations
    // TODO: find a better way to instantiate props when the component mounts
    Object.keys(validations).forEach((key) => {
      rules[key] = (spec) => (value) => validationBuilder(props, validations[key], spec)(value);
    });

    const { intl, type, prefix } = props;
    this.isPersonalPage = prefix === "address";
    this.ruleAddress1 = [rules.required(type)];
    this.ruleCity = [rules.required(intl.formatMessage(messages.address.city))];
    this.ruleCountry = [rules.required(intl.formatMessage(messages.address.country))];
    this.ruleProvince = [rules.required(intl.formatMessage(messages.address.province))];
    this.ruleState = [rules.required(intl.formatMessage(messages.address.state))];
    this.rulePostalCode = this.isPersonalPage
      ? [rules.postalCode()]
      : [rules.required(intl.formatMessage(messages.address.postalCode)), rules.postalCode()];
    this.ruleZipCode = this.isPersonalPage
      ? [rules.zipCode()]
      : [rules.required(intl.formatMessage(messages.address.zipCode)), rules.zipCode()];

    this.handleDefaultAddress();
  }

  getRawValue = (name) => {
    const { state, form } = this.props;
    const selector = formValueSelector(form);
    return selector(state, name);
  };

  handleDefaultAddress = () => {
    if (this.getRawValue(this.props.prefix + ".city")) return;
    const city = this.getRawValue("personalInfo.address.city");
    const country = this.getRawValue("personalInfo.address.country");
    const province = this.getRawValue("personalInfo.address.province");
    const state = this.getRawValue("personalInfo.address.state");
    const postalCode = this.getRawValue("personalInfo.address.postalCode");
    const zipCode = this.getRawValue("personalInfo.address.zipCode");
    if (city) this.changeValue(this.props.prefix + ".city", city);
    if (country) this.changeValue(this.props.prefix + ".country", country);
    if (province) this.changeValue(this.props.prefix + ".province", province);
    if (state) this.changeValue(this.props.prefix + ".state", state);
    if (postalCode) this.changeValue(this.props.prefix + ".postalCode", postalCode);
    if (zipCode) this.changeValue(this.props.prefix + ".zipCode", zipCode);
  };

  resetProvinceAndState = (prevCountry, newCountry) => {
    if (prevCountry !== newCountry) {
      this.changeValue(this.props.prefix + ".province", "");
      this.changeValue(this.props.prefix + ".state", "");
      this.changeValue(this.props.prefix + ".postalCode", "");
      this.changeValue(this.props.prefix + ".zipCode", "");
    }
  };

  ruleAddress1 = [];
  ruleCity = [];
  ruleCountry = [];
  ruleProvince = [];
  ruleState = [];
  rulePostalCode = [];
  ruleZipCode = [];

  // function to get the selected field value
  getValue = (name) => {
    const { state, form, section } = this.props;
    const selector = formValueSelector(form);

    return selector(state, `${section}.${name}`);
  };

  // TODO: this is causing a warning
  // Warning: Cannot update during an existing state transition (such as within `render` or another component's constructor). Render methods should be a pure function of props and state; constructor side-effects are an anti-pattern, but can be moved to `componentWillMount`.
  changeValue = (name, newvalue, options) => {
    const {
      actions: { changeFieldValue }
    } = this.props;
    changeFieldValue({ ...this.props, name, newvalue, options });
  };

  render() {
    const { intl, theme, type, disabled, dropdownOptions, prefix } = this.props;
    // this.handleDefaultAddress();
    const selectedCountry = this.getValue(prefix + ".country");
    return (
      <>
        {!this.isPersonalPage && (
          <Section size="2-3" min="md" theme={theme}>
            <Grid>
              <Col size="1-4" min="sm">
                <Field
                  type="text"
                  name={prefix + ".unit"}
                  label={intl.formatMessage(messages.address.unitNumber)}
                  disabled={disabled}
                  component={Input}
                  props={{ maxLength: "45" }}
                />
              </Col>
              <Col size="3-4" min="sm">
                <Field
                  type="text"
                  name={prefix + ".addressLine"}
                  label={intl.formatMessage(messages.address.addressLine, { type })}
                  required={true}
                  disabled={disabled}
                  validate={this.ruleAddress1}
                  component={Input}
                  props={{ maxLength: "100" }}
                />
              </Col>
            </Grid>
          </Section>
        )}
        <Section size="2-3" min="md" theme={theme}>
          <Grid>
            <Col size="1-2" min="sm">
              <Field
                type="text"
                name={prefix + ".city"}
                label={intl.formatMessage(messages.address.city)}
                required={true}
                disabled={disabled}
                validate={this.ruleCity}
                component={Input}
                props={{ maxLength: "45" }}
              />
            </Col>
          </Grid>

          <Grid>
            <Col size="1-2" min="sm">
              <Field
                name={prefix + ".country"}
                label={intl.formatMessage(messages.address.country)}
                required={true}
                disabled={disabled}
                validate={this.ruleCountry}
                options={dropdownOptions.countries}
                component={Dropdown}
                postChange={this.resetProvinceAndState}
              />
            </Col>

            <Col size="1-2" min="sm">
              <DependOn active={selectedCountry === "CAN"}>
                <Field
                  name={prefix + ".province"}
                  label={intl.formatMessage(messages.address.province)}
                  required={true}
                  disabled={disabled}
                  validate={this.ruleProvince}
                  options={dropdownOptions.provinces}
                  component={Dropdown}
                />
              </DependOn>

              <DependOn active={selectedCountry === "USA"}>
                <Field
                  name={prefix + ".state"}
                  label={intl.formatMessage(messages.address.state)}
                  required={true}
                  disabled={disabled}
                  validate={this.ruleState}
                  options={dropdownOptions.states}
                  component={Dropdown}
                />
              </DependOn>
            </Col>

            <Col size="1-4" min="sm">
              <DependOn active={selectedCountry === "CAN"}>
                <Field
                  type="text"
                  name={prefix + ".postalCode"}
                  label={intl.formatMessage(messages.address.postalCode)}
                  required={this.isPersonalPage ? false : true}
                  disabled={disabled}
                  validate={this.rulePostalCode}
                  format={formatters.postalCode}
                  normalize={compose(normalizers.removeSpaces, normalizers.toUpperCase)}
                  asyoutype={true}
                  component={Input}
                  props={{ maxLength: "45" }}
                />
              </DependOn>

              <DependOn active={selectedCountry === "USA"}>
                <Field
                  type="text"
                  name={prefix + ".zipCode"}
                  label={intl.formatMessage(messages.address.zipCode)}
                  required={this.isPersonalPage ? false : true}
                  disabled={disabled}
                  validate={this.ruleZipCode}
                  component={Input}
                  normalize={compose(normalizers.removeSpaces, normalizers.toUpperCase)}
                  props={{ maxLength: "45" }}
                />
              </DependOn>
            </Col>
          </Grid>
        </Section>
      </>
    );
  }
}

Address.propTypes = {
  theme: PropTypes.array,
  intl: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  section: PropTypes.string.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  type: PropTypes.string,
  prefix: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  dropdownOptions: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.any }))
  )
};

Address.defaultProps = {
  disabled: false
};

const mapStateToProps = state => ({
  dropdownOptions: state.settings.dropdownOptions
});

export default connect(mapStateToProps)(injectIntl(Address));
