import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {formValueSelector} from "redux-form";

import {Txt} from "../../../../elements";
import {Section, Grid, Col, DependOn, Card} from "../../../../components";
import {messages} from "../../../../constants";
import cn from "classnames";

import {FormSectionWrapper} from "../";
import evoRecords from "../../../../assets/img/evo_records_10_2023.png";
import DataCell from "../../../../components/card/dataCell";

import {isIOS} from 'react-device-detect';
import "./requestDrivingRecord.scss";

const RequestDrivingRecord = props => {

  const copyToClipboard = async () => {
    const textToCopy = props.intl.formatMessage(messages.record.bcEVOEmailAddress);
    await navigator.clipboard.writeText(textToCopy);
  }

 // const province = props.dlIsMailingAddress ? props.dlSecondaryProvince : props.dlPrimaryProvince
  const isBCLicense = props.dlPrimaryProvince === "BC";
 // const content = isBCLicense ? messages.record.bcContent : messages.record.nonBcContent;
  const accountLinkCss = cn({"btn btn--cta": !isBCLicense});
  window.scrollTo(0, 0);

  return (
    <FormSectionWrapper {...props}>
      <Section size="1-2" min="md" theme={["div"]}>
        <Grid>
          <Col>
            <Txt><div>{props.intl.formatMessage(messages.record.almostDone,{newLine:<br />})}</div></Txt>
            <br/>
            <DependOn active={isBCLicense === false}>
            <DependOn active={isIOS}>
            <Txt>
              <div>
                {props.intl.formatMessage(messages.record.nonBcContent,{bullets:<ul><li><b>Please contact the driving authority from your licensing province or country.</b>  Evo accepts certified translations (sorry, Google Translate doesn't count)</li><li><b>Ask them to email or fax a copy of your 2-year driving record translated in English to</b> <a href='mailto:records@evo.ca'>records@evo.ca</a>. Check this <a href="https://evo.ca/faq#i-have-a-licence-that-was-not-issued-in-bc-how-can-i-obtain-my-driving-record">handy tips sheet</a> or you can contact the agency that issued your licence, or call Evo for help.</li></ul>, newLine: <br />})}
              </div>
            </Txt>
            </DependOn>
            <DependOn active={isIOS===false}>
              <Txt>
                <div>
                  {props.intl.formatMessage(messages.record.nonBcContent,{bullets:<ul><li><b>Please contact the driving authority from your licensing province or country.</b>  Evo accepts certified translations (sorry, Google Translate doesn't count)</li><li><b>Ask them to email or fax a copy of your 2-year driving record translated in English to</b> <a href='mailto:records@evo.ca'>records@evo.ca</a>. Check this <a href="https://evo.ca/faq#i-have-a-licence-that-was-not-issued-in-bc-how-can-i-obtain-my-driving-record">handy tips sheet</a> or you can contact the agency that issued your licence, or call Evo for help.</li></ul>, newLine: <br />})}
                </div>
              </Txt>
            </DependOn>
            </DependOn>
            <DependOn active={isBCLicense === true}>
              <DependOn active={isIOS}>
                <Txt>
                  <div>

                      {props.intl.formatMessage(messages.record.bcContent,{newLine: <br />, link: <a href='https://onlinebusiness.icbc.com/clio/' className='primary' target='_blank' rel='noreferrer'>ICBC</a>, email: <span className="underline">records@evo.ca</span>})}

                  </div>
                </Txt>
              </DependOn>
              <DependOn active={isIOS===false}>
                <Txt>
                  <div>

                      {props.intl.formatMessage(messages.record.bcContent,{newLine: <br />, link: <a href='https://onlinebusiness.icbc.com/clio/' className='primary' target='_blank' rel='noreferrer'>ICBC</a>, email: <span className="underline">records@evo.ca</span>})}

                  </div>
                </Txt>
              </DependOn>
              <Txt><div>{props.intl.formatMessage(messages.record.evoDrivingRecord)}</div></Txt>
              <br/>
              <Card>
                <Card.Header theme={["bg-primary"]}>
                  <Grid theme={["small-div", "center"]}>
                    <Col size="1-2">
                      <DataCell headerTitle={props.intl.formatMessage(messages.record.bcEVOEmailAddress)}
                                theme={["larger"]}/>
                    </Col>
                  </Grid>
                </Card.Header>
              </Card>
              <Grid theme={["center"]}>
                <Col>
                  <div>
                    <a onClick={() => copyToClipboard()}>
                      {props.intl.formatMessage(messages.record.bcCopyEmail)}
                    </a>
                  </div>
                </Col>
              </Grid>
              <figure>
                <img className="drivingRecordImg"
                  src={evoRecords}
                  alt={props.intl.formatMessage(messages.record.bcImgAlt)}
                />
              </figure>
              {/*<Txt>*/}
              {/*  <div>{Parser(DOMPurify.sanitize(props.intl.formatMessage(messages.record.bcImgText),{ USE_PROFILES: { html: true } }))}</div>*/}
              {/*</Txt>*/}
            </DependOn>
          </Col>
        </Grid>
      </Section>

      <DependOn active={isBCLicense}>
        <Section size="1-2" min="md">
          <Grid>
            <Col>
              <DependOn active={isIOS}>
              <a
                href={props.intl.formatMessage(messages.record.bcButtonLink)}
                className="btn btn--cta"
              >
                <span>{props.intl.formatMessage(messages.record.bcButtonText)}</span>
              </a>
              </DependOn>
              <DependOn active={isIOS===false}>
                <a
                  href={props.intl.formatMessage(messages.record.bcButtonLink)}
                  className="btn btn--cta"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>{props.intl.formatMessage(messages.record.bcButtonText)}</span>
                </a>
              </DependOn>
            </Col>
          </Grid>
        </Section>
      </DependOn>
      <DependOn active={!isBCLicense}>
        <Section size="1-2" min="md">
          <Grid>
            <Col>
              <div>
                <DependOn active={isIOS}>
                <a className={accountLinkCss}
                   href={props.intl.formatMessage(messages.record.nonBCFAQButtonLink)}>
                  {props.intl.formatMessage(messages.record.goToRegistrationFAQs)}
                </a>
                </DependOn>
                <DependOn active={isIOS===false}>
                  <a className={accountLinkCss}
                     href={props.intl.formatMessage(messages.record.nonBCFAQButtonLink)}
                     target="_blank"
                     rel="noopener noreferrer">
                    {props.intl.formatMessage(messages.record.goToRegistrationFAQs)}
                  </a>
                </DependOn>
              </div>
            </Col>
          </Grid>
        </Section>

        <Section size="1-2" min="md">
          <Grid>
            <Col>

              <div>
                <DependOn active={isIOS}>
                <a href={props.intl.formatMessage(messages.record.goToAccountLink)}>
                  {props.intl.formatMessage(messages.record.goToAccount)}

                </a>
                </DependOn>
                <DependOn active={isIOS===false}>
                  <a href={props.intl.formatMessage(messages.record.goToAccountLink)}
                     target="_blank"
                     rel="noopener noreferrer">
                    {props.intl.formatMessage(messages.record.goToAccount)}

                  </a>
                </DependOn>
              </div>
            </Col>
          </Grid>
        </Section>
      </DependOn>

      <Section size="1-3" min="md" theme={["div"]}>
        <Grid>
          <Col>
            <Card>
              <Card.Header theme={["border-primary"]}>
                <Txt>
                  <div>{props.intl.formatMessage(messages.record.approvalContent2)}</div>
                </Txt>
              </Card.Header>
            </Card>
          </Col>
        </Grid>
      </Section>
    </FormSectionWrapper>
  );
};


RequestDrivingRecord.propTypes = {
  form: PropTypes.object,
  section: PropTypes.string,
  intl: PropTypes.object.isRequired,
  dlPrimaryProvince: PropTypes.string,
  dlSecondaryProvince: PropTypes.string,
  dlIsMailingAddress: PropTypes.bool
};

const mapStateToProps = (state, {form}) => {
  const selector = formValueSelector(form);
  return {
    form: state.form.registration,
    dlPrimaryProvince: selector(state, "driverLicence.primary.province"),
    dlSecondaryProvince: selector(state, "driverLicence.secondary.province"),
    dlIsMailingAddress: selector(state, "driverLicence.isMailingAddress")
  };
};

export default connect(mapStateToProps)(injectIntl(RequestDrivingRecord));
