import { defineMessages } from "react-intl";

const namespace = `field`;
const field = defineMessages({
  requiredFieldLabel: {
    id: `${namespace}.requiredFieldLabel`,
    description: "required Field Label for bottom of each form",
    defaultMessage: "* Required Field"
  },
  dropdown: {
    id: `${namespace}.dropdown`,
    description: "Dropdown placeholder",
    defaultMessage: "{txt}"
  },
  EmailAddress: {
    id: `${namespace}.EmailAddress`,
    description: `Email Field`,
    defaultMessage: `Email Address`
  },
  confirmEmailAddress: {
    id: `${namespace}.confirmEmailAddress`,
    description: `Email Field`,
    defaultMessage: `Confirm Email Address`
  },
  LoginEmailAddress: {
    id: `${namespace}.LoginEmailAddress`,
    description: `Email Field`,
    defaultMessage: `Login Email`
  },
  confirmLoginEmailAddress: {
    id: `${namespace}.confirmEmailAddress`,
    description: `Email Field`,
    defaultMessage: `Confirm Login Email`
  },
  password: {
    id: `${namespace}.password`,
    description: "Password Field",
    defaultMessage: "Password"
  },
  newPassword: {
    id: `${namespace}.newPassword`,
    description: "New Password Field",
    defaultMessage: "New password"
  },
  passwordNote: {
    id: `${namespace}.passwordNote`,
    description: "Password Field Notation below",
    defaultMessage:
      "Passwords must be at least 8 characters with one uppercase letter, one lowercase letter, one number and a symbol or special charcter."
  },
  enterCode: {
    id: `${namespace}.enterCode`,
    description: "Placeholder for Enter code input",
    defaultMessage: "Enter Code"
  },
  lang: {
    id: `${namespace}.lang`,
    description: "Preferred language radios title",
    defaultMessage: "Preferred language"
  },
  langen: {
    id: `${namespace}.lang.en`,
    description: "Preferred language English label",
    defaultMessage: "English"
  },
  langfr: {
    id: `${namespace}.lang.fr`,
    description: "Preferred language French label",
    defaultMessage: "French"
  },
  isstudent: {
    id: `${namespace}.isstudent`,
    description: "Question if you are a student checkbox label",
    defaultMessage: "Are you a student?"
  },
  isStudentTooltip: {
    id: `${namespace}.isStudentTooltip`,
    description: "Tool tip for are you a student checkbox",
    defaultMessage:
      "Letting us know if you're a student helps us plan better for things like parking, events, and expansion."
  },
  nameOfSchool: {
    id: `${namespace}.nameOfSchool`,
    description: "Name of School field label",
    defaultMessage: "Name of school"
  },
  ofIssue: {
    id: `${namespace}.ofIssue`,
    description: "Driver's licence issue field (country, province etc)",
    defaultMessage: "of issue"
  },
  driversLicenseNumber: {
    id: `${namespace}.driversLicenseNumber`,
    description: "Driver's Licence Number field label",
    defaultMessage: "Driver's Licence number"
  },
  driversLicenseExpiry: {
    id: `${namespace}.driversLicenseExpiry`,
    description: "Driver's Licence expiry field label",
    defaultMessage: "Driver's Licence expiry"
  },
  editNames: {
    id: `${namespace}.editNames`,
    description: "Edit Names? field link",
    defaultMessage: "Edit Names?"
  },
  promoCode: {
    id: `${namespace}.promoCode`,
    description: "Promo Code label",
    defaultMessage: "Promo Code"
  },
  promoCodeDiscountAndFreeMinute: {
    id: `${namespace}.promoCodeDiscountAndFreeMinute`,
    description: "Promo Code Free Minute success message",
    defaultMessage: "Free registration and {mins} free minutes have been added to your account."
  },
  promoCodeFreeMinute: {
    id: `${namespace}.promoCodeFreeMinute`,
    description: "Promo Code Free Minute success message",
    defaultMessage: "{mins} free minutes have been added to your account."
  },
  promoCodeDiscount: {
    id: `${namespace}.promoCodeDiscount`,
    description: "Promo Code discount success message",
    defaultMessage: "Free registration has been applied to your account."
  },
  forgotPassword: {
    id: `${namespace}.forgotPassword`,
    description: "Forgot password link label",
    defaultMessage: "I forgot my password"
  },
  applyPromoCodeMinutes: {
    id: `${namespace}.applyPromoCodeMinutes`,
    description: "Apply Promo code success message",
    defaultMessage:
      "The promo code {promoCode} has successfully added free minutes to your account!"
  },
  enterAnAddress: {
    id: `${namespace}.enterAnAddress`,
    description: "placehodler for map search input",
    defaultMessage: "Enter an address..."
  },
  isMailingAddress: {
    id: `${namespace}.isMailingAddress`,
    description: "Toggle Switch question on confirm driver's licence page",
    defaultMessage: "My driver licence address is the same as my mailing address."
  },
  yesRadioButton: {
    id: `${namespace}.yesRadioButton`,
    description: "yes radio option",
    defaultMessage: "Yes"
  },
  noRadioButton: {
    id: `${namespace}.noRadioButton`,
    description: "no radio option",
    defaultMessage: "No"
  },
  emailNote: {
    id: `${namespace}.emailNote`,
    description: "note under email field",
    defaultMessage: "Login to your Evo account with this email."
  },
  loginEmailNote: {
    id: `${namespace}.emailNote`,
    description: "note under email field",
    defaultMessage:
      "This is the email you’ll use to login to your account. We already have your work email on hand for business trips, but your login can be any email you’d like and we recommend using one that won’t change."
  },
  pronounNote: {
    id: `${namespace}.pronounNote`,
    description: "note under pronoun field",
    defaultMessage:
      "To help us make your Evo experience amazing, let us know how we can refer to you."
  },

  driverLicenseFront: {
    id: `${namespace}.driverLicense.Front`,
    description: "upload/take driversLicense Front Image",
    defaultMessage: "Please upload a clear photo of the front of your driver's licence."
  },
  driverLicenseBackImage: {
    id: `${namespace}.driverLicense.Back`,
    description: "upload/take driversLicense Back Image",
    defaultMessage: "Please upload a clear photo of the back of your driver's licence."
  },
  selfie: {
    id: `${namespace}.selfie`,
    description: "upload/take selfie Image",
    defaultMessage:
      "Please take a selfie or upload a clear front-facing image to help us confirm your identity."
  },
  isMailingAddressNote: {
    id: `${namespace}.isMailingAddressNote`,
    description: "is Mailing Address Notation below",
    defaultMessage: "This is the address we'll use if we need to send you things."
  },
  is18YearsOld: {
    id: `${namespace}.is18YearsOld`,
    description:
      "show message to let user know they are 1 year away from being able to register to ride an e-bike",
    defaultMessage:
      "We noticed you’re 18 – while you can hop in a car, you’ll have to wait until your next birthday to use our e-bikes (must be 19 or older)."
  },
  isEbikeOnly: {
    id: `${namespace}.isEbikeOnly`,
    description: "ask user",
    defaultMessage: "e-bikes/e-scooters"
  },
  isEbikeOnlyTooltip: {
    id: `${namespace}.isEbikeOnlyTooltip`,
    description: "ask user",
    defaultMessage:
      "By selecting this option, we skip some steps like driving record checks, so you can hop on a bike and hit the road faster. You can always add car sharing later."
  }
});

export default field;
