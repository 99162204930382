import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

import { DependOn } from "../index";
import SpinnerIcon from "./spinner.svg";
import SpinerDarkIcon from "./spinner-dark-bg.svg";
import "./index.scss";

const Spinner = ({ theme, className, onDark, active }) => {
  //Load differnt spinner
  const SpinnerImg = onDark ? SpinerDarkIcon : SpinnerIcon;
  // Handle background theme
  theme.push(onDark ? "dark" : "white");

  const spinnerCSS = cn(
    "spinner",
    className,
    theme ? theme.map(css => `spinner--${css}`).join(" ") : ""
  );
  return (
    <DependOn active={active}>
      <div className={spinnerCSS} data-testid="spinner-container">
        <img src={SpinnerImg} data-testid="spinner-img" alt="spinner" />
      </div>
    </DependOn>
  );
};

Spinner.defaultProps = {
  theme: [],
  className: "",
  active: false,
  onDark: false
};
Spinner.propTypes = {
  theme: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  onDark: PropTypes.bool,
  active: PropTypes.bool
};

export default Spinner;
