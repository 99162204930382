import React, { PureComponent } from "react";
import { Field, formValueSelector } from "redux-form";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import { Checkbox, Grid, Col, Section } from "../../../../components";
import { messages } from "../../../../constants";
import { isIOS } from "react-device-detect";
import { validationBuilder, validations } from "../..";
import Radio from "../../../../components/radio/radio";
import { Txt } from "../../../../elements";
import { useB2BStore } from "../../../../store/b2b";

let rules = {};
class Agreements extends PureComponent {
  constructor(props) {
    super(props);
    const { intl } = this.props;
    // for field level validations
    Object.keys(validations).forEach((key) => {
      rules[key] = (spec, replaceSpecMessage) => (value) =>
        validationBuilder(props, validations[key], spec, replaceSpecMessage)(value);
    });

    this.ruleAgreement = [rules.required(intl.formatMessage(messages.error.acceptAgreement), true)];
    this.ruleMarketingOptin = [
      rules.required(intl.formatMessage(messages.error.acceptMktOptIn), true)
    ];
  }

  getValue = (name) => {
    const { state, form, section } = this.props;
    const selector = formValueSelector(form);
    return selector(state, `${section}.${name}`);
  };

  render() {
    const { intl } = this.props;
    let checkboxMessage = intl.formatMessage(messages.personal.agreement, {
      terms: (
        <a href="https://evo.ca/member-agreement" target="_blank" rel="noreferrer">
          Terms & Conditions
        </a>
      ),
      privacy: (
        <a href="https://evo.ca/member-agreement#Privacy" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
      )
    });
    if (isIOS) {
      checkboxMessage = intl.formatMessage(messages.personal.agreement, {
        terms: <a href="https://evo.ca/member-agreement">Terms & Conditions</a>,
        privacy: <a href="https://evo.ca/member-agreement#Privacy">Privacy Policy</a>
      });
    }

    const b2bData = useB2BStore.getState();
    const isB2B = b2bData.data.isB2B;

    return (
      <div>
        {isB2B && (
          <Section size="2-3" min="md" theme={[`div`]}>
            <Grid>
              <Col>
                <Field
                  name="drivePersonalAccount"
                  label={intl.formatMessage(messages.privateRegistration.drivePersonalAccount)}
                  labelClassName="text--large"
                  radioFieldClassName="compact"
                  required={true}
                  component={Radio}
                  options={[
                    { label: intl.formatMessage(messages.field.yesRadioButton), value: "Yes" },
                    { label: intl.formatMessage(messages.field.noRadioButton), value: "No" }
                  ]}
                />
                <Txt theme={["note"]}>
                  <small>
                    {intl.formatMessage(messages.privateRegistration.drivePersonalAccountNote)}
                  </small>
                </Txt>
              </Col>
            </Grid>
          </Section>
        )}
        <Section size="2-3" min="md" theme={[`div`]}>
          <Grid>
            <Col>
              <Field
                component={Checkbox}
                name="acceptTermsAndConditions"
                required={true}
                validate={this.ruleAgreement}
                label={checkboxMessage}
              />
            </Col>
          </Grid>
        </Section>
        <Section size="2-3" min="md">
          <Grid>
            <Col>
              <Field
                component={Checkbox}
                name="marketingOptin"
                required={true}
                validate={this.ruleMarketingOptin}
                label={intl.formatMessage(messages.personal.marketingOptin)}
              />
            </Col>
          </Grid>
        </Section>
      </div>
    );
  }
}

Agreements.propTypes = {
  intl: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  section: PropTypes.string.isRequired
};

export default injectIntl(Agreements);
