// prettier-ignore
import { defineMessages } from "react-intl";

const namespace = "provinces";
const provinces = defineMessages({
  AB: { id: `${namespace}.AB`, description: "Alberta", defaultMessage: "Alberta" },
  BC: {
    id: `${namespace}.BC`,
    description: "British Columbia",
    defaultMessage: "British Columbia"
  },
  MB: { id: `${namespace}.MB`, description: "Manitoba", defaultMessage: "Manitoba" },
  NB: { id: `${namespace}.NB`, description: "New Brunswick", defaultMessage: "New Brunswick" },
  NL: {
    id: `${namespace}.NL`,
    description: "Newfoundland and Labrador",
    defaultMessage: "Newfoundland and Labrador"
  },
  NS: { id: `${namespace}.NS`, description: "Nova Scotia", defaultMessage: "Nova Scotia" },
  ON: { id: `${namespace}.ON`, description: "Ontario", defaultMessage: "Ontario" },
  PE: {
    id: `${namespace}.PE`,
    description: "Prince Edward Island",
    defaultMessage: "Prince Edward Island"
  },
  QC: { id: `${namespace}.QC`, description: "Quebec", defaultMessage: "Quebec" },
  SK: { id: `${namespace}.SK`, description: "Saskatchewan", defaultMessage: "Saskatchewan" },
  NT: {
    id: `${namespace}.NT`,
    description: "Northwest Territories",
    defaultMessage: "Northwest Territories"
  },
  YT: { id: `${namespace}.YT`, description: "Yukon", defaultMessage: "Yukon" },
  NU: { id: `${namespace}.NU`, description: "Nunavut", defaultMessage: "Nunavut" }
});

export default provinces;
