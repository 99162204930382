import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import {reduxForm, formValueSelector, defaultShouldAsyncValidate} from "redux-form";
import PropTypes from "prop-types";
import Scroll from "react-scroll";
import { injectIntl } from "react-intl";

import * as selectors from "../../../selectors";
import * as allActions from "../../../actions";
import * as bannerActions from "../../../actions/banner";
import { Button } from "../../../elements";
import { DependOn, ErrorBoundary } from "../../../components";
import { messages, constants } from "../../../constants";
import asyncValidate from "../asyncValidate";

const scroll = Scroll.animateScroll;

const calculateNumErrors = (errors) => {
  let result = 0;
  if (typeof errors === "object") {
    for (let section in errors) {
      if (!errors.hasOwnProperty(section) || typeof errors[section] !== "object") continue;
      result += Object.keys(errors[section]).length;
    }
  } else {
    return null;
  }
  return result;
};

const settings = {
  form: constants.FORM.registrationForm, // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  asyncValidate,
  asyncBlurFields: [
    "promosAndSavings.iscaaMember",
    "promosAndSavings.memberNumber",
    "personalInfo.emailAddress",
    "driverLicence.number"
  ],
  asyncChangeFields: [
    "driverLicence.imageFront",
    "driverLicence.imageBack",
    "promosAndSavings.iscaaMember",
    "promosAndSavings.memberNumber" /*'identityVerificationSelfie.imageSelfie'*/
  ],
  shouldAsyncValidate: (params) => {
    return defaultShouldAsyncValidate({ ...params, syncValidationPasses: true });
  },
  onSubmitFail: (syncErrors, dispatch, submitErrors, props) => {
    const { intl, bannerActions } = props;
    const syncErrorsLength = syncErrors ? calculateNumErrors(syncErrors) : null;
    const submitErrorsLength = submitErrors ? calculateNumErrors(submitErrors) : null;
    if (syncErrorsLength || (submitErrorsLength && submitErrors !== "timeout")) {
      const length = syncErrorsLength || submitErrorsLength;
      bannerActions.setAlerts([
        {
          level: constants.ALERT_LEVEL.error,
          message: intl.formatMessage(messages.error.infoMissing, {
            length: length
          })
        }
      ]);
    } else {
      bannerActions.setAlerts([
        {
          level: constants.ALERT_LEVEL.error,
          message: intl.formatMessage(messages.error.genericError)
        }
      ]);
    }
    scroll.scrollToTop();
  }
};

class RegistrationWizard extends PureComponent {
  componentDidUpdate(prevProps) {
    const { actions, progress } = prevProps;

    actions.handleValidIndex({ ...this.props, progress });
  }
  _disableContinue = () => {
    const { invalid, submitting, pristine } = this.props;

    if (invalid || submitting || pristine) {
      return true;
    }
  };
  render() {
    const { handleSubmit, sections, button, submitting, previousPage, active } = this.props;

    const renderSection = (sections) => {
      return sections.map((section, idx) => {
        const CustomTag = section.component;
        return (
          <ErrorBoundary key={idx} name={`custom tag ${idx}`}>
            <CustomTag {...this.props} title={section.title} section={section.section} />
          </ErrorBoundary>
        );
      });
    };

    return (
      // noValidate disable browser validation on special types, such as email
      // which triggers auto focus billingAddressSameAsHome
      <div className="outerForm">
        <form onSubmit={handleSubmit} noValidate>
          {previousPage && (
            <div className="backButton">
              <a onClick={previousPage}>
                <i className="fa fa-arrow-circle-left fa-lg"></i>
              </a>
            </div>
          )}
          {renderSection(sections)}
          <DependOn active={active}>
            <Button
              loading={submitting}
              type="submit"
              theme={["cta"]}
              disabled={this._disableContinue()}
            >
              {button}
            </Button>
          </DependOn>
        </form>
      </div>
    );
  }
}

RegistrationWizard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string
  ]),
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func,
  sections: PropTypes.array,
  button: PropTypes.string,
  state: PropTypes.object.isRequired,
  progress: PropTypes.object.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  bannerActions: PropTypes.objectOf(PropTypes.func),
  submitting: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  validIndex: PropTypes.number,
  summaryParam: PropTypes.object.isRequired
};

RegistrationWizard.defaultProps = {
  children: null,
  sections: [],
  button: "",
  bannerActions: PropTypes.objectOf(PropTypes.func),
  submitting: false,
  active: false
};

const selector = formValueSelector(settings.form);
const mapStateToProps = (state) => ({
  state,
  initialValues: {
    personalInfo: { isEbikeOnly: false },
    promosAndSavings: { promo: state.flow.promoCode.code },
    paymentInfo: { evoCard: false }
  },
  progress: selectors.getSelectedIndex(state),
  // params used to get payment summary
  summaryParam: {
    // signupRegion: selector(state, "homeAddress.signupRegion"),
    isCAAMember: selectors.getiscaaMember(state),
    validateMembership: selectors.getValidateMembership(state),
    promoCode: selectors.getPromoCodeStatus(state).code
  },
  validIndex: selectors.getValidIndex(state),
  // params used to validate promo code
  promoParam: {
    // region: selector(state, "homeAddress.signupRegion"),
    isSignup: true, // this is signup (registration) flow
    promoCode: selector(state, "paymentInfo.promo")
  }
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(allActions.flow, dispatch),
  bannerActions: bindActionCreators(bannerActions, dispatch)
});

const enhancer = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm(settings)
);

export default enhancer(RegistrationWizard);
