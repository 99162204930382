import React from "react";

export const Settings = ({ inverted = false }) => (
  <svg width="44" height="44" viewBox="0 0 72 72">
    <title>Group 2</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" fillRule="nonzero">
        <g id="Group-2">
          <path
            d="M0,4.80786672 C0,2.15255525 2.15481699,0 4.79386497,0 L67.206135,0 C69.8537136,0 72,2.15481698 72,4.80786672 L72,67.1921333 C72,69.8474448 69.845183,72 67.206135,72 L4.79386497,72 C2.14628646,72 0,69.845183 0,67.1921333 L0,4.80786672 Z"
            id="Combined-Shape"
            fill={inverted ? "#FFFFFF" : "#02B8DE"}
          />
          <g
            id="Group"
            transform="translate(13.000000, 14.000000)"
            fill={inverted ? "#02B8DE" : "#FFFFFF"}
          >
            <polygon
              id="Line"
              points="3.55271368e-15 37.7529762 46.4497304 37.7529762 46.4497304 33.9136905 3.55271368e-15 33.9136905"
            />
            <polygon
              id="Line"
              points="3.55271368e-15 23.0357144 46.4497304 23.0357144 46.4497304 19.1964286 3.55271368e-15 19.1964286"
            />
            <polygon
              id="Line"
              points="3.55271368e-15 8.31845244 46.4497304 8.31845244 46.4497304 4.47916674 3.55271368e-15 4.47916674"
            />
            <ellipse id="Oval" cx="32.272053" cy="35.8333334" rx="6.46227704" ry="6.39880953" />
            <ellipse id="Oval" cx="14.1776774" cy="21.1160715" rx="6.46227704" ry="6.39880953" />
            <ellipse id="Oval" cx="32.272053" cy="6.39880953" rx="6.46227704" ry="6.39880953" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Settings.displayName = "Settings";

export default Settings;
