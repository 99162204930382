import React, { PureComponent } from "react";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import InfiniteScroll from "react-infinite-scroller";
import PropTypes from "prop-types";
import orderBy from "lodash/orderBy";
import get from "lodash/get";
import "./index.scss";

import moment from "moment-timezone";

import { messages } from "../../constants";
import {fetchGenerateMonthlyStatement, fetchMonthlyStatements} from "../../actions/statement";
import { DataTable, Section, Grid, Col, Spinner } from "../../components";
import { Title } from "../../elements";
import { loginWithToken } from "../../actions/auth";
import { formatCurrency, formatDate, getJsonFromUrl } from "../../util";

class AccountStatements extends PureComponent {
  state = {
    currentPage: 1,
    hasMore: true
  };

  statementsPerPage = 12;

  componentDidMount() {
    const { loginWithToken } = this.props;
    try {
      const queryObj = getJsonFromUrl(get(this.props, "location.search", ""));
      const { idToken, accessToken } = queryObj;
      if (idToken && accessToken) {
        loginWithToken({ idToken, accessToken });
      }
    } catch (error) {}
    this._fetchStatements();
  }

  render() {
    const { hasMore } = this.state;
    const { intl, monthlyStatements, user } = this.props;

    const name =
      get(user, "personalInfo.preferredName", "") || get(user, "accountSummary.preferredName", "") || get(user, "accountSummary.firstName", "");

    const columnNames = [
      { key: "month", title: intl.formatMessage(messages.title.Month) },
      { key: "amount", title: intl.formatMessage(messages.title.AmountCharged) },
      { key: "download", title: intl.formatMessage(messages.title.Download) }
    ];

    const displayKeys = ["month", "amount", "download"];

    const data = orderBy(monthlyStatements, ["month"], ["desc"]).filter(ms => (ms['en-CA']===true || ms['fr-CA']===true)).map(statement => {
      return {
        month: <span>{formatDate(`${statement.month}01`, "MMMM, YYYY")}</span>,
        amount: (
          <span>
            <strong>{ statement.amount === null ? '' : formatCurrency(statement.amount) }</strong>
          </span>
        ),
        download: this._renderDownloadOptions(statement)
      };
    });

    return (
      <Section>
        <Grid>
          <Col>
            <Title
              data-testid="account-landing-greeting"
              priority={1}
              type={["strong"]}
              className="h2"
            >
              {intl.formatMessage(messages.header.greeting, { name })}
            </Title>
          </Col>
          <Col>{intl.formatMessage(messages.header.downloadMonthlyStatements)}</Col>
          <Col>
            <InfiniteScroll
              pageStart={1}
              loadMore={this._fetchStatements}
              hasMore={hasMore}
              initialLoad={false}
              loader={
                <div key={0}>
                  <Spinner active theme={["center"]} />
                </div>
              }
            >
              <DataTable
                columnNames={columnNames}
                displayKeys={displayKeys}
                data={data}
                nullStateTitle={intl.formatMessage(messages.title.NoStatements)}
              />
            </InfiniteScroll>
          </Col>
        </Grid>
      </Section>
    );
  }

  _fetchStatements = () => {
    const { currentPage } = this.state;
    const {
      monthlyStatements: { totalPages }
    } = this.props;
    this.props.fetchMonthlyStatements(this.statementsPerPage, currentPage).then(() => {
      this.setState(({ currentPage }) => ({
        currentPage: currentPage + 1,
        hasMore: currentPage < totalPages
      }));
    });
  };

  _generateMonthlyStatement = (month, year, languageCode) => {
    this.props.fetchGenerateMonthlyStatement(month, year, languageCode).then((data) => window.open(data.filePath, '_blank'));
  }

  _renderDownloadOptions = statement => {
    const enLink = statement["en-CA"] ? (
      <button

        className="statement link-button center-icon fa fa-lg fa-download"
        onClick={() => this._generateMonthlyStatement(moment(statement.month, "YYYYMM").format("MM"), moment(statement.month, "YYYYMM").format("YYYY"), 'en-CA')}>

      </button>
    ) : null;

    if(enLink)
    {
      return <span>{enLink}</span>;
    }

  };
}

AccountStatements.propTypes = {
  intl: PropTypes.object.isRequired,
  fetchMonthlyStatements: PropTypes.func.isRequired,
  fetchGenerateMonthlyStatement: PropTypes.func,
  user: PropTypes.object.isRequired,
  monthlyStatements: PropTypes.array.isRequired
};

const mapStateToProps = ({ statement, user }) => ({
  user,
  monthlyStatements: statement.monthlyStatements
});

const mapDispatchToProps = dispatch => ({
  fetchMonthlyStatements: bindActionCreators(fetchMonthlyStatements, dispatch),
  fetchGenerateMonthlyStatement: bindActionCreators(fetchGenerateMonthlyStatement, dispatch),
  loginWithToken: bindActionCreators(loginWithToken, dispatch)
});

const enhancer = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export const IntlAccountStatements = injectIntl(AccountStatements);

export default enhancer(AccountStatements);
