import { constants } from "../constants";

export const DEFAULT_STATE = {
  registrationResp: {}
};

const var1 = (state = DEFAULT_STATE, { type, payload } = {}) => {
  switch (type) {
    case constants.SET_REGISTRATION_RESP:
      return { registrationResp: payload.registrationResp };
    case constants.CLEAR_REGISTRATION_RESP:
      return DEFAULT_STATE;
    default:
      return state;
  }
};

export default var1;
