import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import {reduxForm} from "redux-form";
import PropTypes from "prop-types";
import Scroll from "react-scroll";
import { injectIntl } from "react-intl";

import * as selectors from "../../../selectors";
import * as allActions from "../../../actions";
import * as bannerActions from "../../../actions/banner";
import { Button } from "../../../elements";
import { ErrorBoundary } from "../../../components";
import { messages, constants } from "../../../constants";
import {VerifyOtp} from "../fieldGroups";
import {FormSectionWrapper} from "../formSections";

const scroll = Scroll.animateScroll;

const calculateNumErrors = errors => {
  let result = 0;
  if (typeof errors === "object") {
    for (let section in errors) {
      if (!errors.hasOwnProperty(section) || typeof errors[section] !== "object") continue;

      result +=errors[section]? Object.keys(errors[section]).length : 0;
    }
  } else {
    return null;
  }
  return result;
};

const settings = {
  form: constants.FORM.verifyOtpForm, // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  onSubmitFail: (syncErrors, dispatch, submitErrors, props) => {
    const {intl, bannerActions} = props;
    const syncErrorsLength = syncErrors ? calculateNumErrors(syncErrors) : null;
    const submitErrorsLength = submitErrors ? calculateNumErrors(submitErrors) : null;
    if ((syncErrorsLength) || (submitErrorsLength && submitErrors !== "timeout")) {
      const length = syncErrorsLength || submitErrorsLength;
      bannerActions.setAlerts([
        {
          level: constants.ALERT_LEVEL.error,
          message: intl.formatMessage(messages.error.infoMissing, {
            length: length
          })
        }
      ]);
    } else {
      bannerActions.setAlerts([
        {
          level: constants.ALERT_LEVEL.error,
          message: intl.formatMessage(messages.error.genericError)
        }
      ])
    }
    scroll.scrollToTop();
  }
};

class VerifyOtpForm extends PureComponent {

  render() {
    const { handleSubmit, button, submitting, invalid, pristine, resendOtp  } = this.props;
    return (
      // noValidate disable browser validation on special types, such as email
      // which triggers auto focus billingAddressSameAsHome
      <div className="outerForm">

      <form onSubmit={handleSubmit} noValidate>
        <FormSectionWrapper title={"Enter the Verification Code"} section="evolveBusinessRegVerifyOtp" {...this.props}>
        <div className="form-description">
         Verify your email by entering the 4-digit verification code that has been sent to your business email.<br></br><br></br> The code will be expired in 5 minutes.
        </div>
          <ErrorBoundary name={`Verify OTP`}>
            <VerifyOtp section="evolveBusinessRegVerifyOtp" {...this.props} resendOtp={resendOtp} />
          </ErrorBoundary>
          <Button loading={submitting} type="submit" theme={["cta"]} disabled={ invalid || submitting ||pristine }>
            {button}
          </Button>
        </FormSectionWrapper>
        </form>
      </div>
    );
  }
}

VerifyOtpForm.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string
  ]),
  handleSubmit: PropTypes.func.isRequired,
  resendOtp: PropTypes.func.isRequired,
  button: PropTypes.string,
  state: PropTypes.object.isRequired,

  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  bannerActions: PropTypes.objectOf(PropTypes.func),
  submitting: PropTypes.bool,
  intl: PropTypes.object.isRequired
};

VerifyOtpForm.defaultProps = {
  children: null,
  button: "",
  bannerActions: PropTypes.objectOf(PropTypes.func),
  submitting: false
};

//const selector = formValueSelector(settings.form);
const mapStateToProps = state => ({
  state,

  summaryParam: {
   // signupRegion: selector(state, "homeAddress.signupRegion"),
    isCAAMember: selectors.getiscaaMember(state),
    promoCode: selectors.getPromoCodeStatus(state).code
  },

});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(allActions.flow, dispatch),
  bannerActions: bindActionCreators(bannerActions, dispatch)
});

const enhancer = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm(settings)
);

export default enhancer(VerifyOtpForm);
