import { defineMessages } from "react-intl";

const namespace = `address`;
const address = defineMessages({
  postalCode: {
    id: `${namespace}.postalCode`,
    description: "Postal code field",
    defaultMessage: "Postal code"
  },
  zipCode: {
    id: `${namespace}.zipCode`,
    description: "Zip code field",
    defaultMessage: "Zip code"
  },
  postalZipCode: {
    id: `${namespace}.postalZipCode`,
    description: "Postal / Zip code field",
    defaultMessage: "Postal / Zip code"
  },
  unitNumber: {
    id: `${namespace}.unitNumber`,
    description: "Unit Number field",
    defaultMessage: "Unit #"
  },
  addressLine: {
    id: `${namespace}.addressLine`,
    description: "home/billing address line field",
    defaultMessage: "{type}"
  },
  line1: {
    id: `${namespace}.line1`,
    description: "home/billing address line 1 field",
    defaultMessage: "{type} line 1"
  },
  line2: {
    id: `${namespace}.line2`,
    description: "home/billing address line 2 field",
    defaultMessage: "{type} line 2"
  },
  lineInfo: {
    id: `${namespace}.lineInfo`,
    description: "home/billing address line 2 field info icon content",
    defaultMessage: "Add P.O. Box, Company Name, or Rural Road information here"
  },
  city: {
    id: `${namespace}.city`,
    description: "City field",
    defaultMessage: "City"
  },
  country: {
    id: `${namespace}.country`,
    description: "Country field",
    defaultMessage: "Country"
  },
  province: {
    id: `${namespace}.province`,
    description: "Province / State field",
    defaultMessage: "Province / State"
  },
  state: {
    id: `${namespace}.state`,
    description: "State field for US",
    defaultMessage: "State"
  },
  provinceState: {
    id: `${namespace}.provinceState`,
    description: "Province / State field",
    defaultMessage: "Province / State"
  },
  sameAddress: {
    id: `${namespace}.sameAddress`,
    description: "Checkbox for same address",
    defaultMessage: "Use home address for billing?"
  },
  countryOfIssue: {
    id: `${namespace}.countryOfIssue`,
    description: "warning message about country of issue",
    defaultMessage: "Driver’s Licence Country of Issue."
  },
  provinceOfIssue: {
    id: `${namespace}.provinceOfIssue`,
    description: "warning message about province of issue",
    defaultMessage: "Driver’s Licence Province/State of Issue."
  }
});

export default address;
