import React from "react";
import PropTypes from "prop-types";

const AppStore = ({ locale }) => {
  if (locale === "fr") {
    return (
      <svg
        width="127px"
        height="40px"
        viewBox="0 0 127 40"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <polygon id="path-1" points="0 0.04 126.507 0.04 126.507 40 0 40" />
        </defs>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Download_on_the_App_Store_Badge_FR_blk_100517">
            <g id="Group-3">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1" />
              </mask>
              <g id="Clip-2" />
              <path
                d="M116.978,0 L9.535,0 C9.168,0 8.806,0 8.44,0.002 C8.134,0.004 7.83,0.01 7.521,0.015 C6.855,0.031 6.182,0.072 5.517,0.191 C4.848,0.309 4.226,0.509 3.616,0.818 C3.018,1.125 2.471,1.523 1.998,1.997 C1.519,2.471 1.123,3.02 0.819,3.618 C0.508,4.227 0.311,4.852 0.194,5.521 C0.074,6.184 0.032,6.854 0.015,7.523 C0.006,7.83 0.005,8.138 0,8.444 C0,8.807 0,9.17 0,9.536 L0,30.465 C0,30.834 0,31.195 0,31.559 C0.005,31.869 0.006,32.17 0.015,32.48 C0.032,33.15 0.074,33.82 0.194,34.482 C0.311,35.152 0.508,35.78 0.819,36.387 C1.123,36.982 1.519,37.531 1.998,38.001 C2.471,38.479 3.018,38.876 3.616,39.18 C4.226,39.492 4.848,39.69 5.517,39.811 C6.182,39.93 6.855,39.969 7.521,39.987 C7.83,39.994 8.134,39.998 8.44,39.998 C8.806,40 9.168,40 9.535,40 L116.978,40 C117.337,40 117.703,40 118.062,39.998 C118.367,39.998 118.679,39.994 118.984,39.987 C119.654,39.969 120.326,39.93 120.984,39.811 C121.654,39.69 122.277,39.492 122.892,39.18 C123.49,38.876 124.037,38.479 124.509,38.001 C124.986,37.531 125.382,36.982 125.691,36.387 C125.998,35.78 126.197,35.152 126.31,34.482 C126.433,33.82 126.472,33.15 126.496,32.48 C126.5,32.17 126.5,31.869 126.5,31.559 C126.507,31.195 126.507,30.834 126.507,30.465 L126.507,9.536 C126.507,9.17 126.507,8.807 126.5,8.444 C126.5,8.138 126.5,7.83 126.496,7.523 C126.472,6.854 126.433,6.184 126.31,5.521 C126.197,4.852 125.998,4.227 125.691,3.618 C125.382,3.02 124.986,2.471 124.509,1.997 C124.037,1.523 123.49,1.125 122.892,0.818 C122.277,0.509 121.654,0.309 120.984,0.191 C120.326,0.072 119.654,0.031 118.984,0.015 C118.679,0.01 118.367,0.004 118.062,0.002 C117.703,0 117.337,0 116.978,0"
                id="Fill-1"
                fill="#A9AAA9"
                mask="url(#mask-2)"
              />
            </g>
            <path
              d="M8.445,39.125 C8.14,39.125 7.843,39.121 7.541,39.114 C6.981,39.099 6.318,39.067 5.671,38.951 C5.061,38.841 4.519,38.661 4.015,38.403 C3.493,38.139 3.024,37.797 2.618,37.387 C2.204,36.98 1.863,36.514 1.597,35.99 C1.338,35.485 1.161,34.943 1.054,34.333 C0.932,33.66 0.901,32.978 0.888,32.458 C0.881,32.247 0.873,31.545 0.873,31.545 L0.873,8.444 C0.873,8.444 0.882,7.753 0.888,7.55 C0.901,7.026 0.932,6.344 1.053,5.678 C1.16,5.064 1.337,4.522 1.597,4.016 C1.862,3.491 2.203,3.022 2.612,2.618 C3.025,2.204 3.496,1.861 4.014,1.595 C4.528,1.334 5.069,1.156 5.667,1.051 C6.341,0.931 7.023,0.9 7.543,0.887 L8.445,0.875 L118.057,0.875 L118.97,0.888 C119.483,0.9 120.166,0.931 120.829,1.05 C121.431,1.156 121.976,1.335 122.5,1.598 C123.012,1.861 123.482,2.204 123.891,2.614 C124.3,3.02 124.643,3.492 124.915,4.018 C125.172,4.529 125.348,5.071 125.45,5.667 C125.566,6.298 125.602,6.946 125.624,7.554 C125.626,7.837 125.626,8.142 125.626,8.444 C125.634,8.819 125.634,9.176 125.634,9.536 L125.634,30.465 C125.634,30.828 125.634,31.183 125.626,31.54 C125.626,31.865 125.626,32.163 125.623,32.47 C125.602,33.059 125.566,33.706 125.452,34.323 C125.348,34.936 125.172,35.478 124.912,35.993 C124.642,36.513 124.299,36.982 123.896,37.379 C123.483,37.797 123.014,38.138 122.497,38.401 C121.978,38.665 121.449,38.84 120.829,38.951 C120.188,39.067 119.525,39.099 118.959,39.114 C118.667,39.121 118.36,39.125 118.062,39.125 L116.978,39.127 L8.445,39.125"
              id="Fill-4"
              fill="#000000"
            />
            <path
              d="M24.772,20.301 C24.747,17.55 27.024,16.211 27.128,16.148 C25.839,14.268 23.839,14.011 23.137,13.991 C21.458,13.814 19.83,14.996 18.974,14.996 C18.102,14.996 16.785,14.008 15.366,14.038 C13.54,14.066 11.83,15.124 10.893,16.766 C8.959,20.114 10.401,25.035 12.254,27.741 C13.181,29.067 14.264,30.548 15.682,30.495 C17.069,30.438 17.587,29.61 19.261,29.61 C20.92,29.61 21.406,30.495 22.852,30.462 C24.34,30.438 25.278,29.13 26.172,27.792 C27.244,26.273 27.674,24.776 27.691,24.7 C27.656,24.688 24.8,23.599 24.772,20.301"
              id="Fill-5"
              fill="#FFFFFE"
            />
            <path
              d="M22.04,12.211 C22.786,11.278 23.296,10.009 23.155,8.72 C22.075,8.768 20.725,9.467 19.947,10.38 C19.259,11.184 18.645,12.502 18.803,13.741 C20.016,13.832 21.262,13.129 22.04,12.211"
              id="Fill-6"
              fill="#FFFFFE"
            />
            <polyline
              id="Fill-7"
              fill="#FFFFFE"
              points="35.655 14.702 35.655 9.578 33.778 9.578 33.778 8.735 38.455 8.735 38.455 9.578 36.582 9.578 36.582 14.702 35.655 14.702"
            />
            <path
              d="M40.275,9.446 L41.313,8.023 L42.355,8.023 L41.193,9.446 L40.275,9.446 Z M39.639,12.035 L41.913,12.035 C41.893,11.328 41.463,10.868 40.805,10.868 C40.147,10.868 39.688,11.332 39.639,12.035 Z M42.765,13.486 C42.562,14.292 41.843,14.789 40.813,14.789 C39.523,14.789 38.733,13.904 38.733,12.464 C38.733,11.025 39.54,10.112 40.81,10.112 C42.062,10.112 42.818,10.968 42.818,12.382 L42.818,12.692 L39.639,12.692 L39.639,12.742 C39.668,13.531 40.127,14.032 40.838,14.032 C41.376,14.032 41.744,13.837 41.909,13.486 L42.765,13.486 L42.765,13.486 Z"
              id="Fill-8"
              fill="#FFFFFE"
            />
            <polygon
              id="Fill-9"
              fill="#FFFFFE"
              points="44.053 14.702 44.942 14.702 44.942 8.441 44.053 8.441"
            />
            <path
              d="M47.719,9.446 L48.757,8.023 L49.799,8.023 L48.637,9.446 L47.719,9.446 Z M47.082,12.035 L49.356,12.035 C49.336,11.328 48.906,10.868 48.248,10.868 C47.591,10.868 47.132,11.332 47.082,12.035 Z M50.208,13.486 C50.006,14.292 49.286,14.789 48.257,14.789 C46.967,14.789 46.177,13.904 46.177,12.464 C46.177,11.025 46.983,10.112 48.253,10.112 C49.506,10.112 50.262,10.968 50.262,12.382 L50.262,12.692 L47.082,12.692 L47.082,12.742 C47.111,13.531 47.57,14.032 48.281,14.032 C48.819,14.032 49.188,13.837 49.353,13.486 L50.208,13.486 L50.208,13.486 Z"
              id="Fill-10"
              fill="#FFFFFE"
            />
            <path
              d="M54.403,11.67 C54.3,11.232 53.936,10.906 53.34,10.906 C52.596,10.906 52.141,11.476 52.141,12.436 C52.141,13.412 52.6,13.995 53.34,13.995 C53.902,13.995 54.287,13.738 54.403,13.254 L55.268,13.254 C55.151,14.16 54.457,14.789 53.345,14.789 C52.033,14.789 51.231,13.904 51.231,12.436 C51.231,10.993 52.029,10.112 53.34,10.112 C54.469,10.112 55.151,10.769 55.268,11.67 L54.403,11.67"
              id="Fill-11"
              fill="#FFFFFE"
            />
            <path
              d="M56.447,8.441 L57.328,8.441 L57.328,10.922 L57.398,10.922 C57.617,10.417 58.093,10.116 58.771,10.116 C59.73,10.116 60.322,10.724 60.322,11.794 L60.322,14.702 L59.433,14.702 L59.433,12.014 C59.433,11.294 59.098,10.93 58.47,10.93 C57.741,10.93 57.336,11.39 57.336,12.072 L57.336,14.702 L56.447,14.702 L56.447,8.441"
              id="Fill-12"
              fill="#FFFFFE"
            />
            <path
              d="M64.334,13.044 L64.334,12.667 L63.234,12.737 C62.614,12.779 62.333,12.99 62.333,13.387 C62.333,13.792 62.685,14.028 63.168,14.028 C63.838,14.028 64.334,13.602 64.334,13.044 Z M61.44,13.428 C61.44,12.618 62.043,12.15 63.114,12.084 L64.334,12.014 L64.334,11.625 C64.334,11.149 64.02,10.881 63.412,10.881 C62.916,10.881 62.572,11.063 62.474,11.381 L61.613,11.381 C61.704,10.608 62.432,10.112 63.453,10.112 C64.582,10.112 65.218,10.674 65.218,11.625 L65.218,14.702 L64.363,14.702 L64.363,14.069 L64.293,14.069 C64.024,14.52 63.532,14.776 62.94,14.776 C62.072,14.776 61.44,14.251 61.44,13.428 L61.44,13.428 Z"
              id="Fill-13"
              fill="#FFFFFE"
            />
            <path
              d="M66.61,10.199 L67.465,10.199 L67.465,10.889 L67.532,10.889 C67.66,10.447 68.16,10.124 68.748,10.124 C68.879,10.124 69.049,10.136 69.144,10.161 L69.144,11.038 C69.07,11.013 68.805,10.984 68.648,10.984 C67.974,10.984 67.498,11.41 67.498,12.043 L67.498,14.702 L66.61,14.702 L66.61,10.199"
              id="Fill-14"
              fill="#FFFFFE"
            />
            <path
              d="M73.117,12.394 C73.117,11.497 72.653,10.922 71.896,10.922 C71.131,10.922 70.702,11.497 70.702,12.394 C70.702,13.292 71.131,13.866 71.896,13.866 C72.658,13.866 73.117,13.296 73.117,12.394 Z M69.961,15.152 L70.871,15.152 C70.946,15.479 71.322,15.69 71.921,15.69 C72.662,15.69 73.1,15.338 73.1,14.743 L73.1,13.879 L73.034,13.879 C72.76,14.367 72.264,14.635 71.644,14.635 C70.495,14.635 69.784,13.747 69.784,12.398 C69.784,11.025 70.502,10.124 71.653,10.124 C72.273,10.124 72.814,10.43 73.067,10.918 L73.137,10.918 L73.137,10.199 L73.989,10.199 L73.989,14.739 C73.989,15.769 73.182,16.422 71.909,16.422 C70.784,16.422 70.056,15.918 69.961,15.152 L69.961,15.152 Z"
              id="Fill-15"
              fill="#FFFFFE"
            />
            <path
              d="M76.086,12.035 L78.361,12.035 C78.34,11.328 77.911,10.868 77.252,10.868 C76.595,10.868 76.136,11.332 76.086,12.035 Z M79.212,13.486 C79.01,14.292 78.29,14.789 77.261,14.789 C75.971,14.789 75.181,13.904 75.181,12.464 C75.181,11.025 75.988,10.112 77.257,10.112 C78.51,10.112 79.266,10.968 79.266,12.382 L79.266,12.692 L76.086,12.692 L76.086,12.742 C76.116,13.531 76.575,14.032 77.286,14.032 C77.824,14.032 78.192,13.837 78.357,13.486 L79.212,13.486 L79.212,13.486 Z"
              id="Fill-16"
              fill="#FFFFFE"
            />
            <path
              d="M80.46,10.199 L81.315,10.199 L81.315,10.889 L81.381,10.889 C81.509,10.447 82.009,10.124 82.597,10.124 C82.729,10.124 82.899,10.136 82.994,10.161 L82.994,11.038 C82.919,11.013 82.655,10.984 82.498,10.984 C81.824,10.984 81.348,11.41 81.348,12.043 L81.348,14.702 L80.46,14.702 L80.46,10.199"
              id="Fill-17"
              fill="#FFFFFE"
            />
            <path
              d="M87.114,12.448 C87.114,13.403 87.564,13.978 88.317,13.978 C89.066,13.978 89.529,13.395 89.529,12.452 C89.529,11.514 89.061,10.922 88.317,10.922 C87.569,10.922 87.114,11.501 87.114,12.448 Z M86.196,12.448 C86.196,11.025 86.927,10.124 88.065,10.124 C88.681,10.124 89.202,10.417 89.446,10.914 L89.512,10.914 L89.512,8.441 L90.401,8.441 L90.401,14.702 L89.549,14.702 L89.549,13.99 L89.479,13.99 C89.211,14.482 88.685,14.776 88.065,14.776 C86.919,14.776 86.196,13.875 86.196,12.448 L86.196,12.448 Z"
              id="Fill-18"
              fill="#FFFFFE"
            />
            <path
              d="M94.497,13.044 L94.497,12.667 L93.397,12.737 C92.777,12.779 92.496,12.99 92.496,13.387 C92.496,13.792 92.847,14.028 93.331,14.028 C94.001,14.028 94.497,13.602 94.497,13.044 Z M91.602,13.428 C91.602,12.618 92.206,12.15 93.277,12.084 L94.497,12.014 L94.497,11.625 C94.497,11.149 94.182,10.881 93.575,10.881 C93.079,10.881 92.735,11.063 92.636,11.381 L91.776,11.381 C91.867,10.608 92.594,10.112 93.616,10.112 C94.745,10.112 95.381,10.674 95.381,11.625 L95.381,14.702 L94.526,14.702 L94.526,14.069 L94.456,14.069 C94.187,14.52 93.695,14.776 93.103,14.776 C92.235,14.776 91.602,14.251 91.602,13.428 L91.602,13.428 Z"
              id="Fill-19"
              fill="#FFFFFE"
            />
            <path
              d="M96.773,10.199 L97.628,10.199 L97.628,10.914 L97.695,10.914 C97.914,10.414 98.361,10.112 99.039,10.112 C100.043,10.112 100.597,10.715 100.597,11.787 L100.597,14.702 L99.709,14.702 L99.709,12.01 C99.709,11.286 99.394,10.926 98.737,10.926 C98.08,10.926 97.662,11.365 97.662,12.067 L97.662,14.702 L96.773,14.702 L96.773,10.199"
              id="Fill-20"
              fill="#FFFFFE"
            />
            <path
              d="M103.618,10.112 C104.63,10.112 105.292,10.583 105.379,11.377 L104.527,11.377 C104.445,11.046 104.122,10.835 103.618,10.835 C103.122,10.835 102.745,11.071 102.745,11.422 C102.745,11.691 102.972,11.861 103.46,11.973 L104.209,12.146 C105.065,12.345 105.466,12.713 105.466,13.375 C105.466,14.222 104.676,14.789 103.601,14.789 C102.53,14.789 101.832,14.305 101.752,13.507 L102.642,13.507 C102.753,13.854 103.084,14.069 103.622,14.069 C104.175,14.069 104.569,13.821 104.569,13.461 C104.569,13.192 104.358,13.019 103.907,12.911 L103.122,12.729 C102.265,12.526 101.869,12.142 101.869,11.472 C101.869,10.674 102.6,10.112 103.618,10.112"
              id="Fill-21"
              fill="#FFFFFE"
            />
            <polygon
              id="Fill-22"
              fill="#FFFFFE"
              points="35.198 30.485 37.057 30.485 37.057 18.067 35.198 18.067"
            />
            <polyline
              id="Fill-23"
              fill="#FFFFFE"
              points="39.298 22.611 40.313 18.067 42.12 18.067 40.89 22.611 39.298 22.611"
            />
            <path
              d="M44.902,25.58 L48.654,25.58 L46.805,20.132 L46.753,20.132 L44.902,25.58 Z M49.146,27.129 L44.412,27.129 L43.275,30.485 L41.271,30.485 L45.754,18.067 L47.837,18.067 L52.32,30.485 L50.281,30.485 L49.146,27.129 L49.146,27.129 Z"
              id="Fill-24"
              fill="#FFFFFE"
            />
            <path
              d="M60.093,25.959 C60.093,24.125 59.146,22.92 57.7,22.92 C56.28,22.92 55.325,24.151 55.325,25.959 C55.325,27.783 56.28,29.005 57.7,29.005 C59.146,29.005 60.093,27.809 60.093,25.959 Z M62.003,25.959 C62.003,28.772 60.497,30.58 58.225,30.58 C56.935,30.58 55.91,30.003 55.376,28.996 L55.333,28.996 L55.333,33.481 L53.475,33.481 L53.475,21.431 L55.273,21.431 L55.273,22.937 L55.308,22.937 C55.824,21.965 56.926,21.336 58.19,21.336 C60.488,21.336 62.003,23.153 62.003,25.959 L62.003,25.959 Z"
              id="Fill-25"
              fill="#FFFFFE"
            />
            <path
              d="M70.057,25.959 C70.057,24.125 69.11,22.92 67.665,22.92 C66.245,22.92 65.29,24.151 65.29,25.959 C65.29,27.783 66.245,29.005 67.665,29.005 C69.11,29.005 70.057,27.809 70.057,25.959 Z M71.967,25.959 C71.967,28.772 70.461,30.58 68.189,30.58 C66.899,30.58 65.874,30.003 65.34,28.996 L65.297,28.996 L65.297,33.481 L63.439,33.481 L63.439,21.431 L65.238,21.431 L65.238,22.937 L65.272,22.937 C65.789,21.965 66.89,21.336 68.155,21.336 C70.453,21.336 71.967,23.153 71.967,25.959 L71.967,25.959 Z"
              id="Fill-26"
              fill="#FFFFFE"
            />
            <path
              d="M78.553,27.025 C78.691,28.257 79.887,29.065 81.522,29.065 C83.088,29.065 84.215,28.257 84.215,27.147 C84.215,26.183 83.536,25.605 81.926,25.21 L80.317,24.822 C78.037,24.271 76.978,23.205 76.978,21.474 C76.978,19.332 78.845,17.86 81.497,17.86 C84.121,17.86 85.919,19.332 85.98,21.474 L84.104,21.474 C83.992,20.235 82.967,19.487 81.47,19.487 C79.973,19.487 78.949,20.244 78.949,21.345 C78.949,22.223 79.603,22.74 81.204,23.135 L82.572,23.471 C85.12,24.074 86.178,25.098 86.178,26.914 C86.178,29.237 84.328,30.692 81.384,30.692 C78.63,30.692 76.771,29.272 76.651,27.025 L78.553,27.025"
              id="Fill-27"
              fill="#FFFFFE"
            />
            <path
              d="M90.189,19.289 L90.189,21.431 L91.911,21.431 L91.911,22.903 L90.189,22.903 L90.189,27.895 C90.189,28.67 90.534,29.031 91.29,29.031 C91.48,29.031 91.782,29.005 91.902,28.988 L91.902,30.451 C91.696,30.503 91.283,30.537 90.87,30.537 C89.037,30.537 88.322,29.849 88.322,28.093 L88.322,22.903 L87.005,22.903 L87.005,21.431 L88.322,21.431 L88.322,19.289 L90.189,19.289"
              id="Fill-28"
              fill="#FFFFFE"
            />
            <path
              d="M99.602,25.959 C99.602,24.004 98.707,22.851 97.201,22.851 C95.695,22.851 94.799,24.013 94.799,25.959 C94.799,27.921 95.695,29.065 97.201,29.065 C98.707,29.065 99.602,27.921 99.602,25.959 Z M92.907,25.959 C92.907,23.11 94.585,21.32 97.201,21.32 C99.826,21.32 101.496,23.11 101.496,25.959 C101.496,28.815 99.835,30.598 97.201,30.598 C94.568,30.598 92.907,28.815 92.907,25.959 L92.907,25.959 Z"
              id="Fill-29"
              fill="#FFFFFE"
            />
            <path
              d="M103.028,21.431 L104.8,21.431 L104.8,22.972 L104.843,22.972 C105.127,21.948 105.954,21.336 107.021,21.336 C107.288,21.336 107.511,21.372 107.658,21.406 L107.658,23.144 C107.511,23.083 107.184,23.032 106.823,23.032 C105.626,23.032 104.886,23.841 104.886,25.115 L104.886,30.485 L103.028,30.485 L103.028,21.431"
              id="Fill-30"
              fill="#FFFFFE"
            />
            <path
              d="M109.944,25.124 L114.47,25.124 C114.427,23.738 113.541,22.826 112.25,22.826 C110.967,22.826 110.039,23.755 109.944,25.124 Z M116.226,27.826 C115.976,29.47 114.375,30.598 112.328,30.598 C109.694,30.598 108.059,28.833 108.059,26.002 C108.059,23.162 109.703,21.32 112.25,21.32 C114.754,21.32 116.33,23.041 116.33,25.786 L116.33,26.423 L109.935,26.423 L109.935,26.535 C109.935,28.084 110.908,29.1 112.371,29.1 C113.403,29.1 114.212,28.609 114.461,27.826 L116.226,27.826 L116.226,27.826 Z"
              id="Fill-31"
              fill="#FFFFFE"
            />
          </g>
        </g>
      </svg>
    );
  }

  return (
    <svg
      width="120px"
      height="40px"
      viewBox="0 0 120 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <polygon id="path-1" points="0 0.04 119.664 0.04 119.664 40 0 40" />
      </defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Download_on_the_App_Store_Badge_US-UK_blk_092917">
          <g id="Group-3">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1" />
            </mask>
            <g id="Clip-2" />
            <path
              d="M110.134,0 L9.535,0 C9.168,0 8.806,0 8.44,0.002 C8.134,0.004 7.83,0.01 7.521,0.015 C6.855,0.031 6.182,0.072 5.517,0.191 C4.848,0.309 4.226,0.509 3.616,0.818 C3.018,1.125 2.471,1.523 1.998,1.997 C1.519,2.471 1.123,3.02 0.819,3.618 C0.508,4.227 0.311,4.852 0.194,5.521 C0.074,6.184 0.032,6.854 0.015,7.523 C0.006,7.83 0.005,8.138 0,8.444 C0,8.807 0,9.17 0,9.536 L0,30.465 C0,30.834 0,31.195 0,31.559 C0.005,31.869 0.006,32.17 0.015,32.48 C0.032,33.15 0.074,33.82 0.194,34.482 C0.311,35.152 0.508,35.78 0.819,36.387 C1.123,36.982 1.519,37.531 1.998,38.001 C2.471,38.479 3.018,38.876 3.616,39.18 C4.226,39.492 4.848,39.69 5.517,39.811 C6.182,39.93 6.855,39.969 7.521,39.987 C7.83,39.994 8.134,39.998 8.44,39.998 C8.806,40 9.168,40 9.535,40 L110.134,40 C110.495,40 110.859,40 111.218,39.998 C111.524,39.998 111.836,39.994 112.141,39.987 C112.811,39.969 113.483,39.93 114.141,39.811 C114.811,39.69 115.433,39.492 116.049,39.18 C116.646,38.876 117.193,38.479 117.667,38.001 C118.142,37.531 118.539,36.982 118.847,36.387 C119.154,35.78 119.354,35.152 119.466,34.482 C119.59,33.82 119.628,33.15 119.653,32.48 C119.656,32.17 119.656,31.869 119.656,31.559 C119.664,31.195 119.664,30.834 119.664,30.465 L119.664,9.536 C119.664,9.17 119.664,8.807 119.656,8.444 C119.656,8.138 119.656,7.83 119.653,7.523 C119.628,6.854 119.59,6.184 119.466,5.521 C119.354,4.852 119.154,4.227 118.847,3.618 C118.539,3.02 118.142,2.471 117.667,1.997 C117.193,1.523 116.646,1.125 116.049,0.818 C115.433,0.509 114.811,0.309 114.141,0.191 C113.483,0.072 112.811,0.031 112.141,0.015 C111.836,0.01 111.524,0.004 111.218,0.002 C110.859,0 110.495,0 110.134,0"
              id="Fill-1"
              fill="#A9AAA9"
              mask="url(#mask-2)"
            />
          </g>
          <path
            d="M8.445,39.125 C8.14,39.125 7.843,39.121 7.541,39.114 C6.981,39.099 6.318,39.067 5.671,38.951 C5.061,38.841 4.519,38.661 4.015,38.403 C3.493,38.139 3.024,37.797 2.618,37.387 C2.204,36.98 1.863,36.514 1.597,35.99 C1.338,35.485 1.161,34.943 1.054,34.333 C0.932,33.66 0.901,32.978 0.888,32.458 C0.881,32.247 0.873,31.545 0.873,31.545 L0.873,8.444 C0.873,8.444 0.882,7.753 0.888,7.55 C0.901,7.026 0.932,6.344 1.053,5.678 C1.16,5.064 1.337,4.522 1.597,4.016 C1.862,3.491 2.203,3.022 2.612,2.618 C3.025,2.204 3.496,1.861 4.014,1.595 C4.528,1.334 5.069,1.156 5.667,1.051 C6.341,0.931 7.023,0.9 7.543,0.887 L8.445,0.875 L111.213,0.875 L112.126,0.888 C112.639,0.9 113.322,0.931 113.985,1.05 C114.587,1.156 115.133,1.335 115.656,1.598 C116.169,1.861 116.638,2.204 117.047,2.614 C117.458,3.02 117.799,3.492 118.071,4.018 C118.329,4.529 118.504,5.071 118.607,5.667 C118.722,6.298 118.758,6.946 118.78,7.554 C118.783,7.837 118.783,8.142 118.783,8.444 C118.792,8.819 118.792,9.176 118.792,9.536 L118.792,30.465 C118.792,30.828 118.792,31.183 118.783,31.54 C118.783,31.865 118.783,32.163 118.779,32.47 C118.758,33.059 118.722,33.706 118.608,34.323 C118.504,34.936 118.329,35.478 118.068,35.993 C117.799,36.513 117.456,36.982 117.053,37.379 C116.639,37.797 116.17,38.138 115.654,38.401 C115.134,38.665 114.605,38.84 113.985,38.951 C113.345,39.067 112.682,39.099 112.116,39.114 C111.824,39.121 111.516,39.125 111.218,39.125 L110.134,39.127 L8.445,39.125"
            id="Fill-4"
            fill="#000000"
          />
          <path
            d="M24.769,20.301 C24.744,17.55 27.021,16.211 27.126,16.148 C25.836,14.268 23.836,14.011 23.134,13.991 C21.455,13.814 19.827,14.996 18.971,14.996 C18.099,14.996 16.782,14.008 15.363,14.038 C13.537,14.066 11.827,15.124 10.89,16.766 C8.956,20.114 10.399,25.035 12.251,27.741 C13.178,29.067 14.261,30.548 15.679,30.495 C17.066,30.438 17.584,29.61 19.258,29.61 C20.917,29.61 21.403,30.495 22.849,30.462 C24.337,30.438 25.275,29.13 26.17,27.792 C27.241,26.273 27.671,24.776 27.688,24.7 C27.653,24.688 24.797,23.599 24.769,20.301"
            id="Fill-5"
            fill="#FFFFFE"
          />
          <path
            d="M22.037,12.211 C22.783,11.278 23.293,10.009 23.152,8.72 C22.072,8.768 20.722,9.467 19.944,10.38 C19.256,11.184 18.642,12.502 18.8,13.741 C20.013,13.832 21.259,13.129 22.037,12.211"
            id="Fill-6"
            fill="#FFFFFE"
          />
          <path
            d="M38.059,25.591 L41.811,25.591 L39.961,20.144 L39.91,20.144 L38.059,25.591 Z M42.302,27.14 L37.569,27.14 L36.432,30.496 L34.427,30.496 L38.911,18.078 L40.994,18.078 L45.477,30.496 L43.438,30.496 L42.302,27.14 L42.302,27.14 Z"
            id="Fill-7"
            fill="#FFFFFE"
          />
          <path
            d="M53.25,25.97 C53.25,24.137 52.302,22.932 50.857,22.932 C49.437,22.932 48.482,24.162 48.482,25.97 C48.482,27.794 49.437,29.016 50.857,29.016 C52.302,29.016 53.25,27.819 53.25,25.97 Z M55.16,25.97 C55.16,28.783 53.654,30.591 51.381,30.591 C50.091,30.591 49.067,30.014 48.533,29.007 L48.49,29.007 L48.49,33.491 L46.631,33.491 L46.631,21.442 L48.43,21.442 L48.43,22.948 L48.464,22.948 C48.981,21.977 50.083,21.348 51.347,21.348 C53.645,21.348 55.16,23.164 55.16,25.97 L55.16,25.97 Z"
            id="Fill-8"
            fill="#FFFFFE"
          />
          <path
            d="M63.214,25.97 C63.214,24.137 62.267,22.932 60.822,22.932 C59.402,22.932 58.447,24.162 58.447,25.97 C58.447,27.794 59.402,29.016 60.822,29.016 C62.267,29.016 63.214,27.819 63.214,25.97 Z M65.124,25.97 C65.124,28.783 63.619,30.591 61.346,30.591 C60.056,30.591 59.032,30.014 58.498,29.007 L58.455,29.007 L58.455,33.491 L56.596,33.491 L56.596,21.442 L58.395,21.442 L58.395,22.948 L58.429,22.948 C58.946,21.977 60.047,21.348 61.312,21.348 C63.61,21.348 65.124,23.164 65.124,25.97 L65.124,25.97 Z"
            id="Fill-9"
            fill="#FFFFFE"
          />
          <path
            d="M71.711,27.036 C71.848,28.268 73.045,29.076 74.679,29.076 C76.246,29.076 77.373,28.268 77.373,27.157 C77.373,26.193 76.693,25.616 75.084,25.221 L73.474,24.833 C71.194,24.282 70.135,23.216 70.135,21.485 C70.135,19.343 72.003,17.871 74.654,17.871 C77.278,17.871 79.077,19.343 79.137,21.485 L77.261,21.485 C77.149,20.246 76.124,19.498 74.628,19.498 C73.13,19.498 72.106,20.255 72.106,21.356 C72.106,22.234 72.76,22.751 74.361,23.147 L75.729,23.482 C78.277,24.085 79.336,25.108 79.336,26.925 C79.336,29.248 77.485,30.703 74.542,30.703 C71.788,30.703 69.928,29.282 69.808,27.036 L71.711,27.036"
            id="Fill-10"
            fill="#FFFFFE"
          />
          <path
            d="M83.346,19.3 L83.346,21.442 L85.068,21.442 L85.068,22.914 L83.346,22.914 L83.346,27.905 C83.346,28.681 83.691,29.042 84.448,29.042 C84.637,29.042 84.939,29.016 85.059,28.999 L85.059,30.462 C84.853,30.514 84.44,30.548 84.027,30.548 C82.194,30.548 81.479,29.859 81.479,28.104 L81.479,22.914 L80.163,22.914 L80.163,21.442 L81.479,21.442 L81.479,19.3 L83.346,19.3"
            id="Fill-11"
            fill="#FFFFFE"
          />
          <path
            d="M92.76,25.97 C92.76,24.016 91.865,22.862 90.359,22.862 C88.853,22.862 87.959,24.024 87.959,25.97 C87.959,27.932 88.853,29.076 90.359,29.076 C91.865,29.076 92.76,27.932 92.76,25.97 Z M86.065,25.97 C86.065,23.121 87.743,21.331 90.359,21.331 C92.984,21.331 94.654,23.121 94.654,25.97 C94.654,28.826 92.993,30.608 90.359,30.608 C87.726,30.608 86.065,28.826 86.065,25.97 L86.065,25.97 Z"
            id="Fill-12"
            fill="#FFFFFE"
          />
          <path
            d="M96.186,21.442 L97.959,21.442 L97.959,22.983 L98.002,22.983 C98.286,21.959 99.112,21.348 100.179,21.348 C100.446,21.348 100.669,21.383 100.816,21.417 L100.816,23.155 C100.669,23.095 100.342,23.043 99.981,23.043 C98.785,23.043 98.045,23.853 98.045,25.126 L98.045,30.496 L96.186,30.496 L96.186,21.442"
            id="Fill-13"
            fill="#FFFFFE"
          />
          <path
            d="M103.102,25.135 L107.628,25.135 C107.585,23.749 106.699,22.837 105.408,22.837 C104.126,22.837 103.197,23.767 103.102,25.135 Z M109.384,27.837 C109.134,29.481 107.534,30.608 105.486,30.608 C102.852,30.608 101.217,28.844 101.217,26.013 C101.217,23.173 102.861,21.331 105.408,21.331 C107.913,21.331 109.488,23.052 109.488,25.797 L109.488,26.434 L103.093,26.434 L103.093,26.546 C103.093,28.095 104.066,29.11 105.529,29.11 C106.561,29.11 107.371,28.62 107.62,27.837 L109.384,27.837 L109.384,27.837 Z"
            id="Fill-14"
            fill="#FFFFFE"
          />
          <path
            d="M36.598,13.854 L37.723,13.854 C38.971,13.854 39.69,13.077 39.69,11.708 C39.69,10.36 38.959,9.574 37.723,9.574 L36.598,9.574 L36.598,13.854 Z M37.826,8.731 C39.596,8.731 40.634,9.818 40.634,11.696 C40.634,13.602 39.604,14.698 37.826,14.698 L35.671,14.698 L35.671,8.731 L37.826,8.731 L37.826,8.731 Z"
            id="Fill-15"
            fill="#FFFFFE"
          />
          <path
            d="M45.014,12.444 C45.014,11.468 44.575,10.898 43.806,10.898 C43.033,10.898 42.599,11.468 42.599,12.444 C42.599,13.428 43.033,13.995 43.806,13.995 C44.575,13.995 45.014,13.424 45.014,12.444 Z M41.681,12.444 C41.681,10.993 42.491,10.108 43.806,10.108 C45.117,10.108 45.928,10.993 45.928,12.444 C45.928,13.904 45.121,14.785 43.806,14.785 C42.487,14.785 41.681,13.904 41.681,12.444 L41.681,12.444 Z"
            id="Fill-16"
            fill="#FFFFFE"
          />
          <polyline
            id="Fill-17"
            fill="#FFFFFE"
            points="51.573 14.698 50.651 14.698 49.721 11.381 49.65 11.381 48.724 14.698 47.811 14.698 46.569 10.195 47.471 10.195 48.277 13.631 48.344 13.631 49.27 10.195 50.122 10.195 51.048 13.631 51.118 13.631 51.921 10.195 52.81 10.195 51.573 14.698"
          />
          <path
            d="M53.854,10.195 L54.709,10.195 L54.709,10.91 L54.775,10.91 C54.994,10.41 55.441,10.108 56.119,10.108 C57.124,10.108 57.678,10.711 57.678,11.783 L57.678,14.698 L56.789,14.698 L56.789,12.006 C56.789,11.282 56.475,10.922 55.817,10.922 C55.16,10.922 54.742,11.361 54.742,12.063 L54.742,14.698 L53.854,14.698 L53.854,10.195"
            id="Fill-18"
            fill="#FFFFFE"
          />
          <polygon
            id="Fill-19"
            fill="#FFFFFE"
            points="59.094 14.698 59.983 14.698 59.983 8.437 59.094 8.437"
          />
          <path
            d="M64.551,12.444 C64.551,11.468 64.112,10.898 63.343,10.898 C62.57,10.898 62.136,11.468 62.136,12.444 C62.136,13.428 62.57,13.995 63.343,13.995 C64.112,13.995 64.551,13.424 64.551,12.444 Z M61.218,12.444 C61.218,10.993 62.028,10.108 63.343,10.108 C64.654,10.108 65.465,10.993 65.465,12.444 C65.465,13.904 64.658,14.785 63.343,14.785 C62.024,14.785 61.218,13.904 61.218,12.444 L61.218,12.444 Z"
            id="Fill-20"
            fill="#FFFFFE"
          />
          <path
            d="M69.295,13.04 L69.295,12.663 L68.196,12.733 C67.576,12.775 67.295,12.986 67.295,13.383 C67.295,13.788 67.646,14.024 68.129,14.024 C68.799,14.024 69.295,13.598 69.295,13.04 Z M66.401,13.424 C66.401,12.614 67.004,12.146 68.076,12.08 L69.295,12.01 L69.295,11.621 C69.295,11.146 68.981,10.877 68.374,10.877 C67.878,10.877 67.534,11.059 67.435,11.377 L66.575,11.377 C66.666,10.604 67.393,10.108 68.415,10.108 C69.544,10.108 70.18,10.67 70.18,11.621 L70.18,14.698 L69.325,14.698 L69.325,14.065 L69.254,14.065 C68.986,14.516 68.494,14.772 67.902,14.772 C67.034,14.772 66.401,14.247 66.401,13.424 L66.401,13.424 Z"
            id="Fill-21"
            fill="#FFFFFE"
          />
          <path
            d="M72.266,12.444 C72.266,13.399 72.716,13.974 73.469,13.974 C74.218,13.974 74.681,13.391 74.681,12.448 C74.681,11.51 74.213,10.918 73.469,10.918 C72.721,10.918 72.266,11.497 72.266,12.444 Z M71.348,12.444 C71.348,11.021 72.08,10.12 73.217,10.12 C73.834,10.12 74.354,10.414 74.598,10.91 L74.665,10.91 L74.665,8.437 L75.553,8.437 L75.553,14.698 L74.702,14.698 L74.702,13.986 L74.631,13.986 C74.363,14.479 73.837,14.772 73.217,14.772 C72.072,14.772 71.348,13.871 71.348,12.444 L71.348,12.444 Z"
            id="Fill-22"
            fill="#FFFFFE"
          />
          <path
            d="M82.563,12.444 C82.563,11.468 82.124,10.898 81.355,10.898 C80.582,10.898 80.148,11.468 80.148,12.444 C80.148,13.428 80.582,13.995 81.355,13.995 C82.124,13.995 82.563,13.424 82.563,12.444 Z M79.23,12.444 C79.23,10.993 80.041,10.108 81.355,10.108 C82.666,10.108 83.477,10.993 83.477,12.444 C83.477,13.904 82.67,14.785 81.355,14.785 C80.037,14.785 79.23,13.904 79.23,12.444 L79.23,12.444 Z"
            id="Fill-23"
            fill="#FFFFFE"
          />
          <path
            d="M84.67,10.195 L85.525,10.195 L85.525,10.91 L85.591,10.91 C85.81,10.41 86.257,10.108 86.935,10.108 C87.94,10.108 88.494,10.711 88.494,11.783 L88.494,14.698 L87.605,14.698 L87.605,12.006 C87.605,11.282 87.291,10.922 86.633,10.922 C85.976,10.922 85.558,11.361 85.558,12.063 L85.558,14.698 L84.67,14.698 L84.67,10.195"
            id="Fill-24"
            fill="#FFFFFE"
          />
          <path
            d="M93.515,9.074 L93.515,10.215 L94.491,10.215 L94.491,10.964 L93.515,10.964 L93.515,13.279 C93.515,13.751 93.71,13.958 94.152,13.958 C94.289,13.958 94.367,13.949 94.491,13.937 L94.491,14.677 C94.346,14.702 94.18,14.723 94.007,14.723 C93.019,14.723 92.626,14.375 92.626,13.507 L92.626,10.964 L91.911,10.964 L91.911,10.215 L92.626,10.215 L92.626,9.074 L93.515,9.074"
            id="Fill-25"
            fill="#FFFFFE"
          />
          <path
            d="M95.705,8.437 L96.586,8.437 L96.586,10.918 L96.656,10.918 C96.874,10.414 97.35,10.112 98.029,10.112 C98.988,10.112 99.58,10.72 99.58,11.791 L99.58,14.698 L98.69,14.698 L98.69,12.01 C98.69,11.291 98.355,10.926 97.727,10.926 C96.999,10.926 96.593,11.386 96.593,12.068 L96.593,14.698 L95.705,14.698 L95.705,8.437"
            id="Fill-26"
            fill="#FFFFFE"
          />
          <path
            d="M101.635,12.031 L103.91,12.031 C103.889,11.324 103.459,10.864 102.801,10.864 C102.144,10.864 101.685,11.328 101.635,12.031 Z M104.761,13.482 C104.559,14.289 103.839,14.785 102.81,14.785 C101.52,14.785 100.73,13.9 100.73,12.46 C100.73,11.021 101.537,10.108 102.806,10.108 C104.059,10.108 104.815,10.964 104.815,12.378 L104.815,12.688 L101.635,12.688 L101.635,12.738 C101.665,13.527 102.124,14.028 102.834,14.028 C103.373,14.028 103.741,13.833 103.906,13.482 L104.761,13.482 L104.761,13.482 Z"
            id="Fill-27"
            fill="#FFFFFE"
          />
        </g>
      </g>
    </svg>
  );
};

AppStore.propTypes = {
  locale: PropTypes.string
};

AppStore.defaultProps = {
  locale: "en"
};

AppStore.displayName = "AppStore";

export default AppStore;
