import { constants } from "../constants";
import {
  getBillingBalances,
  getPayments,
  getPaymentDetails,
  getTransactionDetails
} from "../api/customer";

export const fetchBillingBalancesSuccess = payload => {
  return { type: constants.BILLING_BALANCES_INFO, payload };
};

export const fetchBillingBalances = () => async dispatch => {
  try {
    const billingBalances = await getBillingBalances();
    dispatch(fetchBillingBalancesSuccess(billingBalances));
  } catch (error) {
    // TODO dispatch another action for spinner and network
  }
};

export const fetchPayments = ({ from, to, count, page }) => async dispatch => {
  try {
    const payments = await getPayments({ from, to, count, page });
    dispatch({ type: constants.BILLING_PAYMENTS, payload: payments });
  } catch (error) {
    // TODO dispatch another action for spinner and network
  }
};

export const resetPayments = () => ({ type: constants.RESET_PAYMENTS });

export const fetchPaymentDetails = paymentId => async dispatch => {
  try {
    const pid = paymentId !== "pending" ? paymentId : undefined;
    const paymentDetails = await getPaymentDetails(pid);
    dispatch({
      type: constants.BILLING_PAYMENT_DETAILS,
      payload: { paymentId, paymentDetails }
    });
  } catch (error) {
    // TODO dispatch another action for spinner and network
  }
};

export const fetchTransactionDeatils = transcalcId => async dispatch => {
  try {
    const transactionDetails = await getTransactionDetails(transcalcId);
    dispatch({
      type: constants.BILLING_TRANSACTION_DETAILS,
      payload: { transcalcId, transactionDetails }
    });
  } catch (error) {
    // TODO dispatch another action for spinner and network
  }
};
