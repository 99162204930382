import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl, FormattedMessage } from "react-intl";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import { Link } from "react-router-dom";

import { messages, constants } from "../../constants";
import * as selectors from "../../selectors";
import { ConnectedLinkList, Section, Grid, Col } from "../../components";
import { Title } from "../../elements";
import * as bannerActions from "../../actions/banner";
import { formatCurrency } from "../../util";

export class AccountLanding extends PureComponent {
  componentDidMount() {
    this.checkAccountStatus();
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps.user, this.props.user) ||
      !isEqual(prevProps.billing, this.props.billing)
    ) {
      this.checkAccountStatus();
    }
  }

  checkAccountStatus = () => {
    const { intl, user, banner } = this.props;
    const alerts = [];

    // check if account is suspended
    const suspendStatus = get(user, "accountSummary.accountSuspendStatus");
    const outstandingBalance = formatCurrency(get(user, "accountSummary.outstandingBalance") || 0);

    if (suspendStatus) {
      switch (suspendStatus) {
        case constants.ACCOUNT_STATUS.SUSPENDEDFLAG:
          alerts.push({
            level: constants.ALERT_LEVEL.warning,
            message: intl.formatMessage(messages.warning.userSuspended)
          });
          break;
        case constants.ACCOUNT_STATUS.SUSPENDEDOSB:
          alerts.push({
            level: constants.ALERT_LEVEL.warning,
            message: (
              <FormattedMessage
                {...messages.warning.accountSuspended}
                values={{
                  outstandingBalance,
                  contactLink: (
                    <a href="https://www.evo.ca/contact" target="_blank" rel="noopener noreferrer">
                      contact us
                    </a>
                  ),
                  paymentLink: (
                    <Link to="/account/payment">
                      {intl.formatMessage(messages.link.updateCCInfo).toLowerCase()}
                    </Link>
                  )
                }}
              />
            )
          });
          break;
        default:
        // do nothing
      }
    }

    // check DL if it's expired
    const driverLicenceActiveStatus = get(user, "accountSummary.dlIsActive");
    if (!driverLicenceActiveStatus) {
      alerts.push({
        level: constants.ALERT_LEVEL.warning,
        message: (
          <FormattedMessage
            {...messages.warning.driverLicenceExpired}
            values={{
              dlLink: (
                <Link to="/account/drivers">{intl.formatMessage(messages.link.updateDLInfo)}</Link>
              )
            }}
          />
        )
      });
    }

    // check CC if it's expired
    const creditCardActiveStatus = get(user, "accountSummary.ccIsActive");
    if (!creditCardActiveStatus) {
      alerts.push({
        level: constants.ALERT_LEVEL.warning,
        message: (
          <FormattedMessage
            {...messages.warning.creditCardExpired}
            values={{
              paymentLink: (
                <Link to="/account/payment">{intl.formatMessage(messages.link.updateCCInfo)}</Link>
              )
            }}
          />
        )
      });
    }

    const drivingRecordStatus = get(user, "accountSummary.dlRecordStatus");
    if (drivingRecordStatus) {
      switch (drivingRecordStatus) {
        case constants.DRIVING_RECORD_STATUS.PENDING:
          alerts.push({
            level: constants.ALERT_LEVEL.warning,
            message: intl.formatMessage(messages.warning.pendingDrivingRecordApproval)
          });
          break;
        case constants.DRIVING_RECORD_STATUS.DECLINED:
          alerts.push({
            level: constants.ALERT_LEVEL.warning,
            message: intl.formatMessage(messages.warning.pendingDrivingRecordStatus)
          });
          break;
        default:
        // do nothing
      }
    }

    if (banner && alerts.length) {
      banner.setAlerts(alerts);
    }
  };

  render() {
    const { intl, user } = this.props;
    const name =
      get(user, "personalInfo.preferredName", "") || get(user, "accountSummary.preferredName", "") || get(user, "accountSummary.firstName", "");
    return (
      <div data-testid="screen-account-landing">
        <Section>
          <Grid>
            <Col>
              <Title
                data-testid="account-landing-greeting"
                priority={1}
                type={["strong--no-cap"]}
                className="h2"
              >
                {intl.formatMessage(messages.header.greeting, { name })}
              </Title>
            </Col>
          </Grid>
        </Section>

        <Section size="1-2" min="md">
          <Grid>
            <Col>
              <ConnectedLinkList theme={["lined"]}>{this.getConnectedLinkList()}</ConnectedLinkList>
            </Col>
          </Grid>
        </Section>
      </div>
    );
  }

  getConnectedLinkList = () => {
    return [
      {
        link: constants.ROUTE_ACCOUNT_LOGIN_INFO,
        title: this.props.intl.formatMessage(messages.title.LoginInfo),
        content: this.props.intl.formatMessage(messages.edit.LoginInfo)
      },
      {
        link: constants.ROUTE_ACCOUNT_PERSONAL,
        title: this.props.intl.formatMessage(messages.title.PersonalInfo),
        content: this.props.intl.formatMessage(messages.edit.PersonalInfo)
      },
      {
        link: constants.ROUTE_ACCOUNT_DRIVERS,
        title: this.props.intl.formatMessage(messages.title.DriversLicenseInfo),
        content: this.props.intl.formatMessage(messages.edit.DriversLicenseInfo)
      },
      {
        link: constants.ROUTE_ACCOUNT_PAYMENT,
        title: this.props.intl.formatMessage(messages.title.PaymentInfo),
        content: this.props.intl.formatMessage(messages.edit.PaymentInfo)
      },
      {
        link: constants.ROUTE_ACCOUNT_PREFERENCE_CENTER,
        title: this.props.intl.formatMessage(messages.title.Preferences),
        content: this.props.intl.formatMessage(messages.edit.Preferences)
      }
    ];
  };
}

AccountLanding.propTypes = {
  intl: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  billing: PropTypes.object,
  banner: PropTypes.objectOf(PropTypes.func)
};

const mapStateToProps = state => ({
  user: selectors.getUser(state),
  billing: selectors.getBilling(state).balance
});

const mapDispatchToProps = dispatch => ({
  banner: bindActionCreators(bannerActions, dispatch)
});

export const IntlAccountLanding = injectIntl(AccountLanding);
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AccountLanding)
);
