import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import DOMPurify from "dompurify";
import Parser from "html-react-parser";
import "./tooltip.scss";

class Tooltip extends PureComponent {
  state = {
    focus: false
  };

  handleBlur = e => {
    const _self = this;
    const currentTarget = e.currentTarget;

    setTimeout(function() {
      if (!currentTarget.contains(document.activeElement)) {
        _self.setState({ focus: false });
      }
    }, 0);
  };

  handleFocus = () => {
    this.setState({ focus: true });
  };

  render() {
    const { className, arrow, children } = this.props;
    const tooltipCSS = cn("tooltip", `tooltip--${arrow}`, className, {
      "is-focused": this.state.focus
    });
    // tag is <span /> by default
    const CustomTag = children.type ? children.type : "small";
    // in case children is string contains html tags
    const content = children.props ? children.props.children : children;
    // sanitize the content
    const sanitized = typeof content === "string" ? Parser(DOMPurify.sanitize(content)) : content;

    return (
      <span
        className={tooltipCSS}
        tabIndex="0"
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        role="tooltip"
      >
        <CustomTag className="tooltip-content">{sanitized}</CustomTag>
      </span>
    );
  }
}

Tooltip.propTypes = {
  className: PropTypes.string,
  arrow: PropTypes.oneOf(["top", "bottom", "left", "right"]),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
};

Tooltip.defaultProps = {
  arrow: "bottom"
};

export default Tooltip;
