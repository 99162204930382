/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, SubmissionError } from "redux-form";
import { goBack, push } from "connected-react-router";

import { messages } from "../../constants";
import { Section, Grid, Col, Input } from "../../components";
import { constants } from "../../constants";
import { Title, Button } from "../../elements";
import * as auth from "../../actions/auth";
import { banner as bannerActions } from "../../actions";
import { validationBuilder, validations } from "../../containers/form";

class UpdatePassword extends PureComponent {
  generateValidationFunction = (validation, spec) => {
    return value => validationBuilder(this.props, validation, spec)(value);
  };

  rulesPassword = [
    this.generateValidationFunction(
      validations.required,
      this.props.intl.formatMessage(messages.field.password)
    )
  ];

  handleSubmit = async values => {
    const { changePassword, intl, setAlerts } = this.props;
    const { oldPassword, newPassword, confirmPassword } = values;

    let errorObject = {};
    if (oldPassword === newPassword) {
      errorObject.newPassword = intl.formatMessage(messages.error.sameAsOldPassword);
    }

    if (validations.password.validation(newPassword)) {
      errorObject.newPassword = intl.formatMessage(messages.error.password);
    }

    if (confirmPassword !== newPassword) {
      errorObject.confirmPassword = intl.formatMessage(messages.error.matchPassword);
    }

    if (Object.keys(errorObject).length > 0) {
      throw new SubmissionError(errorObject);
    }

    try {
      const user = await changePassword(values, intl);
      if (user) {
        // Update password successful
        setAlerts([
          {
            level: constants.ALERT_LEVEL.success,
            message: intl.formatMessage(messages.success.updatePassword)
          }
        ]);

        this.props.push(`${constants.ROUTE_ACCOUNT_LOGIN_INFO}?persistBanner=true`);
      }
    } catch (error) {
      if (!(error instanceof SubmissionError)) {
        setAlerts([
          {
            level: constants.ALERT_LEVEL.error,
            message: intl.formatMessage(messages.error.genericError)
          }
        ]);
      }
      throw error;
    }
  };

  handleClickCancel = () => {
    this.props.goBack();
  };

  render() {
    const { intl, handleSubmit, valid, pristine, submitting } = this.props;
    return (
      <Fragment>
        <Section theme={["div"]}>
          <Grid>
            <Col>
              <Title priority={1} type={["strong"]} className="h2">
                {intl.formatMessage(messages.title.password)}
              </Title>
            </Col>
            <Col size="2-3" min="sm">
              <form autoComplete="on" noValidate onSubmit={handleSubmit(this.handleSubmit)}>
                <Grid theme={["middle"]}>
                  <Col size="2-3" min="sm">
                    <Field
                      type="password"
                      name="oldPassword"
                      label={intl.formatMessage(messages.title.oldPassword)}
                      component={Input}
                      alignLeft
                      asyoutype={true}
                      validate={this.rulesPassword}
                      props={{ maxLength: "50" }}
                    />
                  </Col>
                </Grid>
                <Grid theme={["middle"]}>
                  <Col size="2-3" min="sm">
                    <Field
                      type="password"
                      name="newPassword"
                      label={intl.formatMessage(messages.title.newPassword)}
                      component={Input}
                      alignLeft
                      asyoutype={true}
                      validate={this.rulesPassword}
                      props={{ maxLength: "50" }}
                    />
                  </Col>
                </Grid>
                <Grid theme={["middle"]}>
                  <Col size="2-3" min="sm">
                    <Field
                      type="password"
                      name="confirmPassword"
                      label={intl.formatMessage(messages.title.confirmPassword)}
                      component={Input}
                      alignLeft
                      asyoutype={true}
                      validate={this.rulesPassword}
                      props={{ maxLength: "50" }}
                    />
                  </Col>
                </Grid>
                <Col size="2-3" min="sm">
                  <Button theme={["cta"]} type="submit" disabled={!valid || pristine || submitting}>
                    {<span>{intl.formatMessage(messages.button.save)}</span>}
                  </Button>
                  <a onClick={this.handleClickCancel} className="is-underlined">
                    {intl.formatMessage(messages.button.cancel)}
                  </a>
                </Col>
              </form>
            </Col>
          </Grid>
        </Section>
      </Fragment>
    );
  }
}

UpdatePassword.propTypes = {
  intl: PropTypes.object.isRequired,
  state: PropTypes.object
};

const formSettings = {
  form: "updatePassword", // a unique identifier for this form,
  onChange: (values, dispatch, props) => {
    // trigger change to force re-validation
    dispatch(props.change("oldPassword", values.oldPassword));
    dispatch(props.change("newPassword", values.newPassword));
    dispatch(props.change("confirmPassword", values.confirmPassword));
  }
};

const mapDispatch = {
  changePassword: auth.changePassword,
  setAlerts: bannerActions.setAlerts,
  goBack,
  push
};

const UpdatePasswordWithForm = reduxForm(formSettings)(UpdatePassword);

export default compose(
  injectIntl,
  connect(
    null,
    mapDispatch
  )
)(UpdatePasswordWithForm);
