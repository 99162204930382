import { messages } from "../constants";

export const mapTransactionLocalization = (intl, { productId, fallback = "", nameExt = 0 }) => {
  // Check src/util/README.md to see mappings for product ids

  if (productId === 7) {
    return intl.formatMessage(messages.transactions.product7, { percetage: nameExt });
  } else if (productId > 0 && productId < 27) {
    return intl.formatMessage(messages.transactions[`product${productId}`]);
  }

  // if product id is not in the localization list yet or null
  return fallback;
};
