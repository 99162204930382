import { defineMessages } from "react-intl";

const namespace = `success`;
const success = defineMessages({
  savePersonalInfo: {
    id: `${namespace}.savePersonalInfo`,
    description: "Success save personal info",
    defaultMessage: "Your personal information has been successfully changed."
  },
  saveAddress: {
    id: `${namespace}.saveAddress`,
    description: "Success save address",
    defaultMessage: "Your address has been successfully changed."
  },
  saveDriverLicence: {
    id: `${namespace}.saveDriverLicence`,
    description: "Success save DL",
    defaultMessage: "Your driver's licence has been successfully changed."
  },
  savePaymentInfo: {
    id: `${namespace}.savePaymentInfo`,
    description: "Success save payment info",
    defaultMessage: "Your payment information has been successfully changed."
  },
  saveSubscriptions: {
    id: `${namespace}.saveSubscriptions`,
    description: "Success save sub preferences",
    defaultMessage: "Your subscription preferences has been successfully changed."
  },
  savePreferenceCentre: {
    id: `${namespace}.savePreferenceCentre`,
    description: "Success save preference centre",
    defaultMessage: "Your preference centre has been successfully changed."
  },
  updatePassword: {
    id: `${namespace}.updatePassword`,
    description: "Success update user password",
    defaultMessage: "Your password has been successfully changed. "
  }
});

export default success;
