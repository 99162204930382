import { defineMessages } from "react-intl";

const namespace = `button`;
const button = defineMessages({
  submit: {
    id: `${namespace}.submit`,
    description: `Submit Button Field`,
    defaultMessage: `Submit`
  },
  continue: {
    id: `${namespace}.continue`,
    description: `Continue Button Field`,
    defaultMessage: `Continue`
  },
  login: {
    id: `${namespace}.login`,
    description: `Login Button Field`,
    defaultMessage: `Login`
  },
  signout: {
    id: `${namespace}.signout`,
    description: `Logout Button Field`,
    defaultMessage: `Sign Out`
  },
  signUp: {
    id: `${namespace}.signUp`,
    description: `Sign up Button Field`,
    defaultMessage: `Sign up`
  },
  edit: {
    id: `${namespace}.edit`,
    description: "Edit button label",
    defaultMessage: "Edit"
  },
  save: {
    id: `${namespace}.save`,
    description: "Save button label",
    defaultMessage: "Save"
  },
  apply: {
    id: `${namespace}.apply`,
    description: "Apply button label",
    defaultMessage: "Apply"
  },
  cancel: {
    id: `${namespace}.cancel`,
    description: "Cancel button label",
    defaultMessage: "Cancel"
  },
  backtoAccountDetails: {
    id: `${namespace}.backtoAccountDetails`,
    description: "Back to Account Details link label",
    defaultMessage: "Back to My Account"
  },
  backtoHome: {
    id: `${namespace}.backtoHome`,
    description: "Back to Home button",
    defaultMessage: "Back to Home"
  },
  resetPassword: {
    id: `${namespace}.resetPassword`,
    description: "Reset Password button label",
    defaultMessage: "Reset password"
  },
  goToLogin: {
    id: `${namespace}.goToLogin`,
    description: "label for go to login button",
    defaultMessage: "GO TO LOGIN"
  },
  sendMeANewLink: {
    id: `${namespace}.sendMeANewLink`,
    description: "label for SEND ME A NEW LINK button",
    defaultMessage: "SEND ME A NEW LINK"
  },
  changeEmail: {
    id: `${namespace}.changeEmail`,
    description: "Change Email Button title",
    defaultMessage: "Edit Email"
  },
  changePassword: {
    id: `${namespace}.changePassword`,
    description: "Change Password Button title",
    defaultMessage: "Edit Password"
  },
  search: {
    id: `${namespace}.search`,
    description: "Search Button title",
    defaultMessage: "Search"
  },
  backtoOverview: {
    id: `${namespace}.backtoOverview`,
    description: "Back to Overview Button title",
    defaultMessage: "Back to overview"
  },
  bookThisCar: {
    id: `${namespace}.bookThisCar`,
    description: "book this car btn",
    defaultMessage: "Book this car"
  },
  onlyBike: {
    id: `${namespace}.onlyBike`,
    description: "only bike btn",
    defaultMessage: "E-bikes/E-scooters"
  },
  bikeAndCar: {
    id: `${namespace}.bikeAndCar`,
    description: "bike and car btn",
    defaultMessage: "Car share"
  }
});

export default button;
