import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { bool } from "prop-types";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import Auth from "@aws-amplify/auth";
import cn from "classnames";
import { messages, constants } from "../../constants";
import { Button, Txt, Title } from "../../elements";
import { Section, Grid, Col, ErrorBoundary, CheckMark } from "../../components";
import { validateEmail } from "../../util";
import "./index.scss";

/**
 *
 *
 * @class ForgotPasswordScreen
 * @extends {PureComponent}
 */
export class ForgotPasswordScreen extends PureComponent {
  static displayName = "ForgotPasswordScreen";

  static propTypes = {
    intl: PropTypes.object.isRequired,
    successScreen: bool
  };

  static defaultProps = {
    successScreen: false
  };

  state = {
    inputFocused: false,
    loading: false,
    emailSent: false,
    amplifySendEmailError: null,
    clientSideValidationError: null,
    userEmail: ""
  };

  // Use a ref to grab input value directly from the node,
  // preventing any un-needed updates caused by "controlling" with react state.
  emailInputViewValue = React.createRef();

  render() {
    const { emailSent } = this.state;
    const { successScreen } = this.props;
    const currentScreen = emailSent || successScreen ? this.successView() : this.emailInputView();

    return <ErrorBoundary>{currentScreen}</ErrorBoundary>;
  }

  /**
   *
   *
   * @memberof ForgotPasswordScreen
   * @returns {React.Dom}
   */
  emailInputView = () => {
    const { intl } = this.props;
    const { loading, amplifySendEmailError, clientSideValidationError } = this.state;

    return (
      <div className="forgot-password-email">
        <Section>
          <Grid theme={["center"]}>
            <Col size="1-2" min="sm">
              <Title priority={1} type={["strong"]} className="h1 forgot-password__title blue">
                {intl.formatMessage(messages.personal.forgotYourPassword)}
              </Title>
              <Title priority={4} type={["strong"]} className="h4 forgot-password__title">
                {intl.formatMessage(messages.personal.enterYourEmailAddress)}
              </Title>
            </Col>
          </Grid>
          <Grid theme={["center"]}>
            <Col size="1-2" min="sm">
              <form autoComplete="on" noValidate>
                <fieldset className={this.getInputCSS()} role="group">
                  <div className="input-field">
                    <input
                      data-testid="forgot-password-input"
                      autoComplete="email"
                      className="forgot-password__input"
                      disabled={loading}
                      type="email"
                      placeholder={intl.formatMessage(messages.field.EmailAddress)}
                      ref={this.emailInputViewValue}
                      onFocus={this._onInputFocus}
                      onBlur={this._onInputBlur}
                      onKeyPress={e => {
                        // Submit when the user presses enter
                        if (e.key === "Enter") {
                          this._onInputBlur();
                          this.submitEmail({ exitAfterValidation: false }, e);
                        }
                      }}
                    />
                  </div>
                  {(!!amplifySendEmailError || !!clientSideValidationError) && (
                    <span data-testid="forgot-password-error-message">
                      <Txt theme={["error"]}>
                        <small>{this.fetchErrorMessages()}</small>
                      </Txt>
                    </span>
                  )}
                </fieldset>

                <Button
                  loading={loading}
                  data-testid="forgot-password-submit-button"
                  className="forgot-password__button"
                  type="submit"
                  theme={["large", "bold"]}
                  disabled={!!loading}
                  onClick={event => this.submitEmail({ exitAfterValidation: false }, event)}
                >
                  {<span>{intl.formatMessage(messages.personal.sendMeAResetLink)}</span>}
                </Button>
              </form>
              <Link to={constants.ROUTE_SIGNIN} className="is-underlined forgot-password__link">
                {intl.formatMessage(messages.button.login)}
              </Link>
            </Col>
          </Grid>
        </Section>
      </div>
    );
  };

  /**
   *
   *
   * @memberof ForgotPasswordScreen
   * @returns {React.Dom}
   */
  successView = () => {
    const { intl } = this.props;
    const { userEmail } = this.state;

    return (
      <div className="forgot-password-done">
        <Section>
          <Grid theme={["center"]}>
            <Col size="1-2" min="sm">
              <CheckMark />
              <Title priority={1} type={["strong"]} className="h1 forgot-password__title blue">
                {intl.formatMessage(messages.personal.resetLinkSent)}
              </Title>
              <Title priority={3} type={["strong"]} className="h3 forgot-password__title">
                {userEmail}
              </Title>
              <Link to={constants.ROUTE_SIGNIN} className="is-underlined forgotPasswordEmail__link">
                {intl.formatMessage(messages.button.login)}
              </Link>
            </Col>
          </Grid>
        </Section>
      </div>
    );
  };

  /**
   *
   *
   * @memberof ForgotPasswordScreen
   */
  _onInputFocus = () => {
    this.setState({
      inputFocused: true,

      // Reset the error messages on focus
      amplifySendEmailError: null,
      clientSideValidationError: null
    });
  };

  /**
   *
   *
   * @memberof ForgotPasswordScreen
   */
  _onInputBlur = event => {
    this.setState({ inputFocused: false });

    // Trigger validation only
    this.submitEmail({ exitAfterValidation: true }, event);
  };

  /**
   *
   * @memberof ForgotPasswordScreen
   * @param {Object} exitAfterValidation
   * @param {Object} event
   */
  submitEmail = ({ exitAfterValidation = true }, event) => {
    event && event.preventDefault();
    const value = this.emailInputViewValue.current.value.toLowerCase();
    const { intl } = this.props;

    if (value === "") {
      this.setState({
        clientSideValidationError: intl.formatMessage(messages.error.pleaseEnterEmail)
      });
      return null;
    }

    if (!validateEmail(value)) {
      this.setState({ clientSideValidationError: intl.formatMessage(messages.error.email) });
      return null;
    }

    // Begin send-code request
    if (!exitAfterValidation) {
      this.setState({ loading: true, userEmail: value });

      Auth.forgotPassword(value)
        .then(() => {
          /*
           * response:
           * {
           *   CodeDeliveryDetails: {
           *    "AttributeName": "email",
           *    "DeliveryMedium": "EMAIL",
           *    "Destination": "t***@a***.co"
           *   }
           * }
           *
           */
          this.setState({
            loading: false,
            emailSent: true
          });
        })
        .catch(err => {
          // Set "amplifyError" true as a fallback, for a hard coded error message.
          let amplifyError = true;
          // if amplify returns a message string, use that.
          if (err.message) amplifyError = err.message;
          this.setState({ loading: false, amplifySendEmailError: amplifyError });
        });
    }
  };

  /**
   *
   * @description returns css classes for the <input />
   * @memberof ForgotPasswordScreen
   * @returns {string}
   */
  getInputCSS = () => {
    const value = this.emailInputViewValue.current ? this.emailInputViewValue.current.value : "";
    const { inputFocused, amplifySendEmailError, clientSideValidationError } = this.state;
    return cn("input", {
      "is-pristine": inputFocused && !clientSideValidationError && !amplifySendEmailError,
      "is-valid":
        !inputFocused && !clientSideValidationError && !amplifySendEmailError && value !== "",
      "is-invalid": amplifySendEmailError || clientSideValidationError,
      "is-required": true,
      "is-focused": inputFocused
    });
  };

  /**
   *
   * @description returns an error message string from client or server-side
   * @memberof ForgotPasswordScreen
   * @returns {string}
   */
  fetchErrorMessages = () => {
    const { intl } = this.props;
    const { inputFocused, amplifySendEmailError, clientSideValidationError } = this.state;

    // Return any client side validation errors first
    if (clientSideValidationError && !inputFocused) {
      return clientSideValidationError;
    }

    // Check for server side validation errors coming in from Amplify
    if (!clientSideValidationError && !inputFocused && amplifySendEmailError) {
      const amplifyErrorIsString = typeof amplifySendEmailError === "string";
      // set generic error message as default message
      let error = intl.formatMessage(messages.error.genericError);
      // if we have an Amplify error, use that
      if (amplifyErrorIsString) error = amplifySendEmailError;

      return error;
    }
  };
}

export default injectIntl(ForgotPasswordScreen);
