import { defineMessages } from "react-intl";

const namespace = `drivers`;
const drivers = defineMessages({
  mustListTitle: {
    id: `${namespace}.mustListTitle`,
    description: `Drivers requirement list title`,
    defaultMessage: `To meet the basic car share requirements drivers must:`
  },
  bc: {
    requirement1: {
      id: `${namespace}.bc.requirement1`,
      description: `Drivers requirement list 1`,
      defaultMessage: `Be at least 18 years of age`
    },
    requirement2: {
      id: `${namespace}.bc.requirement2`,
      description: `Drivers requirement list 2`,
      defaultMessage: `Have a minimum N licence (class 7) with 2 years driving experience (including Learners)`
    },
    requirement3: {
      id: `${namespace}.bc.requirement3`,
      description: `Drivers requirement list 3`,
      defaultMessage: `Not have more than 2 traffic violations in the past 24 months`
    },
    requirement4: {
      id: `${namespace}.bc.requirement4`,
      description: `Drivers requirement list 4`,
      defaultMessage: `Not have any major driving violations (6+ points) or any offences that go under the Criminal Code of Canada`
    },
    requirement5: {
      id: `${namespace}.bc.requirement5`,
      description: `Drivers requirement list 5`,
      defaultMessage: `Not have any current suspensions`
    },
    requirement6: {
      id: `${namespace}.bc.requirement6`,
      description: `Drivers requirement list 6`,
      defaultMessage: `If you are unsure if you have any traffic violations or points against your licence, <ICBClink>>check them through ICBC here</ICBClink>`
    },
  },
  asItAppears: {
    id: `${namespace}.asItAppears`,
    description: `Useful note to remind to type as it appears on drivers licence`,
    defaultMessage: `as it appears on Driver's Licence`
  },
  unknown: {
    provincestate: {
      requirement: {
        id: `${namespace}.unknown.provincestate.requirement`,
        description: "Unknown province or state default requirement for drivers licence",
        defaultMessage: `See <OutOfProvinceDLRequirementLink>requirements for licences issued outside of BC</OutOfProvinceDLRequirementLink>`
      }
    }
  }
});

export default drivers;
