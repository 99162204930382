import React, { PureComponent } from "react";
import { bindActionCreators } from "redux";
import { formValueSelector } from "redux-form";
import { FormSectionWrapper } from "../";
import { PaymentSummary, Adyen } from "../../fieldGroups";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import { Col, DependOn, Section } from "../../../../components";
import * as selectors from "../../../../selectors";
import { Title, Txt } from "../../../../elements";
import { messages } from "../../../../constants";
import { flow } from "../../../../actions";

class PaymentInfo extends PureComponent {
  // function to get the selected field value
  getValue = (name) => {
    const { state, form, section } = this.props;
    const selector = formValueSelector(form);
    return selector(state, `${section}.${name}`);
  };

  render() {
    const { isEbikeOnlyMode } = this.props;

    return (
      <FormSectionWrapper {...this.props}>
        <DependOn active={isEbikeOnlyMode === false}>
          <PaymentSummary {...this.props} />
        </DependOn>
        <DependOn active={isEbikeOnlyMode === true}>
          <Section>
            <Col>
              <br />
              <Title priority={3}>
                {this.props.intl.formatMessage(messages.creditCard.EbikeCCInfoTitle)}
              </Title>
              <Txt>{this.props.intl.formatMessage(messages.creditCard.EbikeCCInfoSubtext)}</Txt>
            </Col>
          </Section>
        </DependOn>
        <Adyen {...this.props} />
        <DependOn active={isEbikeOnlyMode === false}>
          <br />
          <Txt>
            {this.props.intl.formatMessage(messages.evoCard.evoCardNote1, {
              link: <a href="mailto:info@evo.ca">info@evo.ca</a>
            })}
          </Txt>
          <br />
          <Txt>{this.props.intl.formatMessage(messages.evoCard.evoCardNote2)}</Txt>
        </DependOn>
      </FormSectionWrapper>
    );
  }
}

PaymentInfo.propTypes = {
  state: PropTypes.object,
  form: PropTypes.string,
  section: PropTypes.string,
  promoParam: PropTypes.object,
  summaryParam: PropTypes.object,
  isEbikeOnlyMode: PropTypes.bool,
  reset: PropTypes.func,
  intl: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  isEbikeOnlyMode: selectors.getIsEbikeOnly(state)
});
const mapDispatchToProps = (dispatch) => ({
  //completeRegistration: bindActionCreators(flow.registerCustomer, dispatch)
  setAdyenResp: bindActionCreators(flow.setAdyenResp, dispatch)
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PaymentInfo));
