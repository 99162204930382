import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { compose } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import { messages, constants } from "../../constants";
import { Section, Grid, Col, InputWithoutRedux } from "../../components";
import { Title, Button } from "../../elements";

class AccountPassword extends Component {
  goToUpdatePasswordPage = () => {
    this.props.push(constants.ROUTE_ACCOUNT_CHANGE_PASSWORD);
  };

  render() {
    const { intl } = this.props;
    const userPassword = "**************";

    return (
      <Fragment>
        <Section theme={["div"]}>
          <Grid>
            <Col>
              <Title priority={1} type={["strong"]} className="h2">
                {intl.formatMessage(messages.title.password)}
              </Title>
            </Col>
            <Col size="2-3" min="sm">
              <form autoComplete="on" noValidate>
                <InputWithoutRedux
                  dirtylook
                  disabled
                  type="password"
                  hideEyeIcon
                  value={userPassword}
                  label={intl.formatMessage(messages.title.password)}
                />
                <Button onClick={this.goToUpdatePasswordPage} theme={["cta"]}>
                  {<span>{intl.formatMessage(messages.button.changePassword)}</span>}
                </Button>
              </form>
            </Col>
          </Grid>
        </Section>
      </Fragment>
    );
  }
}

AccountPassword.propTypes = {
  intl: PropTypes.object.isRequired
};

const mapDispatchToProps = {
  push
};

export default compose(
  injectIntl,
  connect(
    null,
    mapDispatchToProps
  )
)(AccountPassword);
