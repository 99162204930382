import { defineMessages } from "react-intl";

const namespace = "statuses";
const statuses = defineMessages({
  available: {
    id: `${namespace}.available`,
    description: "Available status",
    defaultMessage: "Available"
  },
  expired: {
    id: `${namespace}.expired`,
    description: "Expired status",
    defaultMessage: "Expired"
  }
});

export default statuses;
