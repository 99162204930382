import { messages } from "../../constants";

const fn = (intl) => [
  { value: "CAN", label: intl.formatMessage(messages.countries.CAN), shortAbbr: "CA" },
  { value: "USA", label: intl.formatMessage(messages.countries.USA), shortAbbr: "US" },
  { value: "GBR", label: intl.formatMessage(messages.countries.GBR), shortAbbr: "GB" },
  { value: "AFG", label: intl.formatMessage(messages.countries.AFG), shortAbbr: "AF" },
  { value: "ALB", label: intl.formatMessage(messages.countries.ALB), shortAbbr: "AL" },
  { value: "DZA", label: intl.formatMessage(messages.countries.DZA), shortAbbr: "DZ" },
  { value: "ASM", label: intl.formatMessage(messages.countries.ASM), shortAbbr: "AS" },
  { value: "AND", label: intl.formatMessage(messages.countries.AND), shortAbbr: "AD" },
  { value: "AGO", label: intl.formatMessage(messages.countries.AGO), shortAbbr: "AO" },
  { value: "AIA", label: intl.formatMessage(messages.countries.AIA), shortAbbr: "AI" },
  { value: "ATG", label: intl.formatMessage(messages.countries.ATG), shortAbbr: "AG" },
  { value: "ARG", label: intl.formatMessage(messages.countries.ARG), shortAbbr: "AR" },
  { value: "ARM", label: intl.formatMessage(messages.countries.ARM), shortAbbr: "AM" },
  { value: "ABW", label: intl.formatMessage(messages.countries.ABW), shortAbbr: "AW" },
  { value: "AUS", label: intl.formatMessage(messages.countries.AUS), shortAbbr: "AU" },
  { value: "AUT", label: intl.formatMessage(messages.countries.AUT), shortAbbr: "AT" },
  { value: "AZE", label: intl.formatMessage(messages.countries.AZE), shortAbbr: "AZ" },
  { value: "BHR", label: intl.formatMessage(messages.countries.BHR), shortAbbr: "BH" },
  { value: "BGD", label: intl.formatMessage(messages.countries.BGD), shortAbbr: "BD" },
  { value: "BRB", label: intl.formatMessage(messages.countries.BRB), shortAbbr: "BB" },
  { value: "BLR", label: intl.formatMessage(messages.countries.BLR), shortAbbr: "BY" },
  { value: "BEL", label: intl.formatMessage(messages.countries.BEL), shortAbbr: "BE" },
  { value: "BLZ", label: intl.formatMessage(messages.countries.BLZ), shortAbbr: "BZ" },
  { value: "BEN", label: intl.formatMessage(messages.countries.BEN), shortAbbr: "BJ" },
  { value: "BMU", label: intl.formatMessage(messages.countries.BMU), shortAbbr: "BM" },
  { value: "BTN", label: intl.formatMessage(messages.countries.BTN), shortAbbr: "BT" },
  { value: "BOL", label: intl.formatMessage(messages.countries.BOL), shortAbbr: "BO" },
  { value: "BES", label: intl.formatMessage(messages.countries.BES), shortAbbr: "BQ" },
  { value: "BIH", label: intl.formatMessage(messages.countries.BIH), shortAbbr: "BA" },
  { value: "BWA", label: intl.formatMessage(messages.countries.BWA), shortAbbr: "BW" },
  { value: "BRA", label: intl.formatMessage(messages.countries.BRA), shortAbbr: "BR" },
  { value: "IOT", label: intl.formatMessage(messages.countries.IOT), shortAbbr: "IO" },
  { value: "VGB", label: intl.formatMessage(messages.countries.VGB), shortAbbr: "VG" },
  { value: "BRN", label: intl.formatMessage(messages.countries.BRN), shortAbbr: "BN" },
  { value: "BGR", label: intl.formatMessage(messages.countries.BGR), shortAbbr: "BG" },
  { value: "BFA", label: intl.formatMessage(messages.countries.BFA), shortAbbr: "BF" },
  { value: "MMR", label: intl.formatMessage(messages.countries.MMR), shortAbbr: "MM" },
  { value: "BDI", label: intl.formatMessage(messages.countries.BDI), shortAbbr: "BI" },
  { value: "KHM", label: intl.formatMessage(messages.countries.KHM), shortAbbr: "KH" },
  { value: "CMR", label: intl.formatMessage(messages.countries.CMR), shortAbbr: "CM" },
  { value: "CPV", label: intl.formatMessage(messages.countries.CPV), shortAbbr: "CV" },
  { value: "CYM", label: intl.formatMessage(messages.countries.CYM), shortAbbr: "KY" },
  { value: "CAF", label: intl.formatMessage(messages.countries.CAF), shortAbbr: "CF" },
  { value: "TCD", label: intl.formatMessage(messages.countries.TCD), shortAbbr: "TD" },
  { value: "CHL", label: intl.formatMessage(messages.countries.CHL), shortAbbr: "CL" },
  { value: "CHN", label: intl.formatMessage(messages.countries.CHN), shortAbbr: "CN" },
  { value: "COL", label: intl.formatMessage(messages.countries.COL), shortAbbr: "CO" },
  { value: "COM", label: intl.formatMessage(messages.countries.COM), shortAbbr: "KM" },
  { value: "COK", label: intl.formatMessage(messages.countries.COK), shortAbbr: "CK" },
  { value: "CRI", label: intl.formatMessage(messages.countries.CRI), shortAbbr: "CR" },
  { value: "CIV", label: intl.formatMessage(messages.countries.CIV), shortAbbr: "CI" },
  { value: "HRV", label: intl.formatMessage(messages.countries.HRV), shortAbbr: "HR" },
  { value: "CUB", label: intl.formatMessage(messages.countries.CUB), shortAbbr: "CU" },
  { value: "CUW", label: intl.formatMessage(messages.countries.CUW), shortAbbr: "CW" },
  { value: "CYP", label: intl.formatMessage(messages.countries.CYP), shortAbbr: "CY" },
  { value: "CZE", label: intl.formatMessage(messages.countries.CZE), shortAbbr: "CZ" },
  { value: "DNK", label: intl.formatMessage(messages.countries.DNK), shortAbbr: "DK" },
  { value: "DJI", label: intl.formatMessage(messages.countries.DJI), shortAbbr: "DJ" },
  { value: "DMA", label: intl.formatMessage(messages.countries.DMA), shortAbbr: "DM" },
  { value: "DOM", label: intl.formatMessage(messages.countries.DOM), shortAbbr: "DO" },
  { value: "ECU", label: intl.formatMessage(messages.countries.ECU), shortAbbr: "EC" },
  { value: "EGY", label: intl.formatMessage(messages.countries.EGY), shortAbbr: "EG" },
  { value: "SLV", label: intl.formatMessage(messages.countries.SLV), shortAbbr: "SV" },
  { value: "GNQ", label: intl.formatMessage(messages.countries.GNQ), shortAbbr: "GQ" },
  { value: "ERI", label: intl.formatMessage(messages.countries.ERI), shortAbbr: "ER" },
  { value: "EST", label: intl.formatMessage(messages.countries.EST), shortAbbr: "EE" },
  { value: "ETH", label: intl.formatMessage(messages.countries.ETH), shortAbbr: "ET" },
  { value: "FLK", label: intl.formatMessage(messages.countries.FLK), shortAbbr: "FK" },
  { value: "FRO", label: intl.formatMessage(messages.countries.FRO), shortAbbr: "FO" },
  { value: "FSM", label: intl.formatMessage(messages.countries.FSM), shortAbbr: "FM" },
  { value: "FJI", label: intl.formatMessage(messages.countries.FJI), shortAbbr: "FJ" },
  { value: "FIN", label: intl.formatMessage(messages.countries.FIN), shortAbbr: "FI" },
  { value: "FRA", label: intl.formatMessage(messages.countries.FRA), shortAbbr: "FR" },
  { value: "GUF", label: intl.formatMessage(messages.countries.GUF), shortAbbr: "GF" },
  { value: "PYF", label: intl.formatMessage(messages.countries.PYF), shortAbbr: "PF" },
  { value: "GAB", label: intl.formatMessage(messages.countries.GAB), shortAbbr: "GA" },
  { value: "GEO", label: intl.formatMessage(messages.countries.GEO), shortAbbr: "GE" },
  { value: "DEU", label: intl.formatMessage(messages.countries.DEU), shortAbbr: "DE" },
  { value: "GHA", label: intl.formatMessage(messages.countries.GHA), shortAbbr: "GH" },
  { value: "GIB", label: intl.formatMessage(messages.countries.GIB), shortAbbr: "GI" },
  { value: "GRC", label: intl.formatMessage(messages.countries.GRC), shortAbbr: "GR" },
  { value: "GRL", label: intl.formatMessage(messages.countries.GRL), shortAbbr: "GL" },
  { value: "GRD", label: intl.formatMessage(messages.countries.GRD), shortAbbr: "GD" },
  { value: "GLP", label: intl.formatMessage(messages.countries.GLP), shortAbbr: "GP" },
  { value: "GUM", label: intl.formatMessage(messages.countries.GUM), shortAbbr: "GU" },
  { value: "GTM", label: intl.formatMessage(messages.countries.GTM), shortAbbr: "GT" },
  { value: "GGY", label: intl.formatMessage(messages.countries.GGY), shortAbbr: "GG" },
  { value: "GIN", label: intl.formatMessage(messages.countries.GIN), shortAbbr: "GN" },
  { value: "GNB", label: intl.formatMessage(messages.countries.GNB), shortAbbr: "GW" },
  { value: "GUY", label: intl.formatMessage(messages.countries.GUY), shortAbbr: "GY" },
  { value: "HTI", label: intl.formatMessage(messages.countries.HTI), shortAbbr: "HT" },
  { value: "HND", label: intl.formatMessage(messages.countries.HND), shortAbbr: "HN" },
  { value: "HKG", label: intl.formatMessage(messages.countries.HKG), shortAbbr: "HK" },
  { value: "HUN", label: intl.formatMessage(messages.countries.HUN), shortAbbr: "HU" },
  { value: "ISL", label: intl.formatMessage(messages.countries.ISL), shortAbbr: "IS" },
  { value: "IND", label: intl.formatMessage(messages.countries.IND), shortAbbr: "IN" },
  { value: "IDN", label: intl.formatMessage(messages.countries.IDN), shortAbbr: "ID" },
  { value: "IRN", label: intl.formatMessage(messages.countries.IRN), shortAbbr: "IR" },
  { value: "IRQ", label: intl.formatMessage(messages.countries.IRQ), shortAbbr: "IQ" },
  { value: "IRL", label: intl.formatMessage(messages.countries.IRL), shortAbbr: "IE" },
  { value: "ISR", label: intl.formatMessage(messages.countries.ISR), shortAbbr: "IL" },
  { value: "ITA", label: intl.formatMessage(messages.countries.ITA), shortAbbr: "IT" },
  { value: "JAM", label: intl.formatMessage(messages.countries.JAM), shortAbbr: "JM" },
  { value: "JPN", label: intl.formatMessage(messages.countries.JPN), shortAbbr: "JP" },
  { value: "JEY", label: intl.formatMessage(messages.countries.JEY), shortAbbr: "JE" },
  { value: "JOR", label: intl.formatMessage(messages.countries.JOR), shortAbbr: "JO" },
  { value: "KAZ", label: intl.formatMessage(messages.countries.KAZ), shortAbbr: "KZ" },
  { value: "KEN", label: intl.formatMessage(messages.countries.KEN), shortAbbr: "KE" },
  { value: "KIR", label: intl.formatMessage(messages.countries.KIR), shortAbbr: "KI" },
  { value: "KWT", label: intl.formatMessage(messages.countries.KWT), shortAbbr: "KW" },
  { value: "KGZ", label: intl.formatMessage(messages.countries.KGZ), shortAbbr: "KG" },
  { value: "LAO", label: intl.formatMessage(messages.countries.LAO), shortAbbr: "LA" },
  { value: "LVA", label: intl.formatMessage(messages.countries.LVA), shortAbbr: "LV" },
  { value: "LBN", label: intl.formatMessage(messages.countries.LBN), shortAbbr: "LB" },
  { value: "LSO", label: intl.formatMessage(messages.countries.LSO), shortAbbr: "LS" },
  { value: "LBR", label: intl.formatMessage(messages.countries.LBR), shortAbbr: "LR" },
  { value: "LBY", label: intl.formatMessage(messages.countries.LBY), shortAbbr: "LY" },
  { value: "LIE", label: intl.formatMessage(messages.countries.LIE), shortAbbr: "LI" },
  { value: "LTU", label: intl.formatMessage(messages.countries.LTU), shortAbbr: "LT" },
  { value: "LUX", label: intl.formatMessage(messages.countries.LUX), shortAbbr: "LU" },
  { value: "MAC", label: intl.formatMessage(messages.countries.MAC), shortAbbr: "MO" },
  { value: "MKD", label: intl.formatMessage(messages.countries.MKD), shortAbbr: "MK" },
  { value: "MDG", label: intl.formatMessage(messages.countries.MDG), shortAbbr: "MG" },
  { value: "MWI", label: intl.formatMessage(messages.countries.MWI), shortAbbr: "MW" },
  { value: "MYS", label: intl.formatMessage(messages.countries.MYS), shortAbbr: "MY" },
  { value: "MDV", label: intl.formatMessage(messages.countries.MDV), shortAbbr: "MV" },
  { value: "MLI", label: intl.formatMessage(messages.countries.MLI), shortAbbr: "ML" },
  { value: "MLT", label: intl.formatMessage(messages.countries.MLT), shortAbbr: "MT" },
  { value: "MHL", label: intl.formatMessage(messages.countries.MHL), shortAbbr: "MH" },
  { value: "MTQ", label: intl.formatMessage(messages.countries.MTQ), shortAbbr: "MQ" },
  { value: "MRT", label: intl.formatMessage(messages.countries.MRT), shortAbbr: "MR" },
  { value: "MUS", label: intl.formatMessage(messages.countries.MUS), shortAbbr: "MU" },
  { value: "MYT", label: intl.formatMessage(messages.countries.MYT), shortAbbr: "YT" },
  { value: "MEX", label: intl.formatMessage(messages.countries.MEX), shortAbbr: "MX" },
  { value: "MDA", label: intl.formatMessage(messages.countries.MDA), shortAbbr: "MD" },
  { value: "MCO", label: intl.formatMessage(messages.countries.MCO), shortAbbr: "MC" },
  { value: "MNG", label: intl.formatMessage(messages.countries.MNG), shortAbbr: "MN" },
  { value: "MNE", label: intl.formatMessage(messages.countries.MNE), shortAbbr: "ME" },
  { value: "MSR", label: intl.formatMessage(messages.countries.MSR), shortAbbr: "MS" },
  { value: "MAR", label: intl.formatMessage(messages.countries.MAR), shortAbbr: "MA" },
  { value: "MOZ", label: intl.formatMessage(messages.countries.MOZ), shortAbbr: "MZ" },
  { value: "NAM", label: intl.formatMessage(messages.countries.NAM), shortAbbr: "NA" },
  { value: "NRU", label: intl.formatMessage(messages.countries.NRU), shortAbbr: "NR" },
  { value: "NPL", label: intl.formatMessage(messages.countries.NPL), shortAbbr: "NP" },
  { value: "NLD", label: intl.formatMessage(messages.countries.NLD), shortAbbr: "NL" },
  { value: "ANT", label: intl.formatMessage(messages.countries.ANT), shortAbbr: "AN" },
  { value: "NCL", label: intl.formatMessage(messages.countries.NCL), shortAbbr: "NC" },
  { value: "NZL", label: intl.formatMessage(messages.countries.NZL), shortAbbr: "NZ" },
  { value: "NIC", label: intl.formatMessage(messages.countries.NIC), shortAbbr: "NI" },
  { value: "NER", label: intl.formatMessage(messages.countries.NER), shortAbbr: "NE" },
  { value: "NGA", label: intl.formatMessage(messages.countries.NGA), shortAbbr: "NG" },
  { value: "NIU", label: intl.formatMessage(messages.countries.NIU), shortAbbr: "NU" },
  { value: "NFK", label: intl.formatMessage(messages.countries.NFK), shortAbbr: "NF" },
  { value: "PRK", label: intl.formatMessage(messages.countries.PRK), shortAbbr: "KP" },
  { value: "MNP", label: intl.formatMessage(messages.countries.MNP), shortAbbr: "MP" },
  { value: "NOR", label: intl.formatMessage(messages.countries.NOR), shortAbbr: "NO" },
  { value: "OMN", label: intl.formatMessage(messages.countries.OMN), shortAbbr: "OM" },
  { value: "PAK", label: intl.formatMessage(messages.countries.PAK), shortAbbr: "PK" },
  { value: "PLW", label: intl.formatMessage(messages.countries.PLW), shortAbbr: "PW" },
  { value: "PSE", label: intl.formatMessage(messages.countries.PSE), shortAbbr: "PS" },
  { value: "PAN", label: intl.formatMessage(messages.countries.PAN), shortAbbr: "PA" },
  { value: "PNG", label: intl.formatMessage(messages.countries.PNG), shortAbbr: "PG" },
  { value: "PRY", label: intl.formatMessage(messages.countries.PRY), shortAbbr: "PY" },
  { value: "PER", label: intl.formatMessage(messages.countries.PER), shortAbbr: "PE" },
  { value: "PHL", label: intl.formatMessage(messages.countries.PHL), shortAbbr: "PH" },
  { value: "POL", label: intl.formatMessage(messages.countries.POL), shortAbbr: "PL" },
  { value: "PRT", label: intl.formatMessage(messages.countries.PRT), shortAbbr: "PT" },
  { value: "PRI", label: intl.formatMessage(messages.countries.PRI), shortAbbr: "PR" },
  { value: "QAT", label: intl.formatMessage(messages.countries.QAT), shortAbbr: "QA" },
  { value: "COG", label: intl.formatMessage(messages.countries.COG), shortAbbr: "CG" },
  { value: "REU", label: intl.formatMessage(messages.countries.REU), shortAbbr: "RE" },
  { value: "ROU", label: intl.formatMessage(messages.countries.ROU), shortAbbr: "RO" },
  { value: "RUS", label: intl.formatMessage(messages.countries.RUS), shortAbbr: "RU" },
  { value: "RWA", label: intl.formatMessage(messages.countries.RWA), shortAbbr: "RW" },
  { value: "BLM", label: intl.formatMessage(messages.countries.BLM), shortAbbr: "BL" },
  { value: "SHN", label: intl.formatMessage(messages.countries.SHN), shortAbbr: "SH" },
  { value: "KNA", label: intl.formatMessage(messages.countries.KNA), shortAbbr: "KN" },
  { value: "MAF", label: intl.formatMessage(messages.countries.MAF), shortAbbr: "MF" },
  { value: "SPM", label: intl.formatMessage(messages.countries.SPM), shortAbbr: "PM" },
  { value: "VCT", label: intl.formatMessage(messages.countries.VCT), shortAbbr: "VC" },
  { value: "WSM", label: intl.formatMessage(messages.countries.WSM), shortAbbr: "WS" },
  { value: "SMR", label: intl.formatMessage(messages.countries.SMR), shortAbbr: "SM" },
  { value: "STP", label: intl.formatMessage(messages.countries.STP), shortAbbr: "ST" },
  { value: "SAU", label: intl.formatMessage(messages.countries.SAU), shortAbbr: "SA" },
  { value: "SEN", label: intl.formatMessage(messages.countries.SEN), shortAbbr: "SN" },
  { value: "SRB", label: intl.formatMessage(messages.countries.SRB), shortAbbr: "RS" },
  { value: "SYC", label: intl.formatMessage(messages.countries.SYC), shortAbbr: "SC" },
  { value: "SLE", label: intl.formatMessage(messages.countries.SLE), shortAbbr: "SL" },
  { value: "SGP", label: intl.formatMessage(messages.countries.SGP), shortAbbr: "SG" },
  { value: "SXM", label: intl.formatMessage(messages.countries.SXM), shortAbbr: "SX" },
  { value: "SVK", label: intl.formatMessage(messages.countries.SVK), shortAbbr: "SK" },
  { value: "SVN", label: intl.formatMessage(messages.countries.SVN), shortAbbr: "SI" },
  { value: "SLB", label: intl.formatMessage(messages.countries.SLB), shortAbbr: "SB" },
  { value: "SOM", label: intl.formatMessage(messages.countries.SOM), shortAbbr: "SO" },
  { value: "ZAF", label: intl.formatMessage(messages.countries.ZAF), shortAbbr: "ZA" },
  { value: "KOR", label: intl.formatMessage(messages.countries.KOR), shortAbbr: "KR" },
  { value: "SSD", label: intl.formatMessage(messages.countries.SSD), shortAbbr: "SS" },
  { value: "ESP", label: intl.formatMessage(messages.countries.ESP), shortAbbr: "ES" },
  { value: "LKA", label: intl.formatMessage(messages.countries.LKA), shortAbbr: "LK" },
  { value: "LCA", label: intl.formatMessage(messages.countries.LCA), shortAbbr: "LC" },
  { value: "SDN", label: intl.formatMessage(messages.countries.SDN), shortAbbr: "SD" },
  { value: "SUR", label: intl.formatMessage(messages.countries.SUR), shortAbbr: "SR" },
  { value: "SWZ", label: intl.formatMessage(messages.countries.SWZ), shortAbbr: "SZ" },
  { value: "SWE", label: intl.formatMessage(messages.countries.SWE), shortAbbr: "SE" },
  { value: "CHE", label: intl.formatMessage(messages.countries.CHE), shortAbbr: "CH" },
  { value: "SYR", label: intl.formatMessage(messages.countries.SYR), shortAbbr: "SY" },
  { value: "TWN", label: intl.formatMessage(messages.countries.TWN), shortAbbr: "TW" },
  { value: "TJK", label: intl.formatMessage(messages.countries.TJK), shortAbbr: "TJ" },
  { value: "TZA", label: intl.formatMessage(messages.countries.TZA), shortAbbr: "TZ" },
  { value: "THA", label: intl.formatMessage(messages.countries.THA), shortAbbr: "TH" },
  { value: "BHS", label: intl.formatMessage(messages.countries.BHS), shortAbbr: "BS" },
  { value: "GMB", label: intl.formatMessage(messages.countries.GMB), shortAbbr: "GM" },
  { value: "TLS", label: intl.formatMessage(messages.countries.TLS), shortAbbr: "TL" },
  { value: "TGO", label: intl.formatMessage(messages.countries.TGO), shortAbbr: "TG" },
  { value: "TKL", label: intl.formatMessage(messages.countries.TKL), shortAbbr: "TK" },
  { value: "TON", label: intl.formatMessage(messages.countries.TON), shortAbbr: "TO" },
  { value: "TTO", label: intl.formatMessage(messages.countries.TTO), shortAbbr: "TT" },
  { value: "TUN", label: intl.formatMessage(messages.countries.TUN), shortAbbr: "TN" },
  { value: "TUR", label: intl.formatMessage(messages.countries.TUR), shortAbbr: "TR" },
  { value: "TKM", label: intl.formatMessage(messages.countries.TKM), shortAbbr: "TM" },
  { value: "TCA", label: intl.formatMessage(messages.countries.TCA), shortAbbr: "TC" },
  { value: "TUV", label: intl.formatMessage(messages.countries.TUV), shortAbbr: "TV" },
  { value: "UGA", label: intl.formatMessage(messages.countries.UGA), shortAbbr: "UG" },
  { value: "UKR", label: intl.formatMessage(messages.countries.UKR), shortAbbr: "UA" },
  { value: "ARE", label: intl.formatMessage(messages.countries.ARE), shortAbbr: "AE" },
  { value: "URY", label: intl.formatMessage(messages.countries.URY), shortAbbr: "UY" },
  { value: "VIR", label: intl.formatMessage(messages.countries.VIR), shortAbbr: "VI" },
  { value: "UZB", label: intl.formatMessage(messages.countries.UZB), shortAbbr: "UZ" },
  { value: "VUT", label: intl.formatMessage(messages.countries.VUT), shortAbbr: "VU" },
  { value: "VAT", label: intl.formatMessage(messages.countries.VAT), shortAbbr: "VA" },
  { value: "VEN", label: intl.formatMessage(messages.countries.VEN), shortAbbr: "VE" },
  { value: "VNM", label: intl.formatMessage(messages.countries.VNM), shortAbbr: "VN" },
  { value: "WLF", label: intl.formatMessage(messages.countries.WLF), shortAbbr: "WF" },
  { value: "YEM", label: intl.formatMessage(messages.countries.YEM), shortAbbr: "YE" },
  { value: "ZMB", label: intl.formatMessage(messages.countries.ZMB), shortAbbr: "ZM" },
  { value: "ZWE", label: intl.formatMessage(messages.countries.ZWE), shortAbbr: "ZW" }

];

export default fn;
