import { defineMessages } from "react-intl";

const namespace = `member`;
const member = defineMessages({
  ismember: {
    id: `${namespace}.ismember`,
    description: "Are you BCAA member? checkbox Field",
    defaultMessage: "Are you a BCAA Member?"
  },
  ismemberInfo: {
    id: `${namespace}.ismemberInfo`,
    description: "Are you CAA member? (i) icon content",
    defaultMessage:
      "Update your {memberType} membership here. If you have a different club membership contact us at (888)-268-2222"
  },
  dagger: {
    id: `${namespace}.dagger`,
    description: "Are you a BCAA member? checkbox footer note",
    defaultMessage:
      "BCAA Members get a FREE Evo Membership, 60 FREE driving minutes & save 10% on every trip!"
  },
  daggerWithLink: {
    id: `${namespace}.daggerWithLink`,
    description: "Are you a BCAA member? checkbox footer note",
    defaultMessage:
      'Participating BCAA members save 10% on all driving rates. Want to become a BCAA member? <memberLink>Click here</memberLink>.'
  },
  number: {
    id: `${namespace}.number`,
    description: "membership number field",
    defaultMessage: "Membership number"
  },
  numberhelp: {
    id: `${namespace}.numberhelp`,
    description: "Help text for membership number field",
    defaultMessage: "As it appears on your Membership card."
  },
  since: {
    id: `${namespace}.since`,
    description: "Member since (YYYY) date field",
    defaultMessage: "Member since (YYYY)"
  },
  optin: {
    id: `${namespace}.optin`,
    description: "Media optin dropdown",
    defaultMessage: "Which city are you joining Evo in?"
  },
  BCAAMembersFreeRegHeader: {
    id: `${namespace}.BCAAMembersFreeRegHeader`,
    description: "BCAA Members Free Registration",
    defaultMessage: "Get 5 months FREE on a BCAA GO Membership!{newLine}{newLine}"
  },
  BCAAMembersFreeRegMinutes: {
    id: `${namespace}.BCAAMembersFreeRegMinutes`,
    description: "BCAA Members Free Registration Messaging",
    defaultMessage: "A BCAA GO Membership is your ticket to unlocking sweet perks to help you make the most of life in BC. Save over $1,000/year on the everyday, Evo & more! Sign up today and get 5 months FREE ($20 discount) at {link}",
  }
});

export default member;
