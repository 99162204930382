/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { pushNav } from "../../actions";
import { Txt } from "../../elements";

import "./index.scss";

class NavBar extends PureComponent {
  container = React.createRef();

  componentDidMount() {
    this.container.current.scrollLeft =
      (this.container.current.scrollWidth - this.container.current.offsetWidth) / 2;
  }

  render() {
    const { navList, currentLink } = this.props;
    const renderList = navItems => {
      return navItems.map((navItem, idx) => {
        // Each item can be one of 3 types
        // * internal - for project link, simple redirect, within same tab
        // * external - for links outside of current project, most likely a differnt tab
        // * action - to perform some actions without redirecting users
        const itemCSS = cn(
          "navbar__item",
          "navbar__item--normal",
          currentLink === navItem.link && "navbar__item--active"
        );
        switch (navItem.type) {
          case "link":
            return (
              <NavLink
                to={navItem.link}
                key={`${navItem.title}-${idx}`}
                exact={navItem.exact}
                className="navbar__item navbar__item--normal"
                activeClassName="navbar__item--active"
              >
                <Txt theme={["upper"]} title={navItem.title}>
                  {navItem.title}
                </Txt>
              </NavLink>
            );
          case "action":
            return (
              <div key={`${navItem.title}-${idx}`} className={itemCSS}>
                <Txt theme={["upper"]} title={navItem.title}>
                  <a onClick={navItem.action}>{navItem.title}</a>
                </Txt>
              </div>
            );
          default:
            return null;
        }
      });
    };

    return (
      <div className="navbar__container" ref={this.container}>
        <nav className="navbar">{renderList(navList)}</nav>
      </div>
    );
  }
}

NavBar.propTypes = {
  navList: PropTypes.array.isRequired,
  pushNav: PropTypes.func.isRequired,
  currentLink: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
  pushNav: bindActionCreators(pushNav, dispatch)
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  withRouter
)(NavBar);
