import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { injectIntl } from "react-intl";
import { reset } from "redux-form";
import cn from "classnames";
import get from "lodash/get";

import { constants, messages } from "../../constants";
import * as selectors from "../../selectors";
import { Title, Txt, Button } from "../../elements";
import { pluralAware, formatDate, getJsonFromUrl } from "../../../src/util";
import { fetchMinutesSummary, fetchMinutes } from "../../actions/minutes";
import { applyPromoCode } from "../../actions/flow";
import { loginWithToken } from "../../actions/auth";
import { ApplyPromoCodeForm } from "../../containers/form";
import {
  Card,
  Col,
  DataTable,
  Grid,
  InfoBlock,
  Responsive,
  Section,
  ExpandablePanel
} from "../../components";
import "./index.scss";

class MinutesLanding extends PureComponent {
  state = {
    expandPromoCode: false
  };

  async componentDidMount() {
    const { loginWithToken } = this.props;
    try {
      const queryObj = getJsonFromUrl(get(this.props, "location.search", ""));
      const { idToken, accessToken } = queryObj;
      if (idToken && accessToken) {
        loginWithToken({ idToken, accessToken });
      }
    } catch (error) {}
    await this.props.fetchMinutesSummary();
  }

  togglePromoCodeExpansion = () => {
    this.setState({ expandPromoCode: !this.state.expandPromoCode });
  };

  renderPromoCodeTrigger = () => {
    const { expandPromoCode } = this.state;
    const iconClassName = cn("fa", {
      "fa-plus": !expandPromoCode,
      primary: !expandPromoCode,
      "fa-minus": expandPromoCode,
      "fa-inverse": expandPromoCode
    });
    const { intl } = this.props;
    return (
      <Button theme={["full-width"]} onClick={this.togglePromoCodeExpansion}>
        <span>{intl.formatMessage(messages.title.promoCodeTitle)}</span>
        <span className="fa-stack btn__icon-container">
          <i className="fa fa-circle fa-stack-2x" />
          <i className={`${iconClassName} fa-stack-1x`} />
        </span>
      </Button>
    );
  };

  render() {
    const { expandPromoCode } = this.state;
    const { intl, freeMinutes, freeMinutesSummary } = this.props;
    const displayingFreeMinutes = freeMinutes.slice(0, 50); // For now we only display the first 50 results;
    // Table related objects
    const displayKeys = [
      "availableFreeMin",
      "totalFreeMin",
      "status",
      "applied",
      "expires",
      "typeExt"
    ];
    const columnNames = [
      { key: "availableFreeMin", title: intl.formatMessage(messages.title.availableFreeMin) },
      { key: "totalFreeMin", title: intl.formatMessage(messages.title.totalFreeMin) },
      { key: "status", title: intl.formatMessage(messages.title.Status) },
      { key: "applied", title: intl.formatMessage(messages.title.applied) },
      { key: "expires", title: intl.formatMessage(messages.title.expires) },
      { key: "typeExt", title: intl.formatMessage(messages.title.type) }
    ];

    const processedData = this.processedMinutes(displayingFreeMinutes);

    return (
      <div data-testid="screen-accountMinutesLanding">
        {/* Summary Section */}
        <Grid>
          <Col size="1-2" min="sm">
            <Section theme={["div"]}>
              <Grid>
                <Col>
                  <Title priority={1} type={["strong"]} className="h2">
                    {intl.formatMessage(messages.header.AvailableMinutes)}
                  </Title>
                  <Txt>
                    {intl.formatMessage(messages.header.TotalFreeMinutesIn, {
                          city: intl.formatMessage(messages.cities.vancouver)
                        })}
                  </Txt>
                  <Col size="1-3" min="sm">
                    <InfoBlock
                      title={intl.formatMessage(messages.title.totalAvailable)}
                      infoBody={pluralAware(freeMinutesSummary.totalAvailable, "min")}
                    />
                  </Col>
                </Col>
              </Grid>
            </Section>
          </Col>
          <Col size="1-2" min="sm" className="minutes-landing__apply-promo-code">
            <Responsive device="Mobile">
              <ExpandablePanel
                theme={["box"]}
                expanded={expandPromoCode}
                trigger={this.renderPromoCodeTrigger()}
              >
                <Txt>
                  <div>{intl.formatMessage(messages.title.promoCodeSubtitle)}</div>
                </Txt>
                <ApplyPromoCodeForm onSubmit={this.handleApplyPromoCode} />
              </ExpandablePanel>
            </Responsive>
            <Responsive>
              <Section theme={["div", "border-primary", "vertial-padding"]}>
                <Col>
                  <Txt theme={["primary", "large"]}>
                    <p>{intl.formatMessage(messages.title.promoCodeTitle)}</p>
                  </Txt>
                  <Txt>
                    <div>{intl.formatMessage(messages.title.promoCodeSubtitle)}</div>
                  </Txt>
                  <ApplyPromoCodeForm onSubmit={this.handleApplyPromoCode} />
                </Col>
              </Section>
            </Responsive>
          </Col>
        </Grid>

        {/*  Total Available Minutes Data Talbe */}
        <Section theme={["div"]}>
          <Grid>
            <Col>

              <Responsive device="Mobile">
                {processedData.length ? (
                  processedData.map(this.renderMinutesRecord)
                ) : (
                  <Txt priority={4} theme={["bold", "center", "vertical-gutter"]}>
                    <div>{intl.formatMessage(messages.title.noFreeMinutes)}</div>
                  </Txt>
                )}
              </Responsive>
              <Responsive>
                <DataTable
                  columnNames={columnNames}
                  displayKeys={displayKeys}
                  data={processedData}
                  rowGrayRule={({ isAvailable }) => !isAvailable}
                  nullStateTitle={intl.formatMessage(messages.title.noFreeMinutes)}
                />
              </Responsive>
            </Col>
          </Grid>
        </Section>
      </div>
    );
  }

  mapFreeMinType = (type, typeExt, promoCode) => {
    const { intl } = this.props;
    switch (typeExt) {
      case constants.FREE_MIN_TYPE.GOODWILL_THANK_YOU:
        return intl.formatMessage(messages.freeMinutes.goodWillThankYou);
      case constants.FREE_MIN_TYPE.SERVICE_RECOVERY:
        return intl.formatMessage(messages.freeMinutes.serviceRecovery);
      case constants.FREE_MIN_TYPE.SERVICE_DISRUPTION:
        return intl.formatMessage(messages.freeMinutes.serviceDisruption);
      case constants.FREE_MIN_TYPE.PROMO_MINUTES_EXTENSION:
        return intl.formatMessage(messages.freeMinutes.promoMinutesExtension);
      case constants.FREE_MIN_TYPE.PROMO_MINUTES_EXCEPTION:
        return intl.formatMessage(messages.freeMinutes.promoMinutesException);
      case constants.FREE_MIN_TYPE.OTHER:
        return intl.formatMessage(messages.freeMinutes.other);
      default:
        if (type === constants.FREE_MIN_TYPE.REFERRED_TO) {
          return intl.formatMessage(messages.freeMinutes.referredTo, { clientNum: promoCode });
        } else if (type === constants.FREE_MIN_TYPE.REFERRED_BY) {
          return intl.formatMessage(messages.freeMinutes.referredBy, { clientNum: promoCode });
        } else if (typeExt === promoCode) {
          return intl.formatMessage(messages.freeMinutes.promoCode, { promoCode });
        }
        return typeExt;
    }
  };

  processedMinutes = freeMinutes =>
    freeMinutes.map(
      ({
        availableFreeMin,
        totalFreeMin,
        status,
        appliedDate,
        expiryDate,
        promoCode,
        type,
        typeExt,
        ...rest
      }) => ({
        availableFreeMin: pluralAware(availableFreeMin, "min"),
        totalFreeMin: pluralAware(totalFreeMin, "min"),
        isAvailable: status === "Available",
        status: messages.statuses[status.toLowerCase()]
          ? this.props.intl.formatMessage(messages.statuses[status.toLowerCase()])
          : status,
        applied: formatDate(appliedDate, "MMM D, YYYY"),
        expires: formatDate(expiryDate, "MMM D, YYYY"),
        promoCode,
        type,
        typeExt: this.mapFreeMinType(type, typeExt, promoCode),
        ...rest
      })
    );

  renderDataCell = (headerTitle, data) => (
    <span>
      <div>
        <Txt theme={["note", "small"]}>{headerTitle}</Txt>
      </div>
      {data}
    </span>
  );

  renderMinutesRecord = record => {
    const { intl } = this.props;
    const {
      id,
      availableFreeMin,
      totalFreeMin,
      status,
      applied,
      expires,
      typeExt
    } = record;
    return (
      <Card key={id}>
        <Card.Header>
          <Grid theme={["small-div"]}>
            <Col size="1-3">
              {this.renderDataCell(
                intl.formatMessage(messages.title.availableFreeMin),
                availableFreeMin
              )}
            </Col>
            <Col size="1-3">
              {this.renderDataCell(intl.formatMessage(messages.title.totalFreeMin), totalFreeMin)}
            </Col>
            <Col size="1-3">
              {this.renderDataCell(intl.formatMessage(messages.title.Status), status)}
            </Col>
          </Grid>
        </Card.Header>
        <Card.Body theme={["top-dashed"]}>
          <Grid theme={["small-div"]}>
            <Col size="1-2">
              {this.renderDataCell(intl.formatMessage(messages.title.applied), applied)}
            </Col>
            <Col size="1-2">
              {this.renderDataCell(intl.formatMessage(messages.title.expires), expires)}
            </Col>
          </Grid>
          <Grid theme={["small-div"]}>
            <Col size="1-2">
              {this.renderDataCell(intl.formatMessage(messages.title.type), typeExt)}
            </Col>
          </Grid>
        </Card.Body>
      </Card>
    );
  };

  handleApplyPromoCode = async ({ promo }) => {
    const { intl } = this.props;
    const result = await this.props.applyPromoCode(promo, intl);
    if (result.status === "primary") {
      // when the code is valid and applied successfully
      // reset the form and refetch minutes summary
      this.props.reset(constants.FORM.applyPromoCodeForm);
      this.props.fetchMinutesSummary();
      this.props.fetchMinutes();
    }
  };
}

MinutesLanding.propTypes = {
  intl: PropTypes.object.isRequired,
  freeMinutes: PropTypes.arrayOf(PropTypes.object),
  freeMinutesSummary: PropTypes.object,
  fetchMinutesSummary: PropTypes.func.isRequired,
  fetchMinutes: PropTypes.func.isRequired,
  applyPromoCode: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired
};

MinutesLanding.defaultProps = {
  freeMinutes: [],
  freeMinutesSummary: { totalAvailable: 0 }
};

const mapStateToProps = state => ({
  user: selectors.getUser(state),
  freeMinutes: selectors.getBilling(state).freeMinutes,
  freeMinutesSummary: selectors.getBilling(state).freeMinutesSummary
});

const mapDispatchToProps = dispatch => ({
  fetchMinutesSummary: bindActionCreators(fetchMinutesSummary, dispatch),
  fetchMinutes: bindActionCreators(fetchMinutes, dispatch),
  applyPromoCode: bindActionCreators(applyPromoCode, dispatch),
  loginWithToken: bindActionCreators(loginWithToken, dispatch),
  reset: bindActionCreators(reset, dispatch)
});

export const IntlMinutesLanding = injectIntl(MinutesLanding);

const enhance = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);
export default enhance(MinutesLanding);
