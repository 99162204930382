import { constants } from "../constants";
import { getMonthlyStatements, generateMonthlyStatement } from "../api/customer";

export const fetchMonthlyStatements = (count, page) => async dispatch => {
  const monthlyStatements = await getMonthlyStatements(count, page);
  if (monthlyStatements.error) {
    throw new Error(monthlyStatements.error);
  }

  dispatch({
    type: constants.STATEMENTS_INFO,
    payload: monthlyStatements
  });
  return monthlyStatements;
};

export const fetchGenerateMonthlyStatement = (month, year, languageCode) => async dispatch => {
  const monthlyStatement = await generateMonthlyStatement(month, year, languageCode);
  if (monthlyStatement.error) {
    throw new Error(monthlyStatement.error);
  }

  dispatch({
    type: null ,
    payload: monthlyStatement
  });
  return monthlyStatement;
};
