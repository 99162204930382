import React from "react";

import { PagesContainer } from "../../containers";
import { Error } from "../../screens";

const ErrorPage = props => {
  return (
    <PagesContainer {...props}>
      <Error />
    </PagesContainer>
  );
};

ErrorPage.propTypes = {};

ErrorPage.defaultProps = {};

export default ErrorPage;
