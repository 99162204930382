import React, { PureComponent } from "react";
import { Field, formValueSelector } from "redux-form";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import { Input, Grid, Col, Section } from "../../../../components";
import { Txt } from "../../../../elements";
import { messages } from "../../../../constants";
import { validationBuilder, validations } from "../..";

import { useB2BStore } from "../../../../store/b2b";
let rules = {};
class Credentials extends PureComponent {
  rulesEmail = [];
  rulesPassword = [];
  rulesConfirmEmail = [];

  constructor(props) {
    super(props);

    // for field level validations
    // TODO: find a better way to instantiate props when the component mounts
    Object.keys(validations).forEach((key) => {
      rules[key] = (spec) => (value, allValues) =>
        validationBuilder(props, validations[key], spec)(value, allValues);
    });

    const { intl } = props;
    this.rulesEmail = [
      rules.required(intl.formatMessage(messages.field.EmailAddress)),
      rules.email()
    ];

    this.rulesConfirmEmail = [rules.requiredConfirmEmail(), rules.email(), rules.emailsMatch()];

    this.rulesPassword = [
      rules.required(intl.formatMessage(messages.field.password)),
      rules.shouldHaveSpecialChar(),
      rules.shouldHaveNumber(),
      rules.shouldHaveUpper(),
      rules.shouldHaveLower(),
      rules.shouldHave8Chars(),
      rules.shouldNotHaveSpaces()
    ];
  }

  // function to get the selected field value
  getValue = (name) => {
    const { state, form, section } = this.props;
    const selector = formValueSelector(form);
    return selector(state, `${section}.${name}`);
  };

  render() {
    const { intl } = this.props;
    const b2bData = useB2BStore.getState();
    const isB2B = b2bData.data.isB2B;
    const label = {
      emailLabel: intl.formatMessage(messages.field.EmailAddress),
      confirmEmailLabel: intl.formatMessage(messages.field.confirmEmailAddress),
      emailNote: intl.formatMessage(messages.field.emailNote)
    };

    if (isB2B) {
      label.emailLabel = intl.formatMessage(messages.field.LoginEmailAddress);
      label.confirmEmailLabel = intl.formatMessage(messages.field.confirmLoginEmailAddress);
      label.emailNote = intl.formatMessage(messages.field.loginEmailNote);
    }
    return (
      <Section size="1-2" min="md">
        <Grid>
          <Col>
            <Field
              type="email"
              name="emailAddress"
              label={label.emailLabel}
              required={true}
              validate={this.rulesEmail}
              component={Input}
              props={{ maxLength: "254" }}
            />
            <Txt theme={["note"]}>
              <small>{label.emailNote}</small>
            </Txt>
          </Col>
          <Col>
            <Field
              type="email"
              name="confirmEmailAddress"
              label={label.confirmEmailLabel}
              required={true}
              validate={this.rulesConfirmEmail}
              component={Input}
              props={{ maxLength: "254" }}
              allowPaste={false}
            />
          </Col>
          <Col size="2-3" min="sm">
            <Field
              type="password"
              name="password"
              label={intl.formatMessage(messages.field.password)}
              component={Input}
              validate={this.rulesPassword}
              required={true}
              props={{ maxLength: "50" }}
            />
            <Txt theme={["note"]}>
              <small>{intl.formatMessage(messages.field.passwordNote)}</small>
            </Txt>
          </Col>
        </Grid>
      </Section>
    );
  }
}

Credentials.propTypes = {
  intl: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  section: PropTypes.string.isRequired
};

export default injectIntl(Credentials);
