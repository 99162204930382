// prettier-ignore
import { defineMessages } from "react-intl";

const namespace = "countries";
const countries = defineMessages({
  CAN: { id: `${namespace}.CAN`, description: "Canada", defaultMessage: "Canada"},
  USA: { id: `${namespace}.USA`, description: "United States", defaultMessage: "United States"},
  GBR: { id: `${namespace}.GBR`, description: "United Kingdom", defaultMessage: "United Kingdom"},
  AFG: { id: `${namespace}.AFG`, description: "Afghanistan", defaultMessage: "Afghanistan"},
  ALB: { id: `${namespace}.ALB`, description: "Albania", defaultMessage: "Albania"},
  DZA: { id: `${namespace}.DZA`, description: "Algeria", defaultMessage: "Algeria"},
  ASM: { id: `${namespace}.ASM`, description: "American Samoa", defaultMessage: "American Samoa"},
  AND: { id: `${namespace}.AND`, description: "Andorra", defaultMessage: "Andorra"},
  AGO: { id: `${namespace}.AGO`, description: "Angola", defaultMessage: "Angola"},
  AIA: { id: `${namespace}.AIA`, description: "Anguilla", defaultMessage: "Anguilla"},
  ATG: { id: `${namespace}.ATG`, description: "Antigua and Barbuda", defaultMessage: "Antigua and Barbuda"},
  ARG: { id: `${namespace}.ARG`, description: "Argentina", defaultMessage: "Argentina"},
  ARM: { id: `${namespace}.ARM`, description: "Armenia", defaultMessage: "Armenia"},
  ABW: { id: `${namespace}.ABW`, description: "Aruba", defaultMessage: "Aruba"},
  AUS: { id: `${namespace}.AUS`, description: "Australia", defaultMessage: "Australia"},
  AUT: { id: `${namespace}.AUT`, description: "Austria", defaultMessage: "Austria"},
  AZE: { id: `${namespace}.AZE`, description: "Azerbaijan", defaultMessage: "Azerbaijan"},
  BHR: { id: `${namespace}.BHR`, description: "Bahrain", defaultMessage: "Bahrain"},
  BGD: { id: `${namespace}.BGD`, description: "Bangladesh", defaultMessage: "Bangladesh"},
  BRB: { id: `${namespace}.BRB`, description: "Barbados", defaultMessage: "Barbados"},
  BLR: { id: `${namespace}.BLR`, description: "Belarus", defaultMessage: "Belarus"},
  BEL: { id: `${namespace}.BEL`, description: "Belgium", defaultMessage: "Belgium"},
  BLZ: { id: `${namespace}.BLZ`, description: "Belize", defaultMessage: "Belize"},
  BEN: { id: `${namespace}.BEN`, description: "Benin", defaultMessage: "Benin"},
  BMU: { id: `${namespace}.BMU`, description: "Bermuda", defaultMessage: "Bermuda"},
  BTN: { id: `${namespace}.BTN`, description: "Bhutan", defaultMessage: "Bhutan"},
  BOL: { id: `${namespace}.BOL`, description: "Bolivia", defaultMessage: "Bolivia"},
  BES: { id: `${namespace}.BES`, description: "Bonaire, Sint Eustatius and Saba", defaultMessage: "Bonaire"},
  BIH: { id: `${namespace}.BIH`, description: "Bosnia and Herzegovina", defaultMessage: "Bosnia and Herzegovina"},
  BWA: { id: `${namespace}.BWA`, description: "Botswana", defaultMessage: "Botswana"},
  BRA: { id: `${namespace}.BRA`, description: "Brazil", defaultMessage: "Brazil"},
  IOT: { id: `${namespace}.IOT`, description: "British Indian Ocean Territory", defaultMessage: "British Indian Ocean Territory"},
  VGB: { id: `${namespace}.VGB`, description: "British Virgin Islands", defaultMessage: "British Virgin Islands"},
  BRN: { id: `${namespace}.BRN`, description: "Brunei Darussalam", defaultMessage: "Brunei Darussalam"},
  BGR: { id: `${namespace}.BGR`, description: "Bulgaria", defaultMessage: "Bulgaria"},
  BFA: { id: `${namespace}.BFA`, description: "Burkina Faso", defaultMessage: "Burkina Faso"},
  MMR: { id: `${namespace}.MMR`, description: "Myanmar", defaultMessage: "Myanmar"},
  BDI: { id: `${namespace}.BDI`, description: "Burundi", defaultMessage: "Burundi"},
  KHM: { id: `${namespace}.KHM`, description: "Cambodia", defaultMessage: "Cambodia"},
  CMR: { id: `${namespace}.CMR`, description: "Cameroon", defaultMessage: "Cameroon"},
  CPV: { id: `${namespace}.CPV`, description: "Cabo Verde", defaultMessage: "Cabo Verde"},
  CYM: { id: `${namespace}.CYM`, description: "Cayman Islands", defaultMessage: "Cayman Islands"},
  CAF: { id: `${namespace}.CAF`, description: "Central African Republic", defaultMessage: "Central African Republic"},
  TCD: { id: `${namespace}.TCD`, description: "Chad", defaultMessage: "Chad"},
  CHL: { id: `${namespace}.CHL`, description: "Chile", defaultMessage: "Chile"},
  CHN: { id: `${namespace}.CHN`, description: "China", defaultMessage: "China"},
  COL: { id: `${namespace}.COL`, description: "Colombia", defaultMessage: "Colombia"},
  COM: { id: `${namespace}.COM`, description: "Comoros", defaultMessage: "Comoros"},
  COK: { id: `${namespace}.COK`, description: "Cook Islands", defaultMessage: "Cook Islands"},
  CRI: { id: `${namespace}.CRI`, description: "Costa Rica", defaultMessage: "Costa Rica"},
  CIV: { id: `${namespace}.CIV`, description: "Côte dIvoire", defaultMessage: "Côte dIvoire"},
  HRV: { id: `${namespace}.HRV`, description: "Croatia", defaultMessage: "Croatia"},
  CUB: { id: `${namespace}.CUB`, description: "Cuba", defaultMessage: "Cuba"},
  CUW: { id: `${namespace}.CUW`, description: "Curaçao", defaultMessage: "Curaçao"},
  CYP: { id: `${namespace}.CYP`, description: "Cyprus", defaultMessage: "Cyprus"},
  CZE: { id: `${namespace}.CZE`, description: "Czechia", defaultMessage: "Czechia"},
  DNK: { id: `${namespace}.DNK`, description: "Denmark", defaultMessage: "Denmark"},
  DJI: { id: `${namespace}.DJI`, description: "Djibouti", defaultMessage: "Djibouti"},
  DMA: { id: `${namespace}.DMA`, description: "Dominica", defaultMessage: "Dominica"},
  DOM: { id: `${namespace}.DOM`, description: "Dominican Republic", defaultMessage: "Dominican Republic"},
  ECU: { id: `${namespace}.ECU`, description: "Ecuador", defaultMessage: "Ecuador"},
  EGY: { id: `${namespace}.EGY`, description: "Egypt", defaultMessage: "Egypt"},
  SLV: { id: `${namespace}.SLV`, description: "El Salvador", defaultMessage: "El Salvador"},
  GNQ: { id: `${namespace}.GNQ`, description: "Equatorial Guinea", defaultMessage: "Equatorial Guinea"},
  ERI: { id: `${namespace}.ERI`, description: "Eritrea", defaultMessage: "Eritrea"},
  EST: { id: `${namespace}.EST`, description: "Estonia", defaultMessage: "Estonia"},
  ETH: { id: `${namespace}.ETH`, description: "Ethiopia", defaultMessage: "Ethiopia"},
  FLK: { id: `${namespace}.FLK`, description: "Falkland Islands", defaultMessage: "Falkland Islands"},
  FRO: { id: `${namespace}.FRO`, description: "Faroe Islands", defaultMessage: "Faroe Islands"},
  FSM: { id: `${namespace}.FSM`, description: "Federated States of Micronesia", defaultMessage: "Federated States of Micronesia"},
  FJI: { id: `${namespace}.FJI`, description: "Fiji", defaultMessage: "Fiji"},
  FIN: { id: `${namespace}.FIN`, description: "Finland", defaultMessage: "Finland"},
  FRA: { id: `${namespace}.FRA`, description: "France", defaultMessage: "France"},
  GUF: { id: `${namespace}.GUF`, description: "French Guiana", defaultMessage: "French Guiana"},
  PYF: { id: `${namespace}.PYF`, description: "French Polynesia", defaultMessage: "French Polynesia"},
  GAB: { id: `${namespace}.GAB`, description: "Gabon", defaultMessage: "Gabon"},
  GEO: { id: `${namespace}.GEO`, description: "Georgia", defaultMessage: "Georgia"},
  DEU: { id: `${namespace}.DEU`, description: "Germany", defaultMessage: "Germany"},
  GHA: { id: `${namespace}.GHA`, description: "Ghana", defaultMessage: "Ghana"},
  GIB: { id: `${namespace}.GIB`, description: "Gibraltar", defaultMessage: "Gibraltar"},
  GRC: { id: `${namespace}.GRC`, description: "Greece", defaultMessage: "Greece"},
  GRL: { id: `${namespace}.GRL`, description: "Greenland", defaultMessage: "Greenland"},
  GRD: { id: `${namespace}.GRD`, description: "Grenada", defaultMessage: "Grenada"},
  GLP: { id: `${namespace}.GLP`, description: "Guadeloupe", defaultMessage: "Guadeloupe"},
  GUM: { id: `${namespace}.GUM`, description: "Guam", defaultMessage: "Guam"},
  GTM: { id: `${namespace}.GTM`, description: "Guatemala", defaultMessage: "Guatemala"},
  GGY: { id: `${namespace}.GGY`, description: "Guernsey", defaultMessage: "Guernsey"},
  GIN: { id: `${namespace}.GIN`, description: "Guinea", defaultMessage: "Guinea"},
  GNB: { id: `${namespace}.GNB`, description: "Guinea-Bissau", defaultMessage: "Guinea-Bissau"},
  GUY: { id: `${namespace}.GUY`, description: "Guyana", defaultMessage: "Guyana"},
  HTI: { id: `${namespace}.HTI`, description: "Haiti", defaultMessage: "Haiti"},
  HND: { id: `${namespace}.HND`, description: "Honduras", defaultMessage: "Honduras"},
  HKG: { id: `${namespace}.HKG`, description: "Hong Kong", defaultMessage: "Hong Kong"},
  HUN: { id: `${namespace}.HUN`, description: "Hungary", defaultMessage: "Hungary"},
  ISL: { id: `${namespace}.ISL`, description: "Iceland", defaultMessage: "Iceland"},
  IND: { id: `${namespace}.IND`, description: "India", defaultMessage: "India"},
  IDN: { id: `${namespace}.IDN`, description: "Indonesia", defaultMessage: "Indonesia"},
  IRN: { id: `${namespace}.IRN`, description: "Iran", defaultMessage: "Iran"},
  IRQ: { id: `${namespace}.IRQ`, description: "Iraq", defaultMessage: "Iraq"},
  IRL: { id: `${namespace}.IRL`, description: "Ireland", defaultMessage: "Ireland"},
  ISR: { id: `${namespace}.ISR`, description: "Israel", defaultMessage: "Israel"},
  ITA: { id: `${namespace}.ITA`, description: "Italy", defaultMessage: "Italy"},
  JAM: { id: `${namespace}.JAM`, description: "Jamaica", defaultMessage: "Jamaica"},
  JPN: { id: `${namespace}.JPN`, description: "Japan", defaultMessage: "Japan"},
  JEY: { id: `${namespace}.JEY`, description: "Jersey", defaultMessage: "Jersey"},
  JOR: { id: `${namespace}.JOR`, description: "Jordan", defaultMessage: "Jordan"},
  KAZ: { id: `${namespace}.KAZ`, description: "Kazakhstan", defaultMessage: "Kazakhstan"},
  KEN: { id: `${namespace}.KEN`, description: "Kenya", defaultMessage: "Kenya"},
  KIR: { id: `${namespace}.KIR`, description: "Kiribati", defaultMessage: "Kiribati"},
  KWT: { id: `${namespace}.KWT`, description: "Kuwait", defaultMessage: "Kuwait"},
  KGZ: { id: `${namespace}.KGZ`, description: "Kyrgyzstan", defaultMessage: "Kyrgyzstan"},
  LAO: { id: `${namespace}.LAO`, description: "Laos", defaultMessage: "Laos"},
  LVA: { id: `${namespace}.LVA`, description: "Latvia", defaultMessage: "Latvia"},
  LBN: { id: `${namespace}.LBN`, description: "Lebanon", defaultMessage: "Lebanon"},
  LSO: { id: `${namespace}.LSO`, description: "Lesotho", defaultMessage: "Lesotho"},
  LBR: { id: `${namespace}.LBR`, description: "Liberia", defaultMessage: "Liberia"},
  LBY: { id: `${namespace}.LBY`, description: "Libya", defaultMessage: "Libya"},
  LIE: { id: `${namespace}.LIE`, description: "Liechtenstein", defaultMessage: "Liechtenstein"},
  LTU: { id: `${namespace}.LTU`, description: "Lithuania", defaultMessage: "Lithuania"},
  LUX: { id: `${namespace}.LUX`, description: "Luxembourg", defaultMessage: "Luxembourg"},
  MAC: { id: `${namespace}.MAC`, description: "Macao", defaultMessage: "Macao"},
  MKD: { id: `${namespace}.MKD`, description: "Macedonia", defaultMessage: "Macedonia"},
  MDG: { id: `${namespace}.MDG`, description: "Madagascar", defaultMessage: "Madagascar"},
  MWI: { id: `${namespace}.MWI`, description: "Malawi", defaultMessage: "Malawi"},
  MYS: { id: `${namespace}.MYS`, description: "Malaysia", defaultMessage: "Malaysia"},
  MDV: { id: `${namespace}.MDV`, description: "Maldives", defaultMessage: "Maldives"},
  MLI: { id: `${namespace}.MLI`, description: "Mali", defaultMessage: "Mali"},
  MLT: { id: `${namespace}.MLT`, description: "Malta", defaultMessage: "Malta"},
  MHL: { id: `${namespace}.MHL`, description: "Marshall Islands", defaultMessage: "Marshall Islands"},
  MTQ: { id: `${namespace}.MTQ`, description: "Martinique", defaultMessage: "Martinique"},
  MRT: { id: `${namespace}.MRT`, description: "Mauritania", defaultMessage: "Mauritania"},
  MUS: { id: `${namespace}.MUS`, description: "Mauritius", defaultMessage: "Mauritius"},
  MYT: { id: `${namespace}.MYT`, description: "Mayotte", defaultMessage: "Mayotte"},
  MEX: { id: `${namespace}.MEX`, description: "Mexico", defaultMessage: "Mexico"},
  MDA: { id: `${namespace}.MDA`, description: "Moldova", defaultMessage: "Moldova"},
  MCO: { id: `${namespace}.MCO`, description: "Monaco", defaultMessage: "Monaco"},
  MNG: { id: `${namespace}.MNG`, description: "Mongolia", defaultMessage: "Mongolia"},
  MNE: { id: `${namespace}.MNE`, description: "Montenegro", defaultMessage: "Montenegro"},
  MSR: { id: `${namespace}.MSR`, description: "Montserrat", defaultMessage: "Montserrat"},
  MAR: { id: `${namespace}.MAR`, description: "Morocco", defaultMessage: "Morocco"},
  MOZ: { id: `${namespace}.MOZ`, description: "Mozambique", defaultMessage: "Mozambique"},
  NAM: { id: `${namespace}.NAM`, description: "Namibia", defaultMessage: "Namibia"},
  NRU: { id: `${namespace}.NRU`, description: "Nauru", defaultMessage: "Nauru"},
  NPL: { id: `${namespace}.NPL`, description: "Nepal", defaultMessage: "Nepal"},
  NLD: { id: `${namespace}.NLD`, description: "Netherlands", defaultMessage: "Netherlands"},
  ANT: { id: `${namespace}.ANT`, description: "Netherlands Antilles", defaultMessage: "Netherlands Antilles"},
  NCL: { id: `${namespace}.NCL`, description: "New Caledonia", defaultMessage: "New Caledonia"},
  NZL: { id: `${namespace}.NZL`, description: "New Zealand", defaultMessage: "New Zealand"},
  NIC: { id: `${namespace}.NIC`, description: "Nicaragua", defaultMessage: "Nicaragua"},
  NER: { id: `${namespace}.NER`, description: "Niger", defaultMessage: "Niger"},
  NGA: { id: `${namespace}.NGA`, description: "Nigeria", defaultMessage: "Nigeria"},
  NIU: { id: `${namespace}.NIU`, description: "Niue", defaultMessage: "Niue"},
  NFK: { id: `${namespace}.NFK`, description: "Norfolk Island", defaultMessage: "Norfolk Island"},
  PRK: { id: `${namespace}.PRK`, description: "North Korea", defaultMessage: "North Korea"},
  MNP: { id: `${namespace}.MNP`, description: "Northern Mariana Islands", defaultMessage: "Northern Mariana Islands"},
  NOR: { id: `${namespace}.NOR`, description: "Norway", defaultMessage: "Norway"},
  OMN: { id: `${namespace}.OMN`, description: "Oman", defaultMessage: "Oman"},
  PAK: { id: `${namespace}.PAK`, description: "Pakistan", defaultMessage: "Pakistan"},
  PLW: { id: `${namespace}.PLW`, description: "Palau", defaultMessage: "Palau"},
  PSE: { id: `${namespace}.PSE`, description: "Palestine", defaultMessage: "Palestine"},
  PAN: { id: `${namespace}.PAN`, description: "Panama", defaultMessage: "Panama"},
  PNG: { id: `${namespace}.PNG`, description: "Papua New Guinea", defaultMessage: "Papua New Guinea"},
  PRY: { id: `${namespace}.PRY`, description: "Paraguay", defaultMessage: "Paraguay"},
  PER: { id: `${namespace}.PER`, description: "Peru", defaultMessage: "Peru"},
  PHL: { id: `${namespace}.PHL`, description: "Philippines", defaultMessage: "Philippines"},
  POL: { id: `${namespace}.POL`, description: "Poland", defaultMessage: "Poland"},
  PRT: { id: `${namespace}.PRT`, description: "Portugal", defaultMessage: "Portugal"},
  PRI: { id: `${namespace}.PRI`, description: "Puerto Rico", defaultMessage: "Puerto Rico"},
  QAT: { id: `${namespace}.QAT`, description: "Qatar", defaultMessage: "Qatar"},
  COG: { id: `${namespace}.COG`, description: "Congo", defaultMessage: "Congo"},
  REU: { id: `${namespace}.REU`, description: "Réunion", defaultMessage: "Réunion"},
  ROU: { id: `${namespace}.ROU`, description: "Romania", defaultMessage: "Romania"},
  RUS: { id: `${namespace}.RUS`, description: "Russia", defaultMessage: "Russia"},
  RWA: { id: `${namespace}.RWA`, description: "Rwanda", defaultMessage: "Rwanda"},
  BLM: { id: `${namespace}.BLM`, description: "Saint Barthélemy", defaultMessage: "Saint Barthélemy"},
  SHN: { id: `${namespace}.SHN`, description: "Saint Helena", defaultMessage: "Saint Helena"},
  KNA: { id: `${namespace}.KNA`, description: "Saint Kitts and Nevis", defaultMessage: "Saint Kitts and Nevis"},
  MAF: { id: `${namespace}.MAF`, description: "Saint Martin", defaultMessage: "Saint Martin"},
  SPM: { id: `${namespace}.SPM`, description: "Saint Pierre and Miquelon", defaultMessage: "Saint Pierre and Miquelon"},
  VCT: { id: `${namespace}.VCT`, description: "Saint Vincent and the Grenadines", defaultMessage: "Saint Vincent and the Grenadines"},
  WSM: { id: `${namespace}.WSM`, description: "Samoa", defaultMessage: "Samoa"},
  SMR: { id: `${namespace}.SMR`, description: "San Marino", defaultMessage: "San Marino"},
  STP: { id: `${namespace}.STP`, description: "São Tomé and Príncipe", defaultMessage: "São Tomé and Príncipe"},
  SAU: { id: `${namespace}.SAU`, description: "Saudi Arabia", defaultMessage: "Saudi Arabia"},
  SEN: { id: `${namespace}.SEN`, description: "Senegal", defaultMessage: "Senegal"},
  SRB: { id: `${namespace}.SRB`, description: "Serbia", defaultMessage: "Serbia"},
  SYC: { id: `${namespace}.SYC`, description: "Seychelles", defaultMessage: "Seychelles"},
  SLE: { id: `${namespace}.SLE`, description: "Sierra Leone", defaultMessage: "Sierra Leone"},
  SGP: { id: `${namespace}.SGP`, description: "Singapore", defaultMessage: "Singapore"},
  SXM: { id: `${namespace}.SXM`, description: "Sint Maarten (Dutch part)", defaultMessage: "Sint Maarten (Dutch part)"},
  SVK: { id: `${namespace}.SVK`, description: "Slovakia", defaultMessage: "Slovakia"},
  SVN: { id: `${namespace}.SVN`, description: "Slovenia", defaultMessage: "Slovenia"},
  SLB: { id: `${namespace}.SLB`, description: "Solomon Islands", defaultMessage: "Solomon Islands"},
  SOM: { id: `${namespace}.SOM`, description: "Somalia", defaultMessage: "Somalia"},
  ZAF: { id: `${namespace}.ZAF`, description: "South Africa", defaultMessage: "South Africa"},
  KOR: { id: `${namespace}.KOR`, description: "South Korea", defaultMessage: "South Korea"},
  SSD: { id: `${namespace}.SSD`, description: "South Sudan", defaultMessage: "South Sudan"},
  ESP: { id: `${namespace}.ESP`, description: "Spain", defaultMessage: "Spain"},
  LKA: { id: `${namespace}.LKA`, description: "Sri Lanka", defaultMessage: "Sri Lanka"},
  LCA: { id: `${namespace}.LCA`, description: "Saint Lucia", defaultMessage: "Saint Lucia"},
  SDN: { id: `${namespace}.SDN`, description: "Sudan", defaultMessage: "Sudan"},
  SUR: { id: `${namespace}.SUR`, description: "Suriname", defaultMessage: "Suriname"},
  SWZ: { id: `${namespace}.SWZ`, description: "Swaziland", defaultMessage: "Swaziland"},
  SWE: { id: `${namespace}.SWE`, description: "Sweden", defaultMessage: "Sweden"},
  CHE: { id: `${namespace}.CHE`, description: "Switzerland", defaultMessage: "Switzerland"},
  SYR: { id: `${namespace}.SYR`, description: "Syrian Arab Republic", defaultMessage: "Syrian Arab Republic"},
  TWN: { id: `${namespace}.TWN`, description: "Taiwan", defaultMessage: "Taiwan"},
  TJK: { id: `${namespace}.TJK`, description: "Tajikistan", defaultMessage: "Tajikistan"},
  TZA: { id: `${namespace}.TZA`, description: "Tanzania", defaultMessage: "Tanzania"},
  THA: { id: `${namespace}.THA`, description: "Thailand", defaultMessage: "Thailand"},
  BHS: { id: `${namespace}.BHS`, description: "Bahamas", defaultMessage: "Bahamas"},
  GMB: { id: `${namespace}.GMB`, description: "Gambia", defaultMessage: "Gambia"},
  TLS: { id: `${namespace}.TLS`, description: "Timor-Leste", defaultMessage: "Timor-Leste"},
  TGO: { id: `${namespace}.TGO`, description: "Togo", defaultMessage: "Togo"},
  TKL: { id: `${namespace}.TKL`, description: "Tokelau", defaultMessage: "Tokelau"},
  TON: { id: `${namespace}.TON`, description: "Tonga", defaultMessage: "Tonga"},
  TTO: { id: `${namespace}.TTO`, description: "Trinidad and Tobago", defaultMessage: "Trinidad and Tobago"},
  TUN: { id: `${namespace}.TUN`, description: "Tunisia", defaultMessage: "Tunisia"},
  TUR: { id: `${namespace}.TUR`, description: "Turkey", defaultMessage: "Turkey"},
  TKM: { id: `${namespace}.TKM`, description: "Turkmenistan", defaultMessage: "Turkmenistan"},
  TCA: { id: `${namespace}.TCA`, description: "Turks and Caicos Islands", defaultMessage: "Turks and Caicos Islands"},
  TUV: { id: `${namespace}.TUV`, description: "Tuvalu", defaultMessage: "Tuvalu"},
  UGA: { id: `${namespace}.UGA`, description: "Uganda", defaultMessage: "Uganda"},
  UKR: { id: `${namespace}.UKR`, description: "Ukraine", defaultMessage: "Ukraine"},
  ARE: { id: `${namespace}.ARE`, description: "United Arab Emirates", defaultMessage: "United Arab Emirates"},
  URY: { id: `${namespace}.URY`, description: "Uruguay", defaultMessage: "Uruguay"},
  VIR: { id: `${namespace}.VIR`, description: "U.S. Virgin Islands", defaultMessage: "U.S. Virgin Islands"},
  UZB: { id: `${namespace}.UZB`, description: "Uzbekistan", defaultMessage: "Uzbekistan"},
  VUT: { id: `${namespace}.VUT`, description: "Vanuatu", defaultMessage: "Vanuatu"},
  VAT: { id: `${namespace}.VAT`, description: "Vatican City", defaultMessage: "Vatican City"},
  VEN: { id: `${namespace}.VEN`, description: "Venezuela", defaultMessage: "Venezuela"},
  VNM: { id: `${namespace}.VNM`, description: "Vietnam", defaultMessage: "Vietnam"},
  WLF: { id: `${namespace}.WLF`, description: "Wallis and Futuna", defaultMessage: "Wallis and Futuna"},
  YEM: { id: `${namespace}.YEM`, description: "Yemen", defaultMessage: "Yemen"},
  ZMB: { id: `${namespace}.ZMB`, description: "Zambia", defaultMessage: "Zambia"},
  ZWE: { id: `${namespace}.ZWE`, description: "Zimbabwe", defaultMessage: "Zimbabwe"}

});

export default countries;
