import React, { PureComponent } from "react";
import { Field } from "redux-form";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import { Radio, Grid, Col, Section } from "../../../../components";
import { Txt } from "../../../../elements";
import { messages } from "../../../../constants";
import { validationBuilder, validations } from "../..";

let rules = {};
class LangSelector extends PureComponent {
  constructor(props) {
    super(props);
    Object.keys(validations).forEach(key => {
      rules[key] = spec => value => validationBuilder(props, validations[key], spec)(value);
    });
    const { intl } = props;
    this.ruleLang = [rules.required(intl.formatMessage(messages.personal.lang))];
  }

  render() {
    const { intl } = this.props;
    return (
      <Section size="2-3" min="md">
        <Grid>
          <Col size="1-2" min="sm">
            <Txt theme={[`middle`, `large`]}>
              <p>{intl.formatMessage(messages.field.lang)}*</p>
            </Txt>
          </Col>
          <Col size="1-2" min="sm">
            <Field
              name="locale"
              options={[
                { label: intl.formatMessage(messages.field.langen), value: "EN" },
                { label: intl.formatMessage(messages.field.langfr), value: "FR" }
              ]}
              validate={this.ruleLang}
              component={Radio}
            />
          </Col>
        </Grid>
      </Section>
    );
  }
}

LangSelector.propTypes = {
  intl: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  section: PropTypes.string.isRequired
};

export default injectIntl(LangSelector);
