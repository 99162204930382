import React from "react";
import { Provider } from "react-redux";
import PropTypes from "prop-types";

const ReduxRoot = ({ children, store }) => {
  return <Provider store={store}>{children}</Provider>;
};

ReduxRoot.propTypes = {
  store: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string
  ])
};

export default ReduxRoot;
