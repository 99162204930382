import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import { SubmissionError } from "redux-form";

import set from "lodash/set";
import { PagesContainer } from "../../containers";
import { VerifyOtpForm } from "../../containers/form";
import { constants, messages } from "../../constants";
import { validateBusinessEmailOtp, resendBusinessEmailOTP } from "../../api/propel";
import * as bannerActions from "../../actions/banner";
import * as settingReduxActions from "../../actions/settings";
import { ErrorBoundary } from "../../components";
import {trim} from "lodash";
import * as allActions from "../../actions";
import * as selectors from "../../selectors";

class PrivateRegistrationVerifyOTP extends PureComponent {


  state = { loading: false };
  nextPage = () => {
    const { isEvoMember } = this.props;
    if ( isEvoMember === false) {
      this.props.history.push(constants.ROUTE_REGISTRATION);
    }
    else {
      this.props.history.push(constants.ROUTE_PRIVATE_REGISTRATION_SUCCESS);
    }
  };


  _mapErrorCodeMessage = (code, fieldId) => {
    const { intl } = this.props;
    let spec = fieldId;
    //console.log('error', code);

    switch (code) {
      case 4000: // required
        return intl.formatMessage(messages.error.required, { spec });
      case 4001: // invalid format
        return intl.formatMessage(messages.error.invalidFormat, { spec });
      case 4002: // invalid value
        {
          return intl.formatMessage(messages.error.invalidValue, { spec });
        }
      case 4003: // value too large
        return intl.formatMessage(messages.error.invalidValue, { spec });
      case 4004: // value too small
        return intl.formatMessage(messages.error.invalidValue, { spec });
      case 4005: {// duplicate
        if (fieldId === messages.field.EmailAddress.defaultMessage) {
          return intl.formatMessage(messages.asyncValidation.emailAddressDuplicate)
        }
        return intl.formatMessage(messages.error.duplicated, { spec });
      }
      case 4010: // Existing Business Customer
        return intl.formatMessage(messages.asyncValidation.emailAddressDuplicate, { spec });
      case 4011: // Existing Business Customer
        return intl.formatMessage(messages.asyncValidation.invalidOtp, { spec });
      case 4012: // Existing Business Customer
        return intl.formatMessage(messages.asyncValidation.expiredOtp, { spec });
      case 4013: // Existing Business Customer
        return intl.formatMessage(messages.asyncValidation.multipleBusinessAccounts, { spec });
      default:
        return null;
    }
  };


  _mapFieldId = (field_Id) => {
    switch (trim(field_Id)) {
      case "otp":
        return { storeField: "evolveBusinessRegVerifyOtp.otp", displayField: messages.privateRegistration.OTPEmailInstructions.defaultMessage };
      default:
        return null;
    }
  }

  _formatSubmissionErrors = (errors) => {
    return errors.reduce((result, error) => {
      const errorCode = error.error_codes[0];
      const field = this._mapFieldId(error.field_id);
      const errorMsg = this._mapErrorCodeMessage(errorCode, field.displayField);
      set(result, field.storeField, errorMsg);


      return result;
    }, {});
  };


  _handleSubmit = async (values) => {

    this.setState({ loading: true });

    // promoCode is stored after clicking apply button
    // it has status and message
    const { settingsActions, registration_id, otp } = this.props;

    //await getAydenData(this.props);
    // 5. The submit operation can be carried on
    return new Promise(async (resolve, reject) => {
//console.log("otp", otp);
      settingsActions.toggleSpinnerOn();
      validateBusinessEmailOtp(registration_id, otp, false)
        .then(async (res) => {
          const data = await res.json();
          if (res.status === 200) {
            // Success
            resolve();
            //window.dataLayer.push({'event': 'registration_success'});
            settingsActions.toggleSpinnerOff();
            this.nextPage();
            this.props.banner.resetAlerts();
          } else if (Array.isArray(data.errors) && data.errors.length) {
           // console.log("errors")
            // Errors returned from back end
            // window.dataLayer.push({'event': 'registration_error'});
            const submissionErrors = this._formatSubmissionErrors(data.errors);
            throw new SubmissionError(submissionErrors);
          } else if (data.error) {
            // window.dataLayer.push({'event': 'registration_error'});
            throw new Error(data.error);
          } else {
            // window.dataLayer.push({'event': 'registration_error'});
            throw new Error("unknown error");
          }
        })
        .catch((e) => {
          this.setState({ loading: false });
          settingsActions.toggleSpinnerOff();
          reject(e);
        });
    });
  };

  _resendOTP = async () => {
    const { settingsActions, registration_id } = this.props;
    settingsActions.toggleSpinnerOn();
    return new Promise(async (resolve, reject) => {
      await resendBusinessEmailOTP(registration_id).then(async (res) => {
        settingsActions.toggleSpinnerOff();
        resolve();
      }).catch((e) => {
        this.setState({ loading: false });
        settingsActions.toggleSpinnerOff();
        reject(e);
      });
    });
  }

  render() {
    const { intl } = this.props;
    const { loading } = this.state;

    return (
      <PagesContainer {...this.props}>
        <ErrorBoundary name="verify otp">
          <VerifyOtpForm
            submitting={loading}
            onSubmit={this._handleSubmit}
            resendOtp={this._resendOTP}
            button={intl.formatMessage(messages.button.continue)}
          // sections={sections}
          //previousPage={progress.selectedIndex <= 0 || progress.selectedIndex >=PROGRESS_BAR_LENGTH ? undefined : this.previousPage}
          />
        </ErrorBoundary>
      </PagesContainer>
    );
  }

}

PrivateRegistrationVerifyOTP.propTypes = {
  intl: PropTypes.object.isRequired,
  form: PropTypes.object,
  isEvoMember: PropTypes.bool,
  registration_id: PropTypes.number,
  otp: PropTypes.number
};

//const selector = formValueSelector(constants.FORM.registrationForm);
const mapStateToProps = (state) => ({
  form: state.form.verifyOtpForm,
  isEvoMember: selectors.getIsEvoMember(state),
  registration_id: selectors.getRegistrationId(state),
  otp: selectors.getOTP(state)
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(allActions.flow, dispatch),
  banner: bindActionCreators(bannerActions, dispatch),
  settingsActions: bindActionCreators(settingReduxActions, dispatch)
});

export const IntlPrivateRegistration = injectIntl(PrivateRegistrationVerifyOTP);
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PrivateRegistrationVerifyOTP));
