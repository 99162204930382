// import { constants } from '../constants';
import {formValueSelector} from "redux-form";
import {constants} from "../constants";


//Registration Form get values
const selector = formValueSelector(constants.FORM.registrationForm);

export const getPageTheme = state => state.page.theme;
export const getPageSubNav = state => state.page.sub;

export const getSelectedIndex = state => state.flow.selectedIndex;
export const getValidIndex = state => state.flow.validIndex;
export const getIsEditNameMode = state => state.flow.editNameMode;
export const getPaymentSummary = state => state.flow.paymentSummary;
export const getiscaaMember = state => {return selector(state, "promosAndSavings.iscaaMember") === "Yes" ? true : false};
export const getPromoCodeStatus = state => state.flow.promoCode;
export const getDriversLicenseFront = state => state.flow.driversLicenseFront;
export const getDriversLicenseBack = state => state.flow.driversLicenseBack;
export const getSelfie = state => state.flow.selfie;
export const getIsEbikeOnly = state => selector(state, "personalInfo.isEbikeOnly");
export const getValidateMembership = state => state.flow.validateMembership;


// Get Private Registration Form Values
const selector_b2b = formValueSelector(constants.FORM.privateRegistrationForm);

export const getIsEvoMember = state => {return selector_b2b(state, "evolveBusinessReg.isEvoMember") === "Yes" ? true : false};
export const getPersonalEmail = state => {return selector_b2b(state, "evolveBusinessReg.personalEmail")};
export const getRegistrationId = state => state.flow.registrationId.registration_id;
export const getAccessCode = state => {return selector_b2b(state, "evolveBusinessReg.accessCode")};

//Verify OTP Form Values
const selector_verifyOTP = formValueSelector(constants.FORM.verifyOtpForm);
export const getOTP = state => {return selector_verifyOTP(state, "evolveBusinessRegVerifyOtp.otp");}

export const getForm = state => state.form;

export const getUser = state => state.user;
export const getUserInfo = state => state.user.userInfo;

export const getRegions = ({ regions }) =>
  regions && regions.length ? regions.filter(region => region.active) : [];

export const getBilling = state => state.billing;

export const getOAuth = state => state.oauth;
export const isAuthPending = state => getOAuth(state).authPending;
export const isLoggedIn = state => (getUser(state).accountSummary ? true : false);
export const getAccessToken = state =>
  getOAuth(state).access_token || localStorage.getItem("access_token");
export const getRefreshToken = state =>
  getOAuth(state).efresh_token || localStorage.getItem("refresh_token");

export const getSettings = state => state.settings;
export const shouldShowSubnav = ({ settings: { shouldShowSubNav } }) =>
  typeof shouldShowSubNav === "undefined" ? true : shouldShowSubNav;
