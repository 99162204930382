import React, {PureComponent} from "react";
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";
import 'react-responsive-modal/styles.css';
import {Modal} from 'react-responsive-modal';
import './modal.scss';
import {Col, DependOn, Grid, Section} from "../../../../components";
import {ImageRequirements, WebcamComponent} from "../../fieldGroups";
import {isDesktop, isMobile} from 'react-device-detect';
import {Button, Txt} from "../../../../elements";
import {messages} from "../../../../constants";
import ImagePreview from "../ImagePreview";
//
//
class ImageCapture extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showFileInput: false,
      showFileInputCamera: false,
      showErrorMessage: undefined
    };

    this.fileInput = React.createRef();

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleTakePicAction = this.handleTakePicAction.bind(this);
    this.handleFileUploadAction = this.handleFileUploadAction.bind(this);
    this.displayFileUpload = this.displayFileUpload.bind(this);
    this.handleModalImageResults = this.handleModalImageResults.bind(this);
  }


  handleTakePicAction() {
    if (isMobile) {
      this.setState({showModal: false});
      this.setState({showFileInput: false});
      this.setState({showFileInputCamera: true}, this.displayFileUpload);
    } else if (isDesktop) {
      this.setState({showFileInputCamera: false})
      this.setState({showFileInput: false})
      this.setState({showModal: true});
    }
  }

  handleFileUploadAction() {
    this.setState({showModal: false});
    this.setState({showFileInputCamera: false});
    this.setState({showFileInput: true}, this.displayFileUpload);
  }

  displayFileUpload() {
    this.fileInput.current.click();
  }

  handleCloseModal() {
    this.setState({showModal: false});
  }

  handleModalImageResults = (modalData) => {
    const {
      input: {onChange}
    } = this.props

    this.handleCloseModal();
    this.setState({imageFile: modalData});
    this.props.onValueChange(modalData, onChange);
    //
  }


  convertBlobToFile(blockImage) {
    return new File([blockImage], "dl_front")
  };


  renderMessages = () => {

    const { driverLicenceFront, driversLicenseBack, selfie, isSelfie, isBack } = this.props;
    if ((!isBack && !isSelfie) && ((!driverLicenceFront) || (!driverLicenceFront.message))) return null;
    if ((isBack) && ((!driversLicenseBack) || (!driversLicenseBack.message))) return null;
    if ((isSelfie) && ((!selfie) || (!selfie.message))) return null;

    let message = "";
    let status = "";
    if(isBack){
      status = driversLicenseBack.status;
      message = this.props.intl.formatMessage(driversLicenseBack.message);
    }
    else if(isSelfie){
      status = selfie.status;
      message = this.props.intl.formatMessage(selfie.message);
    }
    else {
      status = driverLicenceFront.status;
      message = this.props.intl.formatMessage(driverLicenceFront.message);
    }

    return (

    <Txt theme={[status]}>
      <p>{message}</p>
    </Txt>
  );
  };

  render() {
    const {
      input: {onChange},
      meta: {error, touched, asyncValidating},
      upperImageCaption, intl, isSelfie, icon, getValue, getName} = this.props
    const captureMode = isSelfie ? "user" : "environment";
    return (

      <Section>
        <Grid>
          <Col>
            <Txt>{upperImageCaption}</Txt>
          </Col>

          <Col>
            <figure>
              {getValue(getName()) ?
                < ImagePreview
                  dataUri={getValue(getName())}
                  isFullscreen={false}
                /> :
                <img
                  src={icon}
                  alt='test'
                />
              }
            </figure>
          </Col>
          <Col>
            <div className={asyncValidating ? "async-validating" : ""}>
              {/*{error}{warning}*/}
              {touched && error && (
                <Txt theme={["error"]}>
                  <small>{error}</small>
                </Txt>
              )}

            </div>
          </Col>

          <Col>{this.renderMessages()}</Col>

          <Col>
            <ImageRequirements {...this.props}/>
          </Col>

          <Col>
            <Button
              theme={["cta"]}
              onClick={this.handleTakePicAction}>
              {intl.formatMessage(messages.imageCapture.takePicButton)}
            </Button>
          </Col>
          <Col>
            <Button
              theme={["cta"]}
              onClick={this.handleFileUploadAction}>
              {intl.formatMessage(messages.imageCapture.uploadPicButton)}
            </Button>
          </Col>
          <DependOn active={this.state.showModal === true}>

            <Modal
              open={this.state.showModal}
              center={true}
              closeOnEsc={true}
              closeOnOverlayClick={true}
              blockScroll={true}

              focusTrapped={false}
              onClose={this.handleCloseModal}
              classNames={{
                overlay: 'customOverlay',
                modal: 'customModal'
              }}
            >
              <WebcamComponent
                {...this.props}
                resultsCallback={this.handleModalImageResults}
                isSelfie={isSelfie}/>
            </Modal>
          </DependOn>
          <DependOn active={this.state.showFileInputCamera === true}>
            <div>
              <label>
                <input
                  style={{display: 'none'}}
                  id="imageInput"
                  type='file'
                  accept="image/*"
                  capture={captureMode}
                  onChange={file => {
                    let newFile = this.fileInput.current.files[0];
                    this.setState({imageFile: newFile});
                    this.props.onValueChange(newFile, onChange);
                  }}
                  ref={this.fileInput}
                />
              </label>
            </div>
          </DependOn>

          <DependOn active={this.state.showFileInput === true}>
            <div>

              <label>
                <input
                  style={{display: 'none'}}
                  id='fileInput'
                  type='file'
                  accept="image/*"
                  onChange={file => {
                    let newFile = this.fileInput.current.files[0];
                    this.setState({imageFile: newFile});
                    this.props.onValueChange(newFile, onChange)
                  }}
                  ref={this.fileInput}
                />
              </label>

            </div>
          </DependOn>

        </Grid>
      </Section>


    );
  }
}

ImageCapture.propTypes = {
  intl: PropTypes.object.isRequired,
  section: PropTypes.string,
  disabled: PropTypes.bool,
  orientation: PropTypes.string.isRequired,
  upperImageCaption: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  isSelfie: PropTypes.bool.isRequired,
  onValueChange: PropTypes.func,
  imageFile: PropTypes.object,
  onChange: PropTypes.func,
  getValue: PropTypes.func.isRequired,
  getName: PropTypes.func.isRequired,
  driverLicenceFront: PropTypes.object,
  driversLicenseBack: PropTypes.object,
  selfie: PropTypes.object,
};

export default injectIntl(ImageCapture);
