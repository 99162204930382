import React, { PureComponent } from "react";
import { Field } from "redux-form";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import { Input, Grid, Col, Section} from "../../../../components";
import { messages } from "../../../../constants";
import {normalizers, validationBuilder, validations} from "../..";
import {compose} from "redux";
import mastercard from "../../../../assets/img/mastercard.svg"
import visa from "../../../../assets/img/visa.svg"
import amex from "../../../../assets/img/amex.svg"


let rules = {};
class CreditCard extends PureComponent {
  constructor(props) {
    super(props);

    // for field level validations
    Object.keys(validations).forEach(key => {
      rules[key] = spec => value => validationBuilder(props, validations[key], spec)(value);
    });

    const { intl } = props;
    this.ruleCcNumber = [rules.required(intl.formatMessage(messages.creditCard.ccNumber)), rules.shouldHave13Numbers(intl.formatMessage(messages.creditCard.ccNumber))];
    this.ruleExpiryDate = [rules.required(intl.formatMessage(messages.creditCard.expiryDate)), rules.monthYearRange(intl.formatMessage(messages.creditCard.expiryDate))];
    this.ruleCvv = [rules.required(intl.formatMessage(messages.creditCard.cvv)), rules.shouldHave3Numbers(intl.formatMessage(messages.creditCard.cvv))];
    this.ruleCardHolderName = [rules.required(intl.formatMessage(messages.creditCard.cardHolderName)), rules.names(intl.formatMessage.creditCardHolderName)];

  }


  render() {
    const { intl } = this.props;


    return (
      <Section size="2-4" min="md" theme={[`div`]}>
        <Grid>
          <Col size="3-4" min="sm">
            <Field
              type="number" 
              name={"ccNumber"}
              label={intl.formatMessage(messages.creditCard.ccNumber)}
              tooltip={intl.formatMessage(messages.creditCard.ccNumberToolTip)}
              required={true}
              validate={this.ruleCcNumber}
              normalize={compose(
                normalizers.removeSpaces
              )}
              disabled={false}
              component={Input}
              props={{ maxLength: "19" }}
            />
          </Col>
          <Col size="1-4" min="sm">
                <br></br>
                <img className="ccLogo" src={mastercard} alt="Master Card" />
                <img className="ccLogo" src={visa} alt="Visa" />
                <img className="ccLogo" src={amex} alt="AMEX" />
          </Col>

          <Col size="3-4" min="sm">
            <Field
              type="text"
              name={"cardHolderName"}
              label={intl.formatMessage(messages.creditCard.cardHolderName)}
              required={true}
              validate={this.ruleCardHolderName}
              disabled={false}
              component={Input}
              props={{ maxLength: "50" }}
            />
          </Col>

          <Col size="1-4" min="sm">
            <Field
              type="number"
              name={"ccExpiryDate"}
              label={intl.formatMessage(messages.creditCard.expiryDate)}
              required={true}
              validate={this.ruleExpiryDate}
              disabled={false}
              component={Input}
              props={{ maxLength: "4" }}
            />
          </Col>

          <Col size="1-4" min="sm">
            <Field
              type="number"
              name={"cvv"}
              label={intl.formatMessage(messages.creditCard.cvv)}
              required={true}
              validate={this.ruleCvv}
              disabled={false}
              component={Input}
              props={{ maxLength: "4" }}
            />
          </Col>

        </Grid>
      </Section>
    );
  }
}

CreditCard.propTypes = {
  theme: PropTypes.array,
  intl: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  section: PropTypes.string.isRequired,
};

CreditCard.defaultProps = {
  disabled: false
};


export default injectIntl(CreditCard);
