import { defineMessages } from "react-intl";

const namespace = "freeMinutes";
const transaction = defineMessages({
  goodWillThankYou: {
    id: `${namespace}.goodWillThankYou`,
    description: "free minute type",
    defaultMessage: "Goodwill/Thank You"
  },
  serviceRecovery: {
    id: `${namespace}.serviceRecovery`,
    description: "free minute type",
    defaultMessage: "Service Recovery"
  },
  serviceDisruption: {
    id: `${namespace}.serviceDisruption`,
    description: "free minute type",
    defaultMessage: "Service Disruption"
  },
  promoMinutesExtension: {
    id: `${namespace}.promoMinutesExtension`,
    description: "free minute type",
    defaultMessage: "Promo Minutes Extension"
  },
  promoMinutesException: {
    id: `${namespace}.promoMinutesException`,
    description: "free minute type",
    defaultMessage: "Promo Minutes Exception"
  },
  promoCode: {
    id: `${namespace}.promoCode`,
    description: "free minute type",
    defaultMessage: "Promo Code ({promoCode})"
  },
  referredTo: {
    id: `${namespace}.referredTo`,
    description: "free minute type",
    defaultMessage: "Referred To ({clientNum})"
  },
  referredBy: {
    id: `${namespace}.referredBy`,
    description: "free minute type",
    defaultMessage: "Referred By ({clientNum})"
  },
  other: {
    id: `${namespace}.other`,
    description: "free minute type",
    defaultMessage: "Other"
  }
});

export default transaction;
