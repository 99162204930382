/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { reduxForm, FormSection } from "redux-form";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import * as selectors from "../../../selectors";
import * as allActions from "../../../actions";
import { Button } from "../../../elements";
import { messages, constants } from "../../../constants";
import { Moneris } from "../fieldGroups";

const UpdateCreditCardForm = props => {
  const {
    isEditable,
    handleSubmit,
    intl,
    section,
    submitting,
    handleClickEdit,
    handleClickCancel
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <FormSection name={section} className="form__section">
        <Moneris
          {...props}
          section={section}
          disabled={!isEditable}
          shouldShowAcceptedCardTypes={false}
        />
      </FormSection>

      {!isEditable && (
        <Button loading={submitting} theme={["cta"]} onClick={handleClickEdit} type="button">
          {intl.formatMessage(messages.button.edit)}
        </Button>
      )}

      {isEditable && (
        <Fragment>
          <Button loading={submitting} theme={["cta"]} type="submit">
            {intl.formatMessage(messages.button.save)}
          </Button>
          <a onClick={handleClickCancel} className="is-underlined">
            {intl.formatMessage(messages.button.cancel)}
          </a>
        </Fragment>
      )}
    </form>
  );
};

UpdateCreditCardForm.propTypes = {
  intl: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  state: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired, // for moneris component
  creditCardValues: PropTypes.object.isRequired, // to display default values
  initialValues: PropTypes.object.isRequired,
  section: PropTypes.string,
  isEditable: PropTypes.bool,
  handleClickEdit: PropTypes.func,
  handleClickCancel: PropTypes.func
};

UpdateCreditCardForm.defaultProps = {
  section: "",
  isEditable: false,
  handleClickEdit: () => {},
  handleClickCancel: () => {}
};

const settings = {
  form: constants.FORM.updateCreditCardForm,
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true
};

// const selector = formValueSelector(settings.form);
const mapStateToProps = state => ({
  state,
  user: selectors.getUser(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(allActions.flow, dispatch)
});

export default compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm(settings)
)(UpdateCreditCardForm);
