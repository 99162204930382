import { jsonToFormStr } from "../../util";
import { messages } from "../../constants";

export const getLocation = (successCallback, errorCallback) => {
  if (navigator.geolocation) {
    // If the browser could detect a location with permission granted
    // It will return position object
    // Ex: {coords: { latitude, longitude, ...rest}, timestamp}
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        successCallback({ lat: latitude, lng: longitude });
      },
      error => {
        errorCallback(error);
      },
      // Optional timeout here we set it to be 60 seconds
      { timeout: 60000 }
    );
  } else {
    // Geolocation is not supported by this browser
  }
};

export const fetchLatLngFromAddress = async address => {
  const queryObj = {
    address,
    key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    region: "ca"
  };
  const queryStr = jsonToFormStr(queryObj);
  const geocodeEndpoint = `https://maps.googleapis.com/maps/api/geocode/json?${queryStr}`;
  try {
    const response = await fetch(geocodeEndpoint);
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const getLatLngFromMarketingRegion = regionId => {
  switch (regionId) {
    case 1: // Vancouver
      return { lat: 49.2428271, lng: -123.0861387 };
    case 2: // Montreal
      return { lat: 45.5576996, lng: -74.0104841 };
    case 3: // Toronto
      return { lat: 43.6567919, lng: -79.4609322 };
    default:
      // Centre of Canada
      return { lat: 57.0861239, lng: -93.2135469 };
  }
};

// Converts numeric degrees to radians
const toRad = deg => (deg * Math.PI) / 180;

// http://stackoverflow.com/a/18883819/7787910
// This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
export const calcCrow = (lat1, lon1, lat2, lon2) => {
  if (!lat1 || !lat2 || !lon1 || !lon2) {
    return;
  }
  const R = 6371000; // Earth radius in metres
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const dLat1 = toRad(lat1);
  const dLat2 = toRad(lat2);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(dLat1) * Math.cos(dLat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
};

export const convertNumberToMetric = distance => {
  if (!distance || isNaN(distance)) {
    return "n/a";
  }
  return distance >= 1000 ? `${(distance / 1000).toFixed(1)} km` : `${distance.toFixed(2)} m`;
};

export const isZoomChangingBoundary = (zooms = [null, null], zoomThreadshold = 8) => {
  const [previousZoom, currentZoom] = zooms;
  if (zooms.length === 2) {
    if (previousZoom) {
      if (
        (previousZoom < zoomThreadshold && currentZoom < zoomThreadshold) ||
        (previousZoom > zoomThreadshold && currentZoom > zoomThreadshold)
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
};

const settings = {
  tanksize: 36, //Prius C tank size (l)
  kmpl: 23, //Prius C efficiency (Km/l)
  averageWalkingSpeed: 5 //Average human walking speed (5km/h), ref: https://en.wikipedia.org/wiki/Walking
};
//calculates the range based on Car efficiency
export const getFuelLevel = level => {
  if (level) {
    const lev = parseInt(level, 10);
    return Math.round((settings.tanksize * lev) / 100);
  }
};

export const getWalkingTime = distanceInMeter => {
  if (distanceInMeter) {
    const distance = distanceInMeter / 1000;
    const hour = distance / settings.averageWalkingSpeed;
    return Math.round(hour * 60);
  }
};


// Convert millisecond to DD HH MM SS
export const convertMsToTime = d => {
  let r = {};
  d>86400000 && (r.days = Math.floor(d/86400000));
  d>3600000 && (r.hours = Math.floor(d%86400000/3600000));
  d>60000 && (r.minutes = Math.floor(d%3600000/60000));
  d>=0 && (r.seconds = Math.floor(d%60000/1000))
  return r;
};

export const expireCountDown = (reservation, formatMessage) => {
  if(reservation) {
    let reservationExpire = convertMsToTime(new Date(reservation.startDate).valueOf() + (reservation.reservationTimeLimit * 60000) - new Date().valueOf());
    return '' +
      (reservationExpire.hours ? (reservationExpire.hours + ' ' + formatMessage(messages.maps.hours) + ', ') : '') +
      (reservationExpire.minutes ? (reservationExpire.minutes + ' ' + formatMessage(messages.maps.minutes) + ', ') : '') +
      (reservationExpire.seconds ? (reservationExpire.seconds + ' ' + formatMessage(messages.maps.seconds)) : '');
  }
};
