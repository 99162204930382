import React from "react";
import { PagesContainer } from "../../containers";
import { ContactUsBusinessScreen } from "../../screens";

const ContactUsBusiness = (props) => {
  return (
    <PagesContainer {...props}>
      <ContactUsBusinessScreen {...props} />
    </PagesContainer>
  );
};

ContactUsBusiness.defaultProps = {};

export default ContactUsBusiness;
