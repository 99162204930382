// THESE ARE ALL REGISTRATION RELATED!!
// MAYBE BETTER TO RENAME THIS AS REGISTRATION??
import { constants } from "../constants";
import { jsonToFormStr } from "../util";
import imageCompression from "browser-image-compression";
import { propel } from "./index";

export const imageCompressionHandler = async (image, filename) => {
  const options = {
    maxSizeMB: 1,
    useWebWorker: true
  };
  const cf = await imageCompression(image, options);
  const newCompressedFile = new File([cf], filename, { type: image.type });

  return newCompressedFile;
};

export const validateDriversLicense = async (dl_front, dl_back = undefined) => {
  const formData = new FormData();
  formData.append("dl_front", dl_front);
  if (dl_back) formData.append("dl_back", dl_back);
  const body = formData;
  //const params = jsonToFormStr({email: email});
  // return fetch(`${constants.ENDPOINTS.validateDriversLicense}`, {method: "POST", body: body}).then(res =>
  //   res.json()
  // );
  const url = `${constants.ENDPOINTS.validateDriversLicense}`;

  try {
    const resp = await fetch(url, {
      method: "POST",
      body: body,
      header: {
        ContentType: "multipart/form-data"
      }
    });
    return await resp.json();
  } catch (error) {
    throw error;
  }
};

export const validateSelfie = async (dlFont, dlBack, selfie) => {
  const formData = new FormData();
  formData.append("dl_front", dlFont);
  formData.append("dl_back", dlBack);
  formData.append("selfie", selfie);

  const url = `${constants.ENDPOINTS.validateSelfie}`;

  const body = formData;
  try {
    const resp = await fetch(url, {
      method: "POST",
      body: body
    });
    return await resp.json();
  } catch (error) {
    throw error;
  }
};

// API call to get a list of regions
// TODO unit tests
export const getRegions = async () => {
  return fetch(constants.ENDPOINTS.getRegions).then((res) => res.json());
};

export const registerBusinessAccount = async (payload) => {
  const url = `${constants.ENDPOINTS.registerBusinessAccount}`;

  const body = JSON.stringify(payload);
  try {
    const resp = await fetch(url, {
      method: "POST",
      body: body
      //headers: formData.getHeaders()
    });
    return resp;
  } catch (error) {
    throw error;
  }
};

export const registerEbike = async (payload) => {
  const formData = new FormData();

  formData.append("request", JSON.stringify(payload));

  const body = formData;
  try {
    const resp = await fetch(constants.ENDPOINTS.registerEBikeOnly, {
      method: "POST",
      body: body
      //headers: formData.getHeaders()
    });
    return resp;
  } catch (error) {
    throw error;
  }
};

export const register = async (payload, dl_front, dl_back, selfie) => {
  const formData = new FormData();

  let selfieCompressed = selfie;
  let dlFrontCompressed = dl_front;
  let dlBackCompressed = dl_back;

  if (selfieCompressed.size > 100000) {
    selfieCompressed = await propel.imageCompressionHandler(selfieCompressed, "selfie", false);
  }

  if (dlFrontCompressed.size > 100000) {
    dlFrontCompressed = await propel.imageCompressionHandler(dlFrontCompressed, "dl_front", false);
  }

  if (dlBackCompressed) {
    if (dlBackCompressed.size > 100000) {
      dlBackCompressed = await propel.imageCompressionHandler(dlBackCompressed, "dl_back", false);
    }
  }

  formData.append("dl_front", dlFrontCompressed);
  formData.append("dl_back", dlBackCompressed);
  formData.append("selfie", selfieCompressed);

  formData.append("request", JSON.stringify(payload));

  const body = formData;
  try {
    const resp = await fetch(constants.ENDPOINTS.register, {
      method: "POST",
      body: body
      //headers: formData.getHeaders()
    });
    return resp;
  } catch (error) {
    throw error;
  }
};

export const updateRegister = async (payload) => {
  const formData = new FormData();

  formData.append("request", JSON.stringify(payload));

  const url = constants.ENDPOINTS.registerUpdateEBikeOnly;

  const body = formData;
  try {
    const resp = await fetch(url, {
      method: "PUT",
      body: body
    });
    return resp;
  } catch (error) {
    throw error;
  }
};

// API call to validate email
export const validateEmail = async (email) => {
  // email.isRequired
  if (!email) {
    return;
  }
  const params = jsonToFormStr({ email: email });
  return fetch(`${constants.ENDPOINTS.validateEmail}?${params}`, { method: "GET" }).then((res) =>
    res.json()
  );
};

// API call to validate drivers license number
export const validateDriversLicenseNumber = async (dl_number) => {
  // email.isRequired
  if (!dl_number) {
    return;
  }
  const params = jsonToFormStr({ dl_number: dl_number });
  return fetch(`${constants.ENDPOINTS.validateDriverLicenseNumber}?${params}`, {
    method: "GET"
  }).then((res) => res.json());
};

// API call to validate promo code and get discount information
export const validatePromoCode = async (promoCode) => {
  // promoCode.isRequired
  if (!promoCode) {
    return;
  }
  const params = jsonToFormStr({ promo_code: promoCode });
  return fetch(`${constants.ENDPOINTS.validatePromo}?${params}`, { method: "GET" }).then((res) =>
    res.json()
  );
};

// API call to validate membership
export const validateMembership = async (memberNumber) => {
  // isRequired
  if (!memberNumber) {
    return;
  }
  const params = jsonToFormStr({
    member_number: memberNumber
  });

  return fetch(`${constants.ENDPOINTS.validateBCAAMembership}?${params}`, { method: "GET" }).then(
    (res) => res.json()
  );
};

// API call to get a list of fees
export const feeSummary = ({ isCAAMember, validateMembership, promoCode }) => {
  let is_member = false;
  if (
    isCAAMember &&
    validateMembership &&
    validateMembership.res &&
    validateMembership.res.valid &&
    validateMembership.res.active
  ) {
    is_member = true;
  }
  const params = jsonToFormStr({
    promo_code: promoCode,
    is_member: is_member
  });

  return fetch(`${constants.ENDPOINTS.feeSummary}?${params}`, { method: "GET" }).then((res) =>
    res.json()
  );
};

export const validateEmailDomain = async (email) => {
  // email.isRequired
  if (!email) {
    return;
  }
  const params = jsonToFormStr({ email: email });
  return fetch(`${constants.ENDPOINTS.validateEmailDomain}?${params}`, { method: "GET" }).then(
    (res) => res.json().then((data) => ({ status: res.status, data: data }))
  );
};

// API call to validate access code
export const validateAccessCode = async (access_code) => {
  // access_code.isRequired
  if (!access_code) {
    return;
  }
  const params = jsonToFormStr({ access_code: access_code });
  return fetch(`${constants.ENDPOINTS.validateB2BAccessCode}?${params}`, { method: "GET" }).then(
    (res) => res.json().then((data) => ({ status: res.status, data: data }))
  );
};

export const validateExistingCustomer = async (email) => {
  // email.isRequired
  if (!email) {
    return;
  }
  const params = jsonToFormStr({ email: email });
  return fetch(`${constants.ENDPOINTS.validateExistingCustomer}?${params}`, { method: "GET" }).then(
    (res) => res.json().then((data) => ({ status: res.status, data: data }))
  );
};

export const validateBusinessEmailOtp = async (registration_id, otp, isNewRegistration, userId) => {
  // email.isRequired
  const payload = {
    otp: otp,
    registration_id: registration_id,
    isNewRegistration: isNewRegistration,
    user_id: userId
  };
  const body = JSON.stringify(payload);
  try {
    const url = `${constants.ENDPOINTS.validateBusinessEmailOtp}`;
    const resp = await fetch(url, {
      method: "POST",
      body: body
      //headers: formData.getHeaders()
    });
    return resp;
  } catch (error) {
    throw error;
  }
};

export const addBusinessAcount = async (business_email, entity_id, userId) => {
  // email.isRequired
  const payload = {
    business_email,
    entity_id,
    user_id: userId
  };
  const body = JSON.stringify(payload);
  try {
    const url = `${constants.ENDPOINTS.addBusinesAccount}`;
    const resp = await fetch(url, {
      method: "POST",
      body: body
      //headers: formData.getHeaders()
    });
    return resp;
  } catch (error) {
    throw error;
  }
};

export const resendBusinessEmailOTP = async (registration_id) => {
  if (!registration_id) {
    return;
  }
  const params = jsonToFormStr({ registration_id: registration_id });
  try {
    return fetch(`${constants.ENDPOINTS.resendBusinessEmailOtp}?${params}`, { method: "GET" }).then(
      (res) => res.json()
    );
  } catch (error) {
    throw error;
  }
};
