import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import { SubmissionError } from "redux-form";

import set from "lodash/set";
import {flow} from "../../actions";
import { PrivateRegistrationForm } from "../../containers/form";
import { constants, messages } from "../../constants";
import { registerBusinessAccount } from "../../api/propel";
import * as bannerActions from "../../actions/banner";
import * as settingReduxActions from "../../actions/settings";
import { ErrorBoundary } from "../../components";

import { trim } from "lodash";
import * as allActions from "../../actions";
import * as selectors from "../../selectors";

class PrivateRegistration extends PureComponent {


  state = { loading: false };


  nextPage = (payload) => {
    if (payload && payload.access_code) {
      const {isEvoMember} = this.props;
      if (isEvoMember === false) {
        this.props.history.push(constants.ROUTE_REGISTRATION);
      } else {
        this.props.history.push(constants.ROUTE_PRIVATE_REGISTRATION_SUCCESS);
      }
    }
    else
      this.props.history.push(constants.ROUTE_PRIVATE_REGISTRATION_OTP);
  };


  _mapErrorCodeMessage = (code, fieldId) => {
    const { intl } = this.props;
    let spec = fieldId;
    //console.log('error', spec);

    switch (code) {
      case 4000: // required
        return intl.formatMessage(messages.error.required, { spec });
      case 4001: // invalid format
        return intl.formatMessage(messages.error.invalidFormat, { spec });
      case 4002: // invalid value
        {
          return intl.formatMessage(messages.error.invalidValue, { spec });
        }
      case 4003: // value too large
        return intl.formatMessage(messages.error.invalidValue, { spec });
      case 4004: // value too small
        return intl.formatMessage(messages.error.invalidValue, { spec });
      case 4005: {// duplicate
        if (fieldId === messages.field.EmailAddress.defaultMessage) {
          return intl.formatMessage(messages.asyncValidation.emailAddressDuplicate)
        }
        return intl.formatMessage(messages.error.duplicated, { spec });
      }
      case 4010: // Existing Business Customer
        return intl.formatMessage(messages.asyncValidation.emailAddressDuplicate, { spec });
      case 4013: // Existing Business Customer
        return intl.formatMessage(messages.asyncValidation.multipleBusinessAccounts, { spec });
      default:
        return null;
    }
  };


  _mapFieldId = (field_Id) => {
    //console.log('map', field_Id);
    switch (trim(field_Id)) {
      case "is_member":
        return { storeField: "evolveBusinessReg.isEvoMember", displayField: messages.privateRegistration.isEvoMember.defaultMessage };
      case "business_email":
        return { storeField: "evolveBusinessReg.businessEmailAddress", displayField: messages.privateRegistration.businessEmailAddress.defaultMessage };
      case "personal_email":
        return { storeField: "evolveBusinessReg.personalEmailAddress", displayField: messages.privateRegistration.personalEmailAddress.defaultMessage };
      case "access_code":
        return { storeField: "evolveBusinessReg.accessCode", displayField: messages.privateRegistration.accessCode.defaultMessage };
      default:
        return null;
    }
  }

  _formatSubmissionErrors = (errors) => {
    return errors.reduce((result, error) => {
      const errorCode = error.error_codes[0];
      const field = this._mapFieldId(error.field_id);
      const errorMsg = this._mapErrorCodeMessage(errorCode, field.displayField);
     // console.log('errorMsg', errorMsg);
      set(result, field.storeField, errorMsg);
      return result;
    }, {});
  };


  _handleSubmit = async (values) => {

    //console.log("handleSubmit");
    this.setState({ loading: true });

    // promoCode is stored after clicking apply button
    // it has status and message
    const { settingsActions } = this.props;


    //await getAydenData(this.props);
    // 5. The submit operation can be carried on
    return new Promise(async (resolve, reject) => {
      const { evolveBusinessReg } = values;
      const {
        isEvoMember,
        businessEmailAddress,
        accessCode,
        personalEmailAddress
      } = evolveBusinessReg;

      let payload = {
        is_member: isEvoMember === 'No' ? false : true,
        business_email: businessEmailAddress,
        access_code: accessCode,
        personal_email: personalEmailAddress
      };
     // console.log("payload", payload);
      if(payload.is_member === false && accessCode) {
        settingsActions.toggleSpinnerOff();
        this.nextPage(payload);
        resolve();
        this.props.banner.resetAlerts();
        return;
      }


      settingsActions.toggleSpinnerOn();
      await registerBusinessAccount(payload)
        .then(async (res) => {
          const data = await res.json();
          //console.log('then', data);

          if (res.status === 201) {
            // Success
            resolve();
            //window.dataLayer.push({'event': 'registration_success'});
            settingsActions.toggleSpinnerOff();
            this.props.setRegistrationId(data);
            this.nextPage(payload);
            this.props.banner.resetAlerts();
          } else if (Array.isArray(data.errors) && data.errors.length) {
           // console.log("errors")
            // Errors returned from back end
            // window.dataLayer.push({'event': 'evolveBusinessReg'});

            const submissionErrors = this._formatSubmissionErrors(data.errors);
            throw new SubmissionError(submissionErrors);
          } else if (data.error) {
            // window.dataLayer.push({'event': 'registration_error'});
            throw new Error(data.error);
          } else {
            // window.dataLayer.push({'event': 'registration_error'});
            throw new Error("unknown error");
          }
        })
        .catch((e) => {
          this.setState({ loading: false });
          settingsActions.toggleSpinnerOff();
          reject(e);
        });
    });
  };

  render() {
    const { intl } = this.props;
    const { loading } = this.state;


    return (
      <ErrorBoundary name="registration private">
        <PrivateRegistrationForm
          submitting={loading}
          onSubmit={this._handleSubmit}
          button={intl.formatMessage(messages.button.continue)}
        // sections={sections}
        //previousPage={progress.selectedIndex <= 0 || progress.selectedIndex >=PROGRESS_BAR_LENGTH ? undefined : this.previousPage}
        />
      </ErrorBoundary>
    );
  }

}

PrivateRegistration.propTypes = {
  intl: PropTypes.object.isRequired,
  form: PropTypes.object,
  setRegistrationId: PropTypes.func,
  isEvoMember: PropTypes.bool
};

//const selector = formValueSelector(constants.FORM.registrationForm);
const mapStateToProps = (state) => ({
  form: state.form.privateRegistrationForm,
  isEvoMember: selectors.getIsEvoMember(state)
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(allActions.flow, dispatch),
  banner: bindActionCreators(bannerActions, dispatch),
  settingsActions: bindActionCreators(settingReduxActions, dispatch),
  setRegistrationId: bindActionCreators(flow.setRegistrationId, dispatch)
});

export const IntlPrivateRegistration = injectIntl(PrivateRegistration);
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PrivateRegistration));
