import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import Parser from "html-react-parser";
import DOMPurify from "dompurify";

import "./banner.scss";

const Banner = props => {
  const { alerts, theme, handleClickDismissIcon } = props;

  if (alerts.length === 0) return null;

  return (
    <div data-testid="component-banner">
      {alerts.map((alert, idx) => {
        const alertCSS = cn("alert", alert.level ? `alert--${alert.level}` : "");
        const alertContentCSS = cn(
          "alert__content",
          alert.level ? `alert__content--${alert.level}` : "",
          theme ? theme.map(css => `alert__content--${css}`).join(" ") : ""
        );
        let sanitizedMessage =
          typeof alert.message === "string"
            ? Parser(DOMPurify.sanitize(alert.message))
            : alert.message;
        return (
          <div data-testid="alert-message" key={idx} className={alertCSS}>
            <div className={alertContentCSS}>
              <span className="alert__content__body">{sanitizedMessage}</span>
              <i
                className="fa fa-times"
                onClick={() => {
                  handleClickDismissIcon(idx);
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

Banner.propTypes = {
  handleClickDismissIcon: PropTypes.func,
  theme: PropTypes.arrayOf(PropTypes.string),
  alerts: PropTypes.arrayOf(
    PropTypes.shape({
      level: PropTypes.oneOf(["success", "warning", "error"]),
      message: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    })
  )
};

Banner.defaultProps = {
  alerts: []
};

export default Banner;
