import React from "react";
import PropTypes from "prop-types";

import "./nav.scss";
// import { ConnectedLanguageSelect } from "../../components";

const Nav = props => {
  const { children } = props;
  return (
    <nav className="nav">
      <div className="nav__inner">
        <div className="spacer"></div>
        {/*<div className="nav__language-select">*/}
        {/* <ConnectedLanguageSelect />*/}
        {/*</div>*/}
        <label htmlFor="nav-checkbox" role="button" />
        {children}
      </div>
    </nav>
  );
};

Nav.propTypes = {
  children: PropTypes.object
};
export default Nav;
