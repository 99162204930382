/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from "react";
import { compose } from "redux";
import { reduxForm, reset, FormSection } from "redux-form";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import { Button, Txt } from "../../../elements";
import * as selectors from "../../../selectors";
import { Membership, IsStudent, SubscriptionPreferences } from "../fieldGroups";
import { constants, messages } from "../../../constants";
import "./preferenceCenterForm.scss";

class UpdatePreferenceCenterForm extends PureComponent {
  state = {
    // This is toggled depending on screen size, see: _handleWindowWidth()
    isMobile: false
  };

  componentDidMount() {
    this._handleAddEventListeners();
    this._handleWindowWidth();
    // get customer membership information
  }

  componentWillUnmount() {
    this._handleRemoveEventListeners();
  }

  render() {
    const { handleSubmit, intl, submitting } = this.props;
    const { isMobile } = this.state;

    const memberShipFooterNote = intl.formatMessage(messages.member.daggerWithLink, {
      memberLink: (...chunks) => `<a href="https://www.bcaa.com/membership/plans" target="_blank">${chunks.join('')}</a>`
    });

    return (
      <form onSubmit={handleSubmit} noValidate>
        <FormSection className="form__section" name="">
          <Membership
            {...this.props}
            footerNote={memberShipFooterNote}
          />
          <IsStudent {...this.props} />
          <SubscriptionPreferences {...this.props} isMobile={isMobile} />
        </FormSection>

          <Button loading={submitting} type="submit" theme={["cta"]}>
            <span>{intl.formatMessage(messages.button.save)}</span>
          </Button>

          <a onClick={this.handleClickCancel} className="is-underlined">
            {intl.formatMessage(messages.button.cancel)}
          </a>


        <div className="fine-print section section-1-2--md">
          <Txt theme={["dagger"]}>
            <small>{intl.formatMessage(messages.subscriptions.disclaimer)}</small>
          </Txt>
        </div>
      </form>
    );
  }

  /**
   * enables editing mode
   */
  handleClickEdit = e => {
    e.preventDefault();
    this.props.reset();
    this.props.toggleUpdateMode(true);
  };

  /**
   * cancels form editing
   */
  handleClickCancel = e => {
    e.preventDefault();
    this.props.reset();
    this.props.toggleUpdateMode(false);
  };

  /**
   *
   * checks window width on resize
   * @method _handleWindowWidth
   * @memberof UpdatePreferenceCenterForm
   */
  _handleWindowWidth = () => {
    if (typeof window !== "undefined" && window.addEventListener) {
      if (window.innerWidth <= 1023) {
        this.setState({ isMobile: true });
      }
      if (window.innerWidth >= 1024) {
        this.setState({ isMobile: false });
      }
    }
  };

  /**
   *
   * Used on mount to add event listeners
   * @method _handleAddEventListeners
   * @memberof UpdatePreferenceCenterForm
   */
  _handleAddEventListeners = () => {
    if (typeof window !== "undefined" && window.addEventListener) {
      window.addEventListener("resize", this._handleWindowWidth);
    }
  };

  /**
   *
   *
   * Used on unmount to remove event listeners
   * @method _handleRemoveEventListeners
   * @memberof UpdatePreferenceCenterForm
   */
  _handleRemoveEventListeners = () => {
    if (typeof window !== "undefined" && window.addEventListener) {
      window.removeEventListener("resize", this._handleWindowWidth);
    }
  };
}

UpdatePreferenceCenterForm.defaultProps = { regions: [] };

UpdatePreferenceCenterForm.propTypes = {
  intl: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired,
  toggleUpdateMode: PropTypes.func.isRequired,
  regions: PropTypes.array
};

const mapStateToProps = state => ({ state, regions: selectors.getRegions(state) });

const mapDispatchToProps = { reset };

const formSettings = {
  form: constants.FORM.updatePreferenceCenterForm,
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true
};

const enhancer = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm(formSettings)
);

export default enhancer(UpdatePreferenceCenterForm);
