import React, {Fragment, PureComponent} from "react";
import get from "lodash/get";
import {Field, formValueSelector} from "redux-form";
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";

import {Address, StaticAddress} from "../../fieldGroups";
import {messages, constants} from "../../../../constants";
import {Checkbox, DependOn} from "../../../../components";

class BillingAddress extends PureComponent {
  constructor(props) {
    super(props);

    this.values =
      props.user ||
      get(props, ["state", "form", constants.FORM.registrationForm, "values"], {
        PersonalInfo: {}
      });
  }

  getValue = name => {
    const {state, form, section} = this.props;
    const selector = formValueSelector(form);
    return selector(state, `${section}.${name}`);
  };

  render() {
    const {intl, homeAddress, onToggle} = this.props;
    this.isBillSameAsHome = this.getValue("billingAddressSameAsHome");
    return (
      <Fragment>
        <DependOn active={!!this.values}>
          <Field
            component={Checkbox}
            name="billingAddressSameAsHome"
            theme={["toggle"]}
            label={intl.formatMessage(messages.address.sameAddress)}
            postChange={onToggle}
          />
        </DependOn>
        {/*
          Base on if value of 'PaymentInfo.sameAddress' is on or null,
          Show appropriate form to display home address or to accept new address
      */}
        <DependOn active={this.isBillSameAsHome}>
          <StaticAddress
            {...(homeAddress ? homeAddress : {})}
            type={intl.formatMessage(messages.title.BillingAddress)}
          />
        </DependOn>
        <DependOn active={!this.isBillSameAsHome}>
          <Address
            {...this.props}
            theme={[`div`]}
            type={intl.formatMessage(messages.title.BillingAddress)}
          />
        </DependOn>
      </Fragment>
    );
  }
}

BillingAddress.propTypes = {
  intl: PropTypes.object.isRequired,
  user: PropTypes.object,
  state: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  onToggle: PropTypes.func
};

export default injectIntl(BillingAddress);
