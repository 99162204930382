//import get from "lodash/get";
import {SubmissionError} from "redux-form";

let monerisReponse;
export const getMonerisResp = (e) => {
  try {
    if (/https:\/\/[\w]*.moneris.com/.test(e.origin)) {
      monerisReponse = JSON.parse(e.data);
    }
  } catch (error) {
    // error handling
  }
}

export const getMonerisData = (props) => {
  // When submit begins, the moneris token is not ready and we will need to first fetch it
  // 1. At the moment we call this funciton, the moneris iframe is in the document and we can call it by id
  const monerisIframe = document.getElementById("monerisIframe");

  // 2. We post a message to this iframe and trigger a request to moneris
  monerisIframe.contentWindow.postMessage("", process.env.REACT_APP_EVO_MONERIS_URL);

  // 3. Since we do not know when the request will be back and we don't have a callback from the iframe
  //    We use a time interval to monitor the redux store every 250ms for total of 4 seconds
  return new Promise((resolve, reject) => {
    window.addEventListener("message", getMonerisResp);
    let monerisRetryInterval, monerisRetryTimeout;


    const clearTimer = () => {
      clearInterval(monerisRetryInterval);
      clearTimeout(monerisRetryTimeout);
      window.removeEventListener("message", null);
    };

    monerisRetryInterval = setInterval(() => {
      // const moneris = get(props, ["moneris"], { monerisResp: { dataKey: "" } });
      if (monerisReponse && monerisReponse.dataKey) {
        // 4a. If we see datakey in redux store, we resolve() this Promise
        resolve();
        clearTimer();
      } else if (monerisReponse && monerisReponse.errorMessage) {
        // if there is an error return from moneris
        reject(new SubmissionError({ moneris: monerisReponse.errorMessage }));
        clearTimer();
      }
    }, 250);

    monerisRetryTimeout = setTimeout(() => {
      // 4b. else, after 4 seconds, we rejects() this Promise
      reject("timeout");
      clearInterval(monerisRetryInterval);
    }, 4000);

  });
};
