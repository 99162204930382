import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

const CardBody = props => {
  const { children, theme } = props;
  if (!children) return null;

  const className = cn("card__body", theme ? theme.map(css => `card__body--${css}`).join(" ") : "");

  return <div className={className}>{children}</div>;
};

CardBody.defaultProps = {
  theme: [],
  children: null
};

CardBody.propTypes = {
  theme: PropTypes.array,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string
  ])
};

export default CardBody;
