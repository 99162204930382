import React, { PureComponent } from "react";
// import ReactDOM from 'react-dom';
// import { connect } from 'react-redux';
import { Field } from "redux-form";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
// import { bindActionCreators } from 'redux';

import { Input, Dropdown, Grid, Col, Section } from "../../../../components";
import { messages } from "../../../../constants";
// import * as selectors from '../../../../selectors';
// import * as actions from '../../../../actions';
import { validationBuilder, validations } from "../..";

let rules = {};
class PersonalUpdate extends PureComponent {
  rulePhone = [];

  constructor(props) {
    super(props);
    // for field level validations
    // TODO: find a better way to instantiate props when the component mounts
    Object.keys(validations).forEach(key => {
      rules[key] = spec => value => validationBuilder(props, validations[key], spec)(value);
    });
    const { intl } = props;
    this.rulePhone = [rules.required(intl.formatMessage(messages.personal.tel)), rules.phone()];
    this.ruleGender = [rules.required(intl.formatMessage(messages.personal.gender))];
    this.rulePreferredName = [rules.names(intl.formatMessage(messages.personal.preferredName))];
  }

  // function to get the selected field value
  // getValue(name) {
  //   const { state, form, section } = this.props;
  //   const selector = formValueSelector(form);
  //   return selector(state, `${section}.${name}`);
  // }

  render() {
    const { intl, disabled } = this.props;

    return (
      <Section size="2-3" min="md" theme={["div"]}>
        <Grid>
          <Col size="1-2" min="sm">
            <Field
              type="text"
              name="preferredName"
              label={intl.formatMessage(messages.personal.preferredName)}
              disabled={disabled}
              tooltip={intl.formatMessage(messages.personal.preferredNameInfo)}
              component={Input}
              validate={this.rulePreferredName}
              props={{ maxLength: "50" }}
            />
          </Col>

          <Col size="1-2" min="sm">
            <Field
              // type={disabled ? "text" : "tel"}
              type="tel"
              name="phoneNumber"
              label={intl.formatMessage(messages.personal.tel)}
              required={true}
              disabled={disabled}
              validate={this.rulePhone}
              component={Input}
              props={{ maxLength: "20" }}
            />
          </Col>

          <Col size="1-2" min="sm">
            <Field
              name="gender"
              label="Gender"
              required={true}
              disabled={disabled}
              options={[
                { label: intl.formatMessage(messages.personal.gendermale), value: "M" },
                { label: intl.formatMessage(messages.personal.genderfemale), value: "F" },
                { label: intl.formatMessage(messages.personal.genderother), value: "O" }
                // { label: intl.formatMessage(messages.personal.genderna), value: "P" },
              ]}
              component={Dropdown}
              validate={this.ruleGender}
            />
          </Col>
        </Grid>
      </Section>
    );
  }
}

PersonalUpdate.propTypes = {
  intl: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  // section: PropTypes.string.isRequired,
  disabled: PropTypes.bool
  // actions: PropTypes.objectOf(PropTypes.func).isRequired,
  // isEditNameMode: PropTypes.bool,
  // endEdit: PropTypes.func.isRequired,
};

PersonalUpdate.defaultProps = {
  disabled: false
};

// const mapStateToProps = state => ({
//   isEditNameMode: selectors.getIsEditNameMode(state)
// });

// const mapDispatchToProps = dispatch => ({
//   actions: bindActionCreators(actions, dispatch)
// });

export default injectIntl(PersonalUpdate);
