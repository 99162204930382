/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { messages } from "../../constants";

import "./index.scss";

class MapSearchInput extends PureComponent {
  render() {
    const { intl, onSearch } = this.props;
    return (
      <form onSubmit={onSearch} data-testid="map-search-form">
        <div className="map-search" data-testid="map-search-container">
          <input
            type="text"
            id="place-search-input"
            className="map-search_term"
            placeholder={intl.formatMessage(messages.field.enterAnAddress)}
            onChange={this._handleChagnge}
            onBlur={this._handleOnBlur}
          />
          <button type="submit" className="map-search_button">
            <i className="fa fa-search" /> <a>{intl.formatMessage(messages.button.search)}</a>
          </button>
        </div>
      </form>
    );
  }
}

MapSearchInput.propTypes = {
  loationAction: PropTypes.func,
  intl: PropTypes.object.isRequired,
  maps: PropTypes.object,
  onPlacesChanged: PropTypes.func,
  onSearch: PropTypes.func
};

MapSearchInput.displayName = "MapSearchInput";
export default injectIntl(MapSearchInput);
