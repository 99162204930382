import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import DOMPurify from "dompurify";
import Parser from "html-react-parser";

import { Tooltip } from "../";
import "./text.scss";

// not Text, to avoid conflict with React Text
const Txt = props => {
  const { children, theme, className, tooltip, tooltipArrow } = props;
  // tag is <span /> by default
  const CustomTag = children.type ? children.type : "span";
  // in case children is string contains html tags
  const content = children.props ? children.props.children : children;
  // sanitize the content
  const sanitized = typeof content === "string" ? Parser(DOMPurify.sanitize(content)) : content;

  const txtCSS = cn("text", className, theme ? theme.map(css => `text--${css}`).join(" ") : "");

  return (
    <CustomTag data-testid="element-text" className={txtCSS}>
      {sanitized}
      {tooltip && (
        <span data-testid="text-tooltip" className="tooltip-container">
          {tooltip && <Tooltip arrow={tooltipArrow}>{tooltip}</Tooltip>}
        </span>
      )}
    </CustomTag>
  );
};

Txt.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.string])),
    PropTypes.element,
    PropTypes.string
  ]),
  theme: PropTypes.array,
  className: PropTypes.string,
  tag: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  tooltipArrow: PropTypes.string
};

Txt.defaultProps = {
  theme: [],
  className: "",
  tooltip: null,
  tooltipArrow: "bottom"
};

export default Txt;
