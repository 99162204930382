// prettier-ignore
import { defineMessages } from "react-intl";

const namespace = "cities";
const cities = defineMessages({
  vancouver: {
    id: `${namespace}.vancouver`,
    description: "Vancouver",
    defaultMessage: "Vancouver"
  },
  montreal: { id: `${namespace}.montreal`, description: "Montreal", defaultMessage: "Montreal" },
  toronto: { id: `${namespace}.toronto`, description: "Toronto", defaultMessage: "Toronto" }
});

export default cities;
