import React, { PureComponent } from "react";
import { Field, formValueSelector } from "redux-form";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import { Input, Checkbox,  DependOn, Grid, Col, Section } from "../../../../components";
import { messages } from "../../../../constants";
import { validationBuilder, validations } from "../..";

let rules = {};
class IsStudent extends PureComponent {
  ruleSchoolName = [];

  constructor(props) {
    super(props);
    // for field level validations
    // TODO: find a better way to instantiate props when the component mounts
    Object.keys(validations).forEach(key => {
      rules[key] = spec => value => validationBuilder(props, validations[key], spec)(value);
    });

    const { intl } = props;
    this.ruleSchoolName = [rules.required(intl.formatMessage(messages.field.nameOfSchool))];
  }

  // function to get the selected field value
  getValue = name => {
    const { state, form, section } = this.props;
    const selector = formValueSelector(form);
    if (section) {
      return selector(state, `${section}.${name}`);
    }
    return selector(state, name);
  };

  render() {
    const { intl, disabled } = this.props;
    return (
      <Section size="1-2" min="md">
        <Grid>
          <Col>
            <Field
              type="checkbox"
              name="isStudent"
              theme={["large-font"]}
              label={intl.formatMessage(messages.field.isstudent)}
              tooltip={intl.formatMessage(messages.field.isStudentTooltip)}
              component={Checkbox}
              disabled={disabled}
            />

            <DependOn active={this.getValue(`isStudent`)==='Yes' || this.getValue('isStudent')===true}>
              <Field
                type="text"
                name="schoolName"
                label={intl.formatMessage(messages.field.nameOfSchool)}
                required={true}
                validate={this.ruleSchoolName}
                component={Input}
                disabled={disabled}
                props={{ maxLength: "60" }}
              />
            </DependOn>
          </Col>
        </Grid>
      </Section>
    );
  }
}

IsStudent.propTypes = {
  intl: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  section: PropTypes.string,
  disabled: PropTypes.bool
};

export default injectIntl(IsStudent);
