import React, {PureComponent} from "react";
import {Field, formValueSelector} from "redux-form";
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";

import {Checkbox, Grid, Col, Section} from "../../../../components";
import {messages} from "../../../../constants";
import {Title, Txt} from "../../../../elements";
import {convertToSentenceCase} from "../../../../util";

class IsMailingAddress extends PureComponent {

  // function to get the selected field value
  getValue = name => {
    const {state, form, section} = this.props;
    const selector = formValueSelector(form);
    if (section) {
      return selector(state, `${section}.${name}`);
    }
    return selector(state, name);
  };

  render() {
    const {intl, disabled} = this.props;
    return (
      <Section>
        <Grid>
          <Col>
            <br /><br />
            <Title priority={2} type={["strong-no-cap"]}>
              {convertToSentenceCase(intl.formatMessage(messages.title.MailingAddress))}
            </Title>
            <Field
              type="checkbox"
              name="isMailingAddress"
              //theme={["toggle"]}
              label={intl.formatMessage(messages.field.isMailingAddress)}
              component={Checkbox}
              disabled={disabled}
            />
            <Txt theme={["note"]}>
              <small>{intl.formatMessage(messages.field.isMailingAddressNote)}</small>
            </Txt>
          </Col>
        </Grid>
      </Section>
    );
  }
}

IsMailingAddress.propTypes = {
  intl: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  section: PropTypes.string,
  disabled: PropTypes.bool
};

export default injectIntl(IsMailingAddress);
