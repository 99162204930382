import React from "react";

export const Close = () => (
  <svg viewBox="0 0 10 10">
    <g
      stroke="#00BCE2"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="bevel"
    >
      <path d="M1 1l8 8M1 9l8-8" />
    </g>
  </svg>
);

Close.displayName = "Close";

export default Close;
