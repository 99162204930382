import * as customerAPI from "../api/customer";
import { constants } from "../constants";

/*
 * Login information
 */
// get email
export const getCustomerEmail = values => async dispatch => {
  const emailAddress = await customerAPI.getCustomerEmail(values);
  if (emailAddress.error) {
    throw new Error(emailAddress.error);
  }

  // update user information in redux store
  dispatch({
    type: constants.USER_LOGIN_INFO_GET,
    payload: emailAddress
  });
  return emailAddress;
};
// update email
export const patchCustomerEmail = values => async dispatch => {
  const user = await customerAPI.patchCustomerEmail(values);
  if (user.error) {
    throw new Error(user.error);
  }

  if (user.emailAddress !== values.emailAddress) {
    throw new Error(user.emailAddress);
  }

  // update user information in redux store
  dispatch({
    type: constants.USER_LOGIN_INFO_PATCH,
    payload: values
  });
  return user;
};

/*
 * Personal information
 */
export const getCustomerPersonalInfo = values => async dispatch => {
  const personalInfo = await customerAPI.getCustomerPersonalInfo(values);
  if (personalInfo.error) {
    throw new Error(personalInfo.error);
  }

  dispatch({
    type: constants.USER_PERSONAL_INFO_GET,
    payload: personalInfo
  });
  return personalInfo;
};

export const patchCustomerPersonalInfo = values => async dispatch => {
  const personalInfo = await customerAPI.patchCustomerPersonalInfo(values);
  if (personalInfo.error) {
    throw new Error(personalInfo.error);
  }

  // update personal information in redux store
  dispatch({
    type: constants.USER_PERSONAL_INFO_PATCH,
    payload: personalInfo
  });
  return personalInfo;
};

/*
 * DL information
 */
export const getCustomerDriverLicence = values => async dispatch => {
  const driverLicence = await customerAPI.getCustomerDriverLicence(values);
  if (driverLicence.error) {
    throw new Error(driverLicence.error);
  }

  dispatch({
    type: constants.USER_DRIVER_LICENCE_GET,
    payload: driverLicence
  });
  return driverLicence;
};

export const updateCustomerDriverLicence = values => async dispatch => {
  const driverLicence = await customerAPI.updateCustomerDriverLicence(values);
  if (driverLicence.error) {
    throw new Error(driverLicence.error);
  }

  // update personal information in redux store
  dispatch({
    type: constants.USER_DRIVER_LICENCE_UPDATE,
    payload: driverLicence
  });
  return driverLicence;
};

export const patchCustomerDriverLicence = values => async dispatch => {
  const driverLicence = await customerAPI.patchCustomerDriverLicence(values);
  if (driverLicence.error) {
    throw new Error(driverLicence.error);
  }

  // update personal information in redux store
  dispatch({
    type: constants.USER_DRIVER_LICENCE_PATCH,
    payload: driverLicence
  });
  return driverLicence;
};

/*
 * Payment information
 */
export const getCustomerPaymentInfo = values => async dispatch => {
  const paymentInformation = await customerAPI.getCustomerPaymentInfo(values);
  if (paymentInformation.error) {
    throw new Error(paymentInformation.error);
  }

  dispatch({
    type: constants.USER_PAYMENT_INFO_GET,
    payload: paymentInformation
  });
  return paymentInformation;
};

export const updateCustomerPaymentInfo = values => async dispatch => {
  const paymentInformation = await customerAPI.updateCustomerPaymentInfo(values);

  if (paymentInformation.error) {
    throw new Error(paymentInformation.error);
  }

  // update user information in redux store
  dispatch({
    type: constants.USER_PAYMENT_INFO_UPDATE,
    payload: paymentInformation
  });
  return paymentInformation;
};

export const patchCustomerPaymentInfo = values => async dispatch => {
  const paymentInformation = await customerAPI.patchCustomerPaymentInfo(values);
  if (paymentInformation.error) {
    throw new Error(paymentInformation.error);
  }

  // update personal information in redux store
  dispatch({
    type: constants.USER_PAYMENT_INFO_PATCH,
    payload: paymentInformation
  });
  return paymentInformation;
};

/*
 * Membership
 */
export const getCustomerMembership = () => async dispatch => {
  const membership = await customerAPI.getCustomerMembership();
  if (membership.error) {
    throw new Error(membership.error);
  }

  dispatch({
    type: constants.USER_MEMBERSHIP_GET,
    payload: membership
  });
  return membership;
};

export const updateCustomerMembership = values => async dispatch => {
  const membership = await customerAPI.updateCustomerMembership(values);
  if (membership.error) {
    throw new Error(membership.error);
  }

  // update membership information in redux store
  dispatch({
    type: constants.USER_MEMBERSHIP_UPDATE,
    payload: { ...membership, memberIsActive: true }
  });
  return membership;
};

// update isStudent, schoolName and marketing preferences
export const patchCustomerMembership = values => async dispatch => {
  const membership = await customerAPI.patchCustomerMembership(values);
  if (membership.error) {
    throw new Error(membership.error);
  }

  // update membership information in redux store
  dispatch({
    type: constants.USER_MEMBERSHIP_PATCH,
    payload: membership
  });
  return membership;
};

export const removeCustomerMembership = memberNumber => async dispatch => {
  const membership = await customerAPI.removeCustomerMembership(memberNumber);
  if (membership.error) {
    throw new Error(membership.error);
  }

  dispatch({
    type: constants.USER_MEMBERSHIP_DELETE,
    payload: { ...membership, memberIsActive: false }
  });
  return membership;
};
