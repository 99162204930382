import { defineMessages } from "react-intl";

const namespace = "edit";
const edit = defineMessages({
  LoginInfo: {
    id: `${namespace}.LoginInfo`,
    description: `Edit Login Info descrption on Account page`,
    defaultMessage: `Review and edit your login info such as account password or email address`
  },
  PersonalInfo: {
    id: `${namespace}.PersonalInfo`,
    description: `Edit Personal Info description on Account page`,
    defaultMessage: `Review and edit your personal info such as name, home address and phone number`
  },
  DriversLicenseInfo: {
    id: `${namespace}.DriversLiceneInfo`,
    description: `Edit Driver's Licence Info description on Account page`,
    defaultMessage: `Review and edit info about your Driver’s Licence`
  },
  PaymentInfo: {
    id: `${namespace}.PaymentInfo`,
    description: `Edit Payment Info description on Account page`,
    defaultMessage: `Review and edit your payment details`
  },
  Preferences: {
    id: `${namespace}.Preferences`,
    description: `Edit Preferences description on Account page`,
    defaultMessage: `Edit information about your subscriptions, CAA Membership and school info`
  }
});

export default edit;
