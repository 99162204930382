import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { messages } from "../../constants";
import { Txt, Title, LogoCAA } from "../../elements";
import { Section, Grid, Col } from "../../components";
import evoCar from "../../assets/img/evo-car.png"

import "./intro.scss";

const Intro = props => {
  const { intl, title, children, region } = props;
  return (
    <div className="intro">
      <Section>
        <Grid theme={[`center`]}>
          <Col>
            <figure className="intro__img">
              <img src={evoCar} alt="Evo Car" />
            </figure>
            {title && (
              <Title priority={1} type={["primary"]}>
                {title}
              </Title>
            )}
          </Col>
        </Grid>
      </Section>

      <Section theme={[`div`]}>
        <Grid theme={[`center`]}>{children}</Grid>
      </Section>

      <Section theme={[`div`]}>
        <Grid theme={[`center`]}>
          <Col>
            <Txt theme={["upper", "smaller"]}>
              {intl.formatMessage(messages.title.CreatedBy)}
              <LogoCAA region={region} theme={[`small`]} />
            </Txt>
          </Col>
        </Grid>
      </Section>
    </div>
  );
};

Intro.propTypes = {
  intl: PropTypes.object.isRequired,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string
  ]),
  region: PropTypes.string
};

Intro.defaultProps = {
  region: "bcaa"
};

export default injectIntl(Intro);
