/**
 * Formats a Canadian postal code. Expects value to contain no spaces.
 * @param {string} value
 * @returns {string} formatted Postal code
 */
export const postalCode = value => {
  if (!value) {
    return "";
  } else if (value.length <= 3) {
    return value;
  } else if (value.length > 3 && value.length < 7) {
    return `${value.substring(0, 3)} ${value.substring(3)}`;
  }
  return `${value.substring(0, 3)} ${value.substring(3, 6)}`;
};

/**
 * Formats a CAA member number. Expects value to contain no spaces.
 * @param {string} value
 * @returns {string} formatted CAA number.
 */
export const memberNumber = value => {
  if (!value || (value.length < 3)) {
    return `620`;
  }

  if (value.length < 7) {
    return `620 ${value.slice(3)}`;
  }

  if (value.length < 14) {
    return `620 ${value.slice(3, 6)} ${value.slice(6)}`;
  }

  return `620 ${value.slice(3, 6)} ${value.slice(6, 13)} ${value.slice(13, 16)}`;
};
