import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import CardHeader from "./cardHeader";
import CardBody from "./cardBody";
import CardFooter from "./cardFooter";
import "./card.scss";
import cn from "classnames";

class Card extends PureComponent {
  static Header = CardHeader;
  static Body = CardBody;
  static Footer = CardFooter;

  render() {
    const { children, theme, className } = this.props;

    if (!children) return null;
    const cardCSS = cn(
      "card",
      className,
      theme ? theme.map(css => `card--${css}`).join(" ") : ""
    );
    return (
      <div data-testid="component-card" className={cardCSS}>
        {children}
      </div>
    );
  }
}

Card.defaultProps = {
  children: null
};

Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string
  ])
};

export default Card;
