import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reset } from "redux-form";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";

import { UpdateForms } from "../../containers/form";
import * as formSections from "../../containers/form/formSections";
import { messages, constants } from "../../constants";
import * as selectors from "../../selectors";
import { user as userActions, banner as bannerActions } from "../../actions";
import { Section, Grid, Col } from "../../components";
import { Txt } from "../../elements";
import {diff} from "deep-object-diff";
import {get, isEmpty} from 'lodash';

class AccountPersonalInfo extends PureComponent {
  state = {
    isLoading: true
  };

  async componentDidMount() {
    const { getCustomerPersonalInfo, setAlerts, intl } = this.props;
    try {
      await getCustomerPersonalInfo();
    } catch (e) {
      setAlerts([
        {
          level: constants.ALERT_LEVEL.error,
          message: intl.formatMessage(messages.error.genericError)
        }
      ]);
    }

    this.setState({ isLoading: false });
  }

  render() {
    const { isLoading } = this.state;
    const { intl, user } = this.props;

    if (isLoading) return null;
    const personalInfo = user.personalInfo ? this.mapFieldsBeToFe(user.personalInfo) : {};

    const PersonalInfoUpdate = {
      title: intl.formatMessage(messages.title.PersonalInfo),
      section: "personalInfo",
      component: formSections.PersonalInfoUpdate
    };

    return (
      <Fragment>
        <Section>
          <Grid>
            <Col>
              <Link to={constants.ROUTE_ACCOUNT}>
                <Txt theme={["upper"]}>
                  <i className="fa fa-chevron-left" />{" "}
                  {intl.formatMessage(messages.button.backtoAccountDetails)}
                </Txt>
              </Link>
            </Col>
          </Grid>
        </Section>

        <Section theme={["div"]}>
          <Grid>
            <Col>
              <UpdateForms
                onSubmit={this._handleSubmit}
                sections={[PersonalInfoUpdate]}
                initialValues={{ personalInfo }}
              />
            </Col>
          </Grid>
        </Section>
      </Fragment>
    );
  }

  _handleSubmit = values => {
    const { intl, patchCustomerPersonalInfo, user } = this.props;

    const personalInfo = this.mapFieldsFeToBe(values.personalInfo);
    const origPersonalInfo = user.personalInfo;

    // isolate only those values that have changed.
    const changedValues = diff(origPersonalInfo, personalInfo);

   // API requires all three values for a patch to Country, Province or PostalCode, even if only one value changed.
    if( get(changedValues, 'homeProvince') || get(changedValues, 'homePostalCode')) {
      changedValues.homeCountry = personalInfo.homeCountry;
      changedValues.homeProvince = personalInfo.homeProvince ? personalInfo.homeProvince : "";
      changedValues.homePostalCode = personalInfo.homePostalCode ? personalInfo.homePostalCode : "";
    }

    isEmpty(changedValues) ?  this.props.resetForm() : patchCustomerPersonalInfo(changedValues)
      .then(() => {
        // set success alert message to banner
        this.props.setAlerts([
          {
            level: constants.ALERT_LEVEL.success,
            message: intl.formatMessage(messages.success.savePersonalInfo)
          }
        ]);
      })
      .catch(() => {
        // set success alert message to banner
        this.props.resetForm("update");
        this.props.setAlerts([
          {
            level: constants.ALERT_LEVEL.error,
            message: intl.formatMessage(messages.error.genericError)
          }
        ]);
      });
  };

  mapFieldsBeToFe = beFields => {
    const {
      homeUnit,
      homeLine1,
      homeLine2,
      homeCity,
      homeCountry,
      homeProvince,
      homePostalCode,
      preferredName,
      phoneNumber,
      genderCode
    } = beFields;
    const isUSA = homeCountry === "USA";
    return {
      preferredName,
      phoneNumber: `+${phoneNumber}`,
      gender: genderCode,
      unit: homeUnit,
      line1: homeLine1,
      line2: homeLine2,
      city: homeCity,
      country: homeCountry,
      province: isUSA ? "" : homeProvince,
      postalCode: isUSA ? "" : homePostalCode,
      state: isUSA ? homeProvince : "",
      zipCode: isUSA ? homePostalCode : ""
    };
  };

  mapFieldsFeToBe = feFields => {
    const {
      preferredName,
      phoneNumber,
      gender,
      unit,
      line1,
      line2,
      city,
      country,
      province,
      state,
      postalCode,
      zipCode
    } = feFields;
    const isUSA = country === "USA";
    return {
      homeUnit: unit,
      homeLine1: line1,
      homeLine2: line2,
      homeCity: city,
      homeCountry: country,
      homeProvince: isUSA ? state : province,
      homePostalCode: isUSA ? zipCode : postalCode,
      preferredName,
      phoneNumber: phoneNumber.replace("+", ""),
      genderCode: gender
    };
  };
}

AccountPersonalInfo.propTypes = {
  intl: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  setAlerts: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: selectors.getUser(state)
});

const mapDispatchToProps = {
  getCustomerPersonalInfo: userActions.getCustomerPersonalInfo,
  patchCustomerPersonalInfo: userActions.patchCustomerPersonalInfo,
  setAlerts: bannerActions.setAlerts,
  resetForm: reset
};

export const IntlAccountPersonalInfo = injectIntl(AccountPersonalInfo);
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AccountPersonalInfo)
);
