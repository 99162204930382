import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import "./column.scss";

const Col = props => {
  const { children, theme, className, min, size } = props;
  const gridCSS = cn(
    "col",
    className,
    theme ? theme.map(css => `col--${css}`).join(" ") : "",
    min || size ? `col-${size}${min ? "--" + min : ""}` : ""
  );

  return <div className={gridCSS}>{children}</div>;
};

Col.displayName = "Col";

Col.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  theme: PropTypes.array,
  className: PropTypes.string,
  min: PropTypes.oneOf(["sm", "md", "lg"]),
  size: PropTypes.string
};

Col.defaultProps = {
  theme: [],
  className: ""
};

export default Col;
