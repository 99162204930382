import { constants } from "../constants";
import { getFreeMinutes, getFreeMinutesSummary } from "../api/customer";

export const fetchMinutes = () => async dispatch => {
  try {
    const freeMinutes = await getFreeMinutes();
    dispatch({ type: constants.FREE_MINUTES, payload: freeMinutes });
  } catch (error) {
    // TODO dispatch another action for spinner and network
  }
};

export const fetchMinutesSummary = () => async dispatch => {
  try {
    const minutesSummary = await getFreeMinutesSummary();
    dispatch({ type: constants.FREE_MINUTES_SUMMARY, payload: minutesSummary });
  } catch (error) {
    // TODO dispatch another action for spinner and network
  }
};
