/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import cn from "classnames";

import { locale as localeActions } from "../../actions";
import { Title } from "../../elements";
import "./languageSelect.scss";

export class LanguageSelect extends PureComponent {
  handleChangeLanguage = (e, lang) => {
    e.preventDefault();
    const { actions } = this.props;
    actions.changeLanguage(lang);
  };

  render() {
    const { locale } = this.props;

    return (
      <Title priority={4}>
        <div>
          <a
            onClick={e => this.handleChangeLanguage(e, "en")}
            className={cn("language-select__link", {
              "language-select__link-selected": locale.lang === "en"
            })}
          >
            EN
          </a>{" "}
          /{" "}
          <a
            onClick={e => this.handleChangeLanguage(e, "fr")}
            className={cn("language-select__link", {
              "language-select__link-selected": locale.lang === "fr"
            })}
          >
            FR
          </a>
        </div>
      </Title>
    );
  }
}

LanguageSelect.propTypes = {
  locale: PropTypes.shape({ lang: PropTypes.string }),
  actions: PropTypes.object
};

const mapStateToProps = ({ locale }) => ({ locale });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(localeActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageSelect);
