import React from "react";

export const ParkingInfo = () => (
  <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1">
    <g id="Design---Find-a-Car" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Logged-In-1.9.1---Find-a-car---parkingInfo-ALL-info-/-long-address"
        transform="translate(-658.000000, -493.000000)"
      >
        <g id="Group-2" transform="translate(658.000000, 493.000000)">
          <path
            d="M24.2565104,6.48307292 C25.4188426,8.46919396 26,10.6414813 26,13 C26,15.3585187 25.4188426,17.5336272 24.2565104,19.5253906 C23.0941782,21.5171541 21.5171541,23.0941782 19.5253906,24.2565104 C17.5336272,25.4188426 15.3585187,26 13,26 C10.6414813,26 8.46637281,25.4188426 6.47460938,24.2565104 C4.48284594,23.0941782 2.90582178,21.5171541 1.74348958,19.5253906 C0.581157383,17.5336272 0,15.3585187 0,13 C0,10.6414813 0.581157383,8.46637281 1.74348958,6.47460938 C2.90582178,4.48284594 4.48284594,2.90582178 6.47460938,1.74348958 C8.46637281,0.581157383 10.6414813,0 13,0 C15.3585187,0 17.5336272,0.581157383 19.5253906,1.74348958 C21.5171541,2.90582178 23.0941782,4.4856671 24.2565104,6.48307292 Z"
            id=""
            fill="#E0E0E0"
          />
          <path
            d="M15.8005569,18.2086347 L15.8005569,16.3934589 C15.8005569,16.2875731 15.7677382,16.2005968 15.7020998,16.1325274 C15.6364615,16.064458 15.5525914,16.0304237 15.4504873,16.0304237 L14.4002785,16.0304237 L14.4002785,10.2218613 C14.4002785,10.1159755 14.3674598,10.0289992 14.3018214,9.96092974 C14.236183,9.89286031 14.152313,9.8588261 14.0502088,9.8588261 L10.5495127,9.8588261 C10.4474086,9.8588261 10.3635385,9.89286031 10.2979002,9.96092974 C10.2322618,10.0289992 10.1994431,10.1159755 10.1994431,10.2218613 L10.1994431,12.037037 C10.1994431,12.1429228 10.2322618,12.2298991 10.2979002,12.2979686 C10.3635385,12.366038 10.4474086,12.4000722 10.5495127,12.4000722 L11.5997215,12.4000722 L11.5997215,16.0304237 L10.5495127,16.0304237 C10.4474086,16.0304237 10.3635385,16.064458 10.2979002,16.1325274 C10.2322618,16.2005968 10.1994431,16.2875731 10.1994431,16.3934589 L10.1994431,18.2086347 C10.1994431,18.3145205 10.2322618,18.4014968 10.2979002,18.4695662 C10.3635385,18.5376356 10.4474086,18.5716698 10.5495127,18.5716698 L15.4504873,18.5716698 C15.5525914,18.5716698 15.6364615,18.5376356 15.7020998,18.4695662 C15.7677382,18.4014968 15.8005569,18.3145205 15.8005569,18.2086347 Z"
            id="Path"
            fill="#000000"
          />
          <path
            d="M14.555865,7.63616189 L14.555865,5.85803051 C14.555865,5.75430566 14.5193997,5.66910439 14.4464682,5.60242413 C14.3735367,5.53574387 14.2803478,5.50240424 14.1668987,5.50240424 L11.8331013,5.50240424 C11.7196522,5.50240424 11.6264633,5.53574387 11.5535318,5.60242413 C11.4806003,5.66910439 11.444135,5.75430566 11.444135,5.85803051 L11.444135,7.63616189 C11.444135,7.73988674 11.4806003,7.82508801 11.5535318,7.89176827 C11.6264633,7.95844853 11.7196522,7.99178816 11.8331013,7.99178816 L14.1668987,7.99178816 C14.2803478,7.99178816 14.3735367,7.95844853 14.4464682,7.89176827 C14.5193997,7.82508801 14.555865,7.73988674 14.555865,7.63616189 Z"
            id="Path"
            fill="#000000"
          />
        </g>
      </g>
    </g>
  </svg>
);

ParkingInfo.displayName = "ParkingInfo";

export default ParkingInfo;
