import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { Redirect, Link } from "react-router-dom";
import Parser from "html-react-parser";
import DOMPurify from "dompurify";

import * as selectors from "../../selectors";
import { Intro, PagesContainer } from "../../containers";
import { Input, Col, CheckMark, Close } from "../../components";
import { Button } from "../../elements";
import { injectIntl } from "react-intl";
import * as auth from "../../actions/auth";
import { validationBuilder, validations } from "../../containers/form";
import { messages, constants } from "../../constants";
import { getJsonFromUrl } from "../../util";
import "./index.scss";

let rules = {};

/**
 *
 *
 * @class Login
 * @extends {PureComponent}
 */
class Login extends PureComponent {
  rulesEmail = [];
  rulesPassword = [];

  state = { isLoggingIn: false, showResetSuccessBanner: true };

  constructor(props) {
    super(props);
    Object.keys(validations).forEach(key => {
      rules[key] = spec => value => validationBuilder(props, validations[key], spec)(value);
    });

    this.rulesEmail = [
      rules.required(props.intl.formatMessage(messages.field.EmailAddress)),
      rules.email()
    ];

    this.rulesPassword = [rules.required(props.intl.formatMessage(messages.field.password))];
  }

  handleSubmit = async values => {
    this.setState({ isLoggingIn: true });
    try {
      await this.props.login(values);
    } catch (error) {
      this.setState({ isLoggingIn: false });
      throw error;
    }
  };

  handleDismissBanner = () => {
    this.setState({ showResetSuccessBanner: false });
  };

  render() {
    const { isLoggingIn } = this.state;
    const { error, handleSubmit, valid, pristine, submitting, intl, isAuthenticated } = this.props;

    if (isAuthenticated) return <Redirect to={constants.ROUTE_ACCOUNT} />;

    return (
      <div className="container container--dark" style={{ height: "100vh" }}>
        <PagesContainer>
          <Fragment>
            {this.renderResetBanner()}
            <Intro title={intl.formatMessage(messages.title.Home)}>
              <Col size="2-5" min="sm">
                {error && <p className="text--error">{error}</p>}
                <form onSubmit={handleSubmit(this.handleSubmit)}>
                  <Field
                    type="text"
                    name="username"
                    label={intl.formatMessage(messages.field.EmailAddress)}
                    validate={this.rulesEmail}
                    component={Input}
                    alignLeft
                    asyoutype={true}
                    disableErrorIcon
                    props={{ maxLength: "254" }}
                  />
                  <Field
                    type="password"
                    name="password"
                    label={intl.formatMessage(messages.field.password)}
                    validate={this.rulesPassword}
                    component={Input}
                    alignLeft
                    asyoutype={true}
                    disableErrorIcon
                    props={{ maxLength: "50" }}
                  />

                  <p className="login__forgot-password">
                    <Link to={constants.ROUTE_RESET_PASSWORD} className="is-underlined">
                      {intl.formatMessage(messages.field.forgotPassword)}
                    </Link>
                  </p>
                  <div className="field__group text--center">
                    <Button
                      loading={isLoggingIn}
                      type="submit"
                      theme={["large", "bold"]}
                      disabled={!valid || pristine || submitting}
                    >
                      <span className="login__button">
                        {intl.formatMessage(messages.button.login)}
                      </span>
                    </Button>
                  </div>
                </form>
              </Col>
            </Intro>
          </Fragment>
        </PagesContainer>
      </div>
    );
  }

  /**
   *
   *
   * @memberof Login
   */
  renderResetBanner = () => {
    const { intl } = this.props;
    const { message } = getJsonFromUrl(this.props.location.search);

    let msg;
    switch (message) {
      case constants.LOGIN_BANNER_TYPE.resetPasswordSuccess:
        msg = messages.personal.resetPasswordSuccessBanner;
        break;
      case constants.LOGIN_BANNER_TYPE.changeEmailSuccess:
        msg = messages.personal.changeEmailSuccessBanner;
        break;
      default:
        msg = "";
    }

    return (
      this.state.showResetSuccessBanner &&
      msg && (
        <Col className="reset-banner__container" size="3-5" min="sm">
          <div className="reset-banner">
            <CheckMark /> <span>{Parser(DOMPurify.sanitize(intl.formatMessage(msg)))}</span>
            <div onClick={this.handleDismissBanner}>
              <Close />
            </div>
          </div>
        </Col>
      )
    );
  };
}

Login.propTypes = {
  login: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  location: PropTypes.object,

  intl: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired
};

const formSettings = {
  form: "login", // a unique identifier for this form,
  onChange: (values, dispatch, props) => {
    // do this only the server side error exists
    if (!props.error) {
      return;
    }
    // trigger change to force re-validation
    dispatch(props.change("password", values.password));
    dispatch(props.change("username", values.username));
  }
};

const mapStateToProps = state => ({
  isAuthenticated: selectors.isLoggedIn(state)
});

const mapDispatch = {
  login: auth.login
};
const WithFormLogin = reduxForm(formSettings)(Login);
const enhance = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatch
  )
);
export default enhance(WithFormLogin);
