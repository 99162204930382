import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
// import { injectIntl } from 'react-intl';
import DOMPurify from "dompurify";
import Parser from "html-react-parser";
import "./checkbox.scss";

import { Tooltip, Txt } from "../../elements";

const Checkbox = props => {
  const {
    className,
    theme,
    label,
    disabled,
    required,
    tooltip,
    tooltipArrow,
    input,
    postChange,
    meta: { touched, error }
  } = props;
  const { onChange, name } = input;
  // sanitize the content
  const sanitized = typeof label === "string" ? Parser(DOMPurify.sanitize(label)) : label;
  const inputCSS = cn(
    "checkbox",
    className,
    theme ? theme.map(css => `checkbox--${css}`).join(" ") : ""
  );

  return (
    <fieldset className={inputCSS} role="group">
      <input
        type="checkbox"
        checked={!!input.value}
        name={name}
        id={name}
        disabled={disabled}
        onChange={e => {onChange(e.target.checked); if(postChange) postChange();}}
      />
      <label htmlFor={name}>
        {sanitized}
        {required ? " *" : ""}
        <span
          className="tooltip-container"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {tooltip && <Tooltip arrow={tooltipArrow}>{tooltip}</Tooltip>}
        </span>
      </label>
      {touched && error && (
        <Txt theme={["error"]}>
          <small>{error}</small>
        </Txt>
      )}
    </fieldset>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.array,
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.object, PropTypes.array]),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  tooltipArrow: PropTypes.string,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  postChange: PropTypes.func
  // value: PropTypes.string
  // intl: PropTypes.object.isRequired
};

Checkbox.defaultProps = {
  theme: [],
  className: "",
  disabled: false,
  require: false
};

export default Checkbox;
