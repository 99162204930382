import React, { PureComponent } from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import { Spinner } from "../../components";

import "./button.scss";

class Button extends PureComponent {
  render() {
    const { children, theme, loadingTheme, className, loading, ...restProps } = this.props;
    const BtnCSS = cn(
      "btn",
      className,
      theme ? theme.map(css => `btn--${css}`).join(" ") : "",
      loading && "btn--loading",
      loading && loadingTheme ? `btn--loading--${loadingTheme}` : ""
    );

    return (
      <button data-testid="component-button" className={BtnCSS} {...(loading ? {} : restProps)}>
        <span data-testid="button-content" className="btn__content">
          {children}
        </span>
        {loading && (
          <div data-testid="button-loadingIcon" className="btn__loading-icon">
            <Spinner active={loading} onDark={loadingTheme === "overlap"} />
          </div>
        )}
      </button>
    );
  }
}

Button.defaultProps = {
  loading: false,
  children: null,
  className: "",
  disabled: false,
  onClick: undefined,
  theme: [],
  loadingTheme: "overlap",
  type: "button"
};

Button.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  theme: PropTypes.array,
  loadingTheme: PropTypes.oneOf(["overlap", "inline"]),
  type: PropTypes.string
};

export default Button;
