import { constants } from "../constants";

const getDefaultState = () => {
  const savedLang = localStorage.getItem("lang");
  const language = navigator.languages ? navigator.languages[0] : navigator.language;
  const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
  return { lang: savedLang || languageWithoutRegionCode || language || "en" };
};

const var1 = (state = getDefaultState(), { type, payload } = {}) => {
  switch (type) {
    case constants.CHANGE_LANGUAGE:
      return { lang: payload };
    default:
      return state;
  }
};

export default var1;
