import * as CryptoJS from "crypto-js";
import moment from "moment-timezone";
import { messages } from "../constants";

export const formatDuration = (duration, unit) => {
  let durationObj = moment.duration(duration, unit);

  const days = Math.floor(durationObj.asDays());
  durationObj.subtract(days, "d");

  const hours = Math.floor(durationObj.asHours());
  durationObj.subtract(hours, "h");

  const minutes = durationObj.asMinutes();

  return `${days}d - ${hours}h - ${minutes}m`;
};

/*
 * @function checkCreditCardExpiry - return true if credit card is expired
 * @param {(string|number)} - credit card expiry in MMYY format, e.g. 0119
 * @returns {bool}
 */
export const checkCreditCardExpiry = ccExpiry => {
  if (typeof ccExpiry !== "string" && typeof ccExpiry !== "number") {
    throw new TypeError("Credit Card expiry must be a number or a string");
  }
  let ccExpiryStr = `${ccExpiry}`;
  if (ccExpiryStr.length !== 4) {
    throw new Error("Invalid length of credit card expiry");
  }

  try {
    const today = moment();
    const ccExpiryDate = moment(ccExpiryStr, "MMYY");

    if (!ccExpiryDate.isValid()) throw new Error("Invalid credit card expiry");

    // credit card expires at the end of the month, so we need to add 1 more month to check it
    // for example, given 0619 as CC expiry, it will expires at 00:00am on July 2019
    // return true if the card is expired
    return today.isAfter(ccExpiryDate.add(1, "months"));
  } catch (error) {
    // throw error if the dates cannot be created
    throw error;
  }
};

// for POST body as form data
export const jsonToFormData = obj => {
  const form_data = new FormData();

  if (Object.prototype.toString.call(obj) !== "[object Object]") {
    throw new Error("argument is not an object");
  }

  for (let key in obj) {
    form_data.append(key, obj[key]);
  }

  return form_data;
};

// for POST body as x-www-form-urlencoded
// for GET query string (add ?)
export const jsonToFormStr = obj => {
  let encodedComponents = [];
  for (let key in obj) {
    // do not encode the parameter if it does not have a value
    if (!obj.hasOwnProperty(key) || (typeof obj[key] !== "boolean" && !obj[key]) ) continue;
    encodedComponents.push(`${key}=${encodeURIComponent(obj[key])}`);
  }
  return encodedComponents.join("&");
};

// for compose URI
export const base64URLEncode = (str, createHash) => {
  const newStr = createHash ? sha256(str) : str;
  return newStr
    .toString("base64")
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
};

// for inserting string into another
export const insertString = (str, idx, insertedStr) => {
  if (typeof str !== "string" || typeof insertedStr !== "string") return str;
  return str.slice(0, idx) + insertedStr + str.slice(idx);
};

// compare two objects
// return false if not comparing objects
// return true if they have same properties
export const isEqual = (itemA, itemB, compareList) => {
  if (!compareList) {
    // Get the itemA type
    const type = Object.prototype.toString.call(itemA);
    // If the two objects are not the same type, return false
    if (type !== Object.prototype.toString.call(itemB)) return false;

    // If items are not an object, return false
    if (type !== "[object Object]") return false;

    // Compare the length of the two items
    const valueLen = Object.keys(itemA).length;
    const otherLen = Object.keys(itemB).length;
    if (valueLen !== otherLen) return false;

    // If values of same property are not equal,
    // objects are not equivalent
    for (let propName in itemA) {
      // If value of propery is another object,
      // compare it using same function isEqual,
      // performance warning: do not compare deep nested objects
      if (typeof itemA[propName] === "object") {
        if (!isEqual(itemA[propName], itemB[propName])) {
          return false;
        }
      }
      if (itemA[propName] !== itemB[propName]) {
        return false;
      }
    }
  } else {
    for (let i = 0; i < compareList.length; i += 1) {
      const propName = compareList[i];
      if (!itemA.hasOwnProperty(propName) || !itemB.hasOwnProperty(propName)) {
        return false;
      }

      // If value of propery is another object,
      // compare it using same function isEqual,
      // performance warning: do not compare deep nested objects
      if (typeof itemA[propName] === "object") {
        if (!isEqual(itemA[propName], itemB[propName])) {
          return false;
        }
      }
      if (itemA[propName] !== itemB[propName]) {
        return false;
      }
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
};

// create hash with sha256
export const sha256 = buffer => {
  const hashedData = CryptoJS.SHA256(buffer);

  const hashedString = hashedData.toString(CryptoJS.enc.Hex);

  // Convert the hashed string to a buffer
  return Buffer.from(hashedString, "hex");
}

/**
 *
 *
 * @param {*} string - string to be modified
 * @param {*} symbol - character that will be used
 * @returns {string} - string with each char replaced with the "symbol" , except the last 3 char(s).
 */
export const hashString = (string, symbol = "#") => {
  return string.replace(/.(?=.{3,}$)/g, symbol);
};

// Format currency
export const formatCurrency = number => (number ? `$${number.toFixed(2)}` : `$0.00`);

/**
 *
 * Careful Staging is currently "development"
 * @function isDev
 * @returns {Boolean}
 */
export const isDev = () => process.env.NODE_ENV === "development";

/**
 * Regex taken from HTML 5 spec: https://www.w3.org/TR/html5/forms.html#valid-e-mail-address
 * @function validateEmail
 * @returns {Boolean}
 */
export const validateEmail = email => {
  const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return re.test(String(email).toLowerCase());
};

/**
 * Returns a mapped string from remote payment status
 * @param obj
 * @return {string}
 */
export const mapPaymentStatus = (status, intl) => {
  switch (status) {
    case "Pending":
    case "Upcoming":
      return intl.formatMessage(messages.billing.pending);
    case "Success":
      return intl.formatMessage(messages.billing.approved);
    case "Unsuccessful":
      return intl.formatMessage(messages.billing.failed);
    default:
      return status;
  }
};

/**
 * Return a formated unit.
 * For example:
 * pluralAware(2,"min") => "2 mins"
 * pluralAware(2, "foot","feet") => "2 feet"  // custom unit for irregular plural form
 *
 * @param {number} value
 * @param {string} singular
 * @param @optional {string} plural
 * @returns {string}
 */
export const pluralAware = (value, singular, plural) => {
  if (isNaN(value) || value === null || !singular) return null;
  return value > 1 ? `${value} ${plural || singular + "s"}` : `${value} ${singular}`;
};

/**
 * Return formated date string
 *
 * @param {*} date
 * @param {string} [format="YYYY-MM-DD"]
 * @returns
 */
export const formatDate = (date, format = "YYYY-MM-DD") => {
  if (moment(date).isValid()) {
    return moment(date).format(format);
  }
};

// TODO CRA
export const getJsonFromUrl = (url /*= location.search*/) => {
  if (!url) return {};

  const query = url.substr(1);
  const result = {};
  query.split("&").forEach(part => {
    const [key, value] = part.split("=");
    result[key] = decodeURIComponent(value);
  });
  return result;
};

export const convertToSentenceCase = str => {
  return (
    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  );
};
