import React from "react";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import { messages } from "../../../../constants";
import { Input } from "../../../../components";
import { Txt } from "../../../../elements";
import { insertString, checkCreditCardExpiry } from "../../../../util";
import "./moneris.scss";

// this component is read-only now
// thus, inputs are disabled
export const StaticMoneris = ({ intl, creditCardValues }) => {
  const { ccExpiryDate } = creditCardValues;
  const isCreditCardExpired = ccExpiryDate ? checkCreditCardExpiry(ccExpiryDate) : false;
  const meta = isCreditCardExpired ? { touched: true, warning: true } : {};

  return (
    <div className="moneris--static">
      <div className="moneris--static__line1">
        <Input
          label={intl.formatMessage(messages.moneris.creditcard)}
          input={{ value: insertString(creditCardValues.ccMasked, 4, "****") }}
          meta={{}}
          disabled
        />
      </div>
      <div className="moneris--static__line2">
        <Input
          label={intl.formatMessage(messages.moneris.expirydate)}
          input={{ value: creditCardValues.ccExpiryDate }}
          className="moneris--static__line2__expiry-date"
          disabled
          meta={meta}
          theme={["warning"]}
        />
        <Input
          label={intl.formatMessage(messages.moneris.cvv2)}
          input={{ value: "***" }}
          className="moneris--static__line2__cvv"
          meta={{}}
          disabled
        />
      </div>
      <Txt theme={["dagger"]}>
        <small>{intl.formatMessage(messages.moneris.cvvhelptext)}</small>
      </Txt>
    </div>
  );
};

StaticMoneris.propTypes = {
  intl: PropTypes.object.isRequired,
  creditCardValues: PropTypes.object
};

const connectedMoneris = injectIntl(StaticMoneris);
export default connectedMoneris;
