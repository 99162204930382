import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { toAdyenStringFormat, styles } from "./adyenTokenizerStyles";
import { injectIntl } from "react-intl";
import cn from "classnames";

import { messages } from "../../constants";
import { Txt } from "../../elements";
import "./adyen.scss";

class AdyenTokenizer extends PureComponent {
  constructor(props) {
    super(props);
    this.adyenFrame = null;
    this.state = {
      adyenResp: {
        responseCode: "",
        dataKey: "",
        errorMessage: ""
      }
    };
    props.actions.clearAdyenResp();
  }

  /*
  STEP TO USE adyen TOKENIZER:
  1. This tokenizer will wrap adyen iframe inside and will `postMessage` to this iframe, we also have the 'message' event listener
  2. This iframe will send data from the form to adyen API endpoint
  3. In <Registration> component, we set up a async/await to check for the adyen response every 250ms for most 4000ms.
  4. Whenever the adyen response is ready, the <Registraion> 's submit action will continue
 */

  componentWillMount() {
    window.addEventListener("message", this._getAdyenResp);
  }

  componentWillUnmount() {
    window.removeEventListener("message", null);
  }


  _getAdyenResp = (e) => {

    try {
      if (/https:\/\/[\w]*.adyen.com/.test(e.origin)) {
        const respData = JSON.parse(e.data);
        this.setState({ adyenResp: respData });
        this.props.actions.setAdyenResp({ adyenResp: respData });
      }
    } catch (error) {
      // error handling
    }
  };

  statusEnum = (statusCode) => {
    const namespace = messages.adyen;
    switch (statusCode) {
      case "001":
        return namespace.respGood;
      case "940":
        return namespace.respInvalidProfId;
      case "941":
        return namespace.respErrorGenToken;
      case "942":
        return namespace.respErrorProfIdOrSourceUrl;
      case "943":
        return namespace.respErrorCardInvalid;
      case "944":
        return namespace.respInvalidExpDate;
      case "945":
        return namespace.respInvalidCVV2;
      default:
        break;
    }
  };

  render() {
    const { intl, id } = this.props;

    const adyenTokenizerUrl = process.env.REACT_APP_EVO_ADYEN_URL + "?";
    const adyenOptions = {
      id: id || process.env.REACT_APP_EVO_ADYEN_ID || "htBNELZK8VYC79V",
      enable_exp: 1,
      enable_cvd: 1,
      display_labels: 1,
      pan_label: `${intl.formatMessage(messages.adyen.creditcard)}`,
      exp_label: `${intl.formatMessage(messages.adyen.expirydate)}`,
      cvd_label: `${intl.formatMessage(messages.adyen.cvv2)}`
    };

    const adyenStyle = toAdyenStringFormat(styles);

    const adyenUrl = `${adyenTokenizerUrl}${Object.keys(adyenOptions)
      .map((optionKey) => `${optionKey}=${adyenOptions[optionKey]}`)
      .join("&")}&${adyenStyle}`;

    const [adyenRespCode] = this.state.adyenResp.responseCode;
    const adyenTextCss = cn("text", adyenRespCode === "001" ? "text--normal" : "text--error");

    return (
      <fieldset className="adyen" role="group">
        <iframe
          title="adyen"
          ref={(ref) => (this.adyenFrame = ref)}
          frameBorder="0"
          scrolling="no"
          height="120"
          src={adyenUrl}
          id="adyenIframe"
        />
        {this.state.adyenResp.responseCode && (
          <small id="adyenResponse" className={adyenTextCss}>
            {intl.formatMessage(this.statusEnum(adyenRespCode))}
          </small>
        )}

        <Txt theme={["dagger"]}>
          <small>{intl.formatMessage(messages.adyen.cvvhelptext)}</small>
        </Txt>
      </fieldset>
    );
  }
}

AdyenTokenizer.propTypes = {
  intl: PropTypes.object.isRequired,
  meta: PropTypes.object,
  submitting: PropTypes.bool,
  actions: PropTypes.object,
  id: PropTypes.string
};

export default injectIntl(AdyenTokenizer);
