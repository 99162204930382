import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import DOMPurify from "dompurify";
import Parser from "html-react-parser";
import "./datalist.scss";

const Datalist = props => {
  const { title, data, theme, className } = props;
  const dataCSS = cn(
    "datalist",
    className,
    theme ? theme.map(css => `datalist--${css}`).join(" ") : ""
  );

  // still wondering.. react never be able to render dl list??
  return (
    <dl className={dataCSS}>
      <dt>{title}</dt>
      <dd>{data ? Parser(DOMPurify.sanitize(data)) : "-"}</dd>
    </dl>
  );
};

Datalist.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.string,
  theme: PropTypes.array,
  className: PropTypes.string
};

Datalist.defaultProps = {
  theme: [],
  className: ""
};

export default Datalist;
