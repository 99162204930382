import { constants } from "../constants";
import { push } from "connected-react-router";

import * as auth from "./auth";
import * as map from "./map";
import * as flow from "./flow";
import * as locale from "./locale";
import * as user from "./user";
import * as banner from "./banner";
import * as billing from "./billing";
import * as statement from "./statement";
import * as forgotPassword from "./forgotPassword";
import * as settings from "./settings";

// set current page theme
const setPageTheme = ({ theme }) => dispatch => {
  dispatch({ type: constants.PAGE_THEME, payload: { theme } });
};

// used for registartion prgressbar only so far
const setPageSubNav = ({ sub }) => dispatch => {
  dispatch({ type: constants.PAGE_SUB_NAV, payload: { sub } });
};

// navigational internal links route
const pushNav = link => dispatch => dispatch(push(link));

export {
  auth,
  map,
  flow,
  locale,
  user,
  banner,
  billing,
  statement,
  forgotPassword,
  settings,
  setPageTheme,
  setPageSubNav,
  pushNav
};
