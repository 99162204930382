import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import "./index.scss";

export default class TableBody extends PureComponent {
  render() {
    const {
      collaspableColumns,
      columnNames,
      data,
      displayKeys,
      mobileDisplayKeys,
      nullStateTitle,
      rowGrayRule,
      singleRow
    } = this.props;

    const bodyCss = cn(
      "data-table__row",
      singleRow ? "data-table__row--single" : { "data-table__row--multiple": data.length > 0 }
    );
    const renderRow = row => {
      // Filter out the null column names
      // Column name can be null because we won't display this column when it's in single region mode
      const filterNull = e => !!e;
      const filteredColumn = columnNames.filter(filterNull);
      const filteredDisplayKeys = displayKeys.filter(filterNull);
      const filteredMobileDisplayKeys = mobileDisplayKeys.filter(filterNull);

      // Map column according to display keys
      return filteredDisplayKeys.map((key, idx) => {
        const { title } = filteredColumn.find(cn => cn.key === key);
        const mobileDisplayOrder = filteredMobileDisplayKeys.indexOf(key) + 1;
        const tdCSS = cn(
          { collapsColumn: collaspableColumns.includes(key) },
          mobileDisplayOrder > 0 ? `order-${mobileDisplayOrder}` : ""
        );
        return (
          <td key={idx} className={tdCSS}>
            <div className="data-table__data__header">{title}</div>
            {row[key]}
          </td>
        );
      });
    };

    const renderBody = () => {
      if (data.length === 0) {
        return (
          <tr>
            <td colSpan={displayKeys.length}>
              <b>{nullStateTitle}</b>
            </td>
          </tr>
        );
      } else {
        return data.map((row, idx) => {
          const shouldRowBeGray = !!rowGrayRule && rowGrayRule(row);
          return (
            row && (
              <tr className={cn({ row_gray: shouldRowBeGray })} key={idx}>
                {renderRow(row)}
              </tr>
            )
          );
        });
      }
    };
    return <tbody className={bodyCss}>{renderBody()}</tbody>;
  }
}

TableBody.defaultProps = {
  collaspableColumns: [],
  columnNames: [],
  data: [],
  singleRow: false
};

TableBody.propTypes = {
  collaspableColumns: PropTypes.arrayOf(PropTypes.string),
  columnNames: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
  data: PropTypes.arrayOf(PropTypes.object),
  displayKeys: PropTypes.arrayOf(PropTypes.string),
  mobileDisplayKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  nullStateTitle: PropTypes.string,
  rowGrayRule: PropTypes.func,
  singleRow: PropTypes.bool
};
