import React, { PureComponent } from "react";
import { change } from "redux-form";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Section } from "../../../../components";
import { messages } from "../../../../constants";
// import { validationBuilder, validations } from "../..";
import bikeAndCar from "../../../../assets/img/Evo-Prius.png";
import eBike from "../../../../assets/img/New-Evo-eBike.png";
import eScooter from "../../../../assets/img/Evo-eScooter.png";
import { Button } from "../../../../elements";
import "./index.scss";

// let rules = {};
class IsEbikeOnly extends PureComponent {
  state = {
    hoveredIndex: -1
  };
  hover(index) {
    console.log("hover: ", index);
    this.setState({
      hoveredIndex: index
    });
  }

  leave() {
    console.log("leave b4: ", this.state.hoveredIndex);
    this.setState({
      hoveredIndex: -1
    });
    console.log("leave after: ", this.state.hoveredIndex);
  }

  changeValue = (name, newvalue, options) => {
    const {
      actions: { changeFieldValue }
    } = this.props;
    changeFieldValue({ ...this.props, name, newvalue, options });
  };

  _disableContinue = () => {
    const { invalid, submitting, pristine } = this.props;

    if (invalid || submitting || pristine) {
      return true;
    }
  };

  _onSubmit = (isEbikeOnly) => {
    const { submit } = this.props;
    window.dataLayer.push({ event: isEbikeOnly ? "evolve_flow" : "evo_flow" });
    this.changeValue("isEbikeOnly", isEbikeOnly);
    submit();
  };

  hoverClassSubtext = (index) => {
    const { hoveredIndex } = this.state;
    if (hoveredIndex > -1 && hoveredIndex === index) return "sub-text whiteText";
    else return "sub-text";
  };
  hoverClassSubButton = (index) =>
    this.state.hoveredIndex > -1 && this.state.hoveredIndex === index
      ? "btn radius blackBackground-whiteBorder"
      : "btn radius";

  render() {
    const { intl } = this.props;

    const icons = [
      {
        src: bikeAndCar,
        alt: "evo",
        "data-testid": "evo-img",
        label: intl.formatMessage(messages.button.bikeAndCar),
        sub_label: "includes e-bikes/e-scooters",
        isEbikeOnly: false,
        className: "bikeAndCar-icon-hw"
      },
      {
        src: eBike,
        alt: "evolve",
        "data-testid": "evolve-img",
        label: intl.formatMessage(messages.button.onlyBike),
        sub_label: null,
        isEbikeOnly: true,
        className: "ebike-icon-hw"
      },
      {
        src: eScooter,
        alt: "evolve-scooter",
        "data-testid": "evolve-scooter-img",
        sub_label: null,
        isEbikeOnly: true,
        className: "escooter-icon-hw"
      }
    ];

    return (
      <Section size="2-3" min="md">
        <div className="div-style" disabled={this._disableContinue()}>
          <Button
            key={0}
            name={0}
            loading={false}
            theme={["large"]}
            disabled={this._disableContinue()}
            onClick={() => this._onSubmit(icons[0].isEbikeOnly)}
            className="btn btn--dark radius-tall"
            onMouseEnter={() => this.hover(0)}
            onMouseLeave={() => this.leave()}
          >
            <img
              className={icons[0].className}
              src={icons[0].src}
              data-testid={icons[0]["data-testid"]}
              alt={icons[0].alt}
              disabled={this._disableContinue()}
            />
            <div className={this.hoverClassSubButton(0)} disabled={this._disableContinue()}>
              {icons[0].label}
            </div>
            <div className={this.hoverClassSubtext(0)} disabled={this._disableContinue()}>
              {icons[0].sub_label}
            </div>
          </Button>
          <Button
            key={1}
            name={1}
            loading={false}
            theme={["large"]}
            disabled={this._disableContinue()}
            onClick={() => this._onSubmit(icons[1].isEbikeOnly)}
            className="btn btn--dark radius-tall"
            onMouseEnter={() => this.hover(1)}
            onMouseLeave={() => this.leave()}
          >
            {/* div wrapping the two images side by side */}
            <div className="img-container">
              <img
                className={icons[1].className}
                src={icons[1].src}
                data-testid={icons[1]["data-testid"]}
                alt={icons[1].alt}
                disabled={this._disableContinue()}
              />
              <img
                className={icons[2].className}
                src={icons[2].src}
                data-testid={icons[2]["data-testid"]}
                alt={icons[2].alt}
                disabled={this._disableContinue()}
              />
            </div>

            <div className={this.hoverClassSubButton(1)} disabled={this._disableContinue()}>
              {icons[1].label}
            </div>
            <div className={this.hoverClassSubtext(1)} disabled={this._disableContinue()}>
              {icons[1].sub_label}
            </div>
          </Button>
        </div>
      </Section>
    );
  }
}

IsEbikeOnly.propTypes = {
  intl: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  section: PropTypes.string,
  disabled: PropTypes.bool,
  change: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ change }, dispatch);
};

export default injectIntl(connect(null, mapDispatchToProps)(IsEbikeOnly));
