import React, { PureComponent } from "react";
// import ReactDOM from 'react-dom';
import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
// import { bindActionCreators } from 'redux';

import { Input, Grid, Col } from "../../../../components";
import { messages } from "../../../../constants";
//import * as selectors from "../../../../selectors";
// import * as actions from '../../../../actions';
import { validationBuilder, validations } from "../..";

let rules = {};
class NameFields extends PureComponent {
  ruleFirstname = [];
  ruleLastname = [];

  constructor(props) {
    super(props);
    // for field level validations
    // TODO: find a better way to instantiate props when the component mounts
    Object.keys(validations).forEach((key) => {
      rules[key] = (spec) => (value) => validationBuilder(props, validations[key], spec)(value);
    });
    const { intl } = props;

    this.ruleFirstname = [
      rules.required(intl.formatMessage(messages.personal.firstName)),
      rules.names(intl.formatMessage(messages.personal.firstName))
    ];
    this.ruleLastname = [
      rules.required(intl.formatMessage(messages.personal.lastName)),
      rules.names(intl.formatMessage(messages.personal.lastName))
    ];
    this.ruleMiddlename = [rules.names(intl.formatMessage(messages.personal.middleName))];
  }

  // UNSAFE_componentWillReceiveProps(newprops) {
  //   if (!newprops.isEditNameMode) {
  //     return;
  //   }
  //
  //   // focus on field to edit
  //   const target = document.getElementById(this.editFieldID);
  //   if (!target) {
  //     return;
  //   }
  //
  //   // UX field focus + scrolling up to the field
  //   target.focus();
  //   const pos = target.getBoundingClientRect();
  //   if (pos) {
  //     window.scrollTo(0, pos.top - 190);
  //   }
  //   this.props.actions.handleEditNameMode(!newprops.isEditNameMode);
  // }

  componentDidMount() {
      this.handleDefaultName();
  }

  // function to get the selected field value
  // removed section so I can fetch both driverLicence and personalInfo sections to update the firstName
  getValue(name) {
    const { state, form } = this.props;
    const selector = formValueSelector(form);
    return selector(state, `${name}`);
  }

  changeValue = (name, newvalue, options) => {
    const {
      actions: { changeFieldValue }
    } = this.props;
    changeFieldValue({ ...this.props, name, newvalue, options });
  };

  handleDefaultName = () => {
    const firstName = this.getValue("personalInfo.firstName");
    const middleName = this.getValue("personalInfo.middleName");
    const lastName = this.getValue("personalInfo.lastName");
      this.changeValue("firstName", firstName);
      this.changeValue("middleName", middleName);
      this.changeValue("lastName", lastName);
  };

  render() {
    const { intl, disabled } = this.props;
    return (
      <Grid>
        <Col size="2-3" min="md">
          <Field
            type="text"
            name="firstName"
            label={intl.formatMessage(messages.personal.firstName)}
            required={true}
            component={Input}
            validate={this.ruleFirstname}
            disabled={disabled}
            props={{ maxLength: "50" }}
            defaultValue={this.getValue("personalInfo.preferredName")}
          />
        </Col>
        <Col size="2-3" min="md">
          <Field
            type="text"
            name="lastName"
            label={intl.formatMessage(messages.personal.lastName)}
            required={true}
            validate={this.ruleLastname}
            component={Input}
            props={{ maxLength: "50" }}
            disabled={disabled}
            defaultValue={this.getValue("personalInfo.lastName")}
          />
        </Col>
        <Col size="2-3" min="md">
          <Field
            type="text"
            name="middleName"
            label={intl.formatMessage(messages.personal.middleName)}
            component={Input}
            validate={this.ruleMiddlename}
            props={{ maxLength: "50" }}
            disabled={disabled}
            defaultValue={this.getValue("personalInfo.middleName")}
          />
        </Col>
      </Grid>
    );
  }
}

NameFields.propTypes = {
  intl: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  section: PropTypes.string.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  isEditNameMode: PropTypes.bool,
  disabled: PropTypes.bool
  // endEdit: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
 // isEditNameMode: selectors.getIsEditNameMode(state)
});

// const mapDispatchToProps = dispatch => ({
//   actions: bindActionCreators(actions, dispatch)
// });

export default injectIntl(connect(mapStateToProps)(NameFields));
