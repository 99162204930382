/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import { constants } from '../../constants';
import * as selectors from "../../selectors";
import { flow } from "../../actions";
import cn from "classnames";
import "./progressbar.scss";
// import { messages } from "../../constants";

export class Progressbar extends PureComponent {
  renderItems(sub) {
    const { progress, validIndex, handleSelectIndex } = this.props;

    return sub.map((item, idx) => {
      const itemCSS = cn("progressbar__item", {
        "progressbar__item--current": progress.selectedIndex === idx
      });
      const isDisabled = !(sub[Math.max(0, validIndex)].allowBack && validIndex >= idx);
      // if the current step is valid, enable next step link
      const linkCSS = cn(`progressbar__link`, {
        "is-disabled": isDisabled
      });
      return (
        <li key={idx} className={itemCSS} style={{ zIndex: sub.length - idx }}>
          <a
            href="#"
            title={item.label}
            className={linkCSS}
            onClick={(e) => {
              e.preventDefault();
              if (isDisabled) {
                return;
              }
              handleSelectIndex({ selectedIndex: idx, selectedSubIndex: 0 });
            }}
          >
            <span>{item.label}</span>
          </a>
        </li>
      );
    });
  }

  render() {
    const { sub } = this.props;
    const itemsCSS = cn("progressbar__items", `progressbar__items--col${sub.length}`);

    return (
      <nav className="progressbar">
        <ol className={itemsCSS}>{this.renderItems(sub)}</ol>
      </nav>
    );
  }
}

Progressbar.propTypes = {
  sub: PropTypes.arrayOf(PropTypes.object),
  progress: PropTypes.object.isRequired,
  validIndex: PropTypes.number,
  // actions: PropTypes.object.isRequired,
  handleSelectIndex: PropTypes.func.isRequired,
  isEbikeOnly: PropTypes.bool,
  intl: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  progress: selectors.getSelectedIndex(state),
  validIndex: selectors.getValidIndex(state),
  isEbikeOnly: selectors.getIsEbikeOnly(state)
});

const mapDispatchToProps = dispatch => ({
  handleSelectIndex: bindActionCreators(flow.handleSelectIndex, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Progressbar);
