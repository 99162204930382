import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class MapControl extends PureComponent {
  componentWillUnmount() {
    const { props } = this;
    if (!props.map) return;
    const index = props.map.controls[props.controlPosition].getArray().indexOf(this.el);
    props.map.controls[props.controlPosition].removeAt(index);
  }

  render() {
    const { props } = this;
    if (!props.map || !props.controlPosition) return null;
    return (
      <div
        ref={el => {
          if (!this.renderedOnce) {
            this.el = el;
            props.map.controls[props.controlPosition].push(el);
          } else if (el && this.el && el !== this.el) {
            this.el.innerHTML = "";
            [].slice.call(el.childNodes).forEach(child => this.el.appendChild(child));
          }
          this.renderedOnce = true;
        }}
      >
        {props.children}
      </div>
    );
  }
}

MapControl.propTypes = {
  controlPosition: PropTypes.number
};

export default MapControl;
