import { defineMessages } from "react-intl";

const namespace = "transaction";
const transaction = defineMessages({
  registrationFee: {
    id: `${namespace}.registrationFee`,
    description: "One time registration fee",
    defaultMessage: "One time registration fee (only charged on driving record approval and will not be charged if you enter a refer a friend code on your My Account page)"
  },
  promo: {
    id: `${namespace}.promo`,
    description: "Promo Discount",
    defaultMessage: "Promo - {promo_code}: {minutes} minutes of free driving"
  },
  nopromo: {
    id: `${namespace}.nopromo`,
    description: "Promo Discount",
    defaultMessage: "Promo"
  },
  annualFee: {
    id: `${namespace}.annualFee`,
    description: "Annual car share operator fee",
    defaultMessage: "Annual car share operator fee (only charged on driving record approval)"
  },
  gst: {
    id: `${namespace}.gst`,
    description: "GST",
    defaultMessage: "GST"
  },
  pst: {
    id: `${namespace}.pst`,
    description: "PST",
    defaultMessage: "PST"
  },
  product1: {
    id: `${namespace}.product1`,
    description: "transaction product 1",
    defaultMessage: "One-time Registration fee"
  },
  product2: {
    id: `${namespace}.product2`,
    description: "transaction product 2",
    defaultMessage: "CAA Registration Discount"
  },
  product3: {
    id: `${namespace}.product3`,
    description: "transaction product 3",
    defaultMessage: "Annual Car Share Operator fee (only charged on driving record approval)"
  },
  product3Helper: {
    id: `${namespace}.product3Helper`,
    description: "transaction product 3 helper text",
    defaultMessage:
      "To be eligible for insurance as a car share operator in BC, Evo is required to charge an annual fee."
  },
  product4: {
    id: `${namespace}.product4`,
    description: "transaction product 4",
    defaultMessage: "Reservation"
  },
  product5: {
    id: `${namespace}.product5`,
    description: "transaction product 5",
    defaultMessage: "Trip Time Charges"
  },
  product6: {
    id: `${namespace}.product6`,
    description: "transaction product 6",
    defaultMessage: "Extra KM Charges"
  },
  product7: {
    id: `${namespace}.product7`,
    description: "transaction product 7",
    defaultMessage: "CAA Discount ({percetage})"
  },
  product8: {
    id: `${namespace}.product8`,
    description: "transaction product 8",
    defaultMessage: "PVRT Charge"
  },
  product9: {
    id: `${namespace}.product9`,
    description: "transaction product 9",
    defaultMessage: "Damage Deductible"
  },
  product10: {
    id: `${namespace}.product10`,
    description: "transaction product 10",
    defaultMessage: "Drained battery fee"
  },
  product11: {
    id: `${namespace}.product11`,
    description: "transaction product 11",
    defaultMessage: "Excessive cleaning fee"
  },
  product12: {
    id: `${namespace}.product12`,
    description: "transaction product 12",
    defaultMessage: "Fuel Reimbursement"
  },
  product13: {
    id: `${namespace}.product13`,
    description: "transaction product 13",
    defaultMessage: "Loss of use fee"
  },
  product14: {
    id: `${namespace}.product14`,
    description: "transaction product 14",
    defaultMessage: "Lost Key"
  },
  product15: {
    id: `${namespace}.product15`,
    description: "transaction product 15",
    defaultMessage: "Parking ticket charge"
  },
  product16: {
    id: `${namespace}.product16`,
    description: "transaction product 16",
    defaultMessage: "Parking ticket charge + Tow ticket"
  },
  product17: {
    id: `${namespace}.product17`,
    description: "transaction product 17",
    defaultMessage: "Replacement Membership card"
  },
  product18: {
    id: `${namespace}.product18`,
    description: "transaction product 18",
    defaultMessage: "Toll charge"
  },
  product19: {
    id: `${namespace}.product19`,
    description: "transaction product 19",
    defaultMessage: "Towing ticket charge"
  },
  product20: {
    id: `${namespace}.product20`,
    description: "transaction product 20",
    defaultMessage: "Unauthorized parking fee"
  },
  product21: {
    id: `${namespace}.product21`,
    description: "transaction product 21",
    defaultMessage: "Unsecured Vehicle fee"
  },
  product22: {
    id: `${namespace}.product22`,
    description: "transaction product 22",
    defaultMessage: "Bad Debt Montreal"
  },
  product23: {
    id: `${namespace}.product23`,
    description: "transaction product 23",
    defaultMessage: "Bad Debt Toronto"
  },
  product24: {
    id: `${namespace}.product24`,
    description: "transaction product 24",
    defaultMessage: "Bad Debt Vancouver"
  },
  product25: {
    id: `${namespace}.product25`,
    description: "transaction product 25",
    defaultMessage: "Promotion Registration Discount"
  },
  product26: {
    id: `${namespace}.product26`,
    description: "transaction product 26",
    defaultMessage: "Free Mins Applied"
  }
});

export default transaction;
