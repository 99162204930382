import * as normalizers from "./normalizers";
import * as formatters from "./formatters";
import RegistrationWizard from "./registrationWizard/registrationWizard";
import UpdateForms from "./updateForms/updateForms";
import UpdatePreferenceCenterForm from "./updatePreferenceCenterForm";
import UpdateBillingAddressForm from "./updateBillingAddressForm";
import UpdateCreditCardForm from "./updateCreditCardForm";
import UpdateEmailForm from "./updateEmailForm";
import ApplyPromoCodeForm from "./applyPromoCodeForm";
import PrivateRegistrationForm from "./PrivateRegistrationForm/privateRegistrationForm";
import VerifyOtpForm from './VerifyOtpForm/verifyOtpForm'
import * as validations from "./validators";
const validationBuilder = validations.validationBuilder;

export {
  validations,
  validationBuilder,
  normalizers,
  formatters,
  RegistrationWizard,
  UpdateForms,
  UpdatePreferenceCenterForm,
  UpdateBillingAddressForm,
  UpdateCreditCardForm,
  UpdateEmailForm,
  ApplyPromoCodeForm,
  PrivateRegistrationForm,
  VerifyOtpForm,
};
