import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import TableHeader from "./tableHeader";
import TableBody from "./tableBody";
import "./index.scss";

export default class DataTable extends PureComponent {
  render() {
    const {
      collaspableColumns,
      columnNames,
      data,
      displayKeys,
      mobileDisplayKeys,
      nullStateTitle,
      rowGrayRule,
      singleRow,
      theme
    } = this.props;
    const tableCSS = cn(
      "data-table",
      theme ? theme.map(css => `data-table--${css}`).join(" ") : ""
    );
    return (
      <table className={tableCSS}>
        <TableHeader columnNames={columnNames} collaspableColumns={collaspableColumns} />
        <TableBody
          collaspableColumns={collaspableColumns}
          columnNames={columnNames}
          data={data}
          displayKeys={displayKeys}
          mobileDisplayKeys={mobileDisplayKeys}
          nullStateTitle={nullStateTitle}
          singleRow={singleRow}
          rowGrayRule={rowGrayRule}
        />
      </table>
    );
  }
}

DataTable.defaultProps = {
  theme: [],
  columnNames: [],
  collaspableColumns: [],
  data: [],
  displayKeys: [],
  nullStateTitle: "",
  mobileDisplayKeys: []
};

DataTable.propTypes = {
  collaspableColumns: PropTypes.arrayOf(PropTypes.string),
  columnNames: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
  data: PropTypes.arrayOf(PropTypes.object),
  displayKeys: PropTypes.arrayOf(PropTypes.string),
  mobileDisplayKeys: PropTypes.arrayOf(PropTypes.string),
  nullStateTitle: PropTypes.string,
  rowGrayRule: PropTypes.func,
  singleRow: PropTypes.bool,
  theme: PropTypes.arrayOf(PropTypes.string)
};
