import React from 'react';
import PropTypes from 'prop-types';

import './imagePreview.scss';

export const ImagePreview = ({ dataUri, isFullscreen }) => {
  let classNameFullscreen = isFullscreen ? 'image-preview-fullscreen' : '';
  let newDataUri = dataUri instanceof File ?  URL.createObjectURL(dataUri) : dataUri;
  return (
    <div className={'image-preview ' + classNameFullscreen}>
      <img src={newDataUri} alt="test"/>
    </div>
  );
};

ImagePreview.propTypes = {
  dataUri: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isFullscreen: PropTypes.bool
};

export default ImagePreview;
