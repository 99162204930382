import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import {reduxForm, defaultShouldAsyncValidate} from "redux-form";
import PropTypes from "prop-types";
import Scroll from "react-scroll";
import { injectIntl } from "react-intl";

import * as selectors from "../../../selectors";
import * as allActions from "../../../actions";
import * as bannerActions from "../../../actions/banner";
import { Button } from "../../../elements";
import { ErrorBoundary } from "../../../components";
import { messages, constants } from "../../../constants";
import {EvoMembershipExists} from "../fieldGroups";
import {FormSectionWrapper} from "../formSections";
import asyncValidate from "../asyncValidate";

const scroll = Scroll.animateScroll;

const calculateNumErrors = errors => {
  let result = 0;
  if (typeof errors === "object") {
    for (let section in errors) {
      if (!errors.hasOwnProperty(section) || typeof errors[section] !== "object") continue;

      result +=errors[section]? Object.keys(errors[section]).length : 0;
    }
  } else {
    return null;
  }
  return result;
};


const settings = {
  form: constants.FORM.privateRegistrationForm, // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  asyncValidate,
  asyncChangeFields: [
    'evolveBusinessReg.businessEmailAddress', 
    'evolveBusinessReg.personalEmailAddress', 
    'evolveBusinessReg.isEvoMember', 
    'evolveBusinessReg.accessCode' 
  ],
  asyncBlurFields: [
    'evolveBusinessReg.businessEmailAddress', 
    'evolveBusinessReg.personalEmailAddress', 
    'evolveBusinessReg.isEvoMember', 
    'evolveBusinessReg.accessCode' 
  ],
  shouldAsyncValidate: (params) => {
    console.log('privateRegistrationForm async params = ', JSON.stringify(params));
    return defaultShouldAsyncValidate({...params, syncValidationPasses: true});
  },
  onSubmitFail: (syncErrors, dispatch, submitErrors, props) => {
    const {intl, bannerActions} = props;
    const syncErrorsLength = syncErrors ? calculateNumErrors(syncErrors) : null;
    const submitErrorsLength = submitErrors ? calculateNumErrors(submitErrors) : null;
    if ((syncErrorsLength) || (submitErrorsLength && submitErrors !== "timeout")) {
      const length = syncErrorsLength || submitErrorsLength;
      bannerActions.setAlerts([
        {
          level: constants.ALERT_LEVEL.error,
          message: intl.formatMessage(messages.error.infoMissing, {
            length: length
          })
        }
      ]);
    } else {
      bannerActions.setAlerts([
        {
          level: constants.ALERT_LEVEL.error,
          message: intl.formatMessage(messages.error.genericError)
        }
      ])
    }
    scroll.scrollToTop();
  }
};

class PrivateRegistrationForm extends PureComponent {
  // componentDidUpdate(prevProps) {
  //   const { actions, progress } = prevProps;
  //
  //   //actions.handleValidIndex({ ...this.props, progress });
  // }

  _disableContinue = () => {
    const {invalid, submitting, pristine} = this.props;

    if (invalid || submitting ||pristine) {
      return true;
    }
  }

  render() {
    const { handleSubmit, button, submitting } = this.props;

    // const renderSection = sections => {
    //   return sections.map((section, idx) => {
    //     const CustomTag = section.component;
    //     return (
    //       <ErrorBoundary key={idx} name={`custom tag ${idx}`}>
    //         <CustomTag {...this.props} title={section.title} section={section.section} />
    //       </ErrorBoundary>
    //     );
    //   });
    // };

    return (
      // noValidate disable browser validation on special types, such as email
      // which triggers auto focus billingAddressSameAsHome

      <form onSubmit={handleSubmit} noValidate>
        <FormSectionWrapper title={"Evolve Business Registration"} section="evolveBusinessReg" {...this.props}>
          <ErrorBoundary name={`Private Registration`}>
            <EvoMembershipExists section="evolveBusinessReg" {...this.props}/>
          </ErrorBoundary>
          <Button loading={submitting} type="submit" theme={["cta"]} disabled={ this._disableContinue() }>
            {button}
          </Button>
        </FormSectionWrapper>
        </form>
    );
  }
}

PrivateRegistrationForm.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string
  ]),
  handleSubmit: PropTypes.func.isRequired,

  button: PropTypes.string,
  state: PropTypes.object.isRequired,

  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  bannerActions: PropTypes.objectOf(PropTypes.func),
  submitting: PropTypes.bool,
  intl: PropTypes.object.isRequired
};

PrivateRegistrationForm.defaultProps = {
  children: null,
  button: "",
  bannerActions: PropTypes.objectOf(PropTypes.func),
  submitting: false
};

//const selector = formValueSelector(settings.form);
const mapStateToProps = state => ({
  state,

  summaryParam: {
   // signupRegion: selector(state, "homeAddress.signupRegion"),
    isCAAMember: selectors.getiscaaMember(state),
    promoCode: selectors.getPromoCodeStatus(state).code
  },

});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(allActions.flow, dispatch),
  bannerActions: bindActionCreators(bannerActions, dispatch)
});

const enhancer = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm(settings)
);

export default enhancer(PrivateRegistrationForm);
