import { defineMessages } from "react-intl";

const namespace = `link`;
const button = defineMessages({
  home: {
    id: `${namespace}.home`,
    description: `link to home`,
    defaultMessage: `HOME`
  },
  findACar: {
    id: `${namespace}.findACar`,
    description: `link to find a car page`,
    defaultMessage: `FIND A CARD`
  },
  howItWorks: {
    id: `${namespace}.howItWorks`,
    description: `link to how it works page`,
    defaultMessage: `HOW IT WORKS`
  },
  rates: {
    id: `${namespace}.rates`,
    description: "rates link",
    defaultMessage: "RATES"
  },
  ourCars: {
    id: `${namespace}.ourCars`,
    description: `link to our cars page`,
    defaultMessage: `OUR CARS`
  },
  getApp: {
    id: `${namespace}.getApp`,
    description: `link to get app page`,
    defaultMessage: `GET THE APP`
  },
  more: {
    id: `${namespace}.more`,
    description: `link to more page`,
    defaultMessage: `MORE`
  },
  parking: {
    id: `${namespace}.parking`,
    description: `link to parking page`,
    defaultMessage: `PARKING`
  },
  faq: {
    id: `${namespace}.faq`,
    description: `link to faq page`,
    defaultMessage: `FAQ'S`
  },
  evoExtra: {
    id: `${namespace}.evoExtra`,
    description: `link to Evo extra page`,
    defaultMessage: `EVO EXTRAS`
  },
  communitySupport: {
    id: `${namespace}.communitySupport`,
    description: `link to community support page`,
    defaultMessage: `COMMUNITY SUPPORT`
  },
  aboutEvo: {
    id: `${namespace}.aboutEvo`,
    description: `link to about Evo page`,
    defaultMessage: `ABOUT EVO`
  },
  contact: {
    id: `${namespace}.contact`,
    description: `link to contact page`,
    defaultMessage: `CONTACT`
  },
  memberAgreement: {
    id: `${namespace}.memberAgreement`,
    description: `link to member agreement page`,
    defaultMessage: `MEMBER AGREEMENT`
  },
  popUpShop: {
    id: `${namespace}.popUpShop`,
    description: `link to pop up shop page`,
    defaultMessage: `POP UP SHOP`
  },
  news: {
    id: `${namespace}.news`,
    description: `link to news page`,
    defaultMessage: `NEWS`
  },
  updateCCInfo: {
    id: `${namespace}.updateCCInfo`,
    description: `update cc info link`,
    defaultMessage: "Update your credit card information"
  },
  updateDLInfo: {
    id: `${namespace}.updateDLInfo`,
    description: `update dl info link`,
    defaultMessage: "Update your Licence Info"
  },
  English: {
    id: `${namespace}.English`,
    description: "English",
    defaultMessage: "English"
  },
  French: {
    id: `${namespace}.French`,
    description: "French",
    defaultMessage: "French"
  }
});

export default button;
