export const styles = {
  css_body: {
    margin: 0,
    padding: 0
  },
  css_textbox: {
    border: 0,
    "border-bottom": "1px solid black",
    outline: 0,
    "font-weight": 600,
    "margin-bottom": "24px",
    "font-size": "1em",
    "padding-bottom": "8px",
    "padding-top": "8px",
    height: "36px"
  },
  css_textbox_pan: {
    width: "360px"
  },
  css_textbox_exp: {
    position: "absolute",
    top: "84px",
    width: "112px"
  },
  css_textbox_cvd: {
    position: "absolute",
    top: "84px",
    left: "128px",
    width: "44px"
  },
  css_input_label: {
    "font-family": "Source Sans, Helvetica",
    color: "rgb(155,155,155)",
    "font-size": "12px",
    "margin-bottom": "4px"
  },
  css_label_exp: {
    position: "absolute",
    top: "68px",
    width: "112px"
  },
  css_label_cvd: {
    position: "absolute",
    top: "68px",
    left: "128px",
    width: "44px"
  }
};

export const toAdyenStringFormat = elements => {
  return Object.keys(elements)
    .map(elementKey => {
      return `${elementKey}=${Object.keys(elements[elementKey])
        .map(styleKey => {
          return `${styleKey}:${elements[elementKey][styleKey]}`;
        })
        .join(";")};`;
    })
    .join("&");
};
