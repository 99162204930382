import { constants } from "../constants";

export const DEFAULT_STATE = { alerts: [] };

const var1 = (state = DEFAULT_STATE, { type, payload } = {}) => {
  switch (type) {
    case constants.SET_ALERTS:
      return { ...state, alerts: payload };
    case constants.RESET_ALERTS:
      return DEFAULT_STATE;
    case constants.DISMISS_ALERT:
      return { ...state, alerts: state.alerts.filter((alert, idx) => idx !== payload) };
    default:
      return state;
  }
};

export default var1;
