const evoOptions = [
  {
    name: "PersonalInfo",
    label: "ContactDetailsProgressTitle",
    showContinueButton: false,
    allowBack: false
  },
  {
    name: "LicenceDetails",
    label: "DriversLicenseProgressTitle",
    hasSubOptions: true,
    subOptions: ["AddDriversLicenseFront", "AddDriversLicenseBack", "DriversLicenseInfo"],
    showContinueButton: true,
    allowBack: true
  },
  {
    name: "IdentityVerificationSelfie",
    label: "IdentityVerificationProgressTitle",
    showContinueButton: true,
    allowBack: true
  },
  {
    name: "PromosAndSavings",
    label: "PromosAndSavingsProgressTitle",
    showContinueButton: true,
    allowBack: true
  },
  {
    name: "PaymentInfo",
    label: "PaymentProgressTitle",
    showContinueButton: false,
    allowBack: false
  },
  {
    name: "RequestDrivingRecord",
    label: "DrivingRecordProgressTitle",
    showContinueButton: false,
    allowBack: false
  }
];

const b2bEvoOptions = [
  {
    name: "PersonalInfo",
    label: "ContactDetailsProgressTitle",
    showContinueButton: true,
    allowBack: false
  },
  {
    name: "LicenceDetails",
    label: "DriversLicenseProgressTitle",
    hasSubOptions: true,
    subOptions: ["AddDriversLicenseFront", "AddDriversLicenseBack", "DriversLicenseInfo"],
    showContinueButton: true,
    allowBack: true
  },
  {
    name: "IdentityVerificationSelfie",
    label: "IdentityVerificationProgressTitle",
    showContinueButton: true,
    allowBack: true
  },
  {
    name: "PromosAndSavings",
    label: "PromosAndSavingsProgressTitle",
    showContinueButton: true,
    allowBack: true
  },
  {
    name: "PaymentInfo",
    label: "PaymentProgressTitle",
    showContinueButton: false,
    allowBack: false
  },
  {
    name: "RequestDrivingRecord",
    label: "DrivingRecordProgressTitle",
    showContinueButton: false,
    allowBack: false
  }
];

const b2bEvoOptionsNoPersonal = [
  {
    name: "PersonalInfo",
    label: "ContactDetailsProgressTitle",
    showContinueButton: true,
    allowBack: false
  },
  {
    name: "LicenceDetails",
    label: "DriversLicenseProgressTitle",
    hasSubOptions: true,
    subOptions: ["AddDriversLicenseFront", "AddDriversLicenseBack", "DriversLicenseInfo"],
    showContinueButton: true,
    allowBack: true
  },
  {
    name: "IdentityVerificationSelfie",
    label: "IdentityVerificationProgressTitle",
    showContinueButton: true,
    allowBack: true
  },
  {
    name: "PromosAndSavings",
    label: "PromosAndSavingsProgressTitle",
    showContinueButton: true,
    allowBack: true
  },
  {
    name: "RequestDrivingRecord",
    label: "DrivingRecordProgressTitle",
    showContinueButton: false,
    allowBack: false
  }
];

const evolveOptions = [
  {
    name: "PersonalInfo",
    label: "ContactDetailsProgressTitle",
    showContinueButton: false,
    allowBack: false
  },
  {
    name: "PromosAndSavings",
    label: "PromosAndSavingsProgressTitle",
    showContinueButton: true,
    allowBack: false
  },
  {
    name: "PaymentInfo",
    label: "CreditCardInfoProgressTitle",
    showContinueButton: false,
    allowBack: false
  },
  {
    name: "EbikeOnlyConfirmation",
    label: "ConfirmationProgressTitle",
    showContinueButton: false,
    allowBack: false
  }
];
const fn = (isEbikeOnly, isB2B, isB2BPersonal) => {
  if (isB2BPersonal) return b2bEvoOptions;
  else if (isB2B && !isB2BPersonal) return b2bEvoOptionsNoPersonal;
  else if (isEbikeOnly) return evolveOptions;
  else return evoOptions;
};

export default fn;
