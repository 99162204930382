import { defineMessages } from "react-intl";

const namespace = `address`;
const asyncValidation = defineMessages({
  emailAddressDuplicate: {
    id: `${namespace}.emailAddressDuplicate`,
    description: "duplicate Email address",
    defaultMessage: "You're already an Evo Member. Please log into your account."
  },
  emailAddressDuplicatePrivateReg: {
    id: `${namespace}.emailAddressDuplicatePrivateReg`,
    description: "duplicate Email address",
    defaultMessage:
      "You're already an Evo Member. Click 'Yes' to sign up for an Evolve for Business account"
  },
  driversLicenseNumberDuplicate: {
    id: `${namespace}.driversLicenseNumberDuplicate`,
    description: "duplicate Drivers Licence Number",
    defaultMessage:
      "That drivers licence number is already registered. Please log into your account."
  },
  BCAAMembershipValidation: {
    id: `${namespace}.BCAAMembershipValidation`,
    description: "BCAA Membership is not valid",
    defaultMessage: "We're unable to verify your BCAA Membership information. Please try again."
  },
  BCAAMembershipInactiveInvalid: {
    id: `${namespace}.BCAAMembershipInactiveInvalid`,
    description: "BCAA Membership is not valid",
    defaultMessage:
      "It looks like your BCAA Membership has expired. To receive your Evo discount, please apply for an eligible BCAA Membership online or by contacting 1.888.268.2222."
  },
  BCAAMembershipInactiveValid: {
    id: `${namespace}.BCAAMembershipInactiveValid`,
    description: "BCAA Membership is not valid",
    defaultMessage:
      "It looks like your BCAA Membership has expired. To receive your Evo discount, please renew your BCAA Membership online or by contacting 1.888.268.2222."
  },
  BCAAMembershipActiveInvalid: {
    id: `${namespace}.BCAAMembershipActiveInvalid`,
    description: "BCAA Membership is not valid",
    defaultMessage:
      "It looks like your BCAA membership type is not eligible for Evo discounts. To receive your Evo discount, please check out the different BCAA Memberships available online or by contacting 1.888.268.2222."
  },
  emailDomainNotRegistered: {
    id: `${namespace}.emailDomainNotRegistered`,
    description: "Business domain not registered for auto-add",
    defaultMessage:
      "Your organization is not an Evolve for Business partner. Please reach out to info@evo.ca for questions"
  },
  invalidB2BAccessCode: {
    id: `${namespace}.invalidB2BAccessCode`,
    description: "Access code is not registered",
    defaultMessage:
      "Invalid Access code. Please reach out to info@evo.ca for any questions"
  },
  invalidExistingCustomer: {
    id: `${namespace}.invalidExistingCustomer`,
    description: "Not an existing evo customer when they said they were an existing member",
    defaultMessage: "This email address is not registered with Evo."
  },
  existingCustomer: {
    id: `${namespace}.existingCustomer`,
    description: "An existing evo customer when they said they were not a member",
    defaultMessage:
      "You are an existing Evo member.  Please select the 'Yes' radio button to continue or double check your email address."
  },
  invalidOtp: {
    id: `${namespace}.invalidOtp`,
    description: "Invalid OTP",
    defaultMessage: "Invalid code, please try again"
  },
  expiredOtp: {
    id: `${namespace}.expiredOtp`,
    description: "Expired OTP",
    defaultMessage: "Code expired. Please request for a new code"
  },
  multipleBusinessAccounts: {
    id: `${namespace}.multipleBusinessAccounts`,
    description: "Found Mutilple Business Account",
    defaultMessage: "We're unable to verify as we found Mutilple Business Accounts with this email"
  }
});

export default asyncValidation;
