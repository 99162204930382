/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { reduxForm, reset } from "redux-form";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import * as selectors from "../../../selectors";
import * as allActions from "../../../actions";
import { Button } from "../../../elements";
// import { DependOn } from '../../../components';
import { messages } from "../../../constants";

const settings = {
  form: "update", // <------ same form name
  enableReinitialize: true,
  destroyOnUnmount: false, // <------ NOT preserve form data
  keepDirtyOnReinitialize: true
  // forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  // initialValues: {},
  // initialValues: {
  //   PersonalInfo: {
  //     preferredName: `Pickle`,
  //   }
  // }
};

class updateForms extends PureComponent {
  state = {
    activated: false
  };

  // TODO: update this to hook with update API
  handleClickSubmit = async values => {
    await this.props.onSubmit(values);
    window.scrollTo(0, 0);
    this.setState({ activated: false });
  };

  // enable editing mode, and reset initialValues
  handleClickEdit = e => {
    e.preventDefault();
    this.props.reset();
    this.setState({ activated: true });
  };

  handleClickCancel = e => {
    e.preventDefault();
    this.props.reset();
    this.setState({ activated: false });
  };

  render() {
    const { handleSubmit, sections, intl, submitting } = this.props;

    const renderSection = sections => {
      return sections.map((section, idx) => {
        const CustomTag = section.component;
        return (
          <CustomTag
            {...this.props}
            key={idx}
            title={section.title}
            section={section.section}
            disabled={!this.state.activated}
          />
        );
      });
    };

    // const btnTxt = intl.formatMessage(this.state.activated ? messages.button.save : messages.button.edit);
    return (
      <form onSubmit={handleSubmit(this.handleClickSubmit)} noValidate>
        {renderSection(sections)}

        {this.state.activated ? (
          <Button loading={submitting} type="submit" theme={["cta"]}>
            <span>{intl.formatMessage(messages.button.save)}</span>
          </Button>
        ) : (
          <Button onClick={this.handleClickEdit} theme={["cta"]}>
            <span>{intl.formatMessage(messages.button.edit)}</span>
          </Button>
        )}

        {this.state.activated && (
          <a onClick={this.handleClickCancel} className="is-underlined">
            {intl.formatMessage(messages.button.cancel)}
          </a>
        )}
      </form>
    );
  }
}

updateForms.propTypes = {
  intl: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string
  ]),
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  sections: PropTypes.array,
  state: PropTypes.object,
  user: PropTypes.object,
  actions: PropTypes.objectOf(PropTypes.func),
  initialValues: PropTypes.object,
  reset: PropTypes.func,
  submitting: PropTypes.bool
};

// const selector = formValueSelector(settings.form);
const mapStateToProps = state => ({
  state,
  user: selectors.getUser(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(allActions.flow, dispatch),
  reset: bindActionCreators(reset, dispatch)
});

export default compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm(settings)
)(updateForms);
