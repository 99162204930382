import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import Select from "react-select";
import { injectIntl } from "react-intl";
import "./dropdown.scss";
import {findIndex} from 'lodash';

import { Txt } from "../../elements";
import { messages } from "../../constants";

class Dropdown extends PureComponent {
  constructor(props) {
    super(props);

    const { options, input } = this.props;
    // redux form props
    const { value } = input;

    const valueOptionIndex = (options && options.length > 0) ? findIndex(options, ['value', value]) : -1;

    this.state = {
      selectedOption: (valueOptionIndex >= 0) ? options[valueOptionIndex] : '',
      focused: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.updateFocusState = this.updateFocusState.bind(this);
  }

  updateFocusState(flag) {
    this.setState({ focused: flag });
  }

  renderOptions(options) {
    return options?.map((item, idx) => (
      <option key={idx} value={item.value}>
        {item.label}
      </option>
    ));
  }

  // used only for desktop react-select
  handleChange(selectedOption) {
    const { onChange, value } = this.props.input;
    const { postChange } = this.props;
    const previousValue = value;
    const newValue = selectedOption ? selectedOption.value : "";
    this.setState({ selectedOption });
    onChange(newValue);

    // call postChange with two args previous value, and new value
    if (postChange) {
      postChange(previousValue, newValue);
    }
  }

  render() {
    const { intl, className, label, options, disabled, required, multi, input, meta } = this.props;
    // redux form props
    const { onChange, onBlur, value, name } = input;
    const { touched, dirty, valid, warning, invalid, error } = meta;
    const txt = `${label}${required ? " *" : ""}`;

    const selectCSS = cn("dropdown", className, {
      // 'is-pristine': pristine,
      "is-valid": dirty && valid,
      "is-invalid": touched && invalid,
      "is-warning": dirty && warning,
      // 'is-required': required,
      "is-focused": this.state.focused,
      "is-disabled": disabled
    });

    const placeholder = intl.formatMessage(messages.field.dropdown, { txt });

    const {selectedOption} = this.state;

    const pickComponent = () => {
      // TODO: not sure about mobile detection
      const max = window.matchMedia("(max-width: 480px)");
      const component = max.matches ? (
        <select
          id={name}
          name={name}
          value={value}
          multiple={multi}
          disabled={disabled}
          required={required}
          onChange={e => onChange(e.target.value)}
          onFocus={() => this.updateFocusState(true)}
          onBlur={() => {
            // calling onBlur to trigger redux-form to set this field to touched
            onBlur();
            this.updateFocusState(false);
          }}
          className={value ? "has-value" : ""}
        >
          <option value="">{placeholder}</option>
          {this.renderOptions(options)}
        </select>
      ) : (
        <Select
          id={name}
          name={name}
          value={selectedOption}
          isMulti={multi}
          isClearable={true}
          isDisabled={disabled}
          required={required}
          onChange={this.handleChange}
          onFocus={() => this.updateFocusState(true)}
          onBlur={() => {
            // calling onBlur to trigger redux-form to set this field to touched
            onBlur();
            this.updateFocusState(false);
          }}
          options={options}
          placeholder={placeholder}
          className= 'react-select-container'
          classNamePrefix={!this.state.selectedOption || this.state.selectedOption === ''? 'react-select' : 'value-selected react-select'}
        />
      );

      return component;
    };

    return (
      <fieldset data-testid="component-dropdown" className={selectCSS} role="group">
        <div data-testid="dropdown-field" className='dropdown-field'>
          {pickComponent()}
          <label data-testid="dropdown-label" htmlFor={name} className={!this.state.selectedOption || this.state.selectedOption === ''? 'dropdown-label placeholder-shown' : 'dropdown-label'} >
            {txt}
          </label>
        </div>
        {touched && error && (
          <Txt theme={["small", "error"]}>
            <small>{error}</small>
          </Txt>
        )}
      </fieldset>
    );
  }
}

Dropdown.displayName = "Dropdown";

Dropdown.propTypes = {
  intl: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  multi: PropTypes.bool,
  state: PropTypes.object,
  regex: PropTypes.string,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  postChange: PropTypes.func
  // locale: PropTypes.string,
};

Dropdown.defaultProps = {
  disabled: false,
  required: false,
  multi: false
};

export default injectIntl(Dropdown);
