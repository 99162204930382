import React, { Component, createRef } from "react";
import GoogleMapReact from "google-map-react";
import { connect } from "react-redux";
import PropTypes, { bool, number, arrayOf, object } from "prop-types";
import { injectIntl } from "react-intl";
import { compose } from "redux";
import supercluster from "points-cluster";
import head from "lodash/fp/head";

import CarMarker from "./carMarker";
import ParkingMarker from "./parkingMarker";
import RegionMarker from "./regionMarker";
import UserLocationMarker from "./userLocationMarker";
import * as selectors from "../../selectors";
import ControlContainer from "./controlContainer";
import { Banner } from "../../components";
import { messages } from "../../constants";
import MapControl from "./mapControl";
import MapSearchInput from "./searchInput";
import {
  calcCrow,
  convertNumberToMetric,
  expireCountDown,
  fetchLatLngFromAddress,
  getFuelLevel,
  getLatLngFromMarketingRegion,
  getLocation,
  getWalkingTime,
  isZoomChangingBoundary
} from "./util";
import "./index.scss";
import {
  fetchParkings,
  fetchHomezone,
  bookACar,
  cancelCarBooking,
  fetchReservation
} from "../../api/findacar";
import { map as mapActions } from "../../actions";
import { drawHomeArea } from "./homezone";
import {BlackCarIcon, GasPumpIcon, PersonIcon} from "./iconStrings";
import {Button} from "../../elements";

export class FindACar extends Component {
  constructor(props) {
    super(props);
    this.mapContainer = createRef();
    this.reservationRefresh = this.reservationRefresh.bind(this);
  }

  parkings = { features: [] };
  zoomTrace = [null, null];
  homezone = { features: [] };
  homezoneArea = null;
  homezoneEdges = null;

  state = {
    isInView: true,
    bookedCar: null,
    hideWhenZoomedIn: false,
    isBookingCar: false,
    userLocation: null,
    shouldDisplayBanner: false,
    alerts: [],
    isDataLoaded: false,
    shouldOpenMapFilters: false,
    openedInfoWindow: -1,
    filters: {
      cars: true,
      parking: false,
      homezone: false
    },
    mapOptions: {
      center: undefined,
      zoom: undefined
    },
    clusters: [],
    reservation: null,
    intervalRef: null
  };


  async componentDidMount() {
    this.setState({
      mapOptions: {
        center: this._getDefaultCenter(),
        zoom: this._getDefaultZoom()
      }
    });

    const promises = [fetchParkings(), fetchHomezone(), this.props.getCars(), fetchReservation()];

    const [parkings, homezone, cars, reservation] = await Promise.all(promises.map(p => p.catch(e => e)));

    const errors = [];
    if (!parkings || parkings instanceof Error) {
      errors.push("parking");
    } else {
      this.parkings = parkings;
    }

    if (!homezone || homezone instanceof Error) {
      errors.push("homezone");
    } else {
      this.homezone = homezone;
    }

    if (!cars || !Array.isArray(cars) || cars instanceof Error) {
      errors.push("cars");
    } else {
      this.cars = cars;
    }

    if (reservation && !(reservation instanceof Error)) {
      this.setState({reservation:reservation});
      this.setState({openedInfoWindow:reservation.vin})
      if(cars)
        this.setState({bookedCar: Array.isArray(cars) ? cars.filter(car => car.booked) : []});
    }

    if (errors.length > 0) {
      this._showBanner({
        message: `Failed loading ${errors.join(", ")}, please try again later`
      });
    }

    this.setState({ isDataLoaded: true });
  }

  // count-down until the desired time has been reached and then clear the interval, reservation and removed the booked car and refresh the screen
  reservationRefresh = () => {
    [...document.getElementsByClassName('reservationExpireTime')].map(r => r.innerHTML = expireCountDown(this.state.reservation, this.props.intl.formatMessage));
    if ( this.state.reservation && (new Date() - new Date(this.state.reservation.startDate) - this.state.reservation.reservationTimeLimit * 60000) > 0) {
      clearInterval(this.state.intervalRef);
      this.setState({ isBookingCar: true });

      this._fetchReservation().then( data => {
          this.setState({reservation: data})
        }
      );
      this.props.removeCarReservation(this.state.reservation.vin);
      this.setState({ isBookingCar: false, bookedCar: null });
      this._createCarMarkerClusters();
    }
  };

  // Set the timeout for the interval
  timerFunction = () => setInterval(this.reservationRefresh, 1000);

  componentDidUpdate(_, prevState) {
    if (prevState.filters.homezone !== this.state.filters.homezone) {
      this._showHomezonePolygon(this.state.filters.homezone);
    }

    // Set the countdown timer if a reservation exists and an existing interval is not already counting down.
    this.state.reservation && this.state.reservation.reservationId && !this.state.intervalRef && (this.setState({intervalRef: this.timerFunction() }));
  }
  render() {
    const { intl } = this.props;
    return (
      <div ref={this.mapContainer} className="map__container" data-testid="map__container">
        {this.state.isDataLoaded && (
          <GoogleMapReact
            ref="map"
            yesIWantToUseGoogleMapApiInternals
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
              libraries: ["places", "drawing"]
            }}
            defaultCenter={this._getDefaultCenter()}
            defaultZoom={this._getDefaultZoom()}
            options={this._createMapOptions()}
            onGoogleApiLoaded={this._drawMap}
            onChange={this._handleMapChange}
            onBoundsChange={this.handleBoundsChanged}
            onZoomAnimationEnd={() => {
              this.zoomTrace.shift();
              this.zoomTrace.push(this.map.getZoom());
            }}
            onTilesLoaded={() => {
              this._handleZoomChanged();
            }}
            {...this.props}
          >
            {!this.state.hideWhenZoomedIn &&
              this.state.filters.parking &&
              this.parkings &&
              this.parkings.map(this._renderParking)}

            {!this.state.hideWhenZoomedIn &&
            this.state.filters.cars && !this.state.reservation &&
            this.state.clusters.map(this._renderCarMarker)}

            {!this.state.hideWhenZoomedIn &&
              this.state.filters.cars && this.state.reservation &&
              this.state.clusters.filter(cluster => cluster.points.filter(car => car.booked).length).map(this._renderCarMarker)}

            {this.state.hideWhenZoomedIn &&
              this.props.regions &&
              this.props.regions.map(this._renderRegionMarker)}

            {!this.state.hideWhenZoomedIn && this.state.userLocation && (
              <UserLocationMarker
                lat={this.state.userLocation.lat}
                lng={this.state.userLocation.lng}
              />
            )}
          </GoogleMapReact>
        )}
        {this._renderBanner()}
        {this._renderSearch()}
        {this._renderSettingLocation()}
        {this.state.reservation && !this.state.isInView && this._renderFloatingReservationWindow(intl)}
      </div>
    );
  }


  _renderFloatingReservationWindow = (intl) => {
    const bookedCar = head(this.state.bookedCar);
    const fuelLevel = bookedCar ? getFuelLevel(bookedCar.fuel) : "";
    const bookedCarCoordinates = bookedCar ? bookedCar.coordinates : null;

    const distance =
      (this.state.userLocation
        && bookedCarCoordinates && bookedCarCoordinates.latitude && bookedCarCoordinates.longitude
        && calcCrow(this.state.userLocation.lat, this.state.userLocation.lng, bookedCarCoordinates.latitude, bookedCarCoordinates.longitude)) ||
      null;

    return (
      <div className="reservation-float-info-window" id="reservationFloatInfoWindow">
        <div className="car-details " id="reservationInfoFloatInsideWindow">
          <div className="car-details_top-car-icon">
            <img src={BlackCarIcon} alt="car icon" draggable="false"></img>
          </div>
          <div className="car-details_brief">
            <div className="car-details_plate">{this.state.bookedCar && head(this.state.bookedCar) && head(this.state.bookedCar).plate}</div>
            <div
              className="car-details_title">{this.state.bookedCar && head(this.state.bookedCar) && head(this.state.bookedCar).address ? head(this.state.bookedCar).address : ""} </div>
            <div className="car-details_metric">
              <div>
                <img src={PersonIcon} alt="person icon" draggable="false"/>
                <span>
                  {intl.formatMessage(messages.maps.walkingDistance, {
                    distance: convertNumberToMetric(distance),
                    walkingTime: getWalkingTime(distance)
                  })}
                </span>
              </div>

              <div>
                <img src={GasPumpIcon} alt="gas pump icon"></img>
                <span>{this.state.bookedCar ? intl.formatMessage(messages.maps.fuelLevel, {fuelLevel: fuelLevel}) : ""}</span>
              </div>
            </div>
          </div>
          {this.state.isBookingCar && <div className="car-details_message">{intl.formatMessage(messages.maps.bookingInProgress)}</div>}
          {!this.state.isBookingCar && this.state.bookedCar && this.state.reservation && <div className="car-details_message">{intl.formatMessage(messages.maps.evoIsReady)}</div>}
          <Button
            theme={["full-width"]}
            className="car-details_button-bookcar car-details_button-bookcar-inverse"
            type="button"
            onClick={() => this._cancelCarBooking(head(this.state.bookedCar).vin)}
          >
            {intl.formatMessage(messages.button.cancel)}
          </Button>
          <div className="car-details_info-bottom">
            <div>{intl.formatMessage(messages.maps.timeRemaining)} :
              <span className="reservationExpireTime">
                      {expireCountDown(this.state.reservation, intl.formatMessage)}
                    </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderParking = (parking, index) => {
    const { latLong } = parking;
    const parkingId = `parking-${latLong.latitude}${latLong.longitude}`;
    return (
      <ParkingMarker
        key={parkingId}
        lat={latLong.latitude}
        lng={latLong.longitude}
        parking={parking}
        onClick={() => this._openInfoWindow(parkingId)}
        onClose={this._closeInfoWindow}
        isInfoWindowOpen={this.state.openedInfoWindow === parkingId}
        userLocation={this.state.userLocation}
        adjustPan={this._adjustPan}
      />
    );
  };

  _renderCarMarker = item => {
    const carId = item.id;
      // single car marker
      if (item.numPoints === 1) {
        const [car] = item.points;
        return (
          <CarMarker
            key={`car-${car.id}`}
            lat={car.lat}
            lng={car.lng}
            car={car}
            reservation={this.state.reservation}
            onClick={() => this._openInfoWindow(car.id)}
            onClose={this._closeInfoWindow}
            bookACar={() => this._bookCar(car.id)}
            cancelCarBooking={() => this._cancelCarBooking(car.id)}
            userLocation={this.state.userLocation}
            isInfoWindowOpen={this.state.openedInfoWindow === car.id}
            isBooked={car.booked}
            isBookingCar={this.state.isBookingCar}
            adjustPan={this._adjustPan}
          />
        );
      }

      // car cluster marker
      return (
        <CarMarker
          key={carId}
          lat={item.lat}
          lng={item.lng}
          onClick={() => this._panToLocation(new this.maps.LatLng(item.lat, item.lng))}
          isGroup
          numPoints={item.numPoints}
        />
      );
  };

  _renderRegionMarker = region => {
    const position = getLatLngFromMarketingRegion(region.id);
    const { lat, lng } = position;
    return (
      <RegionMarker
        key={`region-${region.id}`}
        lat={lat}
        lng={lng}
        onClick={() => this._panToLocation(position, 12)}
      />
    );
  };

  _fetchReservation = async () => {
     let reservationFetched = await fetchReservation();
     this.setState({reservation: reservationFetched})
    return reservationFetched;
  };

  _bookCar = async vin => {
    this.setState({ isBookingCar: true });
    try {
      await bookACar(vin);
      this._fetchReservation();
      this.props.createCarReservation(vin);
      this.setState({bookedCar: this.props.cars.filter(car => car.booked)});
    } catch (e) {
      this._showBanner({
        message: this.props.intl.formatMessage(messages.error.genericError)
      });
    }
    this.setState({ isBookingCar: false });
    this._createCarMarkerClusters();
  };

  _cancelCarBooking = async vin => {
    this.setState({ isBookingCar: true });
    try {
      await cancelCarBooking(vin);
      this.props.removeCarReservation(vin);
      this._fetchReservation();
      this.setState({bookedCar: null});
    } catch (e) {
      this._showBanner({
        message: this.props.intl.formatMessage(messages.error.genericError)
      });
    }
    this.setState({ isBookingCar: false });
    this._createCarMarkerClusters();
  };

  _getCarMarkerClusters = () => {
    const markersData = this.props.cars.map(car => {
      const {
        vin,
        coordinates: { latitude, longitude },
        ...rest
      } = car;
      return { id: vin, lat: latitude, lng: longitude, ...rest };
    });
    let maxZoom = 15;

    if(this.state.reservation && this.state.bookedCar){
      maxZoom = 0;
    }

    const clusters = supercluster(markersData, { maxZoom: maxZoom, radius: 60 });

    return clusters(this.state.mapOptions);
  };

  _createCarMarkerClusters = props => {
    const clusters = this.state.mapOptions.bounds
      ? this._getCarMarkerClusters(props).map(({ wx, wy, numPoints, points, id }) => ({
          lat: wy,
          lng: wx,
          numPoints,
          id: numPoints > 1 ? `${numPoints}_${points[0].id}` : points[0].id,
          points
        }))
      : [];

    this.setState({ clusters });
  };

  _handleMapChange = ({ center, zoom, bounds }) => {
    this.setState(
      {
        mapOptions: {
          center,
          zoom,
          bounds
        }
      },
      () => {
        this._createCarMarkerClusters(this.props);
      }
    );
  };

  handleBoundsChanged = (center, zoom, bounds, marginBounds) => {
    if (this.state.reservation && this.state.bookedCar) {
      if (this.refs && this.refs.map && this.refs.map.maps_) {
        let isInView = true;
        if(head(this.state.bookedCar)) {
          let BookedCarCoordinates = head(this.state.bookedCar).coordinates;

          isInView = BookedCarCoordinates && this.refs.map.map_.getBounds().contains({
            lat: BookedCarCoordinates.latitude,
            lng: BookedCarCoordinates.longitude
          });
        }
        this.setState({isInView: isInView});

        let infoW = document.getElementById('reservationInfoWindow');
        let infoFW = document.getElementById('reservationFloatInfoWindow');

        if (infoW) {
          infoW.style.display = isInView ? 'block' : 'none';
        }
        if (infoFW) {
          infoFW.style.display = isInView ? 'none' : 'block';
        }
      }
    }
  }

  _adjustPan = async (infoWindowDom, markerLat, markerLng) => {
    if (this.mapContainer.current && infoWindowDom) {
      let dx = 0;
      let dy = 0;
      const bufferPadding = 30;
      const mapContainerBound = this.mapContainer.current.getBoundingClientRect();
      const infoWindowBound = infoWindowDom.getBoundingClientRect();

      const mcLeft = mapContainerBound.x;
      const mcRight = mapContainerBound.x + mapContainerBound.width;
      const mcTop = mapContainerBound.y;
      const mcBottom = mapContainerBound.y + mapContainerBound.height;

      const iwLeft = infoWindowBound.x;
      const iwRight = infoWindowBound.x + infoWindowBound.width;
      const iwTop = infoWindowBound.y;
      const iwBottom = infoWindowBound.y + infoWindowBound.height;

      // left bound check
      if (iwLeft - bufferPadding < mcLeft) {
        dx -= mcLeft - (iwLeft - bufferPadding);
      }
      // top bound check
      if (iwTop - bufferPadding < mcTop) {
        dy -= mcTop - (iwTop - bufferPadding);
      }
      // right bound check
      if (iwRight + bufferPadding > mcRight) {
        dx += iwRight + bufferPadding - mcRight;
      }
      // bottom bound check
      if (iwBottom + bufferPadding > mcBottom) {
        dy += iwBottom + bufferPadding - mcBottom;
      }

      if (!isNaN(dx) && !isNaN(dy)) {
        this.map.panBy(dx, dy);
      }
    }
  };

  _closeInfoWindow = () => {
    if (!this.state.isBookingCar) {
      this.setState({ openedInfoWindow: -1 });
    }
  };

  _openInfoWindow = id => {
    if (!this.state.isBookingCar) {
      this._getCurrentPosition();
      this.state.openedInfoWindow === id
        ? !this.state.reservation && this._closeInfoWindow()
        : this.setState({ openedInfoWindow: id });
    }
  };

  _getCurrentPosition = () => {
    getLocation(userLocation => this.setState({ userLocation }), () => {});
  };

  /**
   * _renderHomezone
   * create a Polygon object and store it as this.homezoneArea
   * create PolyEdge objects and store them as this.homezoneEdges
   * @param {bool} isVisibleOnInit - bool to control to show/hide homezone
   */
  _renderHomezone = (isVisibleOnInit = false) => {
    const { homezoneArea, homezoneEdges } = drawHomeArea(
      this.homezone,
      this.map,
      this.maps,
      isVisibleOnInit
    );
    this.homezoneArea = homezoneArea;
    this.homezoneEdges = homezoneEdges;
  };

  /**
   * _drawMap
   * generate markers, polygons on the map and set their visibility
   * based on this.state.fitlers settings
   * @param {object} - Google API object
   */
  _drawMap = ({ map, maps }) => {
    this.map = map;
    this.maps = maps;
    if (this.state.reservation && this.state.bookedCar) {
    let BookedCar = head(this.state.bookedCar);
    if(BookedCar){
      this._panToLocation(new this.maps.LatLng(BookedCar.coordinates.latitude, BookedCar.coordinates.longitude));
    }

    }
    else
      this._getUserLocation();

    this.setState({ mapControlShouldRender: true });
    this._enableSearch();

    this._renderHomezone(this.state.filters.homezone);
   };

  /**
   * Set default options when Google Maps is initialized
   *
   * @memberof FindACar
   */
  _createMapOptions = () => {
    return { gestureHandling: "greedy", fullscreenControl: false, zoomControl: true };
  };

  /**
   * Return default center {lat, lng} for the map
   *
   *  - If MA is in multiRegion mode
   *  - - Go to user location if possible, zoom level 18
   *  - - Go to marketing region if user chose one, zoom level 13
   *  - - If above two conditions fail, show central Canada at zoom level 5
   *
   *  - If MA is in singleRegion mode
   *  - - Go to user location if possible, zoom level 18
   *  - - Go to Vancouver, zoom level 13
   *
   * @memberof FindACar
   */
  _getDefaultCenter = () =>
    getLatLngFromMarketingRegion(this.props.isMultiRegion ? this.props.marketingRegionId : 1);

  /**
   * Get default zoom level for the map
   *
   * @memberof FindACar
   */
  _getDefaultZoom = () => {
    const { isMultiRegion, marketingRegionId } = this.props;
    return isMultiRegion && marketingRegionId === 0 ? 5 : 13;
  };

  /**
   * _enableSearch
   *
   * Binding the search to google autocomplete
   *
   */

  _enableSearch = () => {
    if (this.map) {
      const searchInput = document.getElementById("place-search-input");
      const autocomplete = new window.google.maps.places.Autocomplete(searchInput);
      autocomplete.bindTo("bounds", this.map);
      // Limite search to only Canada and US
      autocomplete.setComponentRestrictions({ country: ["ca"] });
      autocomplete.addListener("place_changed", async () => {
        const place = autocomplete.getPlace();
        if (place && place.geometry) {
          const location = place.geometry.location;
          this._panToLocation(location);
        } else if (place.name) {
          const searchResult = await fetchLatLngFromAddress(place.name);
          const {
            results: [firstPrediction]
          } = searchResult;
          if (firstPrediction) {
            const {
              formatted_address,
              geometry: { location }
            } = firstPrediction;
            if (formatted_address && location) {
              searchInput.value = formatted_address;
              this._panToLocation(location);
            }
          }
        } else {
          this._showBanner({
            message: this.props.intl.formatMessage(messages.warning.mapAddressNotFound)
          });
        }
      });
    }
  };

  /**
   * _panToLocation
   *
   * Pan google map to a certain location
   *
   * @param {latlng} latlng
   * @param {integer} zoomLevel -  Default zoom to be 16
   */
  _panToLocation = (latlng, zoomLevel = 16) => {
    if (this.map && latlng) {
      this.map.panTo(latlng);
      this.map.setZoom(zoomLevel);
    }
  };

  /**
   * Get default map center
   *
   * @memberof FindACar
   */

  /**
   * Adding search box to the top right of the map
   *
   * @returns
   */
  _renderSearch = () => {
    return (
      this.map && (
        <MapControl map={this.map} controlPosition={this.maps.ControlPosition.TOP_RIGHT}>
          <MapSearchInput
            maps={this.maps}
            onSearch={async e => {
              e.preventDefault();
              try {
                const searchInput = document.getElementById("place-search-input");
                const inputAddress = searchInput.value;
                if (inputAddress) {
                  const searchResult = await fetchLatLngFromAddress(inputAddress);
                  const {
                    results: [firstPrediction]
                  } = searchResult;
                  const {
                    formatted_address,
                    geometry: { location }
                  } = firstPrediction;
                  if (formatted_address && location) {
                    searchInput.value = formatted_address;
                    this._panToLocation(location);
                  }
                }
              } catch (error) {
                this._showBanner({
                  message: this.props.intl.formatMessage(messages.warning.mapAddressNotFound)
                });
              }
            }}
          />
        </MapControl>
      )
    );
  };

  _renderSettingLocation = () =>
    this.map && (
      <MapControl map={this.map} controlPosition={this.maps.ControlPosition.TOP_LEFT}>
        <ControlContainer
          shouldOpenMapFilters={this.state.shouldOpenMapFilters}
          filters={this.state.filters}
          toggleFilter={this._toggleFilter}
          settingsAction={() => {
            this._toggleMapFilters();
          }}
          locationAction={() => {
            this._getUserLocation(null, true);
          }}
        />
      </MapControl>
    );

  /**
   * _toggleMapFilters
   */
  _toggleMapFilters = () => {
    this.setState(({ shouldOpenMapFilters }) => ({
      shouldOpenMapFilters: !shouldOpenMapFilters
    }));
  };

  /**
   * type could only be cars, parking, and homezone
   */
  _toggleFilter = (type, isOn) => {
    this.setState(({ filters }) => ({
      filters: { ...filters, [type]: typeof isOn === "boolean" ? isOn : !filters[type] }
    }));
  };

  /**
   * _getUserLocation
   * Pass a callback to navigator.geolocation.getCurrenctPosition
   * When the location is ready, update the map's location
   * Only trigger onMount and user interaction (user click on the location button)
   *
   * @param {integer} zoomLevel - how much you want to zoom to the user's location, default is 18
   * @returns {void}
   * @memberof FindACar
   */
  _getUserLocation = (zoomLevel = 18, shouldShowBanner = false) => {
    getLocation(
      location => {
        this.setState({ userLocation: location });
        if (this.map) {
          this.map.setCenter(location);
          this.map.setZoom(zoomLevel);
        }
      },
      error => {
        if (shouldShowBanner) {
          const { intl } = this.props;
          switch (error.code) {
            case error.PERMISSION_DENIED:
              this._showBanner({ message: intl.formatMessage(messages.warning.permissionDenied) });
              break;
            case error.POSITION_UNAVAILABLE:
              // Location information is unavailable.
              this._showBanner({
                message: intl.formatMessage(messages.warning.positionUnavailable)
              });
              break;
            case error.TIMEOUT:
              // Request timeout
              this._showBanner({ message: intl.formatMessage(messages.warning.timeout) });
              break;
            // The request to get user location timed out.
            case error.UNKNOWN_ERROR:
              this._showBanner({ message: intl.formatMessage(messages.warning.mapUnknownError) });
              break;
            // An unknown error occurred.
            default:
            // do nothing
          }
        }
      }
    );
  };

  _dismissBanner = () => {
    this.setState({ shouldDisplayBanner: false, alerts: [] });
  };

  _showBanner = ({ message, level = "warning" }) => {
    this.setState({ shouldDisplayBanner: true, alerts: [{ message, level }] }, () => {
      setTimeout(this._dismissBanner, 3000);
    });
  };

  _renderBanner = () =>
    this.map && (
      <MapControl map={this.map} controlPosition={this.maps.ControlPosition.TOP_CENTER}>
        {this.state.shouldDisplayBanner && (
          <div className="map__banner">
            <Banner alerts={this.state.alerts} handleClickDismissIcon={this._dismissBanner} />
          </div>
        )}
      </MapControl>
    );

  _displayMarkers = (markerList = [], map = null) => {
    const listLength = markerList.length;
    for (let i = 0; i < listLength; i++) {
      markerList[i].setMap(map);
    }
  };

  _showHomezonePolygon = (flag = true) => {
    this.homezoneArea && this.homezoneArea.setVisible(flag);
    this.homezoneEdges && this.homezoneEdges.forEach(e => e.setVisible(flag));
  };

  /**
   * _handleZoomChanged
   * Show badge or car/parking data depending on the zoom level
   *
   * @memberof FindACar
   */
  _handleZoomChanged = (zoomThreshold = 8) => {
    if (this.map) {
      const currentZoomLevel = this.map.getZoom();
      if(this.state.reservation && this.state.bookedCar){
        zoomThreshold = 0;
      }
      if (isZoomChangingBoundary(this.zoomTrace, zoomThreshold)) {
        if (currentZoomLevel < zoomThreshold) {
          this.setState({ hideWhenZoomedIn: true });
        } else {
          this.setState({ hideWhenZoomedIn: false });
        }
      }
    }
  };
}

FindACar.defaultProps = {
  isMultiRegion: false,
  marketingRegionId: 0,
  regions: []
};

FindACar.propTypes = {
  isMultiRegion: bool,
  marketingRegionId: number,
  intl: PropTypes.object.isRequired,
  regions: arrayOf(object)
};

const mapState = state => ({
  cars: state.map.cars,
  regions: selectors.getRegions(state),
  isMultiRegion: selectors.getRegions(state).length > 1,
  marketingRegionId: selectors.getUser(state).marketingRegionId
});

const mapDispatch = {
  getCars: mapActions.getCars,
  createCarReservation: mapActions.createCarReservation,
  removeCarReservation: mapActions.removeCarReservation
};

const enhance = compose(
  connect(
    mapState,
    mapDispatch
  ),
  injectIntl
);
export default enhance(FindACar);
