import React from "react";
import PropTypes from "prop-types";

import { messages } from "../../../constants";
import { Grid, Col, Card } from "../../";
import DataCell from "../dataCell";

const BillingRecordCard = props => {
  const { intl, record } = props;
  const {
    id,
    car,
    tripStartDateTime,
    tripEndDateTime,
    from,
    to,
    distance,
    tripDuration,
    amount,
    details
  } = record;
  return (
    <Card key={id}>
      <Card.Header>
        <Grid theme={["small-div"]}>
          <Col size="1-3">
            <DataCell headerTitle={intl.formatMessage(messages.title.Car)} data={car} />
          </Col>
          <Col size="1-3">
            <DataCell
              headerTitle={intl.formatMessage(messages.title.Start)}
              data={tripStartDateTime}
            />
          </Col>
          <Col size="1-3">
            <DataCell headerTitle={intl.formatMessage(messages.title.End)} data={tripEndDateTime} />
          </Col>
        </Grid>
      </Card.Header>
      <Card.Body theme={["top-dashed"]}>
        <Grid theme={["small-div"]}>
          <Col size="1-1">
            <DataCell headerTitle={intl.formatMessage(messages.title.From)} data={from} />
          </Col>
        </Grid>
        <Grid theme={["small-div"]}>
          <Col size="1-1">
            <DataCell headerTitle={intl.formatMessage(messages.title.To)} data={to} />
          </Col>
        </Grid>
        <Grid theme={["small-div"]}>
          <Col size="1-3">
            <DataCell headerTitle={intl.formatMessage(messages.title.Distance)} data={distance} />
          </Col>
          <Col size="1-3">
            <DataCell
              headerTitle={intl.formatMessage(messages.title.Duration)}
              data={tripDuration}
            />
          </Col>
          <Col size="1-3">
            <DataCell headerTitle={intl.formatMessage(messages.title.Amount)} data={amount} />
          </Col>
        </Grid>
      </Card.Body>
      <Card.Footer>{details}</Card.Footer>
    </Card>
  );
};

BillingRecordCard.propTypes = {
  intl: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired
};

export default BillingRecordCard;
