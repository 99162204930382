import React from "react";

export const CheckMark = () => (
  <svg
    width="60px"
    height="60px"
    viewBox="0 0 60 60"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Group 7</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <circle id="path-1" cx="30" cy="30" r="30" />
    </defs>
    <g id="Change-Email" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-7">
        <mask id="mask-2" fill="white">
          <use xlinkHref="#path-1" />
        </mask>
        <circle stroke="#00BCE2" strokeWidth="4" cx="30" cy="30" r="28" />
        <polyline
          id="Path-3-Copy"
          stroke="#00BCE2"
          strokeWidth="4"
          mask="url(#mask-2)"
          points="16 33.5 23 40.5 43.5 20"
        />
      </g>
    </g>
  </svg>
);

CheckMark.displayName = "CheckMark";

export default CheckMark;
