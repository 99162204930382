import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import get from "lodash/get";
import { Link } from "react-router-dom";
import { destroy, reset } from "redux-form";

import { Title, Txt } from "../../elements";
import { UpdateBillingAddressForm, UpdateCreditCardForm } from "../../containers/form";
import { messages, constants } from "../../constants";
import * as selectors from "../../selectors";
import { user as userActions, banner as bannerActions } from "../../actions";
import { DependOn, Banner, Section, Grid, Col } from "../../components";
import { checkCreditCardExpiry } from "../../util";
import { diff } from "deep-object-diff";
import { pick, isEmpty } from "lodash";
import { getMonerisData } from "../../util/monerisUtils";

class AccountPaymentInfo extends PureComponent {
  state = { isLoading: true, isAlertDismissed: false, isCCFormEditable: false };

  async componentDidMount() {
    const { getCustomerPaymentInfo, getCustomerPersonalInfo, setAlerts, intl } = this.props;
    try {
      await getCustomerPaymentInfo();
      await getCustomerPersonalInfo();
    } catch (e) {
      setAlerts([
        {
          level: constants.ALERT_LEVEL.error,
          message: intl.formatMessage(messages.error.genericError)
        }
      ]);
    }

    this.setState({ isLoading: false });
  }

  render() {
    const { isLoading } = this.state;
    const { intl, user, destroyForm } = this.props;

    if (isLoading) return null;
    const { paymentCreditCard, paymentBillingAddress, creditCardValues } = user.paymentInformation
      ? this.mapFieldsBeToFe(user.paymentInformation)
      : {};

    const { homeAddress } = user.personalInfo ? this.mapAddressFieldsBEtoFE(user.personalInfo) : {};
    const { isAlertDismissed, isCCFormEditable } = this.state;

    // check CC if it's expired
    const creditCardExpiry = creditCardValues.ccExpiryDate;
    const isCreditCardExpired = creditCardExpiry ? checkCreditCardExpiry(creditCardExpiry) : false;

    return (
      <Fragment>
        <Section>
          <Grid>
            <Col>
              <Link
                to={constants.ROUTE_ACCOUNT}
                onClick={() => {
                  destroyForm(constants.FORM.updateCreditCardForm);
                  destroyForm(constants.FORM.updateBillingAddressForm);
                }}
              >
                <Txt theme={["upper"]}>
                  <i className="fa fa-chevron-left" />{" "}
                  {intl.formatMessage(messages.button.backtoAccountDetails)}
                </Txt>
              </Link>
            </Col>
          </Grid>
        </Section>
        <Section theme={["div"]}>
          <Grid>
            <Col>
              <Title priority={1} type={["strong"]} className="h2">
                {intl.formatMessage(messages.title.PaymentInfo)}
              </Title>
            </Col>
          </Grid>
        </Section>

        <DependOn active={isCreditCardExpired && !isAlertDismissed}>
          <Section>
            <Grid>
              <Col>
                <Banner
                  handleClickDismissIcon={this.dismissAlert}
                  theme={["compact"]}
                  alerts={[
                    {
                      level: "warning",
                      message: intl.formatMessage(messages.warning.expiredCreditCard)
                    }
                  ]}
                />
              </Col>
            </Grid>
          </Section>
        </DependOn>

        <Section>
          <Grid>
            <Col>
              <UpdateCreditCardForm
                onSubmit={this._handleSubmitPaymentInfo}
                initialValues={{ paymentCreditCard }}
                creditCardValues={creditCardValues}
                handleClickEdit={this._handleClickCCFormEdit}
                handleClickCancel={this._handleClickCCFormCancel}
                isEditable={isCCFormEditable}
                section="paymentCreditCard"
              />
            </Col>
          </Grid>
        </Section>

        <Section>
          <Grid>
            <Col>
              <UpdateBillingAddressForm
                onSubmit={this._handleSubmitBillingAddress}
                initialValues={{ paymentBillingAddress, homeAddress }}
                section="paymentBillingAddress"
                clickEditCallback={this.props.resetAlerts}
              />
            </Col>
          </Grid>
        </Section>
      </Fragment>
    );
  }

  _handleClickCCFormEdit = () => {
    this.setState({ isCCFormEditable: true });
    this.props.resetAlerts();
  };

  _handleClickCCFormCancel = () => {
    this.setState({ isCCFormEditable: false });
  };

  dismissAlert = () => {
    this.setState({ isAlertDismissed: true });
  };

  addressAttrList = ["line1", "line2", "unit", "city", "postalCode", "province", "country"];


  _handleSubmitPaymentInfo = async (values) => {
    const { intl, updateCustomerPaymentInfo, setAlerts } = this.props;
    try {
      await getMonerisData(this.props);
      const ccHolderName = get(values, "paymentCreditCard.monerisFullname");
      // do not destructure moneris at line 181 since it refers to the old address
      const tempToken = get(this.props.moneris, "monerisResp.dataKey");

      // throw error to show error banner if ccHolderName or tempToken does not exist
      if (!ccHolderName || !tempToken) throw new Error("invalid input");

      await updateCustomerPaymentInfo({ ccHolderName, tempToken });
      setAlerts([
        {
          level: constants.ALERT_LEVEL.success,
          message: intl.formatMessage(messages.success.savePaymentInfo)
        }
      ]);
      window.scrollTo(0, 0);
      this._handleClickCCFormCancel();
    } catch (e) {
      this.props.resetForm(constants.FORM.updateCreditCardForm);
      this.props.setAlerts([
        {
          level: constants.ALERT_LEVEL.error,
          message: intl.formatMessage(messages.error.genericError)
        }
      ]);
    }
  };

  _handleSubmitBillingAddress = async (values) => {
    const { intl, patchCustomerPaymentInfo, user } = this.props;

    const billingAddress = this.mapAddressFieldsFeToBe(values.paymentBillingAddress);
    const originalBillingAddress = pick(
      user.paymentInformation,
      "isHomeAndBilling",
      "billingUnit",
      "billingLine1",
      "billingLine2",
      "billingCity",
      "billingCountry",
      "billingProvince",
      "billingPostalCode"
    );

    const changedValues = diff(originalBillingAddress, billingAddress);

    if (get(changedValues, "billingProvince") || get(changedValues, "billingPostalCode")) {
      changedValues["billingProvince"] = billingAddress.billingProvince
        ? billingAddress.billingProvince
        : "";
      changedValues["billingCountry"] = billingAddress.billingCountry;
      changedValues["billingPostalCode"] = billingAddress.billingPostalCode
        ? billingAddress.billingPostalCode
        : "";
    }

    isEmpty(changedValues)
      ? this.props.resetForm()
      : patchCustomerPaymentInfo(changedValues)
          .then(() => {
            // set success alert message to banner
            this.props.setAlerts([
              {
                level: constants.ALERT_LEVEL.success,
                message: intl.formatMessage(messages.success.saveAddress)
              }
            ]);
          })
          .catch(() => {
            // set success alert message to banner
            this.props.resetForm(constants.FORM.updateBillingAddressForm);
            this.props.setAlerts([
              {
                level: constants.ALERT_LEVEL.error,
                message: intl.formatMessage(messages.error.genericError)
              }
            ]);
          });
  };

  mapFieldsBeToFe = (beFields) => {
    const {
      // cc info
      ccHolderName,
      ccMasked,
      ccExpiryDate,
      ccIsLatest,
      ccIsActive,
      ccType,

      // billing address
      isHomeAndBilling,
      billingLine1,
      billingLine2,
      billingUnit,
      billingCity,
      billingPostalCode,
      billingProvince,
      billingCountry
    } = beFields;

    const isUSA = billingCountry === "USA";

    const paymentCreditCard = {
      monerisFullname: ccHolderName
    };
    const paymentBillingAddress = {
      billingAddressSameAsHome: isHomeAndBilling,
      line1: billingLine1,
      line2: billingLine2,
      unit: billingUnit,
      city: billingCity,
      province: isUSA ? "" : billingProvince,
      postalCode: isUSA ? "" : billingPostalCode,
      state: isUSA ? billingProvince : "",
      zipCode: isUSA ? billingPostalCode : "",
      country: billingCountry
    };

    const creditCardValues = {
      ccMasked,
      ccIsLatest,
      ccIsActive,
      ccType,
      // convert expiry from YYMM to MMYY format
      ccExpiryDate: ccExpiryDate ? ccExpiryDate.substring(2) + ccExpiryDate.substring(0, 2) : ""
    };

    return {
      paymentCreditCard,
      paymentBillingAddress,
      creditCardValues
    };
  };

  mapAddressFieldsBEtoFE = (beFields) => {
    const {
      // Home address

      homeLine1,
      homeLine2,
      homeUnit,
      homeCity,
      homePostalCode,
      homeProvince,
      homeCountry
    } = beFields;

    const isUSA = homeCountry === "USA";

    const homeAddress = {
      line1: homeLine1,
      line2: homeLine2,
      unit: homeUnit,
      city: homeCity,
      province: isUSA ? "" : homeProvince,
      postalCode: isUSA ? "" : homePostalCode,
      state: isUSA ? homeProvince : "",
      zipCode: isUSA ? homePostalCode : "",
      country: homeCountry
    };
    return { homeAddress };
  };

  mapAddressFieldsFeToBe = (feFields) => {
    const {
      billingAddressSameAsHome,
      unit,
      line1,
      line2,
      city,
      country,
      province,
      state,
      postalCode,
      zipCode
    } = feFields;
    const isUSA = country === "USA";
    return {
      isHomeAndBilling: !!billingAddressSameAsHome,
      billingUnit: unit,
      billingLine1: line1,
      billingLine2: line2,
      billingCity: city,
      billingCountry: country,
      billingProvince: isUSA ? state : province,
      billingPostalCode: isUSA ? zipCode : postalCode
    };
  };
}

AccountPaymentInfo.propTypes = {
  intl: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  setAlerts: PropTypes.func.isRequired,
  destroyForm: PropTypes.func.isRequired,
  getCustomerPaymentInfo: PropTypes.func.isRequired,
  getCustomerPersonalInfo: PropTypes.func.isRequired,
  updateCustomerPaymentInfo: PropTypes.func.isRequired,
  patchCustomerPaymentInfo: PropTypes.func.isRequired,
  resetAlerts: PropTypes.func.isRequired,
  moneris: PropTypes.object
};

AccountPaymentInfo.defaultProps = {
  moneris: { monerisResp: { dataKey: "" } }
};

const mapStateToProps = (state) => ({
  user: selectors.getUser(state),
  moneris: state.moneris
});

const mapDispatchToProps = {
  getCustomerPaymentInfo: userActions.getCustomerPaymentInfo,
  getCustomerPersonalInfo: userActions.getCustomerPersonalInfo,
  updateCustomerPaymentInfo: userActions.updateCustomerPaymentInfo,
  patchCustomerPaymentInfo: userActions.patchCustomerPaymentInfo,
  setAlerts: bannerActions.setAlerts,
  resetAlerts: bannerActions.resetAlerts,
  resetForm: reset,
  destroyForm: destroy
};

export const IntlAccountPersonalInfo = injectIntl(AccountPaymentInfo);
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AccountPaymentInfo));
