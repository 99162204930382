import React, { PureComponent, createRef } from "react";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import cn from "classnames";
import get from "lodash/get";
import "./parkingMarker.scss";

import { ParkingIcon } from "../../components/icons";
import { CloseIcon, PersonIcon, PinPadIcon, ParkingSignIcon, ParkingInfoIcon } from "./iconStrings";
import {calcCrow, convertNumberToMetric, getWalkingTime} from "./util";
import {messages} from "../../constants";
class ParkingMarker extends PureComponent {
  infoWindow = createRef();

  componentDidUpdate(prevProps, prevState) {
    const { isInfoWindowOpen, adjustPan, lat, lng } = this.props;
    // auto pan the info window
    if (prevProps.isInfoWindowOpen !== isInfoWindowOpen && isInfoWindowOpen) {
      adjustPan(this.infoWindow.current, lat, lng);
    }
  }

  render() {
    const { intl, onClick, onClose, isInfoWindowOpen, parking, lat, lng, userLocation } = this.props;

    const properties = get(parking, "properties", {});

    const { address, pin, spaces, type, special_instructions } = properties;

    const markerClasses = cn("parking-marker", {
      "parking-marker_on-top": isInfoWindowOpen
    });
    const infoWindowClasses = cn("parking-details", {
      "parking-details_on-top": isInfoWindowOpen
    });

    const distance =
      (userLocation && lat && lng && calcCrow(userLocation.lat, userLocation.lng, lat, lng)) ||
      null;

    return (
      <div className="parking-container">
        <div className={markerClasses} onClick={onClick}>
          <ParkingIcon />
        </div>

        {isInfoWindowOpen && (
          <div className={infoWindowClasses} ref={this.infoWindow}>
            <div className="iw_container-close" onClick={onClose}>
              <img src={CloseIcon} alt="close icon" draggable="false" />
            </div>
            <div className="parking-details__brief">
              <div className="parking-details__brief__title">{address}</div>
              <div className="parking-details__brief__distance">
                <img src={PersonIcon} alt="person icon" draggable="false" />
                {distance < 1001 ? (<span>
                    {intl.formatMessage(messages.maps.walkingDistance, {
                      distance: convertNumberToMetric(distance),
                      walkingTime: getWalkingTime(distance)
                    })}
                  </span>) : (<span>
                    {intl.formatMessage(messages.maps.distance, {
                      distance: convertNumberToMetric(distance)
                    })}
                  </span>)

                }

              </div>
              <div className="parking-details__brief__pin">
                <img src={PinPadIcon} alt="pin pad icon" draggable="false" />
                <span>PIN: {pin ? `${pin} to enter/exit` : "n/a"}</span>
              </div>
            </div>
            <div className="parking-details_directions">
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {intl.formatMessage(messages.maps.descriptionInfo)}
            </a>
          </div>
            <div className="parking-details__notes">
              {spaces && type && (
                <div className="parking-details__notes__space">
                  <img src={ParkingSignIcon} alt="reserve spot icon" draggable="false" />
                  <span>{this._renderSpotString(spaces, type)}</span>
                </div>
              )}
              <div className="parking-details__notes__instructions">
                <img src={ParkingInfoIcon} alt="parking instruction icon" draggable="false" />
                {special_instructions && special_instructions.length ? (
                  <span>
                    <ul>{this._renderInstructions(special_instructions)}</ul>
                  </span>
                ) : (
                  <span>No special instructions</span>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  _renderSpotString = (spaces, type) => {
    const SPACES_TYPE = {
      RESERVED_SPOT: "Reserved Spot",
      RESERVED_SPOTS: "Reserved Spots",
      SPOT_AND_OVERFLOW: "Reserved Spots + Overflow",
      ALL_OVERFLOW: "Overflow all spots",
      SHARED_SPOT_ON_STREET: "Shared Spots on Street"
    };

    switch (type) {
      case SPACES_TYPE.RESERVED_SPOTS:
        return (
          <span>
            <b>{spaces}</b> Reserved Spots Available
          </span>
        );
      case SPACES_TYPE.RESERVED_SPOT:
        return (
          <span>
            <b>{spaces}</b> Reserved Spot Available
          </span>
        );
      case SPACES_TYPE.SPOT_AND_OVERFLOW:
        return (
          <span>
            <b>{spaces}</b> Reserved Spots Available + (Overflow)
          </span>
        );
      case SPACES_TYPE.ALL_OVERFLOW:
        return "Overflow All Spots";
      case SPACES_TYPE.SHARED_SPOT_ON_STREET:
        return (
          <span>
            <b>{spaces}</b> Shared Spots on Street
          </span>
        );
      default:
        return;
    }
  };

  _renderInstructions = instructions =>
    (instructions || []).map(instruction => (
      <li key={instruction}>
        <span>{instruction}</span>
      </li>
    ));
}

ParkingMarker.propTypes = {
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  adjustPan: PropTypes.func,
  isInfoWindowOpen: PropTypes.bool,
  parking: PropTypes.object.isRequired,
  userLocation: PropTypes.object
};

ParkingMarker.defaultProps = {
  onClick: () => {},
  onClose: () => {},
  adjustPan: () => {},
  isInfoWindowOpen: false,
  userLocation: undefined
};

export default injectIntl(ParkingMarker);
