import React from "react";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import { GpsIcon } from "./iconStrings";
import "./userLocationMarker.scss";

const UserLocationMarker = props => {
  const { onClick } = props;

  return (
    <div onClick={onClick}>
      <img className="user-location-marker" src={GpsIcon} alt="close icon" draggable="false" />
    </div>
  );
};

UserLocationMarker.propTypes = {
  onClick: PropTypes.func
};

UserLocationMarker.defaultProps = {
  onClick: () => {}
};

export default injectIntl(UserLocationMarker);
