import React from "react";
import { PagesContainer } from "../../containers";
import { NewPrivateRegistration } from "../../screens";

const PrivateRegistration = props => {
  return (
    <PagesContainer {...props}>
      <NewPrivateRegistration {...props} />
    </PagesContainer>
  );
};

PrivateRegistration.defaultProps = {};

export default PrivateRegistration;
