import React from "react";

export const EvoPin = () => (
  <svg
    width="28px"
    height="42px"
    viewBox="0 0 28 42"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>car pin</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <polygon
        id="path-1"
        points="6.19073404 0.0391055556 6.19073404 6.48027222 0.0327332028 6.48027222 0.0327332028 0.0391055556 6.19073404 0.0391055556"
      />
      <polygon
        id="path-3"
        points="0.0442214257 7.94444444 7.23585958 7.94444444 7.23585958 0.0552555556 0.0442214257 0.0552555556"
      />
    </defs>
    <g id="DESIGN---Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Mobile---Find-A-Car" transform="translate(-122.000000, -519.000000)">
        <g id="map-icons" transform="translate(22.000000, 269.000000)">
          <g id="cars" transform="translate(100.000000, 0.000000)">
            <g id="map/car_pin" transform="translate(0.000000, 250.000000)">
              <g id="car-pin">
                <g id="pin-vehicle" fill="#242424">
                  <path
                    d="M0.209999967,13.9097744 C0.209999967,24.3045113 14.035,39.6491228 14.035,39.6491228 C14.035,39.6491228 27.86,24.3045113 27.86,13.9097744 C27.86,6.25726817 21.668375,0.0501253133 14.035,0.0501253133 C6.40162497,0.0501253133 0.209999967,6.25726817 0.209999967,13.9097744 Z"
                    id="Path"
                  />
                </g>
                <ellipse
                  id="Oval"
                  fillOpacity="0.1"
                  fill="#4A4A4A"
                  cx="14"
                  cy="39.5"
                  rx="10"
                  ry="2.5"
                />
              </g>
              <g id="Page-1" transform="translate(2.500000, 10.000000)">
                <g id="Group-3" transform="translate(15.827510, 1.389222)">
                  <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-1" />
                  </mask>
                  <g id="Clip-2" />
                  <path
                    d="M3.11176814,5.32782778 C2.02152889,5.32782778 1.13455097,4.39999444 1.13455097,3.25960556 C1.13455097,2.11916111 2.02152889,1.19143889 3.11176814,1.19143889 C4.2020074,1.19143889 5.08898531,2.11916111 5.08898531,3.25960556 C5.08898531,4.39999444 4.2020074,5.32782778 3.11176814,5.32782778 M3.11176814,0.0391055556 C1.41116061,0.0391055556 0.0327332028,1.48105 0.0327332028,3.25955 C0.0327332028,5.03832778 1.41116061,6.48027222 3.11176814,6.48027222 C4.81211011,6.48027222 6.19074997,5.03832778 6.19074997,3.25955 C6.19074997,1.48105 4.81211011,0.0391055556 3.11176814,0.0391055556"
                    id="Fill-1"
                    fill="#00BBE3"
                    mask="url(#mask-2)"
                  />
                </g>
                <g id="Group-6" transform="translate(8.763554, 0.000333)">
                  <mask id="mask-4" fill="white">
                    <use xlinkHref="#path-3" />
                  </mask>
                  <g id="Clip-5" />
                  <polygon
                    id="Fill-4"
                    fill="#00BBE3"
                    mask="url(#mask-4)"
                    points="6.72050948 0.0552555556 3.62570014 5.62225556 1.3422897 1.54703333 1.21588207 1.54703333 1.21582896 1.54697778 0.0442214257 1.54697778 3.63462303 7.94447778 7.23585958 1.54697778"
                  />
                </g>
                <path
                  d="M5.78533202,2.58071111 C6.68431334,2.58071111 7.44414005,3.21176667 7.68335852,4.07248889 L3.88656194,4.07248889 C4.1257273,3.21165556 4.88629758,2.58071111 5.78533202,2.58071111 M8.86426073,4.64882222 C8.86426073,2.87021111 7.48567399,1.42826667 5.78538513,1.42826667 C4.2729019,1.42826667 3.01578332,2.56887778 2.75601033,4.07248889 L5.31124498e-06,4.07248889 L0.66815993,5.20537778 L2.75223935,5.20537778 C3.00431103,6.71887778 4.26620973,7.86948889 5.78538513,7.86948889 C7.04314105,7.86948889 8.12472298,7.08026667 8.60262881,5.9496 L8.45683513,5.9496 L8.35693061,5.9496 L8.11266646,5.9496 L7.856452,5.9496 L7.32102539,5.9496 C6.95826736,6.41743333 6.40472941,6.7171 5.78533202,6.7171 C4.87944607,6.7171 4.11436123,6.07626667 3.88141003,5.20537778 L8.81837158,5.20548889 C8.84848634,5.02471111 8.86426073,4.83865556 8.86426073,4.64882222"
                  id="Fill-7"
                  fill="#00BBE3"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

EvoPin.displayName = "EvoPin";

export default EvoPin;
