import { defineMessages } from "react-intl";

const namespace = "ebikeConfirmation";
const ebikeConfirmation = defineMessages({
  confirmationContent: {
    id: `${namespace}.confirmationContent`,
    description: "Content for Ebike Only Registration",
    defaultMessage:
      "If you're new to cycling or e-bikes, we got you.  Check out {hubLink} for important rules of the road. Remember to ride within your level of experience and comfort."
  },

  confirmationInbox: {
    id: `${namespace}.confirmationInbox`,
    description: "Subheader for Ebike Confirmation Page",
    defaultMessage: "Watch your inbox for more info on how to e-bike/e-scooter with us."
  },

  confirmationSameEvoApp: {
    id: `${namespace}.confirmationSameEvoApp`,
    description: "Subheader 2 for Ebike Confirmation Page",
    defaultMessage: "Download the Evo App to find and unlock Evolve E-Bikes and E-Scooters."
  },

  confirmationInterestedDriving: {
    id: `${namespace}.confirmationInterestedDriving`,
    description: "Subheader 3 for Ebike Confirmation Page",
    defaultMessage:
      "Interested in driving? Simply contact us at {email} to submit your driver's licence, and we'll make sure you're all set."
  },

  BackButton: {
    id: `${namespace}.BackButton`,
    description: "Instructions for user to use the Back Button to add evo",
    defaultMessage:
      "Changed your mind and want to sign up for car share too? We’ll need a bit more info, just click back to start again (don’t forget to unselect the E-bike only option)."
  },
  personalInfoSubheader: {
    id: `${namespace}.personalInfoSubheader`,
    description: "Subheader for Ebike Only Personal Info Page",
    defaultMessage: "We need a few more details about you."
  }
});

export default ebikeConfirmation;
