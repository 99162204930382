/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent, Fragment } from "react";
import { compose } from "redux";
import { reduxForm, Field, reset } from "redux-form";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Input } from "../../../components";
import { Button } from "../../../elements";
import { messages, constants } from "../../../constants";
import { validationBuilder, validations } from "../../../containers/form";

class UpdateEmailForm extends PureComponent {
  generateValidationFunction = (validation, spec) => {
    return value => validationBuilder(this.props, validation, spec)(value);
  };

  rulesEmail = [
    this.generateValidationFunction(
      validations.required,
      this.props.intl.formatMessage(messages.field.EmailAddress)
    ),
    this.generateValidationFunction(validations.email)
  ];

  handleClickEdit = e => {
    e.preventDefault();
    this.props.toggleUpdateMode();
  };

  handleClickCancel = () => {
    this.props.reset(formSettings.form);
    this.props.toggleUpdateMode();
  };

  render() {
    const { intl, handleSubmit, valid, pristine, submitting, loading, updateMode } = this.props;
    return (
      <form autoComplete="on" noValidate onSubmit={handleSubmit}>
        <Field
          type="email"
          name="emailAddress"
          label={intl.formatMessage(messages.title.emailAddress)}
          disabled={!updateMode}
          validate={this.rulesEmail}
          component={Input}
        />
        {updateMode ? (
          <Fragment>
            <Button
              loading={submitting}
              disabled={!valid || pristine || submitting}
              type="submit"
              theme={["cta"]}
            >
              {<span>{intl.formatMessage(messages.button.save)}</span>}
            </Button>
            <a onClick={this.handleClickCancel} theme={["cta"]}>
              {<span>{intl.formatMessage(messages.button.cancel)}</span>}
            </a>
          </Fragment>
        ) : (
          <Button
            type="button"
            onClick={this.handleClickEdit}
            theme={["cta"]}
            loading={loading}
            disabled={loading}
          >
            {<span>{intl.formatMessage(messages.button.changeEmail)}</span>}
          </Button>
        )}
      </form>
    );
  }
}

UpdateEmailForm.propTypes = {
  intl: PropTypes.object.isRequired,
  updateMode: PropTypes.bool,
  loading: PropTypes.bool,
  toggleUpdateMode: PropTypes.func,
  reset: PropTypes.func.isRequired
};

UpdateEmailForm.defaultProps = {
  updateMode: false,
  loading: false,
  toggleUpdateMode: () => {}
};

const formSettings = {
  form: constants.FORM.updateEmailForm,
  enableReinitialize: true,
  destroyOnUnmount: true
};

const mapDispatchToProps = {
  reset
};

const enhancer = compose(
  injectIntl,
  reduxForm(formSettings),
  connect(
    null,
    mapDispatchToProps
  )
);

export default enhancer(UpdateEmailForm);
