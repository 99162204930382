import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import { messages } from "../../constants";
import { Section, Grid, Col } from "../../components";
import { Title, Txt, Button, LogoCAA } from "../../elements";

const ErrorScreen = props => {
  const { intl } = props;

  return (
    <Section theme={["div"]}>
      <Grid>
        <Col size="2-3" min="sm">
          <Title priority={1} type={["strong"]}>
            {intl.formatMessage(messages.title.evoStalled)}
          </Title>
          <Title priority={2}>{intl.formatMessage(messages.title.pageNotFound)}</Title>
          <Txt theme={["bottom-gutter"]}>{intl.formatMessage(messages.text.clickToGetGoing)}</Txt>
          <a href="http://www.evo.ca" target="_blank" rel="noopener noreferrer">
            <Button theme={["cta"]}>{intl.formatMessage(messages.button.backtoHome)}</Button>
          </a>
        </Col>
        <Col size="1-3" min="sm">
          <Title priority={2}>{intl.formatMessage(messages.title.aboutBCAA)}</Title>
          <Txt theme={["bottom-gutter"]}>
            {intl.formatMessage(messages.text.aboutBCAAContent)}
            <div>
              <a href="https://www.bcaa.com" target="_blank" rel="noopener noreferrer">
                www.bcaa.com
              </a>
            </div>
          </Txt>
          <LogoCAA theme={[`small`]} />
        </Col>
      </Grid>
    </Section>
  );
};

ErrorScreen.propTypes = {
  intl: PropTypes.object.isRequired
};

export default injectIntl(ErrorScreen);
