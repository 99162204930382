import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Txt } from "../../elements";

import "./index.scss";

const InfoBlock = ({ title, infoBody, className, theme }) => {
  const infoCSS = cn(
    "block-info",
    className,
    theme ? theme.map(css => `block-info--${css}`).join(" ") : ""
  );
  return (
    <div className={infoCSS}>
      <Txt>{title}</Txt>
      <b>{infoBody}</b>
    </div>
  );
};

InfoBlock.defautProps = {
  className: ""
};

InfoBlock.propTypes = {
  title: PropTypes.string.isRequired,
  infoBody: PropTypes.string.isRequired,
  className: PropTypes.string,
  theme: PropTypes.arrayOf(PropTypes.string)
};

export default InfoBlock;
