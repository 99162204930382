import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import get from "lodash/get";

import { Grid, Col, Section } from "../../../../components";
import { messages } from "../../../../constants";
import { Datalist } from "../../../../elements";

const formatByValue = (kvArray, target) => {
  return kvArray
    ? get(kvArray.find(element => element && element.value === target), "label", "")
    : "";
};

export const StaticAddress = props => (
  <Section size="2-3" min="md" theme={["info", "div"]}>
    <Grid>
      <Col size="1-4" min="sm">
        <Datalist
          title={props.intl.formatMessage(messages.address.unitNumber, { type: props.type })}
          data={props.unit}
        />
      </Col>
      <Col size="3-4" min="sm">
        <Datalist
          title={props.intl.formatMessage(messages.address.addressLine, { type: props.type })}
          data={props.line1}
        />
      </Col>

      <Col>
        <Datalist
          title={props.intl.formatMessage(messages.address.city, { type: props.type })}
          data={props.city}
        />
      </Col>
    </Grid>

    <Grid>
      <Col size="1-2" min="sm">
        <Datalist
          title={props.intl.formatMessage(messages.address.country, { type: props.type })}
          data={formatByValue(props.dropdownOptions.countries, props.country)}
        />
      </Col>

      <Col size="1-2" min="sm">
        {props.province && props.country !== "USA" && (
          <Datalist
            title={props.intl.formatMessage(messages.address.province, { type: props.type })}
            data={formatByValue(props.dropdownOptions.provinces, props.province)}
          />
        )}
        {(props.province || props.state) && props.country === "USA" && (
          <Datalist
            title={props.intl.formatMessage(messages.address.state, { type: props.type })}
            data={formatByValue(props.dropdownOptions.states, props.province || props.state)}
          />
        )}
      </Col>

      <Col size="1-4" min="sm">
        {props.postalCode && props.country !== "USA" && (
          <Datalist
            title={props.intl.formatMessage(messages.address.postalCode, { type: props.type })}
            data={props.postalCode}
          />
        )}
        {(props.postalCode || props.zipCode) && props.country === "USA" && (
          <Datalist
            title={props.intl.formatMessage(messages.address.zipCode, { type: props.type })}
            data={props.postalCode || props.zipCode}
          />
        )}
      </Col>
    </Grid>
  </Section>
);

StaticAddress.propTypes = {
  intl: PropTypes.object.isRequired,
  type: PropTypes.string,
  unit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  line1: PropTypes.string,
  line2: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  province: PropTypes.string,
  state: PropTypes.string,
  postalCode: PropTypes.string,
  zipCode: PropTypes.string,
  postalZipCode: PropTypes.string,
  dropdownOptions: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.any }))
  )
};

const ConnectedStaticAddress = injectIntl(StaticAddress);

const mapStateToProps = state => ({
  dropdownOptions: state.settings.dropdownOptions
});

export default connect(mapStateToProps)(ConnectedStaticAddress);
