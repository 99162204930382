import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import MobileDetect from "mobile-detect";
import { injectIntl } from "react-intl";

import { Title, Txt } from "../../elements";
import { AppStore, GooglePlay } from "../../components/icons";
import { messages } from "../../constants";

class RedirectToNativeAppScreen extends Component {
  static propTypes = {
    intl: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    const md = new MobileDetect(window.navigator.userAgent);
    this.state = { md };
  }

  render() {
    const { intl } = this.props;
    return (
      <div>
        <Title priority={1}>{intl.formatMessage(messages.title.evoApp)}</Title>
        <Txt theme={["bottom-gutter"]}>{intl.formatMessage(messages.title.evoAppContent)}</Txt>
        {this.renderDownloadBadges()}
      </div>
    );
  }

  renderDownloadBadges = () => {
    const { md } = this.state;
    const { intl } = this.props;
    const appStore = (
      <a
        href="https://itunes.apple.com/ca/app/evo-car-share/id972393570?mt=8"
        target="_blank"
        rel="noopener noreferrer"
      >
        <AppStore locale={intl.locale} />
      </a>
    );
    const googlePlay = (
      <a href="https://play.google.com/store/apps/details?id=com.vulog.android.carsharing.vancouver&hl=en">
        <GooglePlay locale={intl.locale} />
      </a>
    );
    if (md.os() === "iOS") {
      return appStore;
    } else if (md.os() === "AndroidOS") {
      return googlePlay;
    } else {
      return (
        <Fragment>
          <div>{appStore}</div>
          <div>{googlePlay}</div>
        </Fragment>
      );
    }
  };
}

export default injectIntl(RedirectToNativeAppScreen);
