import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";

import { store, history } from "./store";
import Root from "./pages/Root";
import * as serviceWorker from "./serviceWorker";
import {fetchInterceptor} from "./util/fetchInterceptor";

window.fetch = fetchInterceptor;

require("./favicon.ico"); // Tell webpack to load favicon.ico
require("./styles/global.scss");

ReactDOM.render(<Root store={store} history={history} />, document.getElementById("app"));

serviceWorker.unregister();
