import {defineMessages} from "react-intl";

const namespace = `imageCapture`;
const imageCapture = defineMessages({
  requirement1: {
    id: `${namespace}.requirement1`,
    description: `Image Capture requirement list 1`,
    defaultMessage: `Use a brightly lit area`
  },
  requirement2: {
    id: `${namespace}.requirement2`,
    description: `Image Capture requirement list 2`,
    defaultMessage: `No flash`
  },
  requirement3: {
    id: `${namespace}.requirement3`,
    description: `Image Capture requirement list 3`,
    defaultMessage: `Have a clear background`
  },
  requirement4: {
    id: `${namespace}.requirement4`,
    description: `Image Capture requirement list 4`,
    defaultMessage: `Take pic in {orientation} orientation`
  },
  takePicButton: {
    id: `${namespace}.takePic.button`,
    description: `Image Capture take pic button text`,
    defaultMessage: `Take pic`
  },
  retakePicButton: {
    id: `${namespace}.retakePic.button`,
    description: `Image Capture retake pic button text`,
    defaultMessage: `Retake pic`
  },
  acceptPicButton: {
    id: `${namespace}.acceptPic.button`,
    description: `Image Capture accept pic button text`,
    defaultMessage: `OK`
  },
  uploadPicButton: {
    id: `${namespace}.uploadPic.button`,
    description: `Image Capture upload pic button text`,
    defaultMessage: `Upload pic`
  },
  driversLicenseFront: {
    id: `${namespace}.driversLicenseFront`,
    description: `Add Drivers Licence Front text`,
    defaultMessage:
      "Upload a clear photo of the <b>front</b> of your driver's licence.  We'll use this to verify your identity."
  },
  driversLicenseBack: {
    id: `${namespace}.driversLicenseBack`,
    description: `Add Drivers Licence Back text`,
    defaultMessage: `Upload a clear photo of the <b>back</b> of your driver's licence.  We'll use this to verify your identity.`
  },
  selfie: {
    id: `${namespace}.selfie`,
    description: `Add Drivers Licence Back text`,
    defaultMessage: `Snap a selfie to help us confirm your identity. We’ll never make this image visible and it won’t be used again once verified.`
  },
  landscape: {
    id: `${namespace}.landscape`,
    description: `landscape`,
    defaultMessage: `landscape`
  },
  portrait: {
    id: `${namespace}.portrait`,
    description: `portrait`,
    defaultMessage: `portrait`
  },
  driversLicenseSubheader: {
    id: `${namespace}.driversLicenseSubheader`,
    description: `drivers Licence Sub header`,
    defaultMessage: `Review your driver's licence details to make sure we got it right.`
  }
});

export default imageCapture;
