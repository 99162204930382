import React, { Component } from "react";
import { oneOfType, arrayOf, node } from "prop-types";

/**
 *
 *
 * @class ErrorBoundary
 * @extends {Component}
 */
export default class ErrorBoundary extends Component {
  static propTypes = {
    children: oneOfType([arrayOf(node), node])
  };

  state = { error: null };

  componentDidCatch(error) {
    this.setState({ error });
  }

  render() {
    if (this.state.error) {
      return (
        <div style={{ border: "1px solid red", padding: "1em" }}>
          <h1>Oops.</h1>
          <div>
            <p>We&apos;re sorry — something&apos;s gone wrong.</p>
          </div>
        </div>
      );
    }
    // when there's not an error, render children untouched
    return this.props.children;
  }
}
