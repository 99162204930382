import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import DOMPurify from "dompurify";
import Parser from "html-react-parser";
import "./table.scss";

import { Tooltip } from "../../elements";

const renderCell = items =>
  items.map((item, idx) => {
    const CustomTag = item.tag || "td";
    const sanitized =
      typeof item.data === "string" ? Parser(DOMPurify.sanitize(item.data)) : item.data;

    return (
      <CustomTag
        key={idx}
        className={item.theme ? item.theme.map(css => `td--${css}`).join(" ") : ""}
      >
        {sanitized}
        {item.tooltip && (
          <Tooltip>
            <small>{item.tooltip}</small>
          </Tooltip>
        )}
      </CustomTag>
    );
  });

const renderRow = items =>
  items.map((item, idx) => (
    <tr key={idx} className={item.theme ? item.theme.map(css => `tr--${css}`).join(" ") : ""}>
      {renderCell(item.row)}
    </tr>
  ));

const Table = props => {
  const { head, body, foot, theme, className } = props;
  const tableCSS = cn("table", className, theme ? theme.map(css => `table--${css}`).join(" ") : "");

  return (
    <div className="table__wrapper">
      <table className={tableCSS}>
        {head && <thead>{renderRow(head)}</thead>}
        <tbody>{renderRow(body)}</tbody>
        {foot && <tfoot>{renderRow(foot)}</tfoot>}
      </table>
    </div>
  );
};

Table.propTypes = {
  head: PropTypes.arrayOf(PropTypes.object),
  body: PropTypes.arrayOf(PropTypes.object),
  foot: PropTypes.arrayOf(PropTypes.object),
  theme: PropTypes.array,
  className: PropTypes.string
};

Table.defaultProps = {
  theme: [],
  className: ""
};

export default Table;
