import React, { PureComponent } from "react";
import classnames from "classnames";
import ReactRecaptcha from "react-recaptcha";
import PropTypes from "prop-types";

import { Txt } from "../../elements";

import "./recaptcha.scss";

const CAPTCHA_EXPIRED_VALUE = -1;

class RecaptchaComponent extends PureComponent {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.reset && !this.props.reset) {
      this.reset();
    }
  }

  captureResponse = response => {
    this.props.input.onChange(response);
    this.setState({ expired: false });
  };

  expire = () => {
    this.props.input.onChange(CAPTCHA_EXPIRED_VALUE);
  };

  reset = () => {
    this.recaptchaInstance.reset();
  };

  render() {
    const {
      meta: { touched, error }
    } = this.props;
    return (
      <div className={classnames("recaptcha")}>
        <ReactRecaptcha
          ref={r => (this.recaptchaInstance = r)}
          sitekey={
            process.env.REACT_APP_RECAPTCHA_SITE_KEY || "6LeacY8UAAAAAOwkgDAbPp3_CyUJN87Pab0QU4_T"
          }
          verifyCallback={this.captureResponse}
          expiredCallback={this.expire}
        />
        {touched && error && (
          <Txt theme={["error"]}>
            <small>{error}</small>
          </Txt>
        )}
      </div>
    );
  }
}

RecaptchaComponent.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
  reset: PropTypes.func,
  onChange: PropTypes.func
};

export default RecaptchaComponent;
