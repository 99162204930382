import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

const CardFooter = props => {
  const { children, theme } = props;
  if (!children) return null;

  const className = cn(
    "card__footer",
    theme ? theme.map(css => `card__footer--${css}`).join(" ") : ""
  );

  return <div className={className}>{children}</div>;
};

CardFooter.defaultProps = {
  theme: [],
  children: null
};

CardFooter.propTypes = {
  theme: PropTypes.array,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string
  ])
};

export default CardFooter;
