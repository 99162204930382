import { constants } from "../constants";

export const DEFAULT_STATE = { payments: { results: [] } };

const var1 = (state = DEFAULT_STATE, { type, payload } = {}) => {
  switch (type) {
    case constants.BILLING_BALANCES_INFO:
      return { ...state, balance: payload };
    case constants.RESET_PAYMENTS:
      return {
        ...state,
        payments: { results: [] }
      };
    case constants.BILLING_PAYMENTS:
      return {
        ...state,
        payments: {
          ...payload,
          results: payload.results
        }
      };
    case constants.FREE_MINUTES:
      return { ...state, freeMinutes: payload };
    case constants.FREE_MINUTES_SUMMARY:
      return { ...state, freeMinutesSummary: payload };
    case constants.BILLING_PAYMENT_DETAILS: {
      const { paymentId, paymentDetails } = payload;
      return { ...state, paymentDetails: { ...state.paymentDetails, [paymentId]: paymentDetails } };
    }
    case constants.BILLING_TRANSACTION_DETAILS: {
      const { transcalcId, transactionDetails } = payload;
      return {
        ...state,
        transactionDetails: { ...state.transactionDetails, [transcalcId]: transactionDetails }
      };
    }
    case constants.USER_DELETE:
      return DEFAULT_STATE;
    default:
      return state;
  }
};

export default var1;
