import React from "react";
import PropTypes from "prop-types";
import { FormSectionWrapper } from "../";
import { injectIntl } from "react-intl";
// import { Field } from 'redux-form';
import { Address, PersonalUpdate } from "../../fieldGroups";

// import { Dropdown, Section, Grid, Col, Input } from '../../../../components';
import { messages } from "../../../../constants";

const PersonalInfoUpdate = props => (
  <div>
    <FormSectionWrapper {...props}>
      <PersonalUpdate {...props} />
      <Address {...props} type={props.intl.formatMessage(messages.title.HomeAddress)} />
    </FormSectionWrapper>
  </div>
);

PersonalInfoUpdate.propTypes = {
  intl: PropTypes.object.isRequired
};

export default injectIntl(PersonalInfoUpdate);
