import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Redirect, Link } from "react-router-dom";
import { injectIntl } from "react-intl";

import { messages, constants } from "../../constants";
import { Button } from "../../elements";
import { Section, Col } from "../../components";
import { Intro } from "../../containers";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import * as selectors from "../../selectors";

class Landing extends PureComponent {
  static displayName = "Landing";

  render() {
    const { intl, pushNav, isAuthenticated } = this.props;
    if (isAuthenticated) return <Redirect to={constants.ROUTE_ACCOUNT} />;

    return (
      <Intro region="bcaa" title={intl.formatMessage(messages.title.Home)}>
        <Col>
          <Button onClick={() => pushNav(constants.ROUTE_SIGNIN)} theme={[`large`]}>
            {intl.formatMessage(messages.button.login)}
          </Button>
        </Col>

        <Col>
          <Button
            onClick={() => pushNav(constants.ROUTE_REGISTRATION)}
            theme={[`large`, `inverse`]}
          >
            {intl.formatMessage(messages.button.signUp)}
          </Button>
        </Col>

        <Col>
          <Section theme={[`div`]}>
            <Link to={constants.ROUTE_RESET_PASSWORD} className="is-underlined">
              {intl.formatMessage(messages.field.forgotPassword)}
            </Link>
          </Section>
        </Col>
      </Intro>
    );
  }
}

Landing.propTypes = {
  pushNav: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: selectors.isLoggedIn(state)
});

const mapDispatchToProps = dispatch => ({
  pushNav: link => dispatch(push(link))
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Landing)
);
