import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { Txt } from "../../elements";
import { SettingsIcon, LocationIcon } from "../../components/icons";
import { CheckboxWithoutRedux } from "../../components";
import { injectIntl } from "react-intl";
import { messages } from "../../constants";

const filterSettings = [
  {
    message: messages.title.cars,
    filterField: "cars"
  },
  {
    message: messages.title.parking,
    filterField: "parking"
  },
  {
    message: messages.title.homezone,
    filterField: "homezone"
  }
];

export const MapControlContainer = ({
  settingsAction,
  locationAction,
  shouldOpenMapFilters,
  toggleFilter,
  filters,
  intl
}) => (
  <div className="controller-container" data-testid="controller-container">
    <div
      className={cn("controller-icon", { "controller-icon_extended": shouldOpenMapFilters })}
      onClick={settingsAction}
      data-testid="setting-map-control-container"
    >
      <SettingsIcon inverted={shouldOpenMapFilters} />
    </div>
    <div
      className="controller-icon"
      onClick={locationAction}
      data-testid="location-map-control-container"
    >
      <LocationIcon />
    </div>
    {shouldOpenMapFilters && (
      <div className="filters-container">
        <Txt priority={2} theme={["large"]}>
          {intl.formatMessage(messages.title.filterBy)}
        </Txt>
        {filterSettings.map(({ message, filterField }) => (
          <div key={filterField} className="filters-container__filter">
            <Txt theme={["large", "upper"]}>{intl.formatMessage(message)}</Txt>

            <CheckboxWithoutRedux
              checked={filters[filterField]}
              name={filterField}
              onChange={() => toggleFilter(filterField)}
              theme={["toggle"]}
              className="filter-input"
            />
          </div>
        ))}
      </div>
    )}
  </div>
);

MapControlContainer.defaultProps = {
  intl: PropTypes.object.isRequired,
  shouldOpenMapFilters: false,
  settingsAction: () => {},
  locationAction: () => {},
  toggleFilter: () => {},
  filters: {
    cars: true,
    parking: true,
    homezone: true
  }
};

MapControlContainer.propTypes = {
  shouldOpenMapFilters: PropTypes.bool,
  settingsAction: PropTypes.func,
  locationAction: PropTypes.func,
  toggleFilter: PropTypes.func,
  filters: PropTypes.shape({
    cars: PropTypes.bool,
    parking: PropTypes.bool,
    homezone: PropTypes.bool
  })
};

MapControlContainer.displayName = "MapControlContainer";
export default injectIntl(MapControlContainer);
