import { constants } from "../constants";

export const DEFAULT_STATE = {
  selectedIndex: {selectedIndex: 0,selectedSubIndex: 0},
  validIndex: -1, // no valid steps
  editNameMode: false,
  paymentSummary: {},
  promoCode: {},
  validateMembership: null,
  registrationId: -1
};

const var1 = (state = DEFAULT_STATE, { type, payload } = {}) => {
  switch (type) {
    case constants.FLOW_SELECTED_INDEX:
      return { ...state, selectedIndex: payload};
    case constants.FLOW_VALID_INDEX:
      return { ...state, validIndex: payload.validIndex };
    case constants.FLOW_EDIT_NAME:
      return { ...state, editNameMode: payload.editNameMode };
    case constants.FLOW_PAYMENT_SUMMARY:
      return { ...state, paymentSummary: payload.paymentSummary };
    case constants.FLOW_PROMO_CODE:
      return { ...state, promoCode: payload };
    case constants.FLOW_EVOLVE_ONLY:
      return { ...state, evolveOnly: payload };
    case constants.FLOW_DRIVERS_LICENSE_FRONT:
      return { ...state, driversLicenseFront: payload };
    case constants.FLOW_DRIVERS_LICENSE_BACK:
      return { ...state, driversLicenseBack: payload };
    case constants.FLOW_SELFIE:
      return { ...state, selfie: payload };
    case constants.FLOW_REGISTER:
        return { ...state, register: payload };
    case constants.FLOW_VALIDATE_MEMBERSHIP:
      return {...state, validateMembership: payload};
    case constants.FLOW_REGISTRATION_ID:
      return { ...state, registrationId: payload.registrationId };
    default:
      return state;
  }
};

export default var1;
