import React from "react";

export const Location = () => (
  <svg width="44" height="44">
    <g fill="none" fillRule="evenodd">
      <rect width="44" height="44" fill="#FFF" rx="13" />
      <path
        fill="#02B8DE"
        d="M0 4.002A4.003 4.003 0 0 1 4.002 0h35.996A4.003 4.003 0 0 1 44 4.002v35.996A4.003 4.003 0 0 1 39.998 44H4.002A4.003 4.003 0 0 1 0 39.998V4.002zm20.665 19.696l.026 10.337 12.287-20.669-22.488 8.511 10.175 1.821z"
      />
    </g>
  </svg>
);

Location.displayName = "Location";

export default Location;
