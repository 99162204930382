/* eslint-disable import/no-named-as-default */
import React from "react";
import PropTypes from "prop-types";
import "./footer.scss";

const Footer = () => {
  // const { children } = props;
  return (
    <footer className="footer">
      <section className="footer__inner">

      </section>
    </footer>
  );
};

Footer.propTypes = {
  children: PropTypes.element
};

export default Footer;
