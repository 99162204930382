import React, { PureComponent } from "react";
import { Field, formValueSelector } from "redux-form";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import { Input, DependOn, Grid, Col, Section, Radio } from "../../../../components";
import { messages } from "../../../../constants";
import { validationBuilder, validations } from "../..";
import { Txt } from "../../../../elements";

let rules = {};

class EvoMembershipExists extends PureComponent {
  constructor(props) {
    super(props);
    // for field level validations
    // TODO: find a better way to instantiate props when the component mounts
    Object.keys(validations).forEach((key) => {
      rules[key] = (spec) => (value) => validationBuilder(props, validations[key], spec)(value);
    });

    const { intl } = props;
    this.ruleisEvoMemberRequired = [
      rules.requiredRadio(intl.formatMessage(messages.privateRegistration.isEvoMember))
    ];
    this.rulesEvoAccountEmail = [
      rules.required(intl.formatMessage(messages.privateRegistration.evoAccountEmailAddress)),
      rules.email()
    ];
    this.rulesPersonalEmail = [
      rules.required(intl.formatMessage(messages.privateRegistration.personalEmailAddress)),
      rules.email()
    ];
    this.rulesWorkEmail = [
      rules.required(intl.formatMessage(messages.privateRegistration.businessEmailAddress)),
      rules.email()
    ];
    this.ruleAccessCode = [
      rules.required(intl.formatMessage(messages.privateRegistration.accessCode)),
      rules.alphanumeric(intl.formatMessage(messages.privateRegistration.accessCode))
    ];
  }

  // function to get the selected field value
  getValue = (name) => {
    const { state, form, section } = this.props;
    const selector = formValueSelector(form);
    if (section) {
      return selector(state, `${section}.${name}`);
    }
    return selector(state, name);
  };

  render() {
    const { intl } = this.props;
    const personalLabel =
      this.getValue(`isEvoMember`) === "No"
        ? messages.privateRegistration.personalEmailAddress
        : messages.privateRegistration.evoAccountEmailAddress;
    const personalNoteLabel =
      this.getValue(`isEvoMember`) === "No"
        ? messages.privateRegistration.personalEmailNote
        : messages.privateRegistration.evoAccountEmailNote;
    const personalEmailError =
      this.getValue(`isEvoMember`) === "No" ? this.rulesPersonalEmail : this.rulesEvoAccountEmail;
    return (
      <Section size="1-2" min="md">
        <Grid>
          <Col>
            <Field
              name="isEvoMember"
              label={intl.formatMessage(messages.privateRegistration.isEvoMember)}
              labelClassName="text--large"
              radioFieldClassName="compact"
              // tooltip={tooltip}
              required={true}
              // tooltipArrow="top"
              component={Radio}
              options={[
                { label: intl.formatMessage(messages.field.yesRadioButton), value: "Yes" },
                { label: intl.formatMessage(messages.field.noRadioButton), value: "No" }
              ]}
              validate={this.ruleisEvoMemberRequired}
            />

            <DependOn active={this.getValue(`isEvoMember`) !== undefined}>
              <Txt theme={["normal"]}>
                {intl.formatMessage(messages.privateRegistration.instructionText)}
              </Txt>
              <Field
                type="text"
                name="accessCode"
                label={intl.formatMessage(messages.privateRegistration.accessCode)}
                required={this.getValue(`businessEmailAddress`) === undefined}
                validate={
                  this.getValue(`businessEmailAddress`) !== undefined
                    ? undefined
                    : this.ruleAccessCode
                }
                component={Input}
                props={{
                  maxLength: "254",
                  disabled: this.getValue(`businessEmailAddress`) !== undefined,
                  hideErrorMessage: this.getValue(`businessEmailAddress`) !== undefined
                }}
              />
              <Txt theme={["note"]}>
                <small>{intl.formatMessage(messages.privateRegistration.accessCodeNote)}</small>
              </Txt>

              <Field
                type="email"
                name="businessEmailAddress"
                label={intl.formatMessage(messages.privateRegistration.businessEmailAddress)}
                required={this.getValue(`accessCode`) === undefined}
                validate={
                  this.getValue(`accessCode`) !== undefined ? undefined : this.rulesWorkEmail
                }
                component={Input}
                // input={{ disabled: this.getValue(`accessCode`) !== undefined}}
                props={{
                  maxLength: "254",
                  disabled: this.getValue(`accessCode`) !== undefined,
                  hideErrorMessage: this.getValue(`accessCode`) !== undefined
                }}
              />
              <Txt theme={["note"]}>
                <small>{intl.formatMessage(messages.privateRegistration.businessEmailNote)}</small>
              </Txt>

              <Field
                type="email"
                name="personalEmailAddress"
                label={intl.formatMessage(personalLabel)}
                required={true}
                validate={personalEmailError}
                component={Input}
                props={{ maxLength: "254" }}
              />
              <Txt theme={["note"]}>
                <small>{intl.formatMessage(personalNoteLabel)}</small>
              </Txt>
            </DependOn>
            <DependOn active={this.getValue(`isEvoMember`) === "No"}>
              <Txt>
                <div>
                  {intl.formatMessage(messages.privateRegistration.NewAccountInstructions, {
                    sameaddress: <span className="bold primary">same personal email address</span>,
                    onlyebike: <span className="bold primary">'E-bikes/E-scooters'</span>,
                    br: <br />
                  })}
                </div>
              </Txt>
            </DependOn>
            {/*<DependOn active={this.getValue(`isEvoMember`) === 'Yes'}>*/}

            {/*  <Txt>*/}
            {/*    <div>*/}
            {/*      {intl.formatMessage(messages.privateRegistration.BBOEmailInstructions,{list: <ol><li>Click <span className='bold primary'>'Set up your profile'</span> on the invitation which will link you to our website</li><li>Select <span className='bold primary'>'Already have an account?'</span> on the website</li><li><span className='bold primary'>Enter your login details</span> and your account will be linked automatically to your business profile</li></ol>, br: <br />})}*/}
            {/*    </div>*/}
            {/*  </Txt>*/}

            {/*</DependOn>*/}
            <br />
            <br />
          </Col>
        </Grid>
      </Section>
    );
  }
}

EvoMembershipExists.propTypes = {
  intl: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};

export default injectIntl(EvoMembershipExists);
