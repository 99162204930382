import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import get from "lodash/get";
import cn from "classnames";

import { messages, constants } from "../../constants";
import * as selectors from "../../selectors";
import {
  Responsive,
  Section,
  Grid,
  Col,
  DataTable,
  ExpandablePanel,
  TransactionReceipt
} from "../../components";
import BillingRecordCard from "../../components/card/billingRecordCard";
import BillingAdjustmentCard from "../../components/card/billingAdjustmentCard";
import { Title, Txt, Button } from "../../elements";
import { fetchPaymentDetails, fetchTransactionDeatils } from "../../actions/billing";
import { formatCurrency, formatDuration, formatDate, mapPaymentStatus } from "../../util";
import { mapTransactionLocalization } from "../../util/localizationMapper";

class BillingDetails extends PureComponent {
  state = { expandTransactionId: null, loadingRecord: {} };

  componentDidMount() {
    const paymentId = get(this.props.match, "params.paymentId");
    this.props.fetchPaymentDetails(paymentId);
  }

  render() {
    const { intl } = this.props;

    // payment for summary table
    const payment = this.getPaymentSummary();

    // format transaction records
    const { tripRecords, otherTransRecords } = this.formatTransactionRecords();

    return (
      <div data-testid="screen-billingDetails">
        <Section>
          <Grid>
            <Col>
              <Link to={constants.ROUTE_ACCOUNT_BILLING}>
                <Txt theme={["upper"]}>
                  <i className="fa fa-chevron-left" />{" "}
                  {intl.formatMessage(messages.button.backtoOverview)}
                </Txt>
              </Link>
            </Col>
          </Grid>
        </Section>
        <Section theme={["div"]}>
          <Grid>
            <Col>
              <Title priority={1} type={["strong"]} className="h2">
                {intl.formatMessage(messages.header.billingDetails)}
              </Title>
              <Txt>{intl.formatMessage(messages.billing.detailSummaryDescription)}</Txt>
              {this.renderSummaryTable(payment ? [payment] : [])}
            </Col>
          </Grid>
        </Section>

        <Section>
          <Grid>
            <Col>
              <Txt
                theme={["large"]}
                tooltip={intl.formatMessage(messages.billing.totalCostSummaryDescriptionTooltip)}
              >
                <div>{intl.formatMessage(messages.billing.totalCostSummaryDescription)}</div>
              </Txt>

              <Responsive device="Mobile">
                {tripRecords.length ? (
                  tripRecords.map((record, idx) => (
                    <BillingRecordCard key={idx} intl={intl} record={record} />
                  ))
                ) : (
                  <Txt priority={4} theme={["bold", "center", "vertical-gutter"]}>
                    <div>{intl.formatMessage(messages.title.NoTripToday)}</div>
                  </Txt>
                )}
              </Responsive>
              <Responsive device="Default">{this.renderTripTable(tripRecords)}</Responsive>
            </Col>
          </Grid>
        </Section>

        <Section>
          <Grid>
            <Col>
              <Txt
                theme={["large"]}
                tooltip={intl.formatMessage(
                  messages.billing.adjustmentAndOtherChargesDescriptionTooltip
                )}
              >
                <div>
                  {intl.formatMessage(messages.billing.adjustmentAndOtherChargesDescription)}
                </div>
              </Txt>

              <Responsive device="Mobile">
                {otherTransRecords.length ? (
                  otherTransRecords.map((record, idx) => (
                    <BillingAdjustmentCard key={idx} intl={intl} record={record} />
                  ))
                ) : (
                  <Txt priority={4} theme={["bold", "center", "vertical-gutter"]}>
                    <div>{intl.formatMessage(messages.title.NoAdjustmentsAndOtherCharges)}</div>
                  </Txt>
                )}
              </Responsive>
              <Responsive device="Default">
                {this.renderOtherTransactionTable(otherTransRecords)}
              </Responsive>
            </Col>
          </Grid>
        </Section>
      </div>
    );
  }

  toggleTransactionDetail = async recordId => {
    this.setState(({ loadingRecord }) => ({
      loadingRecord: { ...loadingRecord, [recordId]: true }
    }));
    if (recordId !== this.state.expandTransactionId) {
      await this.props.fetchTransactionDeatils(recordId);
      this.setState({ expandTransactionId: recordId });
    } else {
      this.setState({ expandTransactionId: null });
    }
    this.setState(({ loadingRecord }) => ({
      loadingRecord: { ...loadingRecord, [recordId]: false }
    }));
  };

  renderTrigger = recordId => {
    const { expandTransactionId, loadingRecord } = this.state;
    const { intl } = this.props;
    const expanded = recordId === expandTransactionId;
    const iconClassName = cn("fa", {
      "fa-chevron-down": !expanded,
      "fa-chevron-up": expanded
    });
    const isLoading = loadingRecord[recordId];
    return (
      <Button
        loading={isLoading}
        theme={["link", "compact"]}
        loadingTheme="inline"
        onClick={() => this.toggleTransactionDetail(recordId)}
      >
        <span>{intl.formatMessage(messages.title.Details)}</span>
        {!isLoading && <i className={iconClassName} />}
      </Button>
    );
  };

  renderSummaryTable = payments => {
    const { intl } = this.props;
    return (
      <DataTable
        theme={["mobile-card"]}
        columnNames={[
          { key: "date", title: intl.formatMessage(messages.title.Date) },
          { key: "time", title: intl.formatMessage(messages.title.billingTime) },
          { key: "cardType", title: intl.formatMessage(messages.title.cardType) },
          { key: "maskedCardNumber", title: intl.formatMessage(messages.title.cardNumber) },
          { key: "status", title: intl.formatMessage(messages.title.Status) },
          { key: "amount", title: intl.formatMessage(messages.title.Amount) }
        ]}
        displayKeys={["date", "time", "cardType", "maskedCardNumber", "status", "amount"]}
        mobileDisplayKeys={["date", "status", "amount", "time", "cardType", "maskedCardNumber"]}
        data={payments}
        singleRow
      />
    );
  };

  renderTripTable = tripRecords => {
    const { intl } = this.props;
    return (
      <DataTable
        theme={["mobile-card"]}
        columnNames={[
          { key: "car", title: intl.formatMessage(messages.title.Car) },
          {
            key: "tripStartDateTime",
            title: intl.formatMessage(messages.title.TripStart)
          },
          { key: "tripEndDateTime", title: intl.formatMessage(messages.title.TripEnd) },
          { key: "from", title: intl.formatMessage(messages.title.From) },
          { key: "to", title: intl.formatMessage(messages.title.To) },
          { key: "distance", title: intl.formatMessage(messages.title.Distance) },
          { key: "tripDuration", title: intl.formatMessage(messages.title.Duration) },
          { key: "amount", title: intl.formatMessage(messages.title.Amount) },
          { key: "details", title: "" }
        ]}
        displayKeys={[
          "car",
          "tripStartDateTime",
          "tripEndDateTime",
          "from",
          "to",
          "distance",
          "tripDuration",
          "amount",
          "details"
        ]}
        data={tripRecords}
        nullStateTitle={intl.formatMessage(messages.title.NoTripToday)}
      />
    );
  };

  renderOtherTransactionTable = otherTransRecords => {
    const { intl } = this.props;
    return (
      <DataTable
        theme={["mobile-card"]}
        columnNames={[
          { key: "posted", title: intl.formatMessage(messages.title.Posted) },
          { key: "type", title: intl.formatMessage(messages.title.Type) },
          { key: "description", title: intl.formatMessage(messages.title.Description) },
          { key: "trip", title: intl.formatMessage(messages.title.Trip) },
          { key: "amount", title: intl.formatMessage(messages.title.Amount) },
          { key: "details", title: "" }
        ]}
        displayKeys={["posted", "type", "description", "trip", "amount", "details"]}
        data={otherTransRecords}
        nullStateTitle={intl.formatMessage(messages.title.NoAdjustmentsAndOtherCharges)}
      />
    );
  };

  /**
   * Get the payment for summary table
   */
  getPaymentSummary = () => {
    const { payments, intl, match } = this.props;
    const paymentId = get(match, "params.paymentId");

    if (payments.length) {
      const foundPayment = payments.find(p =>
        paymentId === "pending" ? p.paymentId === null : `${p.paymentId}` === paymentId
      );
      if (foundPayment) {
        const { timestamp, timeZone, amount, status, ...rest } = foundPayment;
        const date = timestamp ? formatDate(moment.tz(timestamp, timeZone), "MMM D, YYYY") : "-";
        const time = timestamp ? formatDate(moment.tz(timestamp, timeZone), "h:mm a z") : "pending";
        return {
          date,
          time,
          amount: <b>{formatCurrency(amount)}</b>,
          status: mapPaymentStatus(status, intl),
          ...rest
        };
      }
    }

    return undefined;
  };

  formatTransactionRecords = () => {
    const { intl, paymentDetails, match } = this.props;
    const paymentId = get(match, "params.paymentId");
    const { expandTransactionId } = this.state;

    /**
     * Display Transaction table
     */
    const transCalcRecord = get(paymentDetails, [paymentId, "transCalcRecord"], []) || [];

    let tripRecords = [];
    let otherTransRecords = [];
    const dateFormat = "MMM, D, YYYY | hh:mm a z";

    for (let i = 0; i < transCalcRecord.length; i++) {
      const {
        id,
        posted,
        tripStartDateTime,
        tripEndDateTime,
        customerTimezone,
        distance,
        amount,
        tripDuration,
        description,
        productId
      } = transCalcRecord[i];
      const postedDate = posted ? formatDate(moment.tz(posted, customerTimezone), dateFormat) : "-";
      const tripStartDate = tripStartDateTime
        ? formatDate(moment.tz(tripStartDateTime, customerTimezone), dateFormat)
        : "";
      const tripEndDate = tripEndDateTime
        ? formatDate(moment.tz(tripEndDateTime, customerTimezone), dateFormat)
        : "";
      const expanded = id === expandTransactionId;
      const foundTransaction = get(this.props.transactionDetails, id);
      const record = {
        ...transCalcRecord[i],
        type:
          parseFloat(amount) < 0
            ? intl.formatMessage(messages.billing.refund)
            : intl.formatMessage(messages.billing.adjustment),
        posted: postedDate,
        trip: tripStartDate,
        tripStartDateTime: tripStartDate,
        tripEndDateTime: tripEndDate,
        distance: `${distance || 0} km`,
        amount: <b>{formatCurrency(amount)}</b>,
        tripDuration: formatDuration(tripDuration, "minutes"),
        description: mapTransactionLocalization(intl, { productId, fallback: description }),
        details: (
          <Fragment>
            <Responsive>
              <ExpandablePanel
                theme={["modal", "info-bg"]}
                expanded={expanded}
                trigger={this.renderTrigger(id)}
              >
                {foundTransaction && <TransactionReceipt data={foundTransaction} />}
              </ExpandablePanel>
            </Responsive>
            <Responsive device="Mobile">
              <ExpandablePanel
                theme={["card-action", "info-bg"]}
                expanded={expanded}
                trigger={this.renderTrigger(id)}
              >
                {foundTransaction && <TransactionReceipt data={foundTransaction} />}
              </ExpandablePanel>
            </Responsive>
          </Fragment>
        )
      };

      // push record to tripRecords if the descritpion is Trip
      // otherwise, push it to adjustment/charge records
      // TODO - probably need a constant to check, e.g. type
      if (description.toLowerCase() === "trip") {
        tripRecords.push(record);
      } else {
        otherTransRecords.push(record);
      }
    }

    let outstandingPayment = get(paymentDetails, [paymentId, "unsuccessfulPayment"]);
    if (outstandingPayment) {
      const { amount, posted } = outstandingPayment;
      // format timezone after BE returns timezone field
      const postedDate = posted ? formatDate(moment(posted), dateFormat) : "-";
      const prevDate = posted ? formatDate(moment(posted).subtract(1, "day"), "MMM, D, YYYY") : "-";
      outstandingPayment = {
        posted: postedDate,
        type: intl.formatMessage(messages.billing.outstandingBalance),
        description: intl.formatMessage(messages.billing.outstandingBalanceDescription, {
          prevDate
        }),
        trip: "-",
        amount: <b>{formatCurrency(amount)}</b>
      };

      // push to otherTransRecords
      otherTransRecords.push(outstandingPayment);
    }

    return { tripRecords, otherTransRecords };
  };
}

BillingDetails.propTypes = {
  intl: PropTypes.object.isRequired,
  payments: PropTypes.arrayOf(PropTypes.object),
  paymentDetails: PropTypes.object,
  transactionDetails: PropTypes.object,
  fetchPaymentDetails: PropTypes.func.isRequired,
  fetchTransactionDeatils: PropTypes.func.isRequired,
  match: PropTypes.object
};

const mapStateToProps = state => ({
  payments: selectors.getBilling(state).payments.results,
  paymentDetails: selectors.getBilling(state).paymentDetails,
  transactionDetails: selectors.getBilling(state).transactionDetails
});

const mapDispatchToProps = {
  fetchPaymentDetails,
  fetchTransactionDeatils
};

const enhancer = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhancer(BillingDetails);
