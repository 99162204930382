import React from "react";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import { EvoBadgeIcon } from "./iconStrings";
import "./regionMarker.scss";

const RegionMarker = props => {
  const { onClick } = props;

  return (
    <div onClick={onClick}>
      <img className="region-marker" src={EvoBadgeIcon} alt="close icon" draggable="false" />
    </div>
  );
};

RegionMarker.propTypes = {
  onClick: PropTypes.func
};

RegionMarker.defaultProps = {
  onClick: () => {}
};

export default injectIntl(RegionMarker);
