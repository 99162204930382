import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import "./section.scss";

const Section = props => {
  const { min, size, theme } = props;
  const sectionCSS = cn(
    "section",
    min || size ? `section-${size}${min ? "--" + min : ""}` : "",
    theme ? theme.map(css => `section--${css}`).join(" ") : ""
  );
  return <section className={sectionCSS}>{props.children}</section>;
};

Section.displayName = "Section";

Section.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string
  ]),
  min: PropTypes.oneOf(["sm", "md", "lg"]),
  size: PropTypes.oneOf(["1-2", "2-3"]),
  theme: PropTypes.array
};
export default Section;
