import { defineMessages } from "react-intl";

const namespace = "text";
const text = defineMessages({
  clickToGetGoing: {
    id: `${namespace}.clickToGetGoing`,
    description: "404 page text",
    defaultMessage: "Please click the button below to get going again!"
  },
  aboutBCAAContent: {
    id: `${namespace}.aboutBCAAContent`,
    description: "About BCAA Content",
    defaultMessage:
      "The British Columbia Automobile Association (BCAA) is a mission-driven services organization committed to delivering peace of mind to our Members and customers across B.C. and the Yukon. Membership is Rewarding!"
  },
  evolveOrBoth: {
    id: `${namespace}.evolveOrBoth`,
    description: "Choose Evolve or Both",
    defaultMessage:
      "You’re in the driver’s seat – choose to sign up for everything Evo has to offer, or skip steps like uploading your driver’s licence and hit the road right away by signing up for Evolve E-Bikes/E-Scooters only."
  }
});

export default text;
