/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {PureComponent} from "react";
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";

import {FormSectionWrapper} from "../";
import { Address, Drivers, NameFields, IsMailingAddress } from "../../fieldGroups";
import { Section, DependOn } from "../../../../components";
import DriversLicenseRequirements from "../../fieldGroups/driversLicenseRequirements/driversLicenseRequirements";
import { messages } from "../../../../constants";
import { formValueSelector } from "redux-form";
import { Txt } from "../../../../elements";
import * as selectors from "../../../../selectors";
import { connect } from "react-redux";

class DriversLicenseInfo extends PureComponent {
  // function to get the selected field value
  getValue = (name) => {
    const { state, form, section } = this.props;
    const selector = formValueSelector(form);
    if (section) {
      return selector(state, `${section}.${name}`);
    }
    return selector(state, name);
  };

  render() {
    const { isEbikeOnly } = this.props;

    return (
      <FormSectionWrapper {...this.props}>
        <DependOn active={isEbikeOnly === false}>
          <Txt>{this.props.intl.formatMessage(messages.imageCapture.driversLicenseSubheader)}</Txt>
        </DependOn>
        <Section size="2-3" min="md">
          <NameFields {...this.props} />
          <Drivers {...this.props} />
        </Section>
        <Address
          {...this.props}
          type={this.props.intl.formatMessage(messages.title.Address)}
          prefix={"primary"}
        />
        <DependOn active={isEbikeOnly === false}>
          <IsMailingAddress {...this.props} />
          <DependOn active={!this.getValue(`isMailingAddress`)}>
            <Address
              {...this.props}
              type={this.props.intl.formatMessage(messages.title.MailingAddress)}
              prefix={"secondary"}
            />
          </DependOn>
          <DriversLicenseRequirements {...this.props} />
        </DependOn>
        <br />
        <br />
      </FormSectionWrapper>
    );
  }
};

DriversLicenseInfo.propTypes = {
  intl: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  actions: PropTypes.objectOf(PropTypes.func),
  isEbikeOnly: PropTypes.bool
};


const mapStateToProps = (state) => ({
  isEbikeOnly: selectors.getIsEbikeOnly(state)
});


export default injectIntl(connect(mapStateToProps)(DriversLicenseInfo));

