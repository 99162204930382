import { defineMessages } from "react-intl";

const namespace = "header";
const header = defineMessages({
  greeting: {
    id: `${namespace}.greeting`,
    description: `Header Greeting`,
    defaultMessage: `Hi, {name}`
  },
  menu: {
    id: `${namespace}.menu`,
    description: `Menu link label`,
    defaultMessage: `Menu`
  },
  billing: {
    id: `${namespace}.billing`,
    description: `Account billling header`,
    defaultMessage: `Billing`
  },
  todayTotal: {
    id: `${namespace}.todayTotal`,
    description: `Account billling today's total header`,
    defaultMessage: `Today's Total`
  },
  billingDetails: {
    id: `${namespace}.billingDetails`,
    description: `Account billling details header`,
    defaultMessage: `Billing Details`
  },
  todayTotalSub: {
    id: `${namespace}.todayTotalSub`,
    description: `Today's total sub message`,
    defaultMessage:
      "We bill your credit card once a day. Click on 'Details' to view more about your trips, fees, and adjustments below."
  },
  login: {
    id: `${namespace}.login`,
    description: `Header Login link label`,
    defaultMessage: `Member login`
  },
  signout: {
    id: `${namespace}.signout`,
    description: `Header signout link label`,
    defaultMessage: `Sign out`
  },
  PreviousPostings: {
    id: `${namespace}.PreviousPostings`,
    description: "Previous Postings title",
    defaultMessage: "Previous Transactions"
  },
  PreviousPostingsSub: {
    id: `${namespace}.PreviousPostingsSub`,
    description: "Previous Postings message",
    defaultMessage:
      "These billing amounts will be present on your bank statement. You can view the details of all transactions by clicking ‘Details”."
  },
  AvailableMinutes: {
    id: `${namespace}.AvailableMinutes`,
    description: `Header Available Minutes label`,
    defaultMessage: `Available Minutes`
  },
  SummaryMinutes: {
    id: `${namespace}.SummaryMinutes`,
    description: "Header Summary Minutes label",
    defaultMessage: "Summary Minutes"
  },
  TotalFreeMinutesIn: {
    id: `${namespace}.TotalFreeMinutesIn`,
    description: "Subtitle of available minutes page",
    defaultMessage: `Your total free minutes available to use in {city}`
  },
  TotalFreeMinutesAll: {
    id: `${namespace}.TotalFreeMinutesAll`,
    description: "Subtitle of available minutes in multi regions mode",
    defaultMessage: "Your total free minutes include those shared by different cities."
  },
  downloadMonthlyStatements: {
    id: `${namespace}.downloadMonthlyStatements`,
    description: "Subtitle of monthly statement page",
    defaultMessage: "Download your monthly statements here."
  }
});

export default header;
