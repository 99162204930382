import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { toMonerisStringFormat, styles } from "./monerisTokenizerStyles";
import { injectIntl } from "react-intl";
import cn from "classnames";

import { messages } from "../../constants";
import { Txt } from "../../elements";
import "./moneris.scss";

class MonerisTokenizer extends PureComponent {
  constructor(props) {
    super(props);
    this.monerisFrame = null;
    this.state = {
      monerisResp: {
        responseCode: "",
        dataKey: "",
        errorMessage: ""
      }
    };
    props.actions.clearMonerisResp();
  }

  /*
  STEP TO USE MONERIS TOKENIZER:
  1. This tokenizer will wrap Moneris iframe inside and will `postMessage` to this iframe, we also have the 'message' event listener
  2. This iframe will send data from the form to moneris API endpoint
  3. In <Registration> component, we set up a async/await to check for the moneris response every 250ms for most 4000ms.
  4. Whenever the moneris response is ready, the <Registraion> 's submit action will continue
 */

  componentWillMount() {
    window.addEventListener("message", this._getMonerisResp);
  }

  componentWillUnmount() {
    window.removeEventListener("message", null);
  }


  _getMonerisResp = (e) => {

    try {
      if (/https:\/\/[\w]*.moneris.com/.test(e.origin)) {
        const respData = JSON.parse(e.data);
        this.setState({ monerisResp: respData });
        this.props.actions.setMonerisResp({ monerisResp: respData });
      }
    } catch (error) {
      // error handling
    }
  };

  statusEnum = (statusCode) => {
    const namespace = messages.moneris;
    switch (statusCode) {
      case "001":
        return namespace.respGood;
      case "940":
        return namespace.respInvalidProfId;
      case "941":
        return namespace.respErrorGenToken;
      case "942":
        return namespace.respErrorProfIdOrSourceUrl;
      case "943":
        return namespace.respErrorCardInvalid;
      case "944":
        return namespace.respInvalidExpDate;
      case "945":
        return namespace.respInvalidCVV2;
      default:
        break;
    }
  };

  render() {
    const { intl, id } = this.props;

    const monerisTokenizerUrl = process.env.REACT_APP_EVO_MONERIS_URL + "?";
    const monerisOptions = {
      id: id || process.env.REACT_APP_EVO_MONERIS_ID || "htBNELZK8VYC79V",
      enable_exp: 1,
      enable_cvd: 1,
      display_labels: 1,
      pan_label: `${intl.formatMessage(messages.moneris.creditcard)}`,
      exp_label: `${intl.formatMessage(messages.moneris.expirydate)}`,
      cvd_label: `${intl.formatMessage(messages.moneris.cvv2)}`
    };

    const monerisStyle = toMonerisStringFormat(styles);

    const monerisUrl = `${monerisTokenizerUrl}${Object.keys(monerisOptions)
      .map((optionKey) => `${optionKey}=${monerisOptions[optionKey]}`)
      .join("&")}&${monerisStyle}`;

    const [monerisRespCode] = this.state.monerisResp.responseCode;
    const monerisTextCss = cn("text", monerisRespCode === "001" ? "text--normal" : "text--error");

    return (
      <fieldset className="moneris" role="group">
        <iframe
          title="moneris"
          ref={(ref) => (this.monerisFrame = ref)}
          frameBorder="0"
          scrolling="no"
          height="120"
          src={monerisUrl}
          id="monerisIframe"
        />
        {this.state.monerisResp.responseCode && (
          <small id="monerisResponse" className={monerisTextCss}>
            {intl.formatMessage(this.statusEnum(monerisRespCode))}
          </small>
        )}

        <Txt theme={["dagger"]}>
          <small>{intl.formatMessage(messages.moneris.cvvhelptext)}</small>
        </Txt>
      </fieldset>
    );
  }
}

MonerisTokenizer.propTypes = {
  intl: PropTypes.object.isRequired,
  meta: PropTypes.object,
  submitting: PropTypes.bool,
  actions: PropTypes.object,
  id: PropTypes.string
};

export default injectIntl(MonerisTokenizer);
