import React from "react";
import PropTypes from "prop-types";

import { Txt } from "../../../elements";

const DataCell = props => {
  const { headerTitle, data, theme } = props;

  return (

    <span>
      <div>
        <Txt theme={theme}>{headerTitle}</Txt>
      </div>
      {data?
      <Txt>{data}</Txt>:null}
    </span>
  );
};

DataCell.propTypes = {
  headerTitle: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.array]),
  data: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element,PropTypes.array])
};

export default DataCell;
