import { messages } from "../../constants";

const fn = (intl) => [
  { value: "AL", label: intl.formatMessage(messages.states.AL) },
  { value: "AK", label: intl.formatMessage(messages.states.AK) },
  { value: "AZ", label: intl.formatMessage(messages.states.AZ) },
  { value: "AR", label: intl.formatMessage(messages.states.AR) },
  { value: "CA", label: intl.formatMessage(messages.states.CA) },
  { value: "CO", label: intl.formatMessage(messages.states.CO) },
  { value: "CT", label: intl.formatMessage(messages.states.CT) },
  { value: "DE", label: intl.formatMessage(messages.states.DE) },
  { value: "FL", label: intl.formatMessage(messages.states.FL) },
  { value: "GA", label: intl.formatMessage(messages.states.GA) },
  { value: "HI", label: intl.formatMessage(messages.states.HI) },
  { value: "ID", label: intl.formatMessage(messages.states.ID) },
  { value: "IL", label: intl.formatMessage(messages.states.IL) },
  { value: "IN", label: intl.formatMessage(messages.states.IN) },
  { value: "IA", label: intl.formatMessage(messages.states.IA) },
  { value: "KS", label: intl.formatMessage(messages.states.KS) },
  { value: "KY", label: intl.formatMessage(messages.states.KY) },
  { value: "LA", label: intl.formatMessage(messages.states.LA) },
  { value: "ME", label: intl.formatMessage(messages.states.ME) },
  { value: "MD", label: intl.formatMessage(messages.states.MD) },
  { value: "MA", label: intl.formatMessage(messages.states.MA) },
  { value: "MI", label: intl.formatMessage(messages.states.MI) },
  { value: "MN", label: intl.formatMessage(messages.states.MN) },
  { value: "MS", label: intl.formatMessage(messages.states.MS) },
  { value: "MO", label: intl.formatMessage(messages.states.MO) },
  { value: "MT", label: intl.formatMessage(messages.states.MT) },
  { value: "NE", label: intl.formatMessage(messages.states.NE) },
  { value: "NV", label: intl.formatMessage(messages.states.NV) },
  { value: "NH", label: intl.formatMessage(messages.states.NH) },
  { value: "NJ", label: intl.formatMessage(messages.states.NJ) },
  { value: "NM", label: intl.formatMessage(messages.states.NM) },
  { value: "NY", label: intl.formatMessage(messages.states.NY) },
  { value: "NC", label: intl.formatMessage(messages.states.NC) },
  { value: "ND", label: intl.formatMessage(messages.states.ND) },
  { value: "OH", label: intl.formatMessage(messages.states.OH) },
  { value: "OK", label: intl.formatMessage(messages.states.OK) },
  { value: "OR", label: intl.formatMessage(messages.states.OR) },
  { value: "PA", label: intl.formatMessage(messages.states.PA) },
  { value: "RI", label: intl.formatMessage(messages.states.RI) },
  { value: "SC", label: intl.formatMessage(messages.states.SC) },
  { value: "SD", label: intl.formatMessage(messages.states.SD) },
  { value: "TN", label: intl.formatMessage(messages.states.TN) },
  { value: "TX", label: intl.formatMessage(messages.states.TX) },
  { value: "UT", label: intl.formatMessage(messages.states.UT) },
  { value: "VT", label: intl.formatMessage(messages.states.VT) },
  { value: "VA", label: intl.formatMessage(messages.states.VA) },
  { value: "WA", label: intl.formatMessage(messages.states.WA) },
  { value: "WV", label: intl.formatMessage(messages.states.WV) },
  { value: "WI", label: intl.formatMessage(messages.states.WI) },
  { value: "WY", label: intl.formatMessage(messages.states.WY) }
];

export default fn;
