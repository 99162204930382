import React from "react";
import PropTypes from "prop-types";
import { ConnectedRouter } from "connected-react-router";

import { persistor } from "../store";
import { PersistGate } from "redux-persist/integration/react";
import Amplify from "@aws-amplify/core";

import App from "./App";
import ReduxRoot from "./ReduxRoot";
import LocaleRoot from "./LocaleRoot";

// Configure AWS Amplify
Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION, // REQUIRED - Amazon Cognito Region
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID, //OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID, //OPTIONAL - Amazon Cognito Web Client ID
    mandatorySignIn: true
  }
});

if (process.env.NODE_ENV !== "production" && process.env.REACT_APP_DISABLE_LOGGERS === "false") {
  Amplify.Logger.LOG_LEVEL = "DEBUG";
}

const Root = ({ store, history }) => (
  <ReduxRoot store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <LocaleRoot>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </LocaleRoot>
    </PersistGate>
  </ReduxRoot>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default Root;
