import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { object } from "prop-types";
import { injectIntl } from "react-intl";
import { store } from "../../store";
import { push } from "connected-react-router";
import { withRouter, Redirect, Link } from "react-router-dom";
import Auth from "@aws-amplify/auth";
import cn from "classnames";

import { messages, constants } from "../../constants";
import { getJsonFromUrl } from "../../util";
import { Button, Txt, Title } from "../../elements";
import { Section, Grid, Col, ErrorBoundary, CheckMark } from "../../components";
import "./index.scss";

/**
 *
 *
 * @class ChangePasswordScreen
 * @extends {PureComponent}
 */
class ChangePasswordScreen extends PureComponent {
  static displayName = "ChangePasswordScreen";

  static propTypes = {
    intl: PropTypes.object.isRequired,
    location: object.isRequired
  };

  state = {
    inputFocused: false,
    loading: false,
    amplifySendEmailError: null,
    clientSideValidationError: null,
    resetSuccess: false,
    showSendNewLinkScreen: false,
    sentNewPassword: false,
    sendNewLinkError: false,
    passvisible: false
  };

  newPasswordInputValue = React.createRef();

  render() {
    // If the required query string params are not present
    // eg. /reset/password/code?code=1234&email=test@test.com
    // redirect out of here
    if (!this.queryStringContainsCodeAndEmail()) {
      return <Redirect to="/" />;
    }

    const { showSendNewLinkScreen, sentNewPassword } = this.state;
    // If the code has expired, show the screen to reset it
    if (showSendNewLinkScreen) {
      return this.renderNewLinkScreen();
    }

    // If a new code has been sent successfully show the success screen
    if (sentNewPassword) {
      return this.newLinkSentSuccessScreen();
    }

    const { resetSuccess } = this.state;

    // Render success screen if reset has occured
    if (resetSuccess) {
      return this.renderSuccessfulResetScreen();
    }

    return <ErrorBoundary>{this.renderResetCodeFields()}</ErrorBoundary>;
  }

  /**
   *
   * TODO: Write a test for me !
   * @memberof ChangePasswordScreen
   */
  queryStringContainsCodeAndEmail = () => {
    const urlParams = getJsonFromUrl(this.props.location.search);
    const containsCodeAndEmail =
      Object.keys(urlParams).includes("code") && Object.keys(urlParams).includes("email");

    return containsCodeAndEmail;
  };

  /**
   *
   *
   * @memberof ChangePasswordScreen
   * @returns {React.Dom}
   */
  renderResetCodeFields = () => {
    const { intl } = this.props;
    const { loading, resetSuccess, amplifySendEmailError, clientSideValidationError } = this.state;
    const disabled = loading || resetSuccess;
    const passIconCSS = cn("fa", {
      "fa-eye-slash": this.state.passvisible,
      "fa-eye": !this.state.passvisible
    });
    return (
      <div className="reset-code">
        <Section>
          <Grid theme={["center"]}>
            <Col size="1-2" min="sm">
              <Title priority={1} type={["strong"]} className="h1 reset-code__title blue">
                {intl.formatMessage(messages.title.newPasswordBelow)}
              </Title>
            </Col>
          </Grid>
          <Grid theme={["center"]}>
            <Col size="1-2" min="sm">
              <form noValidate>
                <fieldset className={this.getInputCSS()} role="group">
                  <div className="input-field">
                    <input
                      className="reset-code__input"
                      data-testid="reset-code__input"
                      disabled={loading}
                      type={this.state.passvisible ? "text" : "password"}
                      placeholder={intl.formatMessage(messages.field.newPassword)}
                      ref={this.newPasswordInputValue}
                      onFocus={this._onInputFocus}
                      onBlur={this._onInputBlur}
                      onKeyPress={e => {
                        if (e.key === "Enter") {
                          this._onInputBlur();
                          this.submitPasswordChange({ exitAfterValidation: true }, e);
                        }
                      }}
                    />
                    <i className={passIconCSS} onClick={this.handlePassField} />
                  </div>
                  {(!!amplifySendEmailError || !!clientSideValidationError) && (
                    <Txt theme={["error"]}>
                      <small>{this.fetchErrorMessages()}</small>
                    </Txt>
                  )}
                </fieldset>
                <Button
                  loading={loading}
                  className="reset-code__button"
                  type="submit"
                  theme={["large", "bold"]}
                  disabled={!!disabled}
                  onClick={event =>
                    this.submitPasswordChange({ exitAfterValidation: false }, event)
                  }
                >
                  {<span>{intl.formatMessage(messages.button.resetPassword)}</span>}
                </Button>
              </form>
            </Col>
          </Grid>
        </Section>
      </div>
    );
  };

  /**
   *
   *
   * @memberof ChangePasswordScreen
   */
  _onInputFocus = () => {
    this.setState({
      inputFocused: true,
      // Reset the error messages on focus
      amplifySendEmailError: null,
      clientSideValidationError: null
    });
  };

  /**
   *
   *
   * @memberof ChangePasswordScreen
   */
  _onInputBlur = event => {
    this.setState({ inputFocused: false });
    this.submitPasswordChange({ exitAfterValidation: true }, event);
  };

  /**
   *
   *
   * @memberof ChangePasswordScreen
   */
  handlePassField = e => {
    e && e.preventDefault();
    this.setState(state => ({ ...state, passvisible: !state.passvisible }));
  };

  /**
   *
   *
   * @memberof ChangePasswordScreen
   * @param {Object} exitAfterValidation
   */
  submitPasswordChange = ({ exitAfterValidation = true }, event) => {
    event && event.preventDefault();
    const urlParams = getJsonFromUrl(this.props.location.search);
    const newPassword = this.newPasswordInputValue.current.value;

    if (!exitAfterValidation) {
      this.setState({ loading: true });
      Auth.forgotPasswordSubmit(urlParams.email, urlParams.code, newPassword)
        .then(() => {
          this.setState({
            loading: false,
            resetSuccess: true
          });
        })
        .catch(err => {
          // Set "amplifyError" true as a fallback, for a hard coded error message.
          let amplifyError = true;
          // if amplify returns a message string, use that.
          if (err.message) amplifyError = err.message;

          if (err.message === "Invalid code provided, please request a code again.") {
            this.setState({
              loading: false,
              amplifySendEmailError: amplifyError,
              showSendNewLinkScreen: true
            });
          } else {
            this.setState({ loading: false, amplifySendEmailError: amplifyError });
          }
        });
    }
  };

  /**
   *
   * @description returns css classes for the <input />
   * @memberof ChangePasswordScreen
   * @returns {string}
   * @public
   */
  getInputCSS = () => {
    const { inputFocused, amplifySendEmailError, clientSideValidationError } = this.state;
    return cn("input", {
      "is-pristine": inputFocused && !clientSideValidationError && !amplifySendEmailError,
      "is-valid": !inputFocused && !clientSideValidationError && !amplifySendEmailError,
      "is-invalid": amplifySendEmailError || clientSideValidationError,
      "is-required": true,
      "is-focused": inputFocused
    });
  };

  /**
   *
   * @description returns an error message string from client or server-side
   * @memberof ChangePasswordScreen
   * @returns {string}
   * @public
   */
  fetchErrorMessages = () => {
    const { intl } = this.props;
    const { inputFocused, amplifySendEmailError, clientSideValidationError } = this.state;

    // Return any client side validation errors first
    if (clientSideValidationError && !inputFocused) return clientSideValidationError;

    // Check for server side validation errors coming in from Amplify
    if (!clientSideValidationError && !inputFocused && amplifySendEmailError) {
      const amplifyErrorIsString = typeof amplifySendEmailError === "string";
      // set generic error message as default message
      let error = intl.formatMessage(messages.error.genericError);
      // if we have an Amplify error, use that
      if (amplifyErrorIsString) error = amplifySendEmailError;

      return error;
    }
  };

  /**
   *
   *
   * @memberof ChangePasswordScreen
   */
  sendNewLink = () => {
    this.setState({ loading: true });
    const urlParams = getJsonFromUrl(this.props.location.search);
    Auth.forgotPassword(urlParams.email)
      .then(() => {
        /*
         * response:
         * {
         *   CodeDeliveryDetails: {
         *    "AttributeName": "email",
         *    "DeliveryMedium": "EMAIL",
         *    "Destination": "t***@a***.co"
         *   }
         * }
         *
         */
        this.setState({ sentNewPassword: true, loading: false });
      })
      .catch(err => {
        // Set "amplifyError" true as a fallback, for a hard coded error message.
        let amplifyError = true;
        // if amplify returns a message string, use that.
        if (err.message) amplifyError = err.message;

        if (err.message === "Invalid code provided, please request a code again.") {
          this.setState({
            loading: false,
            amplifySendEmailError: amplifyError
          });
        } else {
          this.setState({
            loading: false,
            amplifySendEmailError: amplifyError,
            sendNewLinkError: true
          });
        }
      });
  };

  /**
   *
   *
   * @memberof ChangePasswordScreen
   * @returns {React.Dom}
   */
  renderSuccessfulResetScreen = () => {
    const { intl } = this.props;

    return (
      <div className="reset-code">
        <Section>
          <Grid theme={["center"]}>
            <Col size="1-2" min="sm">
              <CheckMark />
              <Title priority={1} type={["strong"]} className="h1 reset-code__title blue">
                {intl.formatMessage(messages.title.successfulPasswordReset)}
              </Title>
              <Title priority={5} className="h5 reset-code__title">
                {intl.formatMessage(messages.title.loginWithNewPassword)}
              </Title>

              <Button
                className="reset-code__button"
                type="submit"
                theme={["large"]}
                onClick={() =>
                  store.dispatch(
                    push(
                      `${constants.ROUTE_SIGNIN}?message=${constants.LOGIN_BANNER_TYPE.resetPasswordSuccess}`
                    )
                  )
                }
              >
                {<span>{intl.formatMessage(messages.button.goToLogin)}</span>}
              </Button>
            </Col>
          </Grid>
        </Section>
      </div>
    );
  };

  /**
   *
   *
   * @memberof ChangePasswordScreen
   * @returns {React.Dom}
   */
  renderNewLinkScreen = () => {
    const { intl } = this.props;
    const { sendNewLinkError, loading } = this.state;

    return (
      <div className="reset-code">
        <Section>
          <Grid theme={["center"]}>
            <Col size="1-2" min="sm">
              {sendNewLinkError ? (
                intl.formatMessage(messages.error.resetPasswordLinkError)
              ) : (
                <Title priority={1} type={["strong"]} className="h1 reset-code__title blue fail">
                  {intl.formatMessage(messages.personal.linkHasExpired)}
                </Title>
              )}
              <br />
              <br />
              {sendNewLinkError ? (
                <Link to={constants.ROUTE_RESET_PASSWORD} className="is-underlined">
                  {intl.formatMessage(messages.field.forgotPassword)}
                </Link>
              ) : (
                <Button
                  loading={loading}
                  className="reset-code__button"
                  type="submit"
                  theme={["large"]}
                  onClick={this.sendNewLink}
                >
                  {<span>{intl.formatMessage(messages.button.sendMeANewLink)}</span>}
                </Button>
              )}

              {sendNewLinkError ? null : (
                <Link to={constants.ROUTE_SIGNIN} className="is-underlined reset-code__link">
                  {intl.formatMessage(messages.button.login)}
                </Link>
              )}
            </Col>
          </Grid>
        </Section>
      </div>
    );
  };

  /**
   *
   *
   * @memberof ChangePasswordScreen
   * @returns {React.Dom}
   */
  newLinkSentSuccessScreen = () => {
    const { intl } = this.props;
    const { userEmail } = this.state;

    return (
      <div className="reset-code">
        <Section>
          <Grid theme={["center"]}>
            <Col size="1-2" min="sm">
              <CheckMark />
              <Title priority={1} type={["strong"]} className="h1 forgot-password__title blue">
                {intl.formatMessage(messages.personal.resetLinkSent)}
              </Title>
              <Title priority={3} type={["strong"]} className="h3 forgot-password__title">
                {userEmail}
              </Title>
              <Link to={constants.ROUTE_SIGNIN} className="is-underlined reset-code__link">
                {intl.formatMessage(messages.button.login)}
              </Link>
            </Col>
          </Grid>
        </Section>
      </div>
    );
  };
}

export const InjectedChangePasswordScreen = injectIntl(ChangePasswordScreen);
export default injectIntl(withRouter(ChangePasswordScreen));
