import { constants } from "../constants";

export const DEFAULT_STATE = {
  username: undefined,
  firstName: undefined,
  lastName: undefined,
  preferredName: undefined,
  accountInformation: {},
  homeAddress: {},
  drivreLicence: {},
  paymentInformation: {},
  billingAddress: {},
  caaMemberShipInformation: {}
};

const var1 = (state = DEFAULT_STATE, { type, payload } = {}) => {
  switch (type) {
    case constants.USER_INFO:
      return payload;
    case constants.USER_UPDATE:
      return { ...state, ...payload };
    case constants.USER_DELETE:
      return DEFAULT_STATE;
    case constants.USER_LOGIN_INFO_GET:
      return { ...state, ...payload };
    case constants.USER_LOGIN_INFO_PATCH:
      return { ...state, ...payload };
    case constants.USER_PERSONAL_INFO_GET:
      return { ...state, personalInfo: payload };
    case constants.USER_PERSONAL_INFO_PATCH:
      return {
        ...state,
        personalInfo: { ...state.personalInfo, ...payload },
        accountSummary: { ...state.accountSummary, preferredName: payload.preferredName }
      };
    case constants.USER_DRIVER_LICENCE_GET:
      return { ...state, driverLicence: payload };
    case constants.USER_DRIVER_LICENCE_UPDATE:
      return { ...state, driverLicence: { ...state.driverLicence, ...payload } };
    case constants.USER_DRIVER_LICENCE_PATCH:
      return {
        ...state,
        driverLicence: { ...state.driverLicence, ...payload },
        accountSummary: { ...state.accountSummary, firstName: payload.firstName }
      };
    case constants.USER_PAYMENT_INFO_GET:
      return { ...state, paymentInformation: payload };
    case constants.USER_PAYMENT_INFO_UPDATE:
    case constants.USER_PAYMENT_INFO_PATCH:
      return { ...state, paymentInformation: { ...state.paymentInformation, ...payload } };
    case constants.USER_MEMBERSHIP_GET:
      return { ...state, membership: payload };
    case constants.USER_MEMBERSHIP_UPDATE:
    case constants.USER_MEMBERSHIP_PATCH:
    case constants.USER_MEMBERSHIP_DELETE:
      return { ...state, membership: { ...state.membership, ...payload } };
    default:
      return state;
  }
};

export default var1;
