import { constants } from "../constants";

export const DEFAULT_STATE = { regions: [] };

const var1 = (state = DEFAULT_STATE, { type, payload } = {}) => {
  switch (type) {
    case constants.REGIONS_INFO:
      return payload;
    default:
      return state;
  }
};

export default var1;
