import React, { PureComponent } from "react";
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";

import { Grid,Section } from "../../../../components";
import { messages } from "../../../../constants";
import {List} from "../../../../elements";




class ImageRequirements extends PureComponent {

  render() {
    const { intl, orientation } = this.props;
    return (
      <Section size="2-3" min="md">
        <Grid theme={["div"]}>

          <List theme={["col2", "base", "blue-check"]}>
            { [intl.formatMessage(messages.imageCapture.requirement1),
            intl.formatMessage(messages.imageCapture.requirement2),
            intl.formatMessage(messages.imageCapture.requirement3),
            intl.formatMessage(messages.imageCapture.requirement4, {orientation})]}
          </List>
        </Grid>
      </Section>
    );
  }
}

ImageRequirements.propTypes = {
  intl: PropTypes.object.isRequired,
  orientation: PropTypes.string.isRequired
};

export default injectIntl(ImageRequirements);
