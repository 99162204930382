import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Input, Grid, Col } from "../../../../components";
import { Button, Txt } from "../../../../elements";
import { messages } from "../../../../constants";
import { resetPromoCode } from "../../../../actions/flow";
import * as selectors from "../../../../selectors";

class PromoCode extends PureComponent {


  render() {
    const {
      intl,
      btnDisabled,
      handleClickBtn,
      colWidth,
      hideFieldLevelErrorMessage,
      submitting
    } = this.props;

    // hideFieldLevelMessage would hide the message right under the field
    // but show still show the message in the second row after the field and the button

    return (
      <div>
        <Grid theme={["middle"]}>
          <Col size={colWidth} min="sm">
            <Field
              component={Input}
              props={{ maxLength: "45" }}
              name="promo"
              type="text"
              label={intl.formatMessage(messages.field.promoCode)}
              asyoutype={true}
              preventSubmitPressEnter
              onPostChange={this.handlePostChange}
              hideErrorMessage={hideFieldLevelErrorMessage}
            />
          </Col>

          {hideFieldLevelErrorMessage && (
            <Col size={colWidth} min="sm">
              <Button
                loading={submitting}
                onClick={handleClickBtn}
                disabled={btnDisabled}
              >
                <span>{intl.formatMessage(messages.button.apply)}</span>
              </Button>
            </Col>
          )}
        </Grid>

        <Grid>
          <Col>{this.renderMessages()}</Col>
        </Grid>
      </div>
    );
  }

  renderMessages = () => {
    const { promoCode } = this.props;
    if (!promoCode || !promoCode.message) return null;
    const overrideMessage = this.overrideMessage();

    return (
      <Txt theme={[promoCode.status]}>
        <p>{overrideMessage ? overrideMessage : promoCode.message}</p>
      </Txt>
    );
  };

  overrideMessage = () => {
    const {paymentSummary, intl} = this.props;
    let message;
    if(paymentSummary) {
      const promoPaymentSummary = paymentSummary.transactions.find((element) => {
        return element.productId === "promo";
      });

      const freeMinutes = parseFloat(promoPaymentSummary.nameExt);
      const regDiscount = parseFloat(promoPaymentSummary.amount);

      if (freeMinutes && regDiscount) {
        // if the promo code has both freeMinutes and regDiscount
        message = intl.formatMessage(messages.field.promoCodeDiscountAndFreeMinute, {
          mins: freeMinutes
        });
      } else if (freeMinutes) {
        // if the propmo code is for free minutes
        message = intl.formatMessage(messages.field.promoCodeFreeMinute, {
          mins: freeMinutes
        });
      } else if (regDiscount) {
        // if the promo code is for discount
        message = intl.formatMessage(messages.field.promoCodeDiscount);
      }
    }
      return message;
  }

  handlePostChange = () => {
    const { promoCode } = this.props;
    if (promoCode && promoCode.message) {
      this.props.resetPromoCode();
    }
  };
}

PromoCode.defaultProps = {
  handleClickBtn: () => {},
  btnDisabled: false,
  colWidth: "1-2",
  promoCode: {},
  hideFieldLevelErrorMessage: true,
  submitting: false
};

PromoCode.propTypes = {
  resetPromoCode: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  handleClickBtn: PropTypes.func,
  btnDisabled: PropTypes.bool,
  colWidth: PropTypes.string,
  hideFieldLevelErrorMessage: PropTypes.bool,
  submitting: PropTypes.bool,
  promoCode: PropTypes.shape({
    code: PropTypes.string,
    status: PropTypes.string,
    message: PropTypes.string
  }),
  paymentSummary: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
  resetPromoCode: bindActionCreators(resetPromoCode, dispatch)
});

const mapStateToProps = state => ({
  state,
  paymentSummary: selectors.getPaymentSummary(state)
});

const enhancer = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default enhancer(PromoCode);
