import {propel} from "../../api";
import {flow} from "../../actions";
import {constants, messages} from "../../constants";
import {
  DRIVER_LICENSE_STATUS,
  FLOW_DRIVERS_LICENSE_BACK,
  FLOW_DRIVERS_LICENSE_FRONT,
  FLOW_SELFIE,
  FLOW_VALIDATE_MEMBERSHIP,
  SELFIE_STATUS
} from "../../constants/constants";
import * as selectors from "../../selectors";

const asyncValidate = async (values, dispatch, props, field) => {
  dispatch({type: constants.SPINNER_ON});

  if (field === 'promosAndSavings.iscaaMember'|| field === "promosAndSavings.memberNumber") {
    if (values.promosAndSavings.iscaaMember=== 'Yes' && values.promosAndSavings.memberNumber) {
      if (values.promosAndSavings.memberNumber.length >= 16) {
        return await propel.validateMembership(values.promosAndSavings.memberNumber)
        .then(res => {
          let payload = {res};
          dispatch({type: FLOW_VALIDATE_MEMBERSHIP, payload});
          dispatch({type: constants.SPINNER_OFF});
          let err = {};
          if(res.errors && res.errors[0])
          {
            err = {promosAndSavings: {memberNumber: messages.asyncValidation.BCAAMembershipValidation.defaultMessage}}; // must use err variable to keep eslint happy
            throw err
          }
          if(!res.active){
            if(!res.valid) {
              err = {promosAndSavings: {memberNumber: messages.asyncValidation.BCAAMembershipInactiveInvalid.defaultMessage}}; // must use err variable to keep eslint happy
              throw err
            }
            //this scenario needs the continue button to be active,  manually display the error message under the input (Valid, Inactive)
            // else {
            //   err = {promosAndSavings: {memberNumber: messages.asyncValidation.BCAAMembershipInactiveValid.defaultMessage}}; // must use err variable to keep eslint happy
            //   throw err

            //}
          } else if (res.active && !res.valid) {
            err = {promosAndSavings: {memberNumber: messages.asyncValidation.BCAAMembershipActiveInvalid.defaultMessage}}; // must use err variable to keep eslint happy
            throw err;
          }

        });
      }
    }
  }

  if (field === "personalInfo.emailAddress") {
    if (values.personalInfo.emailAddress) {
      return propel.validateEmail(values.personalInfo.emailAddress)
        .then(res => {
          dispatch({type: constants.SPINNER_OFF});
          if (!res.valid) {//
            const err = {personalInfo: {emailAddress: messages.asyncValidation.emailAddressDuplicate.defaultMessage}};  // must use err variable to keep eslint happy
            throw err;
          }

        });
    }
  }

  if (field === "driverLicence.number") {
    if (values && values.driverLicence && values.driverLicence.number) {
      return propel.validateDriversLicenseNumber(values.driverLicence.number)
        .then(res => {
          dispatch({type: constants.SPINNER_OFF});
          if (!res.valid) {
            const err = {driverLicence: {number: messages.asyncValidation.driversLicenseNumberDuplicate.defaultMessage}};  // must use err variable to keep eslint happy
            throw err;
          }

        });
    }
  }

  if (field === "driverLicence.imageFront" || field === "driverLicence.imageBack") {
    let DLInfo = selectors.getDriversLicenseFront(props.state);

    if (field === "driverLicence.imageBack" && DLInfo && DLInfo.data && DLInfo.data.results && DLInfo.data.results.province && DLInfo.data.results.province === "BC") {

      dispatch({type: constants.SPINNER_OFF});
      return Promise.resolve();
    }

    let frontImage, backImage;
    if (values && values.driverLicence && values.driverLicence.imageFront) {
      frontImage = values.driverLicence.imageFront;
      frontImage = await fetch(frontImage).then(r => r.blob());
      frontImage = new File([frontImage], "dl_front", {type: frontImage.type});
    }

    if (values && values.driverLicence && values.driverLicence.imageBack) {
      backImage = values.driverLicence.imageBack;
      backImage = await fetch(backImage).then(r => r.blob());
      backImage = new File([backImage], "dl_back", {type: backImage.type});
    }

    return flow.handleDriversLicense(frontImage, backImage)
      .then(results => {

        let payload = {
          status: DRIVER_LICENSE_STATUS.success,
          message: "",
          data: {results}
        };

        if (field === "driverLicence.imageFront") {
          if (results.errors && results.errors.length > 0) {
            payload.status = DRIVER_LICENSE_STATUS.error;
            payload.message = messages.error.imageValidationAttemptsReachedDriversLicense;
            if (!DLInfo) {
              payload.message = messages.error.imageMeetsCriteriaDriversLicense;
            }
          }
          dispatch({type: FLOW_DRIVERS_LICENSE_FRONT, payload});
          // return Promise.resolve();
        } else if (field === "driverLicence.imageBack") {
          if (results.errors && results.errors.length > 0 ) {
            payload.status = DRIVER_LICENSE_STATUS.error;
            payload.message = messages.error.imageValidationAttemptsReachedDriversLicense;
            if (!selectors.getDriversLicenseBack(props.state)) {
              payload.message = messages.error.imageMeetsCriteriaDriversLicense;
            }
          }
          dispatch({type: FLOW_DRIVERS_LICENSE_BACK, payload});
        }
        dispatch({type: constants.SPINNER_OFF});
      }).catch(() => null);
  }

  if (field === "identityVerificationSelfie.imageSelfie") {

    let selfieImage, frontImage, backImage;
    if (values && values.identityVerificationSelfie && values.identityVerificationSelfie.imageSelfie) {
      selfieImage = values.identityVerificationSelfie.imageSelfie;
      selfieImage = await fetch(selfieImage).then(r => r.blob());
      selfieImage = new File([selfieImage], "selfie", {type: selfieImage.type});
    }

    if (values && values.driverLicence && values.driverLicence.imageFront) {
      frontImage = values.driverLicence.imageFront;
      frontImage = await fetch(frontImage).then(r => r.blob());
      frontImage = new File([frontImage], "dl_front", {type: frontImage.type});
    }

    if (values && values.driverLicence && values.driverLicence.imageBack) {
      backImage = values.driverLicence.imageBack;
      backImage = await fetch(backImage).then(r => r.blob());
      backImage = new File([backImage], "dl_back", {type: backImage.type});
    }

    return flow.handleSelfie(frontImage, backImage, selfieImage)
      .then(results => {

        let payload = {
          status: SELFIE_STATUS.success,
          message: "",
          data: {results}
        };


          if (results.errors && results.errors.length > 0) {
            payload.status = SELFIE_STATUS.error;
            payload.message = messages.error.imageValidationAttemptsReachedSelfie;
            if (!selectors.getDriversLicenseFront(props.state)) {
              payload.message = messages.error.imageMeetsCriteriaSelfie;
            }
          }
          dispatch({type: FLOW_SELFIE, payload});

        dispatch({type: constants.SPINNER_OFF});
      }).catch(() => null);
  }

  if (field === "evolveBusinessReg.businessEmailAddress" || 
      field === "evolveBusinessReg.personalEmailAddress" ||  
      field === "evolveBusinessReg.isEvoMember" ||
      field === "evolveBusinessReg.accessCode") {
    let response_business, response_personal, response_access_code;
    let error_message_business, error_message_personal, error_message_access_code;

    // if business email address validation call the validate email domain
    if (values.evolveBusinessReg.businessEmailAddress) {
      response_business = await propel.validateEmailDomain(values.evolveBusinessReg.businessEmailAddress);
      if(response_business.status >= 500){
        error_message_personal = messages.error.genericError;
        const err = {
          evolveBusinessReg: {
            businessEmailAddress: error_message_business ? error_message_business.defaultMessage : null,
            personalEmailAddress: error_message_personal ? error_message_personal.defaultMessage : null,
            accessCode: error_message_access_code ? error_message_access_code.defaultMessage : null,
          }
        };  // must use err variable to keep eslint happy
        dispatch({type: constants.SPINNER_OFF});
        throw err;
      }
    }


    // access code validation
    if (values.evolveBusinessReg.accessCode) {
      response_access_code = await propel.validateAccessCode(values.evolveBusinessReg.accessCode);
      //console.log ("response_access_code = " + JSON.stringify (response_access_code));
      //console.log ("response_access_code.status = " + response_access_code.status);
      if(response_access_code.status >= 500){
        error_message_personal = messages.error.genericError;
        const err = {
          evolveBusinessReg: {
            businessEmailAddress: error_message_business ? error_message_business.defaultMessage : null,
            personalEmailAddress: error_message_personal ? error_message_personal.defaultMessage : null,
            accessCode: error_message_access_code ? error_message_access_code.defaultMessage : null,
          }
        };  // must use err variable to keep eslint happy
        dispatch({type: constants.SPINNER_OFF});

        //console.log ("throw err in access code = " + JSON.stringify (err));
        throw err;
      }
    }

    // if personal email address validation call the validate existing customer
    if (values.evolveBusinessReg.personalEmailAddress) {
      response_personal = await propel.validateExistingCustomer(values.evolveBusinessReg.personalEmailAddress);
      //console.log ("response_personal = " + JSON.stringify (response_personal));
      //console.log ("response_personal.status = " + response_personal.status);
      if(response_personal.status >= 500){
        error_message_personal = messages.error.genericError;
        const err = {
          evolveBusinessReg: {
            businessEmailAddress: error_message_business ? error_message_business.defaultMessage : null,
            personalEmailAddress: error_message_personal ? error_message_personal.defaultMessage : null,
            accessCode: error_message_access_code ? error_message_access_code.defaultMessage : null,
          }
        };  // must use err variable to keep eslint happy
        dispatch({type: constants.SPINNER_OFF});
        //console.log ("throw err in personal email address = " + JSON.stringify (err));
        throw err;
      }
    }

    // if Yes is selected and validateExistingCustomer returned an error show message that the member does not exist
    if (values.evolveBusinessReg.isEvoMember === "Yes" && (response_personal && response_personal.data.errors && response_personal.data.errors.length > 0)) {
      error_message_personal = messages.asyncValidation.invalidExistingCustomer;
    }

    // evo account exits res.valid = true and user said they are NOT a existing evo member show message saying they are an existing member
    else if (values.evolveBusinessReg.isEvoMember === "No" && (response_personal && response_personal.data.valid)) {
      error_message_personal = messages.asyncValidation.emailAddressDuplicatePrivateReg;
      //console.log ("personal email error message = " + error_message_personal);
    }


    // validate business email domain returned errors
    if (response_business && response_business.data.errors && response_business.data.errors.length > 0) {//
      error_message_business = messages.asyncValidation.emailDomainNotRegistered;
    }

    // validate access code errors 
    if (response_access_code && response_access_code.data.errors && response_access_code.data.errors.length > 0) {
      error_message_access_code = messages.asyncValidation.invalidB2BAccessCode;
      //console.log ("access code error message = " + error_message_access_code);
    }
    

    //if there are any error messages throw them
    if (error_message_business || error_message_personal || error_message_access_code) {
      const err = {
        evolveBusinessReg: {
          businessEmailAddress: error_message_business ? error_message_business.defaultMessage : null,
          personalEmailAddress: error_message_personal ? error_message_personal.defaultMessage : null,
          accessCode: error_message_access_code ? error_message_access_code.defaultMessage : null,
        }
      };  // must use err variable to keep eslint happy

      //console.log ("aysnc collected error messages in private reg - so dispatch spinner off - " + JSON.stringify(err));
      dispatch({type: constants.SPINNER_OFF});
      throw err;
    }

    //console.log ("aysnc no errors - so dispatch spinner off");
    //turn the spinner off
    dispatch({type: constants.SPINNER_OFF});
  }

  dispatch({type: constants.SPINNER_OFF});
  return Promise.resolve();
}
export default asyncValidate;
