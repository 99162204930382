import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import { Grid, Col, Section } from "../../../../components";
import { Table, Title, Txt } from "../../../../elements";
import { messages } from "../../../../constants";
import * as selectors from "../../../../selectors";
import { formatCurrency } from "../../../../util";

class PaymentSummary extends PureComponent {
  // function to get the selected field value
  getValue = (name) => {
    const { state, form, section } = this.props;
    const selector = formValueSelector(form);
    return selector(state, `${section}.${name}`);
  };

  render() {
    const { intl, paymentSummary } = this.props;
    let body = [];
    let total = 0;

    if (paymentSummary && paymentSummary.transactions) {
      body = paymentSummary.transactions.map(({ amount, taxType, name, productId, nameExt }) => {
        const amt = parseFloat(amount);
        total += amt;

        // find all product-code mappings at src/components/transactionReceipt/README.md
        var data;
        if (productId === "promo") {
          if (name) {
            if (!nameExt) nameExt = 0;
            data = intl.formatMessage(messages.transactions.promo, {
              promo_code: name,
              minutes: nameExt
            });
          } else data = intl.formatMessage(messages.transactions.nopromo);
        } else {
          data = intl.formatMessage(messages.transactions[`${productId}`]);
        }

        return {
          theme: [amt < 0 ? "primary" : ""],
          row: [
            { theme: [], data },
            { theme: ["currency"], data: formatCurrency(amt) }
          ]
        };
      });
    }

    const foot = [
      {
        row: [
          { theme: ["large"], data: intl.formatMessage(messages.title.total) },
          { theme: ["large", "currency", "primary"], data: formatCurrency(total) }
        ]
      }
    ];

    return (
      <div>
        <Section size="2-3" min="md" theme={["div"]}>
          <Grid>
            <Col>
              <Title priority={3} type={["normalize"]}>
                {intl.formatMessage(messages.title.WhatYouPay)}
              </Title>
              <div>
                <Txt>{intl.formatMessage(messages.title.NoChargeEvolve, { newLine: <br /> })}</Txt>
              </div>
              <Table body={body} foot={foot} />
            </Col>
          </Grid>
        </Section>
      </div>
    );
  }
}

PaymentSummary.propTypes = {
  state: PropTypes.object,
  form: PropTypes.string,
  section: PropTypes.string,
  intl: PropTypes.object.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  promoParam: PropTypes.object,
  paymentSummary: PropTypes.object
};

const mapStateToProps = (state) => ({
  state,
  paymentSummary: selectors.getPaymentSummary(state)
});

export default injectIntl(connect(mapStateToProps)(PaymentSummary));
