import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import "./title.scss";

const Title = props => {
  const { children, type, className, priority } = props;
  const CustomTag = `h${priority}`;
  // theme normalize to show default header styles
  const titleCSS =
    type.indexOf("normalize") < 0
      ? cn("title", className, type ? type.map(css => `title--${css}`).join(" ") : "")
      : "";

  return <CustomTag className={titleCSS}>{children}</CustomTag>;
};

Title.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string
  ]),
  type: PropTypes.array,
  className: PropTypes.string,
  priority: PropTypes.number
};

Title.defaultProps = {
  type: [],
  className: "",
  priority: 1
};

export default Title;
