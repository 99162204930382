import React, { PureComponent } from "react";
// import moment from "moment-timezone";
import { compose, bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import get from "lodash/get";

import { PagesContainer } from "../../containers";
import { ErrorPage } from "../";
import {
  AccountLanding,
  AccountBilling,
  AccountBillingDetails,
  AccountStatements,
  AccountMinutes
} from "../../screens";
import * as selectors from "../../selectors";
import { fetchBillingBalances, fetchPayments } from "../../actions/billing";
import { loginWithToken } from "../../actions/auth";
import { fetchMinutes } from "../../actions/minutes";
import { getJsonFromUrl } from "../../util";
import { constants } from "../../constants";

class Account extends PureComponent {
  componentDidMount() {
    const { loginWithToken } = this.props;
    try {
      const queryObj = getJsonFromUrl(get(this.props, "location.search", ""));
      const { idToken, accessToken } = queryObj;
      if (idToken && accessToken) {
        loginWithToken({ idToken, accessToken });
      }
    } catch (error) {}
    const { fetchBillingBalances, fetchMinutes } = this.props;

    fetchBillingBalances();
    fetchMinutes();
  }

  render() {
    const { billing, isLoggedIn, isSpinnerActive } = this.props;
    const queryObj = getJsonFromUrl(get(this.props, "location.search", ""));
    const { idToken, accessToken } = queryObj;
    if (idToken && accessToken) {
      loginWithToken({ idToken, accessToken });
    }
    if (!(idToken && accessToken) && !isLoggedIn) {
      return <Redirect to={"/"} />;
    }
    if (!billing) {
      return null;
    }

    if (isSpinnerActive) {
      return null;
    }
    return (
      <PagesContainer {...this.props}>
        <Switch>
          <Route exact path={constants.ROUTE_ACCOUNT} component={AccountLanding} />
          <Route exact path={constants.ROUTE_ACCOUNT_MINUTES} component={AccountMinutes} />
          <Route exact path={constants.ROUTE_ACCOUNT_BILLING} component={AccountBilling} />
          <Route
            exact
            path={constants.ROUTE_ACCOUNT_BILLING_DETAIL}
            component={AccountBillingDetails}
          />
          <Route exact path={constants.ROUTE_ACCOUNT_STATEMENTS} component={AccountStatements} />
          <Route path="*" component={ErrorPage} />
        </Switch>
      </PagesContainer>
    );
  }
}

Account.defaultProps = {
  isLoggedIn: false
};

Account.propTypes = {
  isLoggedIn: PropTypes.bool,
  match: PropTypes.object.isRequired,
  billing: PropTypes.object,
  fetchBillingBalances: PropTypes.func.isRequired,
  fetchPayments: PropTypes.func.isRequired,
  fetchMinutes: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isLoggedIn: selectors.isLoggedIn(state),
  billing: selectors.getBilling(state).balance,
  isSpinnerActive: selectors.getSettings(state).isSpinnerActive
});

const mapDispatchToProps = dispatch => ({
  fetchBillingBalances: bindActionCreators(fetchBillingBalances, dispatch),
  fetchPayments: bindActionCreators(fetchPayments, dispatch),
  fetchMinutes: bindActionCreators(fetchMinutes, dispatch),
  loginWithToken: bindActionCreators(loginWithToken, dispatch)
});

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(Account);
