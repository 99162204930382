import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
// import { injectIntl } from 'react-intl';
import "./radio.scss";

import {Tooltip, Txt} from "../../elements";
import Parser from "html-react-parser";
import DOMPurify from "dompurify";

const Radio = props => {
  const {className, theme, options, disabled, input, meta, label, required, tooltip, tooltipArrow, labelClassName, radioFieldClassName} = props;
  const {onChange, value, name} = input;
  const sanitized = typeof label === "string" ? Parser(DOMPurify.sanitize(label)) : label;
  let dirty, error, asyncValidating;
  if (meta) {
    dirty = meta.dirty;
    error = meta.error;
    asyncValidating = meta.asyncValidating;
  }
   //const { pristine, dirty, valid, warning, invalid, active } = meta;
  const radioCSS = cn("radio", className, theme ? theme.map(css => `radio--${css}`).join(" ") : "");
  const radioFieldName = radioFieldClassName? "radio__field-" + radioFieldClassName : "radio__field";
  const renderOptions = options =>
    options.map((option, idx) => (
      <div className={asyncValidating? 'async-validating ' + radioFieldName: radioFieldName} key={idx}>
        <input
          type="radio"
          name={name}
          id={name + option.value}
          disabled={disabled}
          value={option.value}
          checked={value === option.value}
          onChange={e => onChange(e.target.checked ? e.target.value : null)}
        />
        <label htmlFor={name + option.value}>{option.label}</label>
      </div>
    ));

  // Handle group title and tooltip outside of this module
  return (
    <fieldset className={radioCSS} role="group">

      <label htmlFor={name} className={labelClassName}>
        {sanitized}
        {required ? " *" : ""}
        <span
          className="tooltip-container"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {tooltip && <Tooltip arrow={tooltipArrow}>{tooltip}</Tooltip>}
        </span>
        <span className="padding_left">{renderOptions(options)}</span>
      </label>

      {dirty && error && (
        <Txt theme={["error"]}>
          <small>{error}</small>
        </Txt>
      )}
    </fieldset>
  );
};

Radio.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.array,
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  tooltipArrow: PropTypes.string,
  labelClassName: PropTypes.string,
  radioFieldClassName: PropTypes.string,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object
};

Radio.defaultProps = {
  theme: [],
  className: "",
  disabled: false
};

export default Radio;
