/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {PureComponent} from "react";
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";

import {FormSectionWrapper} from "../";
import DriversLicenseRequirements from "../../fieldGroups/driversLicenseRequirements/driversLicenseRequirements";

import {formValueSelector} from "redux-form";
import {messages} from "../../../../constants";
import driversLicenseBack from "../../../../assets/img/driversLicenseBack_small.png";
import {bindActionCreators} from "redux";
import {flow} from "../../../../actions";
import {connect} from "react-redux";
import CaptureImageWrapper from "../../fieldGroups/imageCapture/captureImageWrapper";

class AddDriversLicenseBack extends PureComponent {
  // function to get the selected field value
  getValue = name => {
    const {state, form, section} = this.props;
    const selector = formValueSelector(form);
    if (section) {
      return selector(state, `${section}.${name}`);
    }
    return selector(state, name);
  };

  render() {
const {intl, handleDriversLicenseValidate} = this.props;
    return (
        <FormSectionWrapper {...this.props}>

          <CaptureImageWrapper
            {...this.props}
            upperImageCaption={intl.formatMessage(messages.imageCapture.driversLicenseBack)}
            orientation={intl.formatMessage(messages.imageCapture.landscape)}
            icon={driversLicenseBack}
            handleValidate={handleDriversLicenseValidate}
            isSelfie={false}
            isBack={true}
          />

          <DriversLicenseRequirements {...this.props}/>
        </FormSectionWrapper>

    );
  };
};

AddDriversLicenseBack.propTypes = {
  intl: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  actions: PropTypes.objectOf(PropTypes.func),
  handleDriversLicenseValidate: PropTypes.func.isRequired
};
const mapDispatchToProps = (dispatch) => ({
  handleDriversLicenseValidate: bindActionCreators(flow.handleDriversLicense, dispatch)
});

export default injectIntl(connect(null,mapDispatchToProps)(AddDriversLicenseBack));
