import { defineMessages } from "react-intl";

const namespace = `personal`;
const personal = defineMessages({
  lang: {
    id: `${namespace}.lang`,
    description: "Language Field",
    defaultMessage: "Language"
  },
  tel: {
    id: `${namespace}.tel`,
    description: "Phone Field",
    defaultMessage: "Phone number"
  },
  firstName: {
    id: `${namespace}.firstName`,
    description: "First Name Field",
    defaultMessage: "First Name"
  },
  middleName: {
    id: `${namespace}.middleName`,
    description: "Middle Name Field",
    defaultMessage: "Middle Name"
  },
  lastName: {
    id: `${namespace}.lastName`,
    description: "Last Name Field",
    defaultMessage: "Last Name"
  },
  preferredName: {
    id: `${namespace}.preferredName`,
    description: "Preferred Name Field",
    defaultMessage: "Preferred first name"
  },
  preferredNameInfo: {
    id: `${namespace}.preferredNameInfo`,
    description: "Preferred Name Field help text",
    defaultMessage: "This is the information we’ll use when contacting you."
  },
  dob: {
    id: `${namespace}.dob`,
    description: "DOB field label",
    defaultMessage: "Date of birth"
  },
  gender: {
    id: `${namespace}.gender`,
    description: "Gender field",
    defaultMessage: "Gender"
  },
  gendermale: {
    id: `${namespace}.gender.male`,
    description: "Gender field male option label",
    defaultMessage: "Male"
  },
  genderfemale: {
    id: `${namespace}.gender.female`,
    description: "Gender field female option label",
    defaultMessage: "Female"
  },
  genderna: {
    id: `${namespace}.gender.na`,
    description: "Gender field na option label",
    defaultMessage: "Prefer not to say"
  },
  genderother: {
    id: `${namespace}.gender.other`,
    description: "Gender field other option label",
    defaultMessage: "Other"
  },
  pronoun:{
    id: `${namespace}.pronoun`,
    description: "Pronoun field option label",
    defaultMessage: "Pronouns"
  },
  pronounHeHim:{
    id: `${namespace}.pronoun.he.him`,
    description: "Pronoun field he/him option label",
    defaultMessage: "He/Him"
  },
  pronounSheHer:{
    id: `${namespace}.pronoun.she.her`,
    description: "Pronoun field she/her option label",
    defaultMessage: "She/Her"
  },
  pronounTheyThem:{
    id: `${namespace}.pronoun.they.them`,
    description: "Pronoun field they/them option label",
    defaultMessage: "They/Them"
  },
  pronounHeThey:{
    id: `${namespace}.pronoun.he.they`,
    description: "Pronoun field he/they option label",
    defaultMessage: "He/They"
  },
  pronounSheThey:{
    id: `${namespace}.pronoun.she.they`,
    description: "Pronoun field she/they option label",
    defaultMessage: "She/They"
  },
  pronounOther:{
    id: `${namespace}.pronoun.other`,
    description: "Pronoun field other option label",
    defaultMessage: "Other"
  },
  pronounOtherCustom:{
    id: `${namespace}.pronoun.other.custom`,
    description: "Pronoun field other option label",
    defaultMessage: "Pronouns"
  },
  pronounPreferNotToSay:{
    id: `${namespace}.pronoun.na`,
    description: "Pronoun field na option label",
    defaultMessage: "Prefer not to say"
  },
  pronounSubText:{
    id: `${namespace}.pronoun.sub`,
    description: "Pronoun field sub label text",
    defaultMessage: "To help us make your Evo experience amazing, let us know how we can refer to you."
  },

  cityoptin: {
    id: `${namespace}.cityoptin`,
    description: "Media optin dropdown label",
    defaultMessage: "Which city are you joining Evo in?"
  },
  agreement: {
    id: `${namespace}.agreement`,
    description: "Agreement Checkbox",
    defaultMessage: "I confirm I am over the age of 18, and have read and accepted the {terms} including the {privacy}"
  },
  agreementlink: {
    id: `${namespace}.agreement.link`,
    description: "Agreement Link",
    defaultMessage: "https://evo.ca/member-agreement"
  },
  agreementtext: {
    id: `${namespace}.agreement.txt`,
    description: "Agreement Link Text",
    defaultMessage: "Evo Membership Agreement and Privacy Policy*"
  },
  marketingOptin: {
    id: `${namespace}.marketingOptin`,
    description: "Marketing Optin Checkbox",
    defaultMessage:
      "I want the latest info on free minutes, partner offers, and Evo news. Opting-in is a requirement to be a Member. You can opt-out at any time."
  },
  marketingOptinCity: {
    id: `${namespace}.marketingOptinCity`,
    description: "Info text for marking opt in city",
    defaultMessage:
      "Choose the city you would like to receive info from. Change your city preferences in your account at any time."
  },
  marketingOptinCityTooltip: {
    id: `${namespace}.marketingOptinCityTooltip`,
    description: "Tooltip for marking opt in city",
    defaultMessage: "Pick the city you will be doing the most driving in."
  },
  forgotYourPassword: {
    id: `${namespace}.forgotYourPassword`,
    description: "Forgot Password Email screen title",
    defaultMessage: "Forgot your password?"
  },
  enterYourEmailAddress: {
    id: `${namespace}.enterYourEmailAddress`,
    description: "Subtitle on forgot password page (email step)",
    defaultMessage: "Enter your email address to reset your password."
  },
  sendMeAResetLink: {
    id: `${namespace}.sendMeAResetLink`,
    description: "Subtitle on forgot password page (email step)",
    defaultMessage: "SEND ME A RESET LINK"
  },
  resetLinkSent: {
    id: `${namespace}.resetLinkSent`,
    description: "Subtitle on forgot password page (email step)",
    defaultMessage: "We’ve sent you the password reset link to:"
  },
  linkHasExpired: {
    id: `${namespace}.linkHasExpired`,
    description: "password reset expiry message",
    defaultMessage: "Your link has expired. Use the link below to get a new one."
  },
  resetPasswordSuccessBanner: {
    id: `${namespace}.resetPasswordSuccessBanner`,
    description: "password reset success banner message",
    defaultMessage: "You have successfully reset your password."
  },
  changeEmailSuccessBanner: {
    id: `${namespace}.changeEmailSuccessBanner`,
    description: "email changed success banner message",
    defaultMessage:
      "<strong>Success!</strong> Your email has been changed. Login with your new email below."
  }
});

export default personal;
