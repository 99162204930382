import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { destroy, reset } from "redux-form";

import { messages, constants } from "../../constants";
import { Section, Grid, Col } from "../../components";
import { Title, Txt } from "../../elements";
import { UpdatePreferenceCenterForm } from "../../containers/form";
import { user as userActions, banner as bannerActions } from "../../actions";
import "./AccountPreferenceCenter.scss";
import { diff } from 'deep-object-diff';
import { omit, isEmpty, pick } from 'lodash';


class AccountPreferenceCenter extends Component {
  state = { updateMode: false, isLoading: true };

  async componentDidMount() {
    this.fetchMembership();
  }

  render() {
    const { intl, destroyForm, user } = this.props;
    const { updateMode, isLoading } = this.state;

    if (isLoading) return null;

    const membership = user.membership ? this.mapFieldsBeToFe(user.membership) : {};

    return (
      <Fragment>
        <Section>
          <Grid>
            <Col>
              <Link
                to={constants.ROUTE_ACCOUNT}
                onClick={() => {
                  destroyForm(constants.FORM.updatePreferenceCenterForm);
                }}
              >
                <Txt theme={["upper"]}>
                  <i className="fa fa-chevron-left" />{" "}
                  {intl.formatMessage(messages.button.backtoAccountDetails)}
                </Txt>
              </Link>
            </Col>
          </Grid>
        </Section>
        <Section theme={["div"]}>
          <Grid>
            <Col>
              <Title priority={1} type={["strong"]} className="h2">
                {intl.formatMessage(messages.title.Preferences)}
              </Title>
            </Col>
          </Grid>
        </Section>
        <Section>
          <Grid>
            <Col>
              <UpdatePreferenceCenterForm
                updateMode={updateMode}
                toggleUpdateMode={this.toggleUpdateMode}
                onSubmit={this.handleSubmit}
                initialValues={membership}
              />
            </Col>
          </Grid>
        </Section>
        <Section>
          <Grid>
            <Col />
          </Grid>
        </Section>
      </Fragment>
    );
  }

  mapFieldsBeToFe = beFields => {
    const {
      memberNumber,
      memberIsActive,
      isStudent,
      schoolName,
      // marketingRegionId, // waiting for api (mutli city)
      isSMSConsent,
      isEmailConsent,
      isBCAAEmailConsent
    } = beFields;

    return {
      iscaaMember: memberIsActive,
      isSMSConsent,
      isEmailConsent,
      isBCAAEmailConsent,
      memberNumber,
      isStudent,
      schoolName
    };
  };

  toggleUpdateMode = value => {
    this.setState({ updateMode: value });
  };

   fetchMembership = async () => {
    const { getCustomerMembership,setAlerts, intl } = this.props;
    try {
      await getCustomerMembership();
      this.setState({ isLoading: false });
    } catch (e) {
      setAlerts([
        {
          level: constants.ALERT_LEVEL.error,
          message: intl.formatMessage(messages.error.genericError)
        }
      ]);
      this.setState({ isLoading: false });
    }
  }

  handleSubmit = async values => {
    const {
      iscaaMember,
      memberNumber,
      isStudent
    } = values;

    const {
      user,
      setAlerts,
      intl,
      updateCustomerMembership,
      removeCustomerMembership,
      patchCustomerMembership
    } = this.props;
    const originalMembershipValues = user.membership ? this.mapFieldsBeToFe(user.membership) : {};

    const changedValues = diff(originalMembershipValues, values);
    let changedPatchValues = omit(changedValues, 'memberNumber', 'iscaaMember');
    let changedMemberPostValues = pick(changedValues, 'memberNumber');

    if (isStudent !== originalMembershipValues.isStudent && !isStudent) {
      changedPatchValues['schoolName'] = '';
    }

    if (iscaaMember !== originalMembershipValues.iscaaMember && !iscaaMember) {
      changedMemberPostValues['memberNumber'] = '';
    }


    // update membership
    // opt out membership if membership id exists and iscaaMember is false
    const promises = [];

    if (iscaaMember) {
      if (!isEmpty(changedMemberPostValues))
        promises.push(updateCustomerMembership({memberNumber}));
    } else {
      if (originalMembershipValues.memberNumber) {
        promises.push(removeCustomerMembership(originalMembershipValues.memberNumber));
      }
    }
    if (!isEmpty(changedPatchValues))
      promises.push(patchCustomerMembership(changedPatchValues));

    isEmpty(promises) ? this.props.resetForm(constants.FORM.updatePreferenceCenterForm) : Promise.all(promises)
      .then((results) => {
        this.setState({updateMode: false});

        // set success alert message to banner
        this.props.setAlerts([
          {
            level: constants.ALERT_LEVEL.success,
            message: intl.formatMessage(messages.success.savePreferenceCentre)
          }
        ]);
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        this.props.resetForm(constants.FORM.updatePreferenceCenterForm);
        setAlerts([
          {
            level: constants.ALERT_LEVEL.error,
            message: intl.formatMessage(messages.error.genericError)
          }
        ]);
        window.scrollTo(0, 0);
      });
  };
}

AccountPreferenceCenter.propTypes = {
  intl: PropTypes.object.isRequired,
  pushNav: PropTypes.func,
  destroyForm: PropTypes.func
};

const mapStateToProps = ({ user }) => ({
  user
});

const mapDispatch = {
  destroyForm: destroy,
  resetForm: reset,
  getCustomerMembership: userActions.getCustomerMembership,
  updateCustomerMembership: userActions.updateCustomerMembership,
  removeCustomerMembership: userActions.removeCustomerMembership,
  patchCustomerMembership: userActions.patchCustomerMembership,
  setAlerts: bannerActions.setAlerts
};

export default compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatch
  )
)(AccountPreferenceCenter);
