import { constants } from "../constants";

export const DEFAULT_STATE = {
  userResetEmail: null
};

const var1 = (state = DEFAULT_STATE, { type, payload } = {}) => {
  switch (type) {
    case constants.SET_USER_RESET_EMAIL:
      return {
        ...state,
        userResetEmail: payload.userResetEmail
      };
    default:
      return state;
  }
};

export default var1;
