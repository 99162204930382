import React, { PureComponent } from "react";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import { messages } from "../../../../constants";
import {Txt} from "../../../../elements";

class RequiredFieldFooterNote extends PureComponent {
  render() {
    const { intl } = this.props;
    return (
      <Txt>
        <br/>
        <small>{intl.formatMessage(messages.field.requiredFieldLabel)}</small>
      </Txt>
    );
  }
}

RequiredFieldFooterNote.propTypes = {
  intl: PropTypes.object.isRequired,

};

export default injectIntl(RequiredFieldFooterNote);
