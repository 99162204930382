const { fetch: originalFetch } = window;


export const fetchInterceptor = async (...args) => {
    let [resource, config] = args;
    // console.log("Came here", resource, config);
    // console.log(process.env)
    if(process.env.REACT_APP_ENV==='local') {
        resource = resource.replace('api','stg')
    }
    try {
        const response = await originalFetch(resource, config);
        return response;
    } catch (error) {
        console.error('Error: ', error) // the console error statement to know what the exact error is
        return new Response('{"valid":false}', { status: 500, statusText: 'Internal Server Error' })
    }
};
