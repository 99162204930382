import { defineMessages } from "react-intl";

const namespace = "maps";
const transaction = defineMessages({
  walkingDistance: {
    id: `${namespace}.walkingDistance`,
    description: "car info window detail walking distance for car <= 1000m away",
    defaultMessage: "{distance} - {walkingTime} min walk"
  },
  distance: {
    id: `${namespace}.walkingDistance`,
    description: "car info window detail distance to car > 1000m away",
    defaultMessage: "{distance}"
  },
  fuelLevel: {
    id: `${namespace}.fuelLevel`,
    description: "car info window detail fuel level",
    defaultMessage: "Fuel {fuelLevel}%"
  },
  bookingInProgress: {
    id: `${namespace}.bookingInProgress`,
    description: "car info window detail message",
    defaultMessage: "Booking in Progress"
  },
  evoIsReady: {
    id: `${namespace}.evoIsReady`,
    description: "car info window detail message",
    defaultMessage: "Your Evo is Ready"
  },
  descriptionInfo: {
    id: `${namespace}.descriptionInfo`,
    description: "car info window detail description info",
    defaultMessage: "Directions & Information"
  },
  timeRemaining: {
    id: `${namespace}.timeRemaining`,
    description: "car info window count down",
    defaultMessage: "Reservation time remaining"
  },
  days: {
    id: `${namespace}.days`,
    description: "car info window days",
    defaultMessage: "Days",
  },
  hours: {
    id: `${namespace}.hours`,
    description: "car info window hours",
    defaultMessage: "Hours",
  },
  minutes: {
    id: `${namespace}.minutes`,
    description: "car info window minutes",
    defaultMessage: "Minutes",
  },
  seconds: {
    id: `${namespace}.seconds`,
    description: "car info window seconds",
    defaultMessage: "Seconds",
  }

});

export default transaction;
