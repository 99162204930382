import React, { PureComponent } from "react";

import { Field, formValueSelector } from "redux-form";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";


import { Input, Grid, Col, Section } from "../../../../components";
import { messages } from "../../../../constants";


import { validationBuilder, validations } from "../..";

let rules = {};
class PhoneNumberField extends PureComponent {
  rulePhone = [];

  constructor(props) {
    super(props);
    // for field level validations
    // TODO: find a better way to instantiate props when the component mounts
    Object.keys(validations).forEach(key => {
      rules[key] = spec => value => validationBuilder(props, validations[key], spec)(value);
    });
    const { intl } = props;
    this.rulePhone = [rules.required(intl.formatMessage(messages.personal.tel)), rules.phone()];
  }

  // function to get the selected field value
  getValue(name) {
    const { state, form, section } = this.props;
    const selector = formValueSelector(form);
    return selector(state, `${section}.${name}`);
  }

  render() {
    const { intl } = this.props;
    return (
      <Section size="1-2" min="md">
        <Grid>
          <Col size="2-3" min="sm">
            <Field
              type="tel"
              name="phoneNumber"
              label={intl.formatMessage(messages.personal.tel)}
              required={true}
              validate={this.rulePhone}
              component={Input}
              props={{ maxLength: "20" }}
            />
          </Col>
        </Grid>

      </Section>
    );
  }
}

PhoneNumberField.propTypes = {
  intl: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  section: PropTypes.string.isRequired
  // actions: PropTypes.objectOf(PropTypes.func).isRequired,
  // isEditNameMode: PropTypes.bool,
  // endEdit: PropTypes.func.isRequired,
};

// const mapStateToProps = state => ({
//   isEditNameMode: selectors.getIsEditNameMode(state)
// });

// const mapDispatchToProps = dispatch => ({
//   actions: bindActionCreators(actions, dispatch)
// });

export default injectIntl(PhoneNumberField);
