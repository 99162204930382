import React, { PureComponent } from "react";
import { Field } from "redux-form";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import { Txt } from "../../../../elements";
import { Input, MonerisTokenizer, Grid, Col, Section, DependOn } from "../../../../components";
import { messages } from "../../../../constants";
import { validationBuilder, validations } from "../..";
// eslint-disable-next-line
import StaticMoneris from "./staticMoneris";
import mastercard from "../../../../assets/img/mastercard.png"
import visa from "../../../../assets/img/visa.png"
import amex from "../../../../assets/img/amex.png"

import "./moneris.scss";

let rules = {};
class Moneris extends PureComponent {
  ruleMonerisName = [];

  constructor(props) {
    super(props);
    // for field level validations
    Object.keys(validations).forEach(key => {
      rules[key] = spec => value => validationBuilder(props, validations[key], spec)(value);
    });

    const { intl } = props;
    this.ruleMonerisName = [rules.required(intl.formatMessage(messages.moneris.fullname))];
  }
  render() {
    const { intl, disabled, creditCardValues, shouldShowAcceptedCardTypes } = this.props;
    return (
      <Section size="2-3" min="md">
        <DependOn active={shouldShowAcceptedCardTypes}>
          <Grid>
            <Col>
              <Txt theme={["note"]}>
                <small>{intl.formatMessage(messages.moneris.acceptedCreditcards)}</small>
              </Txt>
              <img className="ccLogo" src={mastercard} alt="Master Card" />
              <img className="ccLogo" src={visa} alt="Visa" />
              <img className="ccLogo" src={amex} alt="AMEX" />
            </Col>
          </Grid>
        </DependOn>
        <Grid>
          <Col size="1-2" min="sm">
            <Field
              component={Input}
              name="monerisFullname"
              type="text"
              label={intl.formatMessage(messages.moneris.fullname)}
              required={true}
              dirtylook={true}
              validate={this.ruleMonerisName}
              disabled={disabled}
              props={{ maxLength: "50" }}
            />
          </Col>
        </Grid>
        <Grid>
          <Col size="1-2" min="sm">
            {disabled ? (
              <StaticMoneris creditCardValues={creditCardValues} />
            ) : (
              <MonerisTokenizer {...this.props} />
            )}
          </Col>
        </Grid>
      </Section>
    );
  }
}

Moneris.propTypes = {
  intl: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  creditCardValues: PropTypes.object,
  shouldShowAcceptedCardTypes: PropTypes.bool
};

Moneris.defaultProps = {
  shouldShowAcceptedCardTypes: true
};

export default injectIntl(Moneris);
