import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { compose } from "redux";
import { connect } from "react-redux";
import get from "lodash/get";
import { Link } from "react-router-dom";
import { SubmissionError } from "redux-form";

import { messages, constants } from "../../constants";
import { Section, Grid, Col } from "../../components";
import { UpdateEmailForm } from "../../containers/form";
import { Title, Txt } from "../../elements";
import { auth as authActions, user as userActions, banner as bannerActions } from "../../actions";

class AccountLoginInfo extends PureComponent {
  state = {
    loading: true,
    updateMode: false
  };

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    this.props.getCustomerEmail().then(() => {
      this._isMounted && this.setState({ loading: false });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toggleUpdateMode = () => {
    this.setState(({ updateMode }) => ({ updateMode: !updateMode }));
  };

  handleChangeEmail = async ({ emailAddress }) => {
    const { intl, patchCustomerEmail, setAlerts, logout } = this.props;
    try {
      await patchCustomerEmail({ emailAddress: emailAddress.toLowerCase() });
      logout(`${constants.ROUTE_SIGNIN}?message=${constants.LOGIN_BANNER_TYPE.changeEmailSuccess}`);
    } catch (error) {
      if (error.message === "apiUpdateError") {
        throw new SubmissionError({
          emailAddress: intl.formatMessage(messages.error.emailAlreadyExists)
        });
      } else {
        setAlerts([
          {
            level: constants.ALERT_LEVEL.error,
            message: intl.formatMessage(messages.error.genericError)
          }
        ]);
      }
      window.scrollTo(0, 0);
    }
  };

  render() {
    const { intl, state } = this.props;
    const { updateMode, loading } = this.state;
    const emailAddress = get(state, ["user", "emailAddress"], "");

    return (
      <Fragment>
        <Section>
          <Grid>
            <Col>
              <Link to={constants.ROUTE_ACCOUNT}>
                <Txt theme={["upper"]}>
                  <i className="fa fa-chevron-left" />
                  {intl.formatMessage(messages.button.backtoAccountDetails)}
                </Txt>
              </Link>
            </Col>
          </Grid>
        </Section>
        <Section theme={["div"]}>
          <Grid>
            <Col>
              <Title priority={1} type={["strong"]} className="h2">
                {intl.formatMessage(messages.title.email)}
              </Title>
              <Txt>{intl.formatMessage(messages.title.emailSub)}</Txt>
            </Col>
            <Col size="2-3" min="sm">
              <UpdateEmailForm
                updateMode={updateMode}
                toggleUpdateMode={this.toggleUpdateMode}
                onSubmit={this.handleChangeEmail}
                initialValues={{ emailAddress }}
                loading={loading}
              />
            </Col>
          </Grid>
        </Section>
      </Fragment>
    );
  }
}

AccountLoginInfo.propTypes = {
  intl: PropTypes.object.isRequired,
  state: PropTypes.object,
  setAlerts: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  state
});

const mapDispatchToProps = {
  getCustomerEmail: userActions.getCustomerEmail,
  patchCustomerEmail: userActions.patchCustomerEmail,
  setAlerts: bannerActions.setAlerts,
  logout: authActions.logout
};

export default compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AccountLoginInfo);
