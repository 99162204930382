import { defineMessages } from "react-intl";

const namespace = "record";
const record = defineMessages({
  approvalContent: {
    id: `${namespace}.approvalContent`,
    description: "Content for BC Driver",
    defaultMessage: "You're almost ready to roll.<br /><br />"
  },
  approvalContent2: {
    id: `${namespace}.approvalContent2`,
    description: "Content for BC Driver",
    defaultMessage:
      "Want to hop on an e-bike/e-scooter? You can use the Evo App to find and unlock one right away."
  },
  bcContent: {
    id: `${namespace}.bcContent`,
    description: "Content for BC Driver",
    defaultMessage:
      "To complete your registration, visit, {link} and have your 2-year driving record sent to {email} (we don't accept driving records unless they come directly from ICBC)  We've put together a handy guide below to help.{newLine}{newLine}"
  },
  bcEVOEmailAddress: {
    id: `${namespace}.bcEVOEmailAddress`,
    description: "Email Address for Copy EVO Email Address to send to ICBC",
    defaultMessage: "records@evo.ca"
  },
  bcCopyEmail: {
    id: `${namespace}.bcCopyEmail`,
    description: "Copy email link text",
    defaultMessage: "copy email"
  },
  bcButtonText: {
    id: `${namespace}.bcButton.text`,
    description: "Button Text for BC",
    defaultMessage: "REQUEST DRIVING RECORD FROM ICBC"
  },
  bcButtonLink: {
    id: `${namespace}.bcButton.link`,
    description: "Button Link for BC",
    defaultMessage: "https://onlinebusiness.icbc.com/clio/"
  },
  nonBcContent: {
    id: `${namespace}.nonBcContent`,
    description: "Content for non-BC Driver",
    defaultMessage:
      "Before you can hit the road, we need your 2-year driving record sent to us. {newLine}{newLine}Here’s how to do it:{newLine}{bullets}  It is important to ensure the documents provided verify your history as a driver, and not just provide a confirmation that you have a valid licence."
  },
  nonBcButtonText: {
    id: `${namespace}.nonBcButton.text`,
    description: "Button Text for non-BC",
    defaultMessage: "CONTINUE"
  },
  nonBcButtonLink: {
    id: `${namespace}.nonBcButton.link`,
    description: "Button Link for non-BC",
    defaultMessage: "https://onlinebusiness.icbc.com/clio/"
  },
  goToAccount: {
    id: `${namespace}.go.to.account`,
    description: "Go to account text",
    defaultMessage: "Take me to my account"
  },
  goToAccountLink: {
    id: `${namespace}.go.to.account.link`,
    description: "Go to account link",
    defaultMessage: " https://evo-fo.vulog.center/login"
  },
  goToRegistrationFAQs: {
    id: `${namespace}.go.to.registrationFAQs`,
    description: "Go to registration FAQs text",
    defaultMessage: "GO TO REGISTRATION FAQs"
  },
  nonBCFAQButtonLink: {
    id: `${namespace}.nonBCFAQButtonLink`,
    description: "Button Link for BC",
    defaultMessage:
      "https://evo.ca/faq#i-have-a-licence-that-was-not-issued-in-bc-how-can-i-obtain-my-driving-record"
  },
  bcImgAlt: {
    id: `${namespace}.bcImgAlt`,
    description: "BC screenshot image alt text",
    defaultMessage: "Enter records@evo.ca in the email field when requesting from ICBC"
  },
  bcImgText: {
    id: `${namespace}.bcImgText`,
    description: "BC screenshot image sub text",
    defaultMessage:
      "Enter <span class='bold'>records@evo.ca</span> in the contact email to avoid delaying your Membership approval.  We don't accept records unless they come directly from ICBC."
  },
  almostDone: {
    id: `${namespace}.almostDone`,
    description: "Ebikes text",
    defaultMessage: "You’re almost done!{newLine}"
  },
  evoDrivingRecord: {
    id: `${namespace}.evoDrivingRecord`,
    description: "Evo request driving text",
    defaultMessage:
      "Once you've submitted your driving record, keep an eye on you inbox - we'll let you know your Evo Membership is approved in a few business days."
  }
});

export default record;
