// prettier-ignore
import { defineMessages } from "react-intl";

const namespace = "states";
const states = defineMessages({
  AL: { id: `${namespace}.AL`, description: "Alabama", defaultMessage: "Alabama" },
  AK: { id: `${namespace}.AK`, description: "Alaska", defaultMessage: "Alaska" },
  AZ: { id: `${namespace}.AZ`, description: "Arizona", defaultMessage: "Arizona" },
  AR: { id: `${namespace}.AR`, description: "Arkansas", defaultMessage: "Arkansas" },
  CA: { id: `${namespace}.CA`, description: "California", defaultMessage: "California" },
  CO: { id: `${namespace}.CO`, description: "Colorado", defaultMessage: "Colorado" },
  CT: { id: `${namespace}.CT`, description: "Connecticut", defaultMessage: "Connecticut" },
  DE: { id: `${namespace}.DE`, description: "Delaware", defaultMessage: "Delaware" },
  FL: { id: `${namespace}.FL`, description: "Florida", defaultMessage: "Florida" },
  GA: { id: `${namespace}.GA`, description: "Georgia", defaultMessage: "Georgia" },
  HI: { id: `${namespace}.HI`, description: "Hawaii", defaultMessage: "Hawaii" },
  ID: { id: `${namespace}.ID`, description: "Idaho", defaultMessage: "Idaho" },
  IL: { id: `${namespace}.IL`, description: "Illinois", defaultMessage: "Illinois" },
  IN: { id: `${namespace}.IN`, description: "Indiana", defaultMessage: "Indiana" },
  IA: { id: `${namespace}.IA`, description: "Iowa", defaultMessage: "Iowa" },
  KS: { id: `${namespace}.KS`, description: "Kansas", defaultMessage: "Kansas" },
  KY: { id: `${namespace}.KY`, description: "Kentucky", defaultMessage: "Kentucky" },
  LA: { id: `${namespace}.LA`, description: "Louisiana", defaultMessage: "Louisiana" },
  ME: { id: `${namespace}.ME`, description: "Maine", defaultMessage: "Maine" },
  MD: { id: `${namespace}.MD`, description: "Maryland", defaultMessage: "Maryland" },
  MA: { id: `${namespace}.MA`, description: "Massachusetts", defaultMessage: "Massachusetts" },
  MI: { id: `${namespace}.MI`, description: "Michigan", defaultMessage: "Michigan" },
  MN: { id: `${namespace}.MN`, description: "Minnesota", defaultMessage: "Minnesota" },
  MS: { id: `${namespace}.MS`, description: "Mississippi", defaultMessage: "Mississippi" },
  MO: { id: `${namespace}.MO`, description: "Missouri", defaultMessage: "Missouri" },
  MT: { id: `${namespace}.MT`, description: "Montana", defaultMessage: "Montana" },
  NE: { id: `${namespace}.NE`, description: "Nebraska", defaultMessage: "Nebraska" },
  NV: { id: `${namespace}.NV`, description: "Nevada", defaultMessage: "Nevada" },
  NH: { id: `${namespace}.NH`, description: "New Hampshire", defaultMessage: "New Hampshire" },
  NJ: { id: `${namespace}.NJ`, description: "New Jersey", defaultMessage: "New Jersey" },
  NM: { id: `${namespace}.NM`, description: "New Mexico", defaultMessage: "New Mexico" },
  NY: { id: `${namespace}.NY`, description: "New York", defaultMessage: "New York" },
  NC: { id: `${namespace}.NC`, description: "North Carolina", defaultMessage: "North Carolina" },
  ND: { id: `${namespace}.ND`, description: "North Dakota", defaultMessage: "North Dakota" },
  OH: { id: `${namespace}.OH`, description: "Ohio", defaultMessage: "Ohio" },
  OK: { id: `${namespace}.OK`, description: "Oklahoma", defaultMessage: "Oklahoma" },
  OR: { id: `${namespace}.OR`, description: "Oregon", defaultMessage: "Oregon" },
  PA: { id: `${namespace}.PA`, description: "Pennsylvania", defaultMessage: "Pennsylvania" },
  RI: { id: `${namespace}.RI`, description: "Rhode Island", defaultMessage: "Rhode Island" },
  SC: { id: `${namespace}.SC`, description: "South Carolina", defaultMessage: "South Carolina" },
  SD: { id: `${namespace}.SD`, description: "South Dakota", defaultMessage: "South Dakota" },
  TN: { id: `${namespace}.TN`, description: "Tennessee", defaultMessage: "Tennessee" },
  TX: { id: `${namespace}.TX`, description: "Texas", defaultMessage: "Texas" },
  UT: { id: `${namespace}.UT`, description: "Utah", defaultMessage: "Utah" },
  VT: { id: `${namespace}.VT`, description: "Vermont", defaultMessage: "Vermont" },
  VA: { id: `${namespace}.VA`, description: "Virginia", defaultMessage: "Virginia" },
  WA: { id: `${namespace}.WA`, description: "Washington", defaultMessage: "Washington" },
  WV: { id: `${namespace}.WV`, description: "West Virginia", defaultMessage: "West Virginia" },
  WI: { id: `${namespace}.WI`, description: "Wisconsin", defaultMessage: "Wisconsin" },
  WY: { id: `${namespace}.WY`, description: "Wyoming", defaultMessage: "Wyoming" }
});

export default states;
