import { defineMessages } from "react-intl";

const namespace = `business`;
const business = defineMessages({
  lang: {
    id: `${namespace}.lang`,
    description: "Language Field",
    defaultMessage: "Language"
  },
  numOfEmployees: {
    id: `${namespace}.numOfEmployees`,
    description: "Number of Employees Field",
    defaultMessage: "Number of Employees"
  },
  companyName: {
    id: `${namespace}.companyName`,
    description: "Company Name Field",
    defaultMessage: "Company Name"
  },
  industry: {
    id: `${namespace}.industry`,
    description: "Industry Field",
    defaultMessage: "Industry"
  },
  message: {
    id: `${namespace}.message`,
    description: "Message Field",
    defaultMessage: "Message"
  },
  title: {
    id: `${namespace}.title`,
    description: "Page Title",
    defaultMessage: "Get in touch with us"
  },
  description: {
    id: `${namespace}.description`,
    description: "Page Description",
    defaultMessage: `Thanks for your interest in Evo for Business! Enter your details below and we’ll email you with next steps.`
  },
  successMessage: {
    id: `${namespace}.successMessage`,
    description: "Success Message",
    defaultMessage: `Success!`
  },
  successMessageDescription: {
    id: `${namespace}.successMessageDescription`,
    description: "Success Message Description",
    defaultMessage: `Keep an eye on your inbox for next steps.`
  }
});

export default business;
