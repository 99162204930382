import { constants } from "../constants";

export const DEFAULT_STATE = {
  isSpinnerActive: false,
  dropdownOptions: {},
  shouldShowSubNav: true
};

const var1 = (state = DEFAULT_STATE, { type, payload } = {}) => {
  switch (type) {
    case constants.SPINNER_ON:
      return { ...state, isSpinnerActive: true };
    case constants.SPINNER_OFF:
      return { ...state, isSpinnerActive: false };
    case constants.SET_DROPDOWN_OPTIONS:
      return { ...state, dropdownOptions: payload };
    case constants.SHOW_SUB_NAV:
      return { ...state, shouldShowSubNav: payload };
    default:
      return state;
  }
};

export default var1;
