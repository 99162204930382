import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

const CardHeader = props => {
  const { children, theme } = props;
  if (!children) return null;

  const className = cn(
    "card__header",
    theme ? theme.map(css => `card--${css}`).join(" ") : ""
  );

  return <div className={className}>{children}</div>;
};

CardHeader.defaultProps = {
  theme: [],
  children: null
};

CardHeader.propTypes = {
  theme: PropTypes.array,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string
  ])
};

export default CardHeader;
