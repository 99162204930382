import React from "react";
import PropTypes from "prop-types";
import { FormSection } from "redux-form";
import { Title } from "../../../elements";
import "../form.scss";

import HomeAddress from "./homeAddress/homeAddress";
import PersonalInfo from "./personalInfo/personalInfo";
import PersonalInfoUpdate from "./personalInfoUpdate/personalInfoUpdate";
import DriversLicenseInfo from "./driversLicenseInfo/driversLicenseInfo";
import DriversLicenseInfoUpdate from "./driversLicenseInfoUpdate/driversLicenseInfoUpdate";
import PaymentInfo from "./paymentInfo/paymentInfo";
import RequestDrivingRecord from "./requestDrivingRecord/requestDrivingRecord";
import IdentityVerificationSelfie from "./identityVerificationSelfie/identityVerificationSelfie";
import PromosAndSavings from "./promosAndSavings/promosAndSavings";
import AddDriversLicenseFront from "./addDriversLicenseFront/addDriversLicenseFront";
import AddDriversLicenseBack from "./addDriversLicenseBack/addDriversLicenseBack";
import EbikeConfirmation from "./ebikeConfirmation/ebikeConfirmation";

import {convertToSentenceCase} from "../../../util";

let FormSectionWrapper = props => {
  const { title, section, children } = props;

  return (
    <FormSection name={section} className="form__section">
      {title && (
        <Title priority={2} type={["strong-no-cap"]}>
          {convertToSentenceCase(title)}
        </Title>
      )}
      {children}
    </FormSection>
  );
};

FormSectionWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string
  ]),
  title: PropTypes.string,
  section: PropTypes.string
};

export {
  FormSectionWrapper,
  HomeAddress,
  PersonalInfo,
  PersonalInfoUpdate,
  AddDriversLicenseFront,
  AddDriversLicenseBack,
  DriversLicenseInfo,
  DriversLicenseInfoUpdate,
  PaymentInfo,
  RequestDrivingRecord,
  PromosAndSavings,
  IdentityVerificationSelfie,
  EbikeConfirmation
};
