import React, { PureComponent, createRef } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { injectIntl } from "react-intl";

import { Button } from "../../elements/";
import { EvoCarIcon, CloseIcon, BlackCarIcon, PersonIcon, GasPumpIcon } from "./iconStrings";
import { calcCrow, convertNumberToMetric, getWalkingTime, getFuelLevel,expireCountDown } from "./util";
import { messages } from "../../constants";
import "./carMarker.scss";

class CarMarker extends PureComponent {
  infoWindow = createRef();

  componentDidUpdate(prevProps) {
    const { isInfoWindowOpen, adjustPan, lat, lng} = this.props;
    // auto pan the info window
    if (prevProps.isInfoWindowOpen !== isInfoWindowOpen && isInfoWindowOpen) {
      adjustPan(this.infoWindow.current, lat, lng);
    }
  }

  render() {
    const {
      intl,
      userLocation,
      lat,
      lng,
      numPoints,
      car,
      reservation,
      isGroup,
      onClick,
      onClose,
      bookACar,
      cancelCarBooking,
      isInfoWindowOpen,
      isBooked,
      isBookingCar
    } = this.props;
    const { plate, address, fuel } = car;
    const fuelLevel = getFuelLevel(fuel);

    const distance =
      (userLocation && lat && lng && calcCrow(userLocation.lat, userLocation.lng, lat, lng)) ||
      null;

    const markerClasses = cn("car-marker__marker", {
      "car-marker__marker_on-top": isInfoWindowOpen
    });
    const infoWindowClasses = cn("car-details_container", {
      "car-details_container_on-top": isInfoWindowOpen
    });


    return (
      <div className="car-marker" >
        <div
          className={markerClasses}
          onClick={onClick}
          style={{
            backgroundImage: `url(${EvoCarIcon})`
          }}
        >
          {!!isGroup && <span className="car-marker__marker__badge">{numPoints}</span>}
        </div>

        {!!isInfoWindowOpen && (
          <div className={infoWindowClasses} ref={this.infoWindow} id={"reservationInfoWindow"}>
            { !reservation && !isBooked &&
              ( <div className="iw_container-close" onClick={onClose}>
                <img src={CloseIcon} alt="close icon" draggable="false"/>
              </div>)
            }
            <div className="car-details_top-car-icon">
              <img src={BlackCarIcon} alt="car icon" draggable="false" />
            </div>
            <div className="car-details_brief">
              <div className="car-details_plate">{plate}</div>
              <div className="car-details_title">{!!address && address}</div>
              <div className="car-details_metric">
                <div>
                  <img src={PersonIcon} alt="person icon" draggable="false" />
                  {distance< 1001 ? (<span>
                    {intl.formatMessage(messages.maps.walkingDistance, {
                      distance: convertNumberToMetric(distance),
                      walkingTime: getWalkingTime(distance)
                    })}
                  </span>) : (<span>
                    {intl.formatMessage(messages.maps.distance, {
                      distance: convertNumberToMetric(distance)
                    })}
                  </span>)
                  }
                </div>
                <div>
                  <img src={GasPumpIcon} alt="gas pump icon" draggable="false" />
                  <span>{intl.formatMessage(messages.maps.fuelLevel, { fuelLevel })}</span>
                </div>
              </div>
            </div>
            {(() => {
              if (isBookingCar)
                return (
                  <div className="car-details_message">
                    {intl.formatMessage(messages.maps.bookingInProgress)}
                  </div>
                );
              else if (isBooked)
                return (
                  <div className="car-details_message">
                    {intl.formatMessage(messages.maps.evoIsReady)}
                  </div>
                );
              else
                return (
                  <div className="car-details_directions">
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {intl.formatMessage(messages.maps.descriptionInfo)}
                    </a>
                  </div>
                );
            })()}

            {!isBooked && (
              <Button
                theme={["full-width"]}
                loading={isBookingCar}
                className="car-details_button-bookcar"
                type="button"
                onClick={bookACar}
              >
                {intl.formatMessage(messages.button.bookThisCar)}
              </Button>
            )}

            {isBooked && (

              <Button
                theme={["full-width"]}
                className="car-details_button-bookcar car-details_button-bookcar-inverse"
                type="button"
                onClick={cancelCarBooking}
              >
                {intl.formatMessage(messages.button.cancel)}
              </Button>
            )}
            {isBooked && (
              <div className="car-details_info-bottom">
                <div>{intl.formatMessage(messages.maps.timeRemaining)} : <span className="reservationExpireTime">{expireCountDown(reservation,  intl.formatMessage )}</span></div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

CarMarker.propTypes = {
  intl: PropTypes.object.isRequired,
  car: PropTypes.shape({}),
  isGroup: PropTypes.bool,
  numPoints: PropTypes.number,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  adjustPan: PropTypes.func,
  bookACar: PropTypes.func,
  cancelCarBooking: PropTypes.func,
  isInfoWindowOpen: PropTypes.bool,
  isBooked: PropTypes.bool,
  isBookingCar: PropTypes.bool,
  userLocation: PropTypes.object
};

CarMarker.defaultProps = {
  car: {},
  isGroup: false,
  numPoints: 1,
  onClick: () => {},
  onClose: () => {},
  adjustPan: () => {},
  bookACar: () => {},
  cancelCarBooking: () => {},
  isInfoWindowOpen: false,
  isBooked: false,
  isBookingCar: false,
  userLocation: undefined
};

export default injectIntl(CarMarker);
