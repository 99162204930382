import { defineMessages } from "react-intl";

const namespace = `error`;
const error = defineMessages({
  required: {
    id: `${namespace}.required`,
    description: `Required error message`,
    defaultMessage: `Please enter this field`
  },
  requiredRadio: {
    id: `${namespace}.requiredRadio`,
    description: `Required error message`,
    defaultMessage: `Please specify if you {spec}`
  },
  invalidFormat: {
    id: `${namespace}.invalidFormat`,
    description: `Inproper format error message`,
    defaultMessage: `Please enter this field in a proper format`
  },
  invalidPronounFormat: {
    id: `${namespace}.invalidPronounFormat`,
    description: `Invalid custom pronoun format`,
    defaultMessage: `Please enter this field in a proper format`
  },
  alphanumeric: {
    id: `${namespace}.alphanumeric`,
    description: `Field is alphanumeric only`,
    defaultMessage: `Please enter only letters and numbers`
  },
  invalidValue: {
    id: `${namespace}.invalidValue`,
    description: `Inproper value error message`,
    defaultMessage: `Please enter a valid value`
  },
  duplicated: {
    id: `${namespace}.duplicated`,
    description: `Duplicate error message`,
    defaultMessage: `It has been used. Please try another`
  },
  email: {
    id: `${namespace}.email`,
    description: `Email validation error message`,
    defaultMessage: `Please enter a valid email address`
  },
  confirmEmail: {
    id: `${namespace}.confirmEmail`,
    description: `Confirm Email validation error message`,
    defaultMessage: `Please confirm email addresses match`
  },
  password: {
    id: `${namespace}.password`,
    description: `Password validation error message`,
    defaultMessage: `Passwords must be at least 8 characters with one uppercase letter, one lowercase letter, one number and a symbol or special character.`
  },
  pleaseEnterEmail: {
    id: `${namespace}.pleaseEnterEmail`,
    description: `Email validation error message`,
    defaultMessage: `Please enter your email address.`
  },
  phone: {
    id: `${namespace}.phone`,
    description: `Phone validation error message`,
    defaultMessage: `Please enter a valid phone number`
  },
  shouldHaveSpecialChar: {
    id: `${namespace}.shouldHaveSpecialChar`,
    description: `At least one symbol or special chars in a string validation error message`,
    defaultMessage: `Please contain at least one symbol or special characters`
  },
  shouldHaveNumber: {
    id: `${namespace}.shouldHaveNumber`,
    description: `At least one number in a string validation error message`,
    defaultMessage: `Please contain at least one number`
  },
  shouldHaveUpper: {
    id: `${namespace}.shouldHaveUpper`,
    description: `At least one uppercase in a string validation error message`,
    defaultMessage: `Please contain at least one uppercase`
  },
  shouldHaveLower: {
    id: `${namespace}.shouldHaveLower`,
    description: `At least one lowercase in a string validation error message`,
    defaultMessage: `Please contain at least one lowercase`
  },
  shouldHave8Chars: {
    id: `${namespace}.shouldHave8Chars`,
    description: `At least 8 chars in a string validation error message`,
    defaultMessage: `Please contain minimum 8 characters`
  },
  shouldNotHaveSpaces: {
    id: `${namespace}.shouldNotHaveSpaces`,
    description: `No spaces in password error message`,
    defaultMessage: `Please do not enter spaces`
  },
  shouldHave3Numbers: {
    id: `${namespace}.shouldHave3Numbers`,
    description: `At least 3 chars in a string validation error message`,
    defaultMessage: `Please enter minimum 3 digits`
  },
  shouldHave13Numbers: {
    id: `${namespace}.shouldHave13Numbers`,
    description: `At least 13 chars in a string validation error message`,
    defaultMessage: `Please enter minimum 13 digits`
  },
  numberOnly: {
    id: `${namespace}.numberOnly`,
    description: `Number only error message`,
    defaultMessage: `Please contain only numbers`
  },
  yearFormat: {
    id: `${namespace}.yearFormat`,
    description: `Not a proper year format error message`,
    defaultMessage: `Please enter in a proper year format`
  },
  monthYearFormat: {
    id: `${namespace}.monthYearFormat`,
    description: `Not a proper Month year format for Adyen CC error message`,
    defaultMessage: `Please enter your Expiry in (MMYY) format`
  },
  yearRange: {
    id: `${namespace}.yearRange`,
    description: `Not a valid year error message`,
    defaultMessage: `Please enter valid year`
  },
  isFuture: {
    id: `${namespace}.isFuture`,
    description: `Not future date error message`,
    defaultMessage: `Please enter future date`
  },
  isOverAge: {
    id: `${namespace}.isOverAge`,
    description: `Not over xx year old error message`,
    defaultMessage: `Must be at least {spec} years of age`
  },
  invalidDate: {
    id: `${namespace}.invalidDate`,
    description: `Not a valid date`,
    defaultMessage: `Please enter valid date format (MM/DD/YYYY)`
  },
  isNotAfterDate: {
    id: `${namespace}.isNotAfterDate`,
    description: `date must come after given date`,
    defaultMessage: `To Date must come after From Date`
  },
  isNotBeforeDate: {
    id: `${namespace}.isNotBeforeDate`,
    description: `date must come before given date`,
    defaultMessage: `From Date must come before To Date`
  },

  isAfterDate: {
    id: `${namespace}.isNotAfterDate`,
    description: `date must come after given date`,
    defaultMessage: `Please enter a date in 2014 or later`
  },
  isBeforeDate: {
    id: `${namespace}.isNotBeforeDate`,
    description: `date must come before given date`,
    defaultMessage: `Date cannot be in the future.`
  },

  isValidAge: {
    id: `${namespace}.isValidAge`,
    description: `Not over some year old error message`,
    defaultMessage: `Must be at least certain age`
  },
  postalCode: {
    id: `${namespace}.postalCode`,
    description: `Postal Code validation error message`,
    defaultMessage: `Please enter valid Postal Code`
  },
  zipCode: {
    id: `${namespace}.zipCode`,
    description: `Zip Code validation error message`,
    defaultMessage: `Please enter valid Zip Code`
  },
  memberNumber: {
    id: `${namespace}.memberNumber`,
    description: `Membership Number error message`,
    defaultMessage: `Please enter valid membership information`
  },
  expiredDriversLicense: {
    id: `${namespace}.expiredDriversLicense`,
    description: `Driver's licence expiry error message`,
    defaultMessage: `You must have a valid (non-expired) Driver's Licence`
  },
  regOnlyPromoCode: {
    id: `${namespace}.regOnlyPromoCode`,
    description: "Reg only Promo Code error message",
    defaultMessage: "This code is only valid at registration."
  },
  invalidPromoCode: {
    id: `${namespace}.invalidPromoCode`,
    description: `Invalid Promo Code error message`,
    defaultMessage: `We don't recognize this code - try again.`
  },
  duplicatePromoCode: {
    id: `${namespace}.duplicatePromoCode`,
    description: `Duplicate Promo Code error message`,
    defaultMessage: "This promo code has already been applied to your account"
  },
  invalidPromoCodeInThisCity: {
    id: `${namespace}.invalidPromoCodeInThisCity`,
    description: "Invalid promo code in this city",
    defaultMessage: "This code isn't valid in this city."
  },
  expiredPromoCode: {
    id: `${namespace}.expiredPromoCode`,
    description: `Expired Promo Code error message`,
    defaultMessage: "This promo code has expired or is not active"
  },
  unknownPromoCodeError: {
    id: `${namespace}.unknownPromoCodeError`,
    description: `Unknown promo code error`,
    defaultMessage: `Oops! Something went wrong - please try again in a few minute`
  },
  infoMissing: {
    id: `${namespace}.infoMissing`,
    description: `Promo API server error`,
    defaultMessage:
      "Some required information is missing. Please check the {length} highlighted field(s)."
  },
  genericError: {
    id: `${namespace}.genericError`,
    description: `Generic API error`,
    defaultMessage: "Oops! Something went wrong - please try again in a few minutes."
  },
  resetPasswordLinkError: {
    id: `${namespace}.resetPasswordLinkError`,
    description: `Used when there is an error in the reset process, reset code or email id was incorrect`,
    defaultMessage: "There was a problem with the link, please start a new request."
  },
  matchPassword: {
    id: `${namespace}.matchPassword`,
    description: "Used when password doesn't match",
    defaultMessage: "Passwords do not match"
  },
  wrongCurrentPassword: {
    id: `${namespace}.wrongCurrentPassword`,
    description: "Used when user input wrong current passwrod",
    defaultMessage: "The old password does not match"
  },
  sameAsOldPassword: {
    id: `${namespace}.sameAsOldPassword`,
    description: "Used when new password is the same as the old one",
    defaultMessage: "New Password is the same as your old password"
  },
  emailAlreadyExists: {
    id: `${namespace}.emailAlreadyExists`,
    description: `Email duplicate error message`,
    defaultMessage: `Email already exists`
  },
  acceptAgreement: {
    id: `${namespace}.acceptAgreement`,
    description: `Agreement not checked error message`,
    defaultMessage: `Please accept our Member agreement to continue.`
  },
  acceptMktOptIn: {
    id: `${namespace}.acceptMktOptIn`,
    description: `Agreement not checked error message`,
    defaultMessage: `Please accept our Marketing Opt-in to continue. Opting-in is a requirement to be a Member. You can opt-out at any time.`
  },
  imageMeetsCriteriaDriversLicense: {
    id: `${namespace}.imageMeetsCriteria.driversLicense`,
    description: `Uploaded Image is provided and meets API Validation`,
    defaultMessage: `We can't read your driver's licence info.  Please retake photo.`
  },

  imageValidationAttemptsReachedDriversLicense: {
    id: `${namespace}.imageValidationAttemptsReached.driversLicense`,
    description: `User has attempted to Validate the Image the max allowed times`,
    defaultMessage: `We are unfortunately unable to read your driver's licence.  Please fill in your driver's licence info on the next screen.`
  },

  imageMeetsCriteriaSelfie: {
    id: `${namespace}.imageMeetsCriteria.selfie`,
    description: `Uploaded Image is provided and meets API Validation`,
    defaultMessage: `Not quite as clear as we need it to be.  Please retake photo.`
  },

  imageValidationAttemptsReachedSelfie: {
    id: `${namespace}.imageValidationAttemptsReached.selfie`,
    description: `User has attempted to Validate the Image the max allowed times`,
    defaultMessage: `Not quite as clear as we need it to be.  You can also try uploading a pic instead.`
  }

});

export default error;
