import { constants } from "../constants";

export const DEFAULT_STATE = {
  cars: []
};

const var1 = (state = DEFAULT_STATE, { type, payload } = {}) => {
  switch (type) {
    case constants.MAP_FETCH_CARS:
      return { ...state, cars: payload };
    case constants.MAP_CREATE_CAR_RESERVATION:
      return {
        ...state,
        cars: state.cars.map((car) => (car.vin === payload ? { ...car, booked: true } : car))
      };
    case constants.MAP_REMOVE_CAR_RESERVATION:
      return {
        ...state,
        cars: state.cars.map((car) => (car.vin === payload ? { ...car, booked: false } : car))
      };
    default:
      return state;
  }
};

export default var1;
