import { defineMessages } from "react-intl";

const namespace = "adyen";
const adyen = defineMessages({
  acceptedCreditcards: {
    id: `${namespace}.accepted.creditcards`,
    description: "label for accepted credit cards",
    defaultMessage: "Accepted credit cards"
  },
  creditcard: {
    id: `${namespace}.creditcard`,
    description: "Field for credit card number (star mandatory at end)",
    defaultMessage: "Credit card number *"
  },
  expirydate: {
    id: `${namespace}.expirydate`,
    description: "Field for credit card expiry date (star mandatory at end)",
    defaultMessage: "Expiration (MMYY) *"
  },
  cvv2: {
    id: `${namespace}.cvv2`,
    description: "Field for credit card cvv2 (star mandatory at end)",
    defaultMessage: "CVV *"
  },
  name: {
    id: `${namespace}.cardholdername`,
    description: "Field for input of cardholder name",
    defaultMessage: "Cardholder name *"
  },
  respGood: {
    id: `${namespace}.status.001`,
    description: "Approved transaction",
    defaultMessage: "Approved"
  },
  respInvalidProfId: {
    id: `${namespace}.status.940`,
    description: "Server error generating payment token from Adyen",
    defaultMessage: "Invalid Profile ID on tokenization request"
  },
  respErrorGenToken: {
    id: `${namespace}.status.941`,
    description: "Server error generating payment token from Adyen",
    defaultMessage: "Error generating token"
  },
  respErrorProfIdOrSourceUrl: {
    id: `${namespace}.status.942`,
    description: "Server error generating payment token from Adyen",
    defaultMessage: "Invalid Profile ID or source URL"
  },
  respErrorCardDetailsInvalid: {
    id: `${namespace}.invalid.card.details`,
    description: "Card data is invalid",
    defaultMessage: "Credit card details are invalid. Please make sure you have entered your credit card details correctly"
  },
  respErrorSessionTimeOut: {
    id: `${namespace}.session.timeout`,
    description: "Payment session timeout",
    defaultMessage: "Your session has timed out. Please use the following link to login to your account and add your payment details."
  },
  respErrorCardInvalid: {
    id: `${namespace}.status.943`,
    description: "Card data is invalid",
    defaultMessage: "Please enter your credit card number without spaces"
  },
  respInvalidExpDate: {
    id: `${namespace}.status.944`,
    description: "Invalid Expiration Date",
    defaultMessage: "Please check that the expiration date on your credit card is in the future"
  },
  respInvalidCVV2: {
    id: `${namespace}.status.945`,
    description: "Invalid CVV2",
    defaultMessage: "Please check that your CVV2 matches the one on your card"
  },
  cvvhelptext: {
    id: `${namespace}.cvv.helptext`,
    description: "CVV Helptext",
    defaultMessage:
      "The CVV Number (\"Card Verification Value\") on your credit card or debit card is a 3 digit number on VISA<span class='superscript'>Ⓡ </span>and MasterCard<span class='superscript'>Ⓡ </span> branded credit and debit cards. On your American Express<span class='superscript'>Ⓡ </span> branded credit or debit card, it is a 4 digit numeric code."
  },
  fullname: {
    id: `${namespace}.fullname`,
    description: "Full name label",
    defaultMessage: "Full name"
  }
});

export default adyen;
