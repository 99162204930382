import React from "react";
import PropTypes from "prop-types";
import { FormSectionWrapper } from "../";
import { injectIntl } from "react-intl";
import {
  Address,
  Agreements,
  Credentials,
  Personal,
  RequiredFieldFooterNote,
  PhoneNumberField
} from "../../fieldGroups";
import IsEbikeOnly from "../../fieldGroups/isEbikeOnly";
import * as selectors from "../../../../selectors";
import { connect } from "react-redux";
import { Txt } from "../../../../elements";
import { messages } from "../../../../constants";

import { useB2BStore } from "../../../../store/b2b";

const PersonalInfo = (props) => {
  const { intl } = props;
  const b2bData = useB2BStore.getState();
  const isB2B = b2bData.data.isB2B;

  return (
    <div>
      <FormSectionWrapper {...props}>
        <Personal {...props} />
        <Credentials {...props} />
        <PhoneNumberField {...props} />
        <br />
        <Address {...props} prefix={"address"} />
        <Agreements {...props} />
        <RequiredFieldFooterNote {...props} />
        <br />
        <br />
        {!isB2B && (
          <FormSectionWrapper {...props} title={intl.formatMessage(messages.title.EvoEvolveIcon)}>
            <Txt theme={["note"]}>{intl.formatMessage(messages.text.evolveOrBoth)}</Txt>
            <IsEbikeOnly {...props} />
          </FormSectionWrapper>
        )}
      </FormSectionWrapper>
    </div>
  );
};

PersonalInfo.propTypes = {
  intl: PropTypes.object.isRequired,
  isEbikeOnly: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isEbikeOnly: selectors.getIsEbikeOnly(state)
});

export default injectIntl(connect(mapStateToProps)(PersonalInfo));
