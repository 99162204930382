import {findIndex} from "lodash";
/**
 * This function translate the gender phrase into gender code
 *
 * @param {string} gender
 * @returns {string}
 */
// export const getGenderCode = gender =>{
//     switch (gender) {
//         case 'male':
//             return 'M';
//         case 'female':
//             return 'F';
//         default:
//             return 'X';
//     }
// };

/**
 * Take a date and return a timestamp integer
 *
 * @param {Date} date
 * @returns {int}
 */
export const dateToTimestamp = date => new Date(date).getTime() / 1000;

/**
 * Remove time part from JavaScript Date
 *
 * @param {Date} date
 * @returns {string}
 */
export const trimTimeFromDate = date => date.toJSON().split("T")[0];

/**
 * Remove time part from JavaScript Date
 *
 * @param {Date} date
 * @returns {string}
 */
export const trimTimeFromDateString = dateString => dateString.split("T")[0];

/**
 * Returns true if the value is 'on', or else false
 *
 * @param {String} toggleValue
 * @returns {boolean}
 */
export const toggleBoolean = toggleValue => (toggleValue === "on" ? true : false);

/**
 * Strip all non-numeric characters
 *
 * @param {String} input
 * @returns {String}
 */
export const stripSymbols = input => input.replace(/\D/g, "");

/*
 * @function filterAndFormatAddress - extract address fields from object and return a new one with state map to province and zipCode map to state
 * @param {object} - address object { ('province' | 'state'): string, ('postalCode' | 'zipCode'): string }
 * @returns {object} - address object
 */
export const filterAndFormatAddress = (address, countries, useTwoLetterAbbr) => {
  const {
    addressLine = "",
    line1 = "",
    line2 = "",
    unit = "",
    city = "",
    country = "",
    postalCode = "",
    zipCode = "",
    province = "",
    state = ""
  } = address;

  let longCountryName = "";
  let twoLetterAbbr = "";
  //let threeLetterAbbr = "";
  let index;
  if(country && countries) {
    index = findIndex(countries, x => x.value === country);
  }
  if(index !== -1 && countries) {
    longCountryName = countries[index] && countries[index].label ? countries[index].label : "";
   // threeLetterAbbr =  countries[index] && countries[index].value ? countries[index].value : "";
    twoLetterAbbr =  countries[index] && countries[index].shortAbbr ? countries[index].shortAbbr : "";
  }

  let unitAddressLine = unit && addressLine ?  unit + "-" + addressLine  : (addressLine ? addressLine : "");

  switch (country) {
    case "USA":
      return {
        addressLine:unitAddressLine,
        line1,
        line2,
        unit,
        city,
        country: useTwoLetterAbbr? twoLetterAbbr: longCountryName,
        province: state,
        postalCode: zipCode
      };
    case "CAN":
    default:
      // if country is not USA
      return {
        addressLine:unitAddressLine,
        line1,
        line2,
        unit,
        city,
        country: useTwoLetterAbbr? twoLetterAbbr: longCountryName,
        province,
        postalCode
      };
  }
};
