import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
// import DOMPurify from 'dompurify';
// import Parser from 'html-react-parser';
import "./grid.scss";

const Grid = props => {
  const { children, theme, className, min, size } = props;
  const gridCSS = cn(
    "grid",
    className,
    theme ? theme.map(css => `grid--${css}`).join(" ") : "",
    min || size ? `grid-${size}${min ? "--" + min : ""}` : ""
  );

  return <div className={gridCSS}>{children}</div>;
};

Grid.displayName = "Grid";

Grid.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string
  ]),
  theme: PropTypes.array,
  className: PropTypes.string,
  min: PropTypes.oneOf(["sm", "md", "lg"]),
  size: PropTypes.oneOf(["1-2", "2-3"])
};

Grid.defaultProps = {
  theme: [],
  className: ""
};

export default Grid;
