import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {formValueSelector} from "redux-form";
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";

import {Grid, Col, Section, DependOn} from "../../../../components";
import { Txt} from "../../../../elements";
// import DOMPurify from 'dompurify';
import {messages, constants} from "../../../../constants";
import * as selectors from "../../../../selectors";

import {PromoCode, Membership, RequiredFieldFooterNote} from "../../fieldGroups";
import {FormSectionWrapper} from "../";

class PromosAndSavings extends PureComponent {
  state = {
    summaryParam: this.props.summaryParam,
    loading: false
  };

  componentDidMount() {
    const { actions } = this.props;
    // initial summary table
    actions.setPaymentSummary({
      isCAAMember: this.getValue("iscaaMember"),
      promoCode: this.getValue("promo")
    });
  }

  // function to get the selected field value
  getValue = (name) => {
    const { state, form, section } = this.props;
    const selector = formValueSelector(form);
    return selector(state, `${section}.${name}`);
  };

  checkPromoCode = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const { actions, intl, summaryParam } = this.props;
    const formPromoCode = this.getValue("promo");
    // update the promo code data
    const newPromoCode = await actions.handlePromoCodeUpdate(formPromoCode, intl);
    if (newPromoCode) {
      const { status, code } = newPromoCode;
      // backend returns discount with invalid promo code
      // thus, do not pass invalid promo code to fetch itemized payment summary
      const promoCode = status === constants.PROMO_CODE_STATUS.error ? undefined : code;
      // update the summary table
      if (promoCode) {
        actions.setPaymentSummary({ ...summaryParam, promoCode });
      }
    }
    this.setState({ loading: false });
  };

  render() {
    const { intl, promoCode } = this.props;
    const { loading } = this.state;
    const { isEbikeOnly } = this.props;

    return (
      <FormSectionWrapper {...this.props}>
        <Section size="2-3" min="md" theme={["div"]}>
          <Grid>
            <Col>
              <Txt>{intl.formatMessage(messages.title.promoCodeTitle, { br: <br /> })}</Txt>
            </Col>
          </Grid>
          <Grid>
            <Col>
              <Txt>
                {intl.formatMessage(
                  isEbikeOnly
                    ? messages.title.referAFriendTitleEvolve
                    : messages.title.referAFriendTitleEvo,
                  {
                    br: <br />,
                    bold: <span className="bold">Note:</span>
                  }
                )}
              </Txt>
            </Col>
          </Grid>

          <DependOn active={isEbikeOnly === true}>
            <Grid>
              <Col>
                <Txt>
                  {intl.formatMessage(messages.title.businessNoteTitle, {
                    br: <br />,
                    message: (
                      <span className="bold">
                        No promo codes would be required for business profiles
                      </span>
                    )
                  })}
                </Txt>
              </Col>
            </Grid>
          </DependOn>

          <PromoCode
            intl={intl}
            handleClickBtn={this.checkPromoCode}
            btnDisabled={!this.getValue("promo")}
            colWidth="1-3"
            promoCode={promoCode}
            submitting={loading}
            {...this.props}
          />
          <Membership {...this.props} />
          <RequiredFieldFooterNote />
        </Section>
      </FormSectionWrapper>
    );
  }
}

PromosAndSavings.propTypes = {
  state: PropTypes.object,
  form: PropTypes.string,
  section: PropTypes.string,
  intl: PropTypes.object.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  promoParam: PropTypes.object,
  summaryParam: PropTypes.object,
  paymentSummary: PropTypes.object,
  promoCode: PropTypes.object,
  isEbikeOnly: PropTypes.bool
};

const mapStateToProps = state => ({
  state,
  paymentSummary: selectors.getPaymentSummary(state),
  promoCode: selectors.getPromoCodeStatus(state),
  isEbikeOnly: selectors.getIsEbikeOnly(state)
});

export default injectIntl(connect(mapStateToProps)(PromosAndSavings));
