import { defineMessages } from "react-intl";

const namespace = `creditCard`;
const creditCard = defineMessages({
  ccNumber: {
    id: `${namespace}.ccNumber`,
    description: "Credit Card Number field",
    defaultMessage: "Credit card number"
  },
  expiryDate: {
    id: `${namespace}.expiryDate`,
    description: "Expiry Date field",
    defaultMessage: "Expiry (MMYY)"
  },
  cvv: {
    id: `${namespace}.cvv`,
    description: "CVV field",
    defaultMessage: "CVV"
  },
  ccNumberToolTip: {
    id: `${namespace}.ccNumberToolTip`,
    description: "CC Number Tool tip field",
    defaultMessage: "We accept American Express, Mastercard, Visa and Visa Debit."
  },
  cardHolderName: {
    id: `${namespace}.cardHolderName`,
    description: "Card Holder Name field",
    defaultMessage: "Cardholder name"
  },
  EbikeCCInfoTitle: {
    id: `${namespace}.EbikeCCInfoTitle`,
    description: "Payment Info Screen title for Ebike flow only",
    defaultMessage: "Enter your credit card details."
  },
  EbikeCCInfoSubtext: {
    id: `${namespace}.EbikeCCInfoSubtext`,
    description: "Payment Info Screen subtext",
    defaultMessage: "You won't be charged until your first ride."
  }
});

export default creditCard;
