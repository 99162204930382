import { AdyenCheckout, Dropin, Card } from "@adyen/adyen-web";
import { useState, useEffect, useRef } from "react";
import { createSession } from "../../../../api/adyen";
import { constants, messages } from "../../../../constants";
import { Txt } from "../../../../elements";
import "./adyen.scss";
import { RequiredFieldFooterNote } from "../../fieldGroups";
import { isIOS } from "react-device-detect";

const Adyen = (props) => {
  const { state, dispatch, intl } = props;
  const paymentContainer = useRef(null);
  const [session, setSession] = useState(null);
  const [render, setRender] = useState(0);
  const [uiElement, setUiElement] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const getSession = async () => {
      const response = await createSession(state.registration.registrationResp);
      setSession(response);
      dispatch({ type: constants.SPINNER_OFF });
    };

    if (!session) {
      dispatch({ type: constants.SPINNER_ON });
      getSession();
    }
  }, [render, state.registration.registrationResp, dispatch, session]);

  useEffect(() => {
    const config = {
      onPaymentCompleted: (result, component) => {
        if (result.resultCode === "Authorised") {
          props.setAdyenResp({ adyenResp: result });
          props.submit();
        } else {
          setError({
            code: 1,
            message: intl.formatMessage(messages.adyen.respErrorCardDetailsInvalid)
          });
          setRender((prev) => prev + 1);
        }
      },
      onPaymentFailed: (result, component) => {
        setError({
          code: 1,
          message: intl.formatMessage(messages.adyen.respErrorCardDetailsInvalid)
        });
        setRender((prev) => prev + 1);
      },
      onError: (error, component) => {
        if (error.message === "The session has expired.") {
          setError({
            code: 2,
            message: intl.formatMessage(messages.adyen.respErrorSessionTimeOut)
          });
        } else {
          setError({
            code: 1,
            message: intl.formatMessage(messages.adyen.respErrorCardDetailsInvalid)
          });
          setRender((prev) => prev + 1);
        }
      }
    };
    let ignore = false;

    if (!session || !paymentContainer.current) {
      // initiateCheckout is not finished yet.
      return;
    }

    const translations = {
      "en-US": {
        "payButton.saveDetails": "Add Card",
        "creditCard.holderName.placeholder": "",
        "form.instruction": "",
        "creditCard.cardNumber.label": "Card Number *",
        "creditCard.expiryDate.label": "Expiry Date *",
        "creditCard.holderName": "Name on Card *",
        "creditCard.securityCode.label": "Security Code *"
      }
    };

    const configuration = {
      locale: "en-US",
      translations,
      ...config,
      ...session
    };

    const createCheckout = async () => {
      if (render < 1) {
        const checkout = await AdyenCheckout(configuration);

        const dropin = new Dropin(checkout, {
          paymentMethodComponents: [Card], // Only needed with tree-shakable npm package
          paymentMethodsConfiguration: {
            card: {
              hasHolderName: true,
              holderNameRequired: true,
              enableStoreDetails: true,
              name: "Credit or debit card"
            }
          }
        });

        setUiElement(dropin);
        setRender((prev) => prev + 1);
      }

      // The 'ignore' flag is used to avoid double re-rendering caused by React 18 StrictMode
      // More about it here: https://beta.reactjs.org/learn/synchronizing-with-effects#fetching-data
      if (paymentContainer.current && uiElement && !ignore) {
        //   if (render > 0) {
        //     uiElement.update(configuration);
        //   } else {
        uiElement.mount(paymentContainer.current);
        //   }
      }
    };

    createCheckout();

    return () => {
      ignore = true;
    };
  }, [session, render, uiElement, props, intl]);
  return (
    <div className="payment-container">
      <div ref={paymentContainer} className="payment"></div>
      <RequiredFieldFooterNote {...props} />
      {error && (
        <Txt theme={["error"]}>
          <small>{error.message}</small>
        </Txt>
      )}
      {error?.code === 2 && (
        <Txt theme={["error"]}>
          <small>
            {isIOS ? (
              <a href={props.intl.formatMessage(messages.record.goToAccountLink)}>
                {props.intl.formatMessage(messages.record.goToAccount)}
              </a>
            ) : (
              <a
                href={props.intl.formatMessage(messages.record.goToAccountLink)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {props.intl.formatMessage(messages.record.goToAccount)}
              </a>
            )}
          </small>
        </Txt>
      )}
    </div>
  );
};

export default Adyen;
