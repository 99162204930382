import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { bool, arrayOf, object } from "prop-types";
import { Field } from "redux-form";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import { messages } from "../../../../constants";
import { /* Dropdown, */ Section, Col, Grid, Checkbox, DependOn } from "../../../../components";
import { Title /*, Txt, Tooltip*/ } from "../../../../elements";
import * as selectors from "../../../../selectors";

/**
 * TODO: uncommeting the following code when implementing multi city for marketing preferences, track S-12012
 */
// const input = {
//   onBlur: () => {},
//   onChange: () => {},
//   value: "BC"
// };

// const meta = {
//   pristine: true,
//   dirty: false,
//   invalid: false,
//   valid: true,
//   warning: false,
//   error: ""
// };

class SubscriptionPreferences extends PureComponent {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    regions: arrayOf(object),
    isMobile: bool,
  };

  static defaultProps = {
    regions: [],
    isMobile: false,
  };

  render() {
    const { intl, isMobile, regions } = this.props;

    const isMultiRegion = regions.length > 1;
    return (
      <div className="subscription-preferences">
        <Section size="1-2" min="md">
          <Grid>
            <Col>
              <Title priority={1} type={["strong"]} className="h2">
                {intl.formatMessage(messages.title.Subscriptions)}
              </Title>
              <DependOn active={isMultiRegion}>
                {/* TODO: enable the following components for multi city when implementing multi city, track S-12012 */}
                {/* <Txt theme={["header"]}>
                  <div>
                    {intl.formatMessage(messages.title.MultiCity)}{" "}
                    <Tooltip arrow="top">{intl.formatMessage(messages.title.MultiCity)}</Tooltip>
                  </div>
                </Txt>
                <br /> */}
                {/* <Dropdown
                  name="city"
                  id="city"
                  label={intl.formatMessage(messages.address.city)}
                  required={true}
                  input={input}
                  meta={meta}
                  disabled={disabled}
                  options={[
                    { label: intl.formatMessage(messages.cities.vancouver), value: "BC" },
                    { label: intl.formatMessage(messages.provinces.QC), value: "QB" },
                    { label: intl.formatMessage(messages.provinces.ON), value: "ON" }
                  ]}
                /> */}
                <br />
              </DependOn>
              <Field
                theme={[`${isMobile && "toggle checkbox--toggle--left"}`]}
                name="isEmailConsent"
                label={intl.formatMessage(messages.subscriptions.freeminutesEmail)}
                component={Checkbox}
              />
              <Field
                theme={[`${isMobile && "toggle checkbox--toggle--left"}`]}
                name="isSMSConsent"
                label={intl.formatMessage(messages.subscriptions.freeminutesSms)}
                component={Checkbox}
              />
              <Field
                theme={[`${isMobile && "toggle checkbox--toggle--left"}`]}
                name="isBCAAEmailConsent"
                label={intl.formatMessage(messages.subscriptions.promotionsEmail)}
                component={Checkbox}
              />
            </Col>
          </Grid>
        </Section>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  regions: selectors.getRegions(state)
});

export default injectIntl(connect(mapStateToProps)(SubscriptionPreferences));
