export const removeSpaces = value => {
  if (value) {
    return value.replace(/\s+/g, "");
  }
  return "";
};
export const toUpperCase = value => {
  if (value) {
    return value.toUpperCase();
  }
  return "";
};
export const onlyNumbers = value => {
  if (value) {
    return value.replace(/[^\d]/g, "");
  }
  return "";
};
export const onlyNumChars = limit => value => {
  if (value && value.length < limit) {
    return value;
  } else if (value && value.length >= limit) {
    return value.substring(0, 16);
  }

  return "";
};
