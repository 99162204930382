import React from "react";
import PropTypes from "prop-types";

import { messages } from "../../../constants";
import { Grid, Col, Card } from "../../";
import DataCell from "../dataCell";

const BillingAdjustmentCard = props => {
  const { intl, record } = props;
  const { posted, type, description, trip, amount, details } = record;
  return (
    <Card>
      <Card.Header>
        <Grid theme={["small-div"]}>
          <Col size="1-2">
            <DataCell headerTitle={intl.formatMessage(messages.title.Posted)} data={posted} />
          </Col>
          <Col size="1-2">
            <DataCell headerTitle={intl.formatMessage(messages.title.Type)} data={type} />
          </Col>
        </Grid>
      </Card.Header>
      <Card.Body theme={["top-dashed"]}>
        <Grid theme={["small-div"]}>
          <Col size="1-1">
            <DataCell
              headerTitle={intl.formatMessage(messages.title.Description)}
              data={description}
            />
          </Col>
        </Grid>
        <Grid theme={["small-div"]}>
          <Col size="1-2">
            <DataCell headerTitle={intl.formatMessage(messages.title.Trip)} data={trip} />
          </Col>
          <Col size="1-2">
            <DataCell headerTitle={intl.formatMessage(messages.title.Amount)} data={amount} />
          </Col>
        </Grid>
      </Card.Body>
      <Card.Footer>{details}</Card.Footer>
    </Card>
  );
};

BillingAdjustmentCard.propTypes = {
  intl: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired
};

export default BillingAdjustmentCard;
