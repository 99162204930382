/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import cn from "classnames";
import DOMPurify from "dompurify";
import Parser from "html-react-parser";
import "./linklist.scss";

import { Title, Txt } from "../../elements";
import { pushNav } from "../../actions";

export const LinkList = props => {
  const { children, theme, className, pushNav } = props;
  const listCSS = cn(
    "linklist",
    className,
    theme ? theme.map(css => `linklist--${css}`).join(" ") : ""
  );

  const renderList = items =>
    items.map((item, idx) => {
      const itemCSS = cn("linklist__link", item.status ? `linklist__link--${item.status}` : "");

      return (
        <li key={idx}>
          {item.external && (
            <Title priority={3} className={itemCSS}>
              <a href={item.link} title={item.title} target="evo">
                {item.title}
              </a>
            </Title>
          )}

          {!item.external && (
            <Title priority={3} className={itemCSS}>
              <a title={item.title} onClick={() => pushNav(item.link)}>
                {item.title}
              </a>
            </Title>
          )}

          {item.content && (
            <Txt theme={[`small`]}>
              <p>{Parser(DOMPurify.sanitize(item.content))}</p>
            </Txt>
          )}
        </li>
      );
    });

  return <ul className={listCSS}>{renderList(children)}</ul>;
};

LinkList.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object),
  theme: PropTypes.array,
  className: PropTypes.string,
  pushNav: PropTypes.func
};

LinkList.defaultProps = {
  theme: [],
  className: ""
};

const mapDispatchToProps = dispatch => ({
  pushNav: bindActionCreators(pushNav, dispatch)
});

export default connect(
  null,
  mapDispatchToProps
)(LinkList);
