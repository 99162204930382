import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import "./index.scss";
import { formatCurrency } from "../../util";
import { Title, List, Txt } from "../../elements";
import { messages } from "../../constants";
import { mapTransactionLocalization } from "../../util/localizationMapper";

const renderList = (title, listData) => {
  return listData && !!listData.length ? (
    <div data-testid={`TransactionReceipt-${title}-section`}>
      <Title priority={4} type={["bottom-border"]}>
        {title}
      </Title>
      <List theme={["plain", "plain-underlined"]}>
        {listData.map((item, idx) => (
          <div data-testid={`TransactionReceipt-${title}-items`} key={idx}>
            <div>
              {item.name}
              <Txt theme={["right", "float-right"]}>{formatCurrency(item.amount)}</Txt>
            </div>
            {item.sublist && !!item.sublist.length && (
              <List theme={["plain", "plain-sub"]}>
                {item.sublist.map((subItem, subidx) => (
                  <div key={subidx}>
                    {subItem.name}
                    <Txt theme={["right", "float-right"]}>{`-${subItem.consumedMinutes} mins`}</Txt>
                  </div>
                ))}
              </List>
            )}
          </div>
        ))}
      </List>
    </div>
  ) : null;
};

const mapFreeMinutesLocalization = (intl, transaction) =>
  transaction.promoCode ? transaction.promoCode : intl.formatMessage(messages.title.freeMinutes);

// eslint-disable-next-line react/no-multi-comp
const TransactionReceipt = props => {
  const { intl, data } = props;

  if (!data || !data.transactions || !data.transactions.length) return null;
  let transRedemptions = data.transCalcRedemption ? [...data.transCalcRedemption] : [];

  transRedemptions = transRedemptions.map(freeMinutes => ({
    ...freeMinutes,
    name: mapFreeMinutesLocalization(intl, freeMinutes)
  }));

  const [charges, discounts, taxes, total] = data.transactions.reduce(
    ([charges, discounts, taxes, total], transaction) => {
      const { productId, amount } = transaction;
      let newTransaction = {
        ...transaction,
        name: mapTransactionLocalization(intl, { ...transaction, fallback: transaction.name })
      };

      // productId 8 === PVRT
      if (productId === null || productId === 8) {
        taxes.push(newTransaction);
      } else if (amount < 0) {
        // productId 26 === free minutes applied
        if (productId === 26) {
          newTransaction.sublist = transRedemptions;
        }
        discounts.push(newTransaction);
      } else {
        charges.push(newTransaction);
      }

      // summing up the amounts
      if (amount && typeof amount === "number") {
        total += amount;
      }

      return [charges, discounts, taxes, total];
    },
    [[], [], [], 0]
  );

  return (
    <div data-testid="component-TransactionReceipt" className="transaction-receipt">
      {renderList(intl.formatMessage(messages.title.charges), charges)}
      {renderList(intl.formatMessage(messages.title.discounts), discounts)}
      {renderList(intl.formatMessage(messages.title.taxes), taxes)}
      <div data-testid="TransactionReceipt-Total-section" className="total-text">
        <Txt theme={["right", "bold", "right-gutter"]}>
          {intl.formatMessage(messages.title.total)}
        </Txt>
        <Txt theme={["right", "bold"]}>{formatCurrency(total)}</Txt>
      </div>
    </div>
  );
};

TransactionReceipt.propTypes = {
  intl: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

export default injectIntl(TransactionReceipt);
