import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import "./index.scss";

class ExpandablePanel extends PureComponent {
  render() {
    const { children, trigger, theme, expanded } = this.props;

    const containerCSS = cn(
      "expandable-panel",
      theme ? theme.map(css => `expandable-panel--${css}`).join(" ") : "",
      { expanded }
    );

    const headerCSS = cn(
      "expandable-panel__header",
      theme ? theme.map(css => `expandable-panel__header--${css}`).join(" ") : "",
      { expanded }
    );

    const bodyCSS = cn(
      "expandable-panel__body",
      theme ? theme.map(css => `expandable-panel__body--${css}`).join(" ") : "",
      { expanded }
    );

    return (
      <div data-testid="component-ExpandablePanel" className={containerCSS}>
        <div data-testid="ExpandablePanel-header" className={headerCSS} onClick={this.toggleExpand}>
          {trigger}
        </div>
        {expanded && (
          <div data-testid="ExpandablePanel-body" className={bodyCSS}>
            {children}
          </div>
        )}
      </div>
    );
  }
}

ExpandablePanel.defaultProps = {
  expanded: undefined,
  theme: [],
  trigger: null,
  children: null
};

ExpandablePanel.propTypes = {
  expanded: PropTypes.bool,
  theme: PropTypes.arrayOf(PropTypes.string),
  trigger: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string
  ])
};

export default ExpandablePanel;
