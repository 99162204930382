import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import DOMPurify from "dompurify";
import Parser from "html-react-parser";
import "./list.scss";

const renderList = items =>
  items.map((item, idx) => (
    <li key={idx}>{typeof item === "string" ? Parser(DOMPurify.sanitize(item)) : item}</li>
  ));

const List = props => {
  const { children, type, theme, className } = props;
  const CustomTag = type;
  const listCSS = cn("list", className, theme ? theme.map(css => `list--${css}`).join(" ") : "");

  return <CustomTag className={listCSS}>{renderList(children)}</CustomTag>;
};

List.propTypes = {
  children: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.string])),
  type: PropTypes.string,
  theme: PropTypes.array,
  className: PropTypes.string
};

List.defaultProps = {
  type: "ul",
  theme: [],
  className: ""
};

export default List;
