import { constants } from "../constants";

export const DEFAULT_STATE = {
  theme: undefined,
  sub: []
};

const var1 = (state = DEFAULT_STATE, { type, payload } = {}) => {
  switch (type) {
    case constants.PAGE_THEME:
      return { ...state, theme: payload.theme };
    case constants.PAGE_SUB_NAV:
      return { ...state, sub: payload.sub };
    default:
      return state;
  }
};

export default var1;
