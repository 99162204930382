import React from "react";

export const Parking = () => (
  <svg
    width="29px"
    height="29px"
    viewBox="0 0 29 29"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>pin parking</title>
    <desc>Created with Sketch.</desc>
    <g id="DESIGN---Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Mobile---Find-A-Car" transform="translate(-22.000000, -378.000000)">
        <g id="map-icons" transform="translate(22.000000, 269.000000)">
          <g id="pins" transform="translate(0.000000, 19.000000)">
            <g id="map/pin_parking" transform="translate(0.000000, 90.000000)">
              <g id="pin-parking">
                <path
                  d="M0.625,14.5 C0.625,21.8994505 6.61969962,27.8958333 14.0157675,27.8958333 L14.9842325,27.8958333 C22.37873,27.8958333 28.375,21.8978261 28.375,14.5 C28.375,7.1005495 22.3803004,1.10416667 14.9842325,1.10416667 L14.0157675,1.10416667 C6.62126997,1.10416667 0.625,7.10217386 0.625,14.5 Z"
                  id="Rectangle-2"
                  stroke="#231F20"
                  fill="#02B8DE"
                />
                <path
                  d="M19.4308965,8.91898698 C18.9704645,8.21699407 18.3993753,7.76110011 17.7172798,7.55165419 C17.2732544,7.41237265 16.3199264,7.34185919 14.858692,7.34185919 L11,7.34185919 L11,21.3126003 L13.3862872,21.3126003 L13.3862872,16.0404974 L14.9581788,16.0404974 C16.0493921,16.0404974 16.8826378,15.9738237 17.4579159,15.8401274 C17.8816948,15.7322628 18.297794,15.5119955 18.707959,15.1782783 C19.118822,14.8456084 19.4563791,14.3862237 19.7220263,13.8018695 C19.9883717,13.2175154 20.1213699,12.4963233 20.1213699,11.6389913 C20.1217189,10.5278807 19.8913284,9.62063081 19.4308965,8.91898698 Z M17.3839117,12.7783771 C17.2016937,13.0928951 16.9493114,13.3236347 16.6267647,13.470945 C16.304567,13.6186044 15.6668042,13.6922595 14.7124289,13.6922595 L13.3866363,13.6922595 L13.3866363,9.68974797 L14.5570899,9.68974797 C15.4301303,9.68974797 16.0106446,9.72221208 16.3003781,9.78609309 C16.6927401,9.86952238 17.0177304,10.0786192 17.2736035,10.4116382 C17.5298257,10.7453554 17.6586349,11.1684362 17.6586349,11.6819277 C17.6582858,12.0990742 17.5671768,12.4642083 17.3839117,12.7783771 Z"
                  id="Shape"
                  fill="#231F20"
                  fillRule="nonzero"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Parking.displayName = "Parking";

export default Parking;
