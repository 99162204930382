import React, { PureComponent } from "react";
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";

import { Grid,Section } from "../../../../components";
import { messages } from "../../../../constants";
import {List} from "../../../../elements";




class DriversLicenseRequirements extends PureComponent {

  render() {
    const { intl } = this.props;
    return (
      <Section size="2-3" min="md">
        <Grid theme={["div"]}>
          <p className="col">{intl.formatMessage(messages.drivers.mustListTitle)}</p>
          <List theme={["col2", "smaller"]}>
            { [intl.formatMessage(messages.drivers.bc.requirement1),
            intl.formatMessage(messages.drivers.bc.requirement2),
            intl.formatMessage(messages.drivers.bc.requirement3),
            intl.formatMessage(messages.drivers.bc.requirement4),
            intl.formatMessage(messages.drivers.bc.requirement5)]}
          </List>
        </Grid>
      </Section>
    );
  }
}

DriversLicenseRequirements.propTypes = {
  intl: PropTypes.object.isRequired
};

export default injectIntl(DriversLicenseRequirements);
