/* eslint-disable import/no-named-as-default */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Switch, Route, withRouter } from "react-router-dom";
import cn from "classnames";
import { injectIntl } from "react-intl";
import { compose } from "redux";
import DOMPurify from "dompurify";
import get from "lodash/get";
// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.
import {
  // AccountDriversPage,
  // AccountLoginInfoPage,
  // AccountPage,
  // AccountPaymentPage,
  // AccountPreferenceCenterPage,
  // AccountUserPage,
  // UpdatePasswordPage,
  ErrorPage,
  PrivateRegistrationPage,
  // FindACarPage,
  // ForgotPasswordCodePage,
  // ForgotPasswordPage,
  // HomePage,
  // LoginPage,
  RegistrationPage,
} from "./";
//import { fetchRegions } from "../actions/regions";
import {toggleSpinnerOff, setDropdownOptions, toggleSpinnerOn} from "../actions/settings";
import { setPromoCodeStatus, setEvolveOnlyStatus} from "../actions/flow";
import { Header, Footer, Nav, /*isAuthenticated*/ } from "../containers";
import * as selectors from "../selectors";
import { constants, options } from "../constants";
import { NavLinkList, Spinner, DependOn } from "../components";
import { getJsonFromUrl } from "../util";
import PrivateRegistrationSuccessPage from "./privateRegistration/success";
import PrivateRegistrationVerifyOTP from "./privateRegistration/verifyOTP";
import ContactUsBusiness from "./contactUsBusiness";

// DOMPurify Global configuration
// by default, DOMPurify omits 'target' attr
DOMPurify.setConfig({ ADD_ATTR: ["target"] });

class App extends PureComponent {
  componentDidMount() {
    this.props.toggleSpinnerOff();
    this._setDropdownMenuOptions();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.intl.locale !== this.props.intl.locale) {
      this._setDropdownMenuOptions();
    }
  }

  render() {
    const { theme = "", isSpinnerActive, shouldShowSubnav } = this.props;
    const containerCSS = cn("container", `${theme === "" ? "" : "container--" + theme}`);

    const queryObj = getJsonFromUrl(get(this.props, "location.search", ""));
    const { idToken, accessToken, promocode } = queryObj;

    const isMobileAppview = (idToken && accessToken) || !shouldShowSubnav;
    const payload = { code: promocode, status: "", message: "" };

    if (promocode !== null || promocode !== "") this.props.setPromoCodeStatus(payload);

    return (
      <div className={containerCSS}>
        <input type="checkbox" id="nav-checkbox" tabIndex="4" />
        <Spinner theme={["fullpage"]} active={isSpinnerActive} />
        <DependOn active={!isMobileAppview}>
          <Header />
        </DependOn>
        <Nav>
          <NavLinkList />
        </Nav>
        <Switch>
          <Route exact path={constants.ROUTE_REGISTRATION} component={RegistrationPage} />
          <Route
            exact
            path={constants.ROUTE_PRIVATE_REGISTRATION}
            component={PrivateRegistrationPage}
          />
          <Route
            exact
            path={constants.ROUTE_PRIVATE_REGISTRATION_OTP}
            component={PrivateRegistrationVerifyOTP}
          />
          <Route
            exact
            path={constants.ROUTE_PRIVATE_REGISTRATION_SUCCESS}
            component={PrivateRegistrationSuccessPage}
          />
          <Route exact path={constants.ROUTE_CONTACT_US_BUSINESS} component={ContactUsBusiness} />
          {/*<Route exact path={constants.ROUTE_HOME} component={HomePage} />*/}
          {/*<Route exact path={constants.ROUTE_FIND_A_CAR} component={FindACarPage} />*/}
          {/*<Route exact path={constants.ROUTE_SIGNIN} component={LoginPage} />*/}
          {/*<Route exact path={constants.ROUTE_RESET_PASSWORD} component={ForgotPasswordPage} />*/}
          {/*<Route*/}
          {/*  exact*/}
          {/*  path={constants.ROUTE_RESET_PASSWORD_CODE}*/}
          {/*  component={ForgotPasswordCodePage}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  exact*/}
          {/*  path={constants.ROUTE_ACCOUNT_LOGIN_INFO}*/}
          {/*  component={isAuthenticated(AccountLoginInfoPage)}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  exact*/}
          {/*  path={constants.ROUTE_ACCOUNT_PREFERENCE_CENTER}*/}
          {/*  component={isAuthenticated(AccountPreferenceCenterPage)}*/}
          {/*/>*/}
          {/*<Route exact path={constants.ROUTE_REGISTRATION} component={RegistrationPage} />*/}
          {/*<Route path={constants.ROUTE_ACCOUNT} component={AccountPage} />*/}
          {/*<Route*/}
          {/*  exact*/}
          {/*  path={constants.ROUTE_ACCOUNT_PERSONAL}*/}
          {/*  component={isAuthenticated(AccountUserPage)}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  exact*/}
          {/*  path={constants.ROUTE_ACCOUNT_PAYMENT}*/}
          {/*  component={isAuthenticated(AccountPaymentPage)}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  exact*/}
          {/*  path={constants.ROUTE_ACCOUNT_DRIVERS}*/}
          {/*  component={isAuthenticated(AccountDriversPage)}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  exact*/}
          {/*  path={constants.ROUTE_ACCOUNT_CHANGE_PASSWORD}*/}
          {/*  component={isAuthenticated(UpdatePasswordPage)}*/}
          {/*/>*/}
          <Route path="*" component={ErrorPage} />
        </Switch>
        <DependOn active={!isMobileAppview}>
          <Footer />
        </DependOn>
      </div>
    );
  }

  _setDropdownMenuOptions = () => {
    const { intl } = this.props;
    // set options;
    const payload = {
      countries: options.countries(intl),
      provinces: options.provinces(intl),
      states: options.states(intl),
      optin: options.optin(intl)
    };

    this.props.setDropdownOptions(payload);
  };
}

App.propTypes = {
  theme: PropTypes.string,
 // fetchRegions: PropTypes.func.isRequired,
  toggleSpinnerOn: PropTypes.func.isRequired,
  toggleSpinnerOff: PropTypes.func.isRequired,
  setDropdownOptions: PropTypes.func.isRequired,
  setPromoCodeStatus: PropTypes.func.isRequired,
  setEvolveOnlyStatus: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool,
  isSpinnerActive: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired
};

App.defaultProps = {
  isSpinnerActive: false
};

const mapStateToProps = state => ({
  theme: selectors.getPageTheme(state),
  isSpinnerActive: selectors.getSettings(state).isSpinnerActive,
  shouldShowSubnav: selectors.shouldShowSubnav(state)
});

const mapDispatch = {
  toggleSpinnerOff,
  toggleSpinnerOn,
 // fetchRegions,
  setDropdownOptions,
  setPromoCodeStatus,
  setEvolveOnlyStatus
};

const enhancer = compose(
  withRouter,
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatch
  )
);

export default enhancer(App);
