import React from "react";
import { PagesContainer } from "../../containers";
import {Section, Grid, Col} from "../../components";
import {messages} from "../../constants";
import {Title, Txt} from "../../elements";
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";
import hubIcon from "../../assets/img/HUBLogo.jpg";
import phoneIcon from "../../assets/img/evoApp.png";


const PrivateRegistrationSuccessPage = props => {

  return (
    <PagesContainer {...props}>
      <div className="outerForm">
        <div className="form__section">
          <Title priority={2} type={["strong"]}>
            {"Get ready to roll!"}
          </Title>
          <div className="grid">
            <div className="col">
        <Section size="1-2" min="md" theme={["div"]}>
        <Grid>
          <Col size="2-3">
            <Txt className="text--middle">
               <div>
          {props.intl.formatMessage(messages.privateRegistration.BCOEmailInstructions,{br: <br />, evolve: <a href="https://www.evo.ca/evolve" target='_blank' rel='noopener noreferrer'>www.evo.ca/evolve</a>} )} 
        </div>
            </Txt>
          </Col>
          <Col size="1-3" theme={["right"]}>
            <a href='https://evo.ca/car-share-app' target='_blank' rel='noopener noreferrer'><img className="evoApp" src={phoneIcon} alt="EvoApp" /></a>
          </Col>
        </Grid>
        </Section>
      </div>
      </div>
        </div>
      </div>
      <hr className="hr--primary"/>
      <Section size="1-2" min="md" theme={["div"]}>
        <Grid>
          <Col size="1-3" theme={["left"]}>
            <img className="evoApp" src={hubIcon} alt="EvoApp" />
          </Col>
          <Col  size="2-3">
            <Txt className="text--middle">
              <div>{props.intl.formatMessage(messages.ebikeConfirmation.confirmationContent, {newLine: <br />, hubLink: <a className='bold primary' href='https://bikehub.b-cdn.net/sites/default/files/hub_cycling_x_bcaa_-_e-bike_online_v6_1.pdf' target='_blank' rel='noopener noreferrer'>HUB Cycling's E-Bike Safety Handbook</a>})}</div>
            </Txt>
          </Col>
        </Grid>
      </Section>
    </PagesContainer>
  );
};

PrivateRegistrationSuccessPage.defaultProps = {};

PrivateRegistrationSuccessPage.propTypes = {
  intl: PropTypes.object.isRequired
};
export default injectIntl(PrivateRegistrationSuccessPage);
