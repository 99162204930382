import Auth from "@aws-amplify/auth";
import { SubmissionError } from "redux-form";
import { constants, messages } from "../constants";
import { LOGIN_ERRORS, CHANGE_PASSWORD_ERRORS } from "../constants/auth";
import { getCustomerAccountSummary } from "../api/customer";
import { push } from "connected-react-router";
import jwtDecode from "jwt-decode";

export const mapLoginErrors = error => {
  let errorObject = {};

  switch (error.code) {
    // If the e-mail used doesn't exist or the password used is wrong for the
    // given e-mail, show a generic error message describing either case.
    case LOGIN_ERRORS.USER_NOT_FOUND:
    case LOGIN_ERRORS.USER_NOT_AUTHORIZED:
      errorObject = {
        username: true,
        password: true,
        _error: "Sorry we don’t recognize that email or password."
      };
      break;
    case LOGIN_ERRORS.USER_NOT_CONFIRMED:
      errorObject["username"] = error.message;
      break;
    default:
      // TODO: temp fix for `mixpanel` error
      if (error.message && error.message.indexOf("given_name") < 0) {
        errorObject["_error"] = "Oops! Something went wrong - please try again in a few minutes.";
      }
      break;
  }

  return errorObject;
};

export const mapChangePasswordErrors = (error, intl) => {
  // 1. {"__type":"NotAuthorizedException","message":"Incorrect username or password."}
  // 2. {"__type":"LimitExceededException","message":"Attempt limit exceeded, please try after some time."}

  let errorObject = {};
  switch (error.code) {
    case CHANGE_PASSWORD_ERRORS.USER_NOT_AUTHORIZED:
      errorObject["oldPassword"] = intl.formatMessage(messages.error.wrongCurrentPassword);
      break;
    default:
      // TODO: Localize following string
      errorObject["_error"] = intl.formatMessage(messages.error.genericError);
      break;
  }
  return errorObject;
};

export const login = ({ username, password }) => async dispatch => {
  dispatch({ type: constants.SPINNER_ON });
  // swap the email value to lowercase to avoid aws case sensitive error
  username = username && username.toLowerCase();
  try {
    const cognitoUser = await Auth.signIn(username, password);
    if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
      await new Promise((resolve, reject) => {
        cognitoUser.completeNewPasswordChallenge(
          password,
          cognitoUser.challengeParam.requiredAttributes,
          {
            onSuccess: resolve,
            onFailure: reject
          }
        );
      });
    }

    const accountSummary = await getCustomerAccountSummary();

    // update user information in redux store
    dispatch({ type: constants.SPINNER_OFF });
    dispatch({
      type: constants.USER_INFO,
      payload: { accountSummary }
    });
    return accountSummary;
  } catch (error) {
    // Email to the user confirmation, and redirect to ConfirmEmail page
    if (error.code === LOGIN_ERRORS.USER_NOT_CONFIRMED) {
      Auth.resendSignUp(username);
      // dispatch({ type: "REGISTER_SUCCESS", payload: { user: { username } } });
    }
    dispatch({ type: constants.SPINNER_OFF });
    throw new SubmissionError(mapLoginErrors(error));
  }
};

export const loginWithToken = ({ idToken, accessToken }) => async dispatch => {
  try {
    const userObj = jwtDecode(idToken);
    const prefix = "CognitoIdentityServiceProvider";
    const { aud } = userObj; // aud is the cognito web client id
    const combinedKey = `${prefix}.${aud}`;

    const lastAuthUser = userObj["cognito:username"];
    //console.log(jwtDecode(idToken));

    localStorage.setItem(`${combinedKey}.LastAuthUser`, lastAuthUser);
    localStorage.setItem(`${combinedKey}.${lastAuthUser}.idToken`, idToken);
    localStorage.setItem(`${combinedKey}.${lastAuthUser}.accessToken`, accessToken);

    const accountSummary = await getCustomerAccountSummary(); // update user information in redux store
    dispatch({
      type: constants.USER_INFO,
      payload: { accountSummary }
    });
    dispatch({ type: constants.SHOW_SUB_NAV, payload: false });
  } catch (error) {
    //
  }
};

export const logout = (redirectPath = "/") => async dispatch => {
  try {
    dispatch({ type: constants.SPINNER_ON });
    await Auth.signOut();
    dispatch({ type: constants.USER_DELETE });
    dispatch({ type: constants.SPINNER_OFF });
    dispatch(push(redirectPath));
  } catch (e) {
    dispatch({ type: constants.SPINNER_OFF });
    throw new Error(e);
  }
};

export const changePassword = ({ oldPassword, newPassword }, intl) => async dispatch => {
  dispatch({ type: constants.SPINNER_ON });
  try {
    const user = await Auth.currentAuthenticatedUser();
    const cognitoUser = await Auth.changePassword(user, oldPassword, newPassword);
    dispatch({ type: constants.SPINNER_OFF });
    return cognitoUser;
  } catch (error) {
    dispatch({ type: constants.SPINNER_OFF });
    throw new SubmissionError(mapChangePasswordErrors(error, intl));
  }
};
