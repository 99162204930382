/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { reduxForm, reset, FormSection } from "redux-form";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import { get } from "lodash";

import * as selectors from "../../../selectors";
import * as allActions from "../../../actions";
import { Button } from "../../../elements";
import { messages, constants } from "../../../constants";
import { BillingAddress } from "../fieldGroups";

const settings = {
  form: constants.FORM.updateBillingAddressForm,
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true
};

class UpdateBillingAddressForm extends PureComponent {
  state = {
    isUpdating: false
  };

  // submit the form values
  handleClickSubmit = async values => {
    await this.props.onSubmit(values);
    window.scrollTo(0, 0);
    this.setState({ isUpdating: false });
  };

  // enable editing mode, and reset values to default
  handleClickCancel = e => {
    e.preventDefault();
    this.props.reset();
    this.setState({ isUpdating: false });
    this.props.clickEditCallback();
  };

  render() {
    const { handleSubmit, intl, section, submitting } = this.props;

    return (
      // noValidate disable browser validation
      <form onSubmit={handleSubmit(this.handleClickSubmit)} noValidate>
        <FormSection name={section} className="form__section">
          <BillingAddress
            {...this.props}
            section={section}
            onToggle={this.props.clickEditCallback}
            homeAddress = {get(this.props.initialValues, "homeAddress", {})}
          />
        </FormSection>


          <Button loading={submitting} type="submit" theme={["cta"]}>
            <span>{intl.formatMessage(messages.button.save)}</span>
          </Button>


          <a onClick={this.handleClickCancel} className="is-underlined">
            {intl.formatMessage(messages.button.cancel)}
          </a>

      </form>
    );
  }
}

UpdateBillingAddressForm.propTypes = {
  intl: PropTypes.object.isRequired,
  section: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string
  ]),
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  sections: PropTypes.array,
  state: PropTypes.object,
  user: PropTypes.object,
  actions: PropTypes.objectOf(PropTypes.func),
  initialValues: PropTypes.object,
  reset: PropTypes.func,
  clickEditCallback: PropTypes.func,
  submitting: PropTypes.bool
};

// const selector = formValueSelector(settings.form);
const mapStateToProps = state => ({
  state,
  user: selectors.getUser(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(allActions.flow, dispatch),
  reset: () => {
    dispatch(reset());
  }
});

export default compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm(settings)
)(UpdateBillingAddressForm);
