import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import "./dependon.scss";

const DependOn = props => {
  const { children, active, className } = props;
  const outerCSS = cn("dependon", className, {
    "dependon--active": active
  });

  return <div className={outerCSS}>{active && children}</div>;
};

DependOn.displayName = "DependOn";

DependOn.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string
  ]),
  active: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.bool]),
  className: PropTypes.string
};

DependOn.defaultProps = {};

export default DependOn;
