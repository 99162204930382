import React from "react";
import PropTypes from "prop-types";
import { FormSectionWrapper } from "../";
import { injectIntl } from "react-intl";
import { Address, Membership } from "../../fieldGroups";

import { messages } from "../../../../constants";
import { validationBuilder, validations } from "../../";

let rules = {};
const HomeAddress = props => {
  const { intl } = props;

  Object.keys(validations).forEach(key => {
    rules[key] = spec => value => validationBuilder(props, validations[key], spec)(value);
  });

  return (
    <FormSectionWrapper {...props}>
      <Address {...props} type={ intl.formatMessage(messages.title.HomeAddress) } />
      <Membership {...props} />
    </FormSectionWrapper>
  );
};

HomeAddress.propTypes = {
  intl: PropTypes.object.isRequired
};

export default injectIntl(HomeAddress);
