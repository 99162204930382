import React, {useState} from 'react';

import Webcam from "react-webcam";
import ImagePreview from "./../ImagePreview";
import './webcam.scss';
import {CircleButton} from "./circleButton";
import {Button} from "../../../../elements";
import PropTypes from "prop-types";
import {messages} from "../../../../constants";
import {bindActionCreators} from "redux";
import {flow} from "../../../../actions";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Col, Grid} from "../../../../components";


function WebcamComponent({intl, resultsCallback, isSelfie}) {
  const [dataUri, setDataUri] = useState(null);
  const [videoConstraints] = React.useState({facingMode: isSelfie ? "user" : "environment"});
  const webcamRef = React.useRef(null);


  function dataURItoBlob(dataURI) {
    let byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    let blob = new Blob([ab], {type: mimeString});
    return blob;
  }

  const capture = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setDataUri(imageSrc);
  }, [webcamRef, setDataUri]);

  const submitPhoto = React.useCallback(async () => {

    const blob = dataURItoBlob(dataUri)
    const fileUrl = new File([blob], "dl_front", {type:blob.type} )
    return resultsCallback(fileUrl);
  }, [dataUri, resultsCallback]);


  const clearCapture = React.useCallback(() => {
    //const imageSrc = webcamRef.current.getScreenshot();
    setDataUri(null);
  }, [setDataUri]);


  const isFullscreen = true;

  return (
    <div id="video-stream">

      {
        (dataUri)
          ?
          <div theme={["center"]}>
            <ImagePreview
              dataUri={dataUri}
              isFullscreen={isFullscreen}
            />
            <Grid theme={["center"]}>
            <Col size='1-2' theme={["center"]}>

            <Button
              theme={["cta", "center"]}
              onClick={clearCapture}>{intl.formatMessage(messages.imageCapture.retakePicButton)}
            </Button>

            <Button
              theme={["cta", "center"]}
              onClick={submitPhoto}>{intl.formatMessage(messages.imageCapture.acceptPicButton)}
            </Button>
            </Col>
            </Grid>
          </div>
          :
          <div theme={['center']}>

            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              mirrored={false}
              screenshotQuality={1}
              videoConstraints={videoConstraints}
            />

            <CircleButton
              isClicked={!!dataUri}
              onClick={capture}
            />

          </div>
      }
    </div>
  );
}

WebcamComponent.propTypes = {
  intl: PropTypes.object.isRequired,
  handleDriversLicenseValidate: PropTypes.func.isRequired,
  resultsCallback: PropTypes.func.isRequired,
  isSelfie: PropTypes.bool.isRequired
}


const mapDispatchToProps = (dispatch) => ({
  handleDriversLicenseValidate: bindActionCreators(flow.handleDriversLicense, dispatch)
});
export const IntlWebcam = injectIntl(WebcamComponent);
export default injectIntl(connect(null, mapDispatchToProps)(WebcamComponent));
