import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import "react-phone-number-input/style.css";
import "./input.scss";

import { Tooltip, Txt } from "../../elements";

class InputWithoutRedux extends PureComponent {
  static displayName = "InputWithoutRedux";

  state = {
    passvisible: false,
    focused: false,
    error: null,
    value: this.props.value || ""
  };

  handlePassField = () => {
    this.setState(state => ({ ...state, passvisible: !state.passvisible }));
  };

  handleChange = e => {
    this.setState({ value: e.target.value });
  };

  render() {
    const {
      className,
      dirtylook,
      disabled,
      disableErrorIcon,
      hideEyeIcon,
      label,
      name,
      preventSubmitPressEnter,
      required,
      tooltip,
      type,
      onChange,
      ...rest
    } = this.props;

    const { error, value } = this.state;

    const txt = `${label}${required ? " *" : ""}`;
    const ispass = type === "password";
    const inputtype = ispass ? (this.state.passvisible ? "text" : type) : type;

    const inputCSS = cn("input", className, {
      "is-focused": this.state.focused,
      "has-icon": tooltip
    });
    const passIconCSS = cn("fa", {
      "fa-eye-slash": this.state.passvisible,
      "fa-eye": !this.state.passvisible
    });

    return (
      <fieldset className={inputCSS} role="group">
        <div className="input-field">
          <input
            disabled={disabled}
            type={inputtype}
            placeholder={dirtylook ? "" : txt}
            className={dirtylook ? "" : "placeholder-shown"}
            id={name}
            required={required}
            value={value}
            onChange={onChange}
            onKeyPress={e => {
              if (preventSubmitPressEnter && e.key === "Enter") {
                e.preventDefault();
              }
            }}
            {...rest}
          />
          <label htmlFor={name}>{txt}</label>
          {ispass && !hideEyeIcon && (
            <i className={passIconCSS} onClick={this.handlePassField} role="button" />
          )}
          {tooltip && <Tooltip>{tooltip}</Tooltip>}
        </div>
        {error && (
          <Txt theme={[!disableErrorIcon && "error"]}>
            <small>{error}</small>
          </Txt>
        )}
      </fieldset>
    );
  }
}

InputWithoutRedux.propTypes = {
  className: PropTypes.string,
  dirtylook: PropTypes.bool,
  disabled: PropTypes.bool,
  disableErrorIcon: PropTypes.bool,
  hideEyeIcon: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  preventSubmitPressEnter: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  type: PropTypes.string,
  value: PropTypes.string
};

InputWithoutRedux.defaultProps = {
  dirtylook: false,
  disabled: false,
  disableErrorIcon: false,
  hideEyeIcon: false,
  required: false
};

export default InputWithoutRedux;
