import { constants } from "../constants";
import * as mapAPI from "../api/findacar";

export const getCars = values => async dispatch => {
  const cars = await mapAPI.fetchCars();
  if (cars.error) {
    throw new Error(cars.error);
  }

  // filter out cars that do not have coordinates
  const payload = cars.filter(car => car.coordinates);

  // update user information in redux store
  dispatch({
    type: constants.MAP_FETCH_CARS,
    payload
  });
  return cars;
};

export const createCarReservation = vin => ({
  type: constants.MAP_CREATE_CAR_RESERVATION,
  payload: vin
});

export const removeCarReservation = vin => ({
  type: constants.MAP_REMOVE_CAR_RESERVATION,
  payload: vin
});
