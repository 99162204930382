import React, {PureComponent} from "react";
import {Field, formValueSelector, getFormValues} from "redux-form";
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";
import "./membership.scss";
import {Card, Col, DependOn, Grid, Input, Radio} from "../../../../components";
import {Txt} from "../../../../elements";
import {formatters, normalizers, validationBuilder, validations} from "../..";
import {constants, messages} from "../../../../constants";
import {compose} from "redux";
import bcaaGoLogo from "../../../../assets/img/BCAA_GO_LOGO.png";
import DataCell from "../../../../components/card/dataCell";

import {BrowserView, MobileView, isIOS} from 'react-device-detect';
import asyncValidate from "../../asyncValidate";
import {connect} from "react-redux";

import * as selectors from "../../../../selectors";

let rules = {};

class Membership extends PureComponent {
  ruleMembershipNumber = [];
  ruleIsCaaMember = [];

  constructor(props) {
    super(props);
    // for field level validations
    // TODO: find a better way to instantiate props when the component mounts
    Object.keys(validations).forEach(key => {
      rules[key] = spec => value => validationBuilder(props, validations[key], spec)(value);
    });

    const { intl } = props;
    this.ruleMembershipNumber = [
      rules.required(intl.formatMessage(messages.member.number)),
      rules.memberNumber()
    ];
    this.ruleIsCaaMember = [
      rules.required(intl.formatMessage(messages.member.ismember)),
    ]
  }

  async componentDidMount() {
    const {section, dispatch, formValues} = this.props;

    let iscaaMember = this.getValue('iscaaMember');
    let memberNumber = this.getValue('memberNumber');
    const name = 'memberNumber';

    if (iscaaMember==='Yes' && (memberNumber)) {

      await asyncValidate(formValues, dispatch, this.props, `${section}.${name}`).then(r => {
        this.stopAsyncValue(r);
      }, f => {
        this.stopAsyncValue(f)
      });
    }

  }

  stopAsyncValue = (errors) => {
    const {
      actions: {manualStopAsyncValidation}
    } = this.props;
    manualStopAsyncValidation({...this.props, errors});
  };

  getValue = name => {
    const { state, form, section } = this.props;
    const selector = formValueSelector(form);
    return section ? selector(state, `${section}.${name}`) : selector(state, name);
  };

  render() {
    const { intl, footerNote, validateMembership } = this.props;

    return (
      <div className={"marginTop"}>
        <Grid>
          <Col>
            <Field
              name="iscaaMember"
              label={intl.formatMessage(messages.member.ismember)}
              labelClassName="text--large"
              radioFieldClassName="compact"
             // tooltip={tooltip}
              required={true}
             // tooltipArrow="top"
              component={Radio}
              options={[
                { label: intl.formatMessage(messages.field.yesRadioButton), value: "Yes" },
                { label: intl.formatMessage(messages.field.noRadioButton), value: "No"}
              ]}
              validate={this.ruleIsCaaMember}
            />
          </Col>
        </Grid>
        <Grid>
          <Col size="3-4" min="sm">
            <Txt>
              <small>
                {footerNote ? footerNote : intl.formatMessage(messages.member.dagger)}
              </small>
            </Txt>
          </Col>
        </Grid>
        <DependOn active={this.getValue(`iscaaMember`) === "Yes"}>
          <Grid>
            <Col size="1-2" min="sm">
              <Field
                type="text"
                name="memberNumber"
                label={intl.formatMessage(messages.member.number)}
                locale={intl.locale}
                required={true}
                validate={this.ruleMembershipNumber}
                format={formatters.memberNumber}
                normalize={compose(
                  normalizers.onlyNumChars(16),
                  normalizers.onlyNumbers,
                  normalizers.removeSpaces
                )}
                asyoutype={true}
                component={Input}
                props={{ maxLength: "22" }}
              />
              <DependOn active={validateMembership && validateMembership.res && validateMembership.res.valid===true && validateMembership.res.active===false}>
              <Txt theme={["small", "error", "left"]}>
                <small>{intl.formatMessage(messages.asyncValidation.BCAAMembershipInactiveValid)}</small>
              </Txt>
              </DependOn>
              <Txt theme={["note"]}>
                <small>{intl.formatMessage(messages.member.numberhelp)}</small>
              </Txt>
            </Col>
          </Grid>
        </DependOn>
        <DependOn active={this.getValue(`iscaaMember`) === "No"}>
          <BrowserView>
          <Card>
            <Card.Header>
              <Grid theme={["small-div"]}>
                  <Col size="5-6">
                  <DependOn active = {isIOS === true}>
                    <DataCell theme={["bold"]}
                                headerTitle={intl.formatMessage(messages.member.BCAAMembersFreeRegHeader,{newLine:<br />})}
                                data={intl.formatMessage(messages.member.BCAAMembersFreeRegMinutes, {link: <a href="https://www.bcaa.com/5free">bcaa.com/5free</a>})} {...this.props}/>

                  </DependOn>
                  <DependOn active= {isIOS=== false}>
                  <DataCell theme={["bold"]}
                              headerTitle={intl.formatMessage(messages.member.BCAAMembersFreeRegHeader,{newLine:<br />})}
                              data={intl.formatMessage(messages.member.BCAAMembersFreeRegMinutes, {link: <a href="https://www.bcaa.com/5free" target="_blank" rel="noreferrer">bcaa.com/5free</a>})} {...this.props}/>
                  </DependOn>
                  </Col>
                  <Col size="1-6" theme={["right"]}>
                    <img className="memberCard" src={bcaaGoLogo} alt="Basic BCAA Membership Card" />
                </Col>
              </Grid>
            </Card.Header>
          </Card>
          </BrowserView>
          <MobileView>
          <Card>
            <Card.Header>
              <Grid theme={["small-div"]}>

                  <Col size="3-4">
                    <DependOn active = {isIOS === true}>
                      <DataCell theme={["bold"]}
                                headerTitle={intl.formatMessage(messages.member.BCAAMembersFreeRegHeader,{newLine:<br />})}
                                data={intl.formatMessage(messages.member.BCAAMembersFreeRegMinutes, {link: <a href="https://www.bcaa.com/go">bcaa.com/go</a>})} {...this.props}/>

        </DependOn>
                    <DependOn active= {isIOS=== false}>
                    <DataCell theme={["bold"]}
                              headerTitle={intl.formatMessage(messages.member.BCAAMembersFreeRegHeader,{newLine:<br />})}
                              data={intl.formatMessage(messages.member.BCAAMembersFreeRegMinutes, {link: <a href="https://www.bcaa.com/go" target="_blank" rel="noreferrer">bcaa.com/go</a>})} {...this.props}/>
                    </DependOn>
                    </Col>

                  <Col size="1-4" theme={["right"]}>
                    <img className="memberCard" src={bcaaGoLogo} alt="Basic BCAA Membership Card" />
                  </Col>
              </Grid>
            </Card.Header>
          </Card>
          </MobileView>
        </DependOn>



      </div>
    );
  }
}

const mapStateToProps = state => ({
  formValues: getFormValues(constants.FORM.registrationForm)(state),
  validateMembership: selectors.getValidateMembership(state)
});


Membership.propTypes = {
  intl: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  section: PropTypes.string,
  footerNote: PropTypes.string,
  isMultiRegion: PropTypes.bool
};

export default  injectIntl(connect(mapStateToProps, null)(Membership));
