import React, {PureComponent} from "react";
import {Field, formValueSelector, getFormValues} from "redux-form";
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import moment from "moment-timezone";

import {
  Input,
  Datepicker,
  Grid,
  Col, DependOn
} from "../../../../components";

import {constants, messages} from "../../../../constants";
import {validationBuilder, validations} from "../..";
import {hashString} from "../../../../util";
import * as selectors from "../../../../selectors";
import asyncValidate from "../../asyncValidate";

const rules = {};

/**
 *
 *
 * @class Drivers
 * @extends {PureComponent}
 */
class Drivers extends PureComponent {
  static displayName = "Drivers";

  static propTypes = {
    intl: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    state: PropTypes.object.isRequired,
    section: PropTypes.string.isRequired,
    actions: PropTypes.objectOf(PropTypes.func),
    disabled: PropTypes.bool,
    dropdownOptions: PropTypes.objectOf(
      PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.any }))
    ),
    isEbikeOnly: PropTypes.bool,
    formValues: PropTypes.object.isRequired
  };

  static defaultProps = {
    disabled: false
  };

  ruleCountry = [];
  ruleProvince = [];
  ruleState = [];
  ruleDriverLicense = [];
  ruleDriverLicenseExpiry = [];
  ruleDob = [];

  async componentDidMount() {
    const { section, dispatch, formValues } = this.props;
    this.generateValidationRulesFromProps();

    let number = this.getValue("number");
    const name = "number";

    if (number) {
      await asyncValidate(formValues, dispatch, this.props, `${section}.${name}`).then(
        (r) => {
          this.stopAsyncValue(r);
        },
        (f) => {
          this.stopAsyncValue(f);
        }
      );
    }

    this.handleDefaultBirthDate();
  }

  getBaseValue = (name) => {
    const { state, form, section } = this.props;
    const selector = formValueSelector(form);
    return section ? selector(state, name) : selector(state, name);
  };

  getValue = (name) => {
    const { state, form, section } = this.props;
    const selector = formValueSelector(form);
    return section ? selector(state, `${section}.${name}`) : selector(state, name);
  };

  handleDefaultBirthDate = () => {
    const birthDate = this.getBaseValue("personalInfo.birthDate");
    this.changeValue("birthDate", birthDate);
  };

  render() {
    const { intl, disabled, isEbikeOnly } = this.props;
    return (
      <Grid>
        <Col size="2-3" min="sm">
          <DependOn active={isEbikeOnly === false}>
            <Field
              type="text"
              name="number"
              label={intl.formatMessage(messages.field.driversLicenseNumber)}
              required={true}
              disabled={disabled}
              validate={this.ruleDriverLicense}
              component={Input}
              props={{ maxLength: "36" }}
              format={(value) => (value ? this.isDLHashed(value, disabled) : "")}
            />
          </DependOn>
        </Col>

        <Col size="1-2" min="sm">
          <Field
            name="birthDate"
            label={intl.formatMessage(messages.personal.dob)}
            required={true}
            disabled={disabled}
            locale={intl.locale}
            validate={this.ruleDob18}
            component={Datepicker}
            initialMonth={moment().subtract(18, "years").toDate()}
            disabledDays={{
              after: moment().subtract(18, "years").toDate()
            }}
          />
        </Col>

        <Col size="1-2" min="sm">
          <DependOn active={isEbikeOnly === false}>
            <Field
              name="expiryDate"
              label={intl.formatMessage(messages.field.driversLicenseExpiry)}
              required={true}
              disabled={disabled}
              locale={intl.locale}
              validate={this.ruleDriverLicenseExpiry}
              component={Datepicker}
              initialMonth={moment().add(1, "days").toDate()}
              disabledDays={{
                before: moment().add(1, "days").toDate()
              }}
            />
          </DependOn>
        </Col>
      </Grid>
    );
  }

  /**
   *
   *
   * @memberof Drivers
   * TODO: this is causing a warning
   * Warning: Cannot update during an existing state transition (such as within `render` or another component's constructor). Render methods should be a pure function of props and state; constructor side-effects are an anti-pattern, but can be moved to `componentWillMount`.
   */
  changeValue = (name, newvalue, options) => {
    const {
      actions: { changeFieldValue }
    } = this.props;
    changeFieldValue({ ...this.props, name, newvalue, options });
  };

  stopAsyncValue = (errors) => {
    const {
      actions: { manualStopAsyncValidation }
    } = this.props;
    manualStopAsyncValidation({ ...this.props, errors });
  };

  /**
   *
   *
   * @memberof Drivers
   */
  resetProvinceAndState = (prevCountry, newCountry) => {
    if (prevCountry !== newCountry) {
      this.changeValue("province", "");
      this.changeValue("state", "");
      this.changeValue("postalCode", "");
      this.changeValue("zipCode", "");
    }
  };

  /**
   *
   *
   * @memberof Drivers
   */
  generateValidationRulesFromProps = () => {
    const props = this.props;

    Object.keys(validations).forEach((key) => {
      rules[key] = (spec) => (value) => validationBuilder(props, validations[key], spec)(value);
    });

    const { intl } = props;
    this.ruleCountry = [rules.required(intl.formatMessage(messages.address.countryOfIssue))];
    this.ruleProvince = [rules.required(intl.formatMessage(messages.address.provinceOfIssue))];
    this.ruleState = [
      rules.required(
        `${intl.formatMessage(messages.address.state)} ${intl.formatMessage(
          messages.field.ofIssue
        )}`
      )
    ];
    this.ruleDriverLicense = [
      rules.required(intl.formatMessage(messages.field.driversLicenseNumber)),
      rules.alphanumeric(intl.formatMessage(messages.field.driversLicenseNumber))
    ];
    this.ruleDriverLicenseExpiry = [
      rules.isValidDate(intl.formatMessage(messages.error.invalidDate)),
      rules.required(intl.formatMessage(messages.field.driversLicenseExpiry)),
      rules.isFuture(intl.formatMessage(messages.error.expiredDriversLicense))
    ];
    this.ruleDob18 = [
      rules.isValidDate(intl.formatMessage(messages.error.invalidDate)),
      rules.required(intl.formatMessage(messages.personal.dob)),
      rules.isOverAge(18)
    ];
    this.ruleDob19 = [
      rules.isValidDate(intl.formatMessage(messages.error.invalidDate)),
      rules.required(intl.formatMessage(messages.personal.dob)),
      rules.isOverAge(19)
    ];
  };

  /**
   *
   *
   * @memberof Drivers
   * @param {string} value
   * @param {boolean} disabled
   * @returns {string}
   */
  isDLHashed = (value, disabled) => (value && disabled ? hashString(value) : value);
}

const mapStateToProps = state => ({
  formValues: getFormValues(constants.FORM.registrationForm)(state),
  dropdownOptions: state.settings.dropdownOptions,
  isEbikeOnly: selectors.getIsEbikeOnly(state)
});


export default injectIntl(connect(mapStateToProps, null)(Drivers));
