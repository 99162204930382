import {formValueSelector, change, stopAsyncValidation} from "redux-form";
import {constants, messages} from "../constants";
import {propel} from "../api";
import {postApplyPromoCode} from "../api/customer";

// registration progressbar index
export const handleSelectIndex = ({selectedIndex, selectedSubIndex}) => dispatch => {
  // TODO: so I want to submit the form and see if the form is still valid to go forward.
  dispatch({type: constants.FLOW_SELECTED_INDEX, payload: {selectedIndex, selectedSubIndex}});
};

// stamp on the valid steps
export const handleValidIndex =
  ({ pristine, valid, progress, validIndex: currentValidIndex }) =>
  (dispatch) => {
    // do only after selectedIndex is stamped
    if (typeof progress === "undefined" || typeof progress.selectedIndex === "undefined") {
      return;
    }

    // if the form is pristine or invalid, stamp on the last step, otherwise current step
    const validIndex =
      pristine || !valid
        ? progress.selectedIndex - 1
        : Math.max(currentValidIndex, progress.selectedIndex);
    dispatch({ type: constants.FLOW_VALID_INDEX, payload: { validIndex } });
  };

// registration edit name field actions
export const handleEditNameMode = editNameMode => dispatch => {
  dispatch({type: constants.FLOW_EDIT_NAME, payload: {editNameMode}});
};

// update valid promo code value
export const setPromoCodeStatus = promoCode => dispatch => {
  dispatch({type: constants.FLOW_PROMO_CODE, payload: promoCode});
};

// update valid promo code value
export const setEvolveOnlyStatus = isEvolveOnly => dispatch => {
  dispatch({type: constants.FLOW_EVOLVE_ONLY, payload: isEvolveOnly});
};

// change form field value manually
export const changeFieldValue = data => {
  const {state, form, section, name, newvalue, options} = data;
  // find current value with formValueSelector
  const selector = formValueSelector(form);
  const field = `${section}.${name}`;
  const current = selector(state, field);

  // if options([{value, label}]) are specified, find if current value is in the options
  // if it's in the option, keep the current value. otherwise set to the newvalue
  // if options are not specified, simply change the value to newvalue
  const value =
    !options || !(options.filter(option => option.value === current).length)
      ? newvalue
      : current;

  return dispatch => dispatch(change(form, field, value));
};


export const manualStopAsyncValidation = data => {
  const {form, errors} = data;

  return dispatch => dispatch(stopAsyncValidation(form, errors));
};


export const setAdyenResp = ({adyenResp}) => dispatch => {
  dispatch({
    type: constants.SET_ADYEN_RESP,
    payload: {adyenResp}
  });
};

export const clearAdyenResp = () => dispatch => {
  dispatch({
    type: constants.CLEAR_ADYEN_RESP
  });
};

export const setRegistrationResp = ({registrationResp}) => dispatch => {
  dispatch({
    type: constants.SET_REGISTRATION_RESP,
    payload: {registrationResp}
  });
};

export const setMonerisResp = ({monerisResp}) => dispatch => {
  dispatch({
    type: constants.SET_MONERIS_RESP,
    payload: {monerisResp}
  });
};


export const clearMonerisResp = () => dispatch => {
  dispatch({
    type: constants.CLEAR_MONERIS_RESP
  });
};

// set the paymentSummary object
export const setPaymentSummary = data => async dispatch => {
  try {
    const paymentSummary = await propel.feeSummary(data);
    dispatch({
      type: constants.FLOW_PAYMENT_SUMMARY,
      payload: {paymentSummary}
    });
  } catch (e) {
    console.warn(e);
  }
};

export const setRegistrationId = registrationId => async dispatch => {
  dispatch({
    type: constants.FLOW_REGISTRATION_ID,
    payload: {registrationId}
  });
}

export const validatePromoCode = (data, promoCode, intl, isRegistration = true) => {
  const payload = {code: promoCode, status: constants.PROMO_CODE_STATUS.primary, message: ""};
  // 100 : Oops, this promo code is not active.  (Promo has expired)
  // 101 : This isn’t a valid promo code. Please try again.
  // 103 : Oops, this promo code is only valid at registration.
  // 104 : Oops, this promo code has reached the maximum number of uses.
  // 105 : This promo code isn't valid in this city.
  // 106 : This promo code has already been applied to your account.

  if (data.errors) {
    payload.status = constants.PROMO_CODE_STATUS.error;
    switch (data.errors[0].error_codes[0]) {
      case 4000:
        payload.message = intl.formatMessage(messages.error.invalidFormat);
        break;
      case 4001:
        payload.message = intl.formatMessage(messages.error.invalidFormat);
        break;
      case 4002:
        payload.message = intl.formatMessage(messages.error.invalidPromoCode);
        break;
      // case "":
      //   payload.message = intl.formatMessage(messages.error.regOnlyPromoCode);
      //   break;
      // case "":
      //   payload.message = intl.formatMessage(messages.error.invalidPromoCodeInThisCity);
      //   break;
      case 4005:
        payload.message = intl.formatMessage(messages.error.duplicatePromoCode);
        break;
      default:
        payload.message = intl.formatMessage(messages.error.unknownPromoCodeError);
        break;
    }
  }

  // SUCCESS case if there's no messages added
  if (payload.status === constants.PROMO_CODE_STATUS.primary) {
    if (isRegistration) {

      // success message for applying promo code
      payload.message = intl.formatMessage(messages.field.applyPromoCodeMinutes, {promoCode});
    }
  }

  return payload;
};

export const handlePromoCodeUpdate = (promoCode, intl) => async dispatch => {
  try {

    const promoData = await propel.validatePromoCode(promoCode);

    const payload = validatePromoCode(promoData, promoCode, intl);

    dispatch({type: constants.FLOW_PROMO_CODE, payload});
    return payload;
  } catch (e) {
    const payload = {
      status: constants.PROMO_CODE_STATUS.error,
      message: intl.formatMessage(messages.error.unknownPromoCodeError)
    };
    dispatch({type: constants.FLOW_PROMO_CODE, payload});
    return payload;
  }
};

export const applyPromoCode = (promoCode, intl) => async dispatch => {
  try {
    const promoData = await postApplyPromoCode(promoCode);
    const payload = validatePromoCode(promoData, promoCode, intl, false);

    dispatch({type: constants.FLOW_PROMO_CODE, payload});
    return payload;
  } catch (e) {
    const payload = {
      status: constants.PROMO_CODE_STATUS.error,
      message: intl.formatMessage(messages.error.unknownPromoCodeError)
    };
    dispatch({type: constants.FLOW_PROMO_CODE, payload});
    return payload;
  }
};

export const resetPromoCode = () => dispatch => {
  dispatch({type: constants.FLOW_PROMO_CODE, payload: {}});
};

export const handleDriversLicense = async (dlFront, dlBack = undefined) => {
  try {
    if (!dlFront || (dlBack && !dlFront)) {
      const payload = {
        status: constants.DRIVER_LICENSE_STATUS.error,
        message: messages.error.genericError
      };
      //dispatch({type: constants.FLOW_DRIVERS_LICENSE, payload});
      return payload;
    }

    let dlFrontCompressed = dlFront;
    let dlBackCompressed = dlBack;

    if (dlFrontCompressed.size > 100000) {
      dlFrontCompressed = await propel.imageCompressionHandler(dlFrontCompressed, "dl_front", false);
    }

    if (dlBackCompressed) {
      if (dlBackCompressed.size > 100000) {
        dlBackCompressed = await propel.imageCompressionHandler(dlBackCompressed, "dl_back");
      }
    }

    const validateDLResp = await propel.validateDriversLicense(dlFrontCompressed, dlBackCompressed);

   // dispatch({type: constants.FLOW_DRIVERS_LICENSE, validateDLResp});
    return validateDLResp
  } catch (e) {

    // const payload = {
    //   status: constants.DRIVER_LICENSE_STATUS.error,
    //   message: messages.error.genericError
    // };
    // dispatch({type: constants.FLOW_DRIVERS_LICENSE, payload});
    return e;
  }
};

export const handleSelfie = async (dlFront, dlBack, selfie) => {
  try {

    if (!dlFront || !dlBack || !selfie) {
      const payload = {
        status: constants.SELFIE_STATUS.error,
        message: messages.error.genericError
      };
     // dispatch({type: constants.FLOW_SELFIE, payload});
      return payload;
    }

    let selfieCompressed = selfie;
    let dlFrontCompressed = dlFront;
    let dlBackCompressed = dlBack;

    if (selfieCompressed.size > 100000) {
      selfieCompressed = await propel.imageCompressionHandler(selfieCompressed, "selfie", false);
    }

    if (dlFrontCompressed.size > 100000) {
      dlFrontCompressed = await propel.imageCompressionHandler(dlFrontCompressed, "dl_front", false);
    }

    if (dlBackCompressed) {
      if (dlBackCompressed.size > 100000) {
        dlBackCompressed = await propel.imageCompressionHandler(dlBackCompressed, "dl_back");
      }

    }

    const validateDLResp = await propel.validateSelfie(dlFrontCompressed, dlBackCompressed, selfieCompressed);

   // dispatch({type: constants.FLOW_SELFIE, validateDLResp});
    return validateDLResp;
  } catch (e) {
    // const payload = {
    //   status: constants.SELFIE_STATUS.error,
    //   message: messages.error.genericError
    // };
    // dispatch({type: constants.FLOW_SELFIE, payload});
    return e;
  }
};


