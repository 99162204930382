import { constants } from "../constants";
import Auth from "@aws-amplify/auth";
import { jsonToFormStr } from "../util";

const { REACT_APP_PROPEL_SERVICE_URL } = constants;

export const getToken = async () => {
  try {
    const session = await Auth.currentSession();
    const idToken = await session.getIdToken();
    const jwtToken = await idToken.getJwtToken();
    return jwtToken;
  } catch (error) {
    throw error;
  }
};

export const generateHeader = async () => {
  const token = await getToken();
  return {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json"
  };
};

export const getUserId = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user.username;
  } catch (error) {
    throw error;
  }
};

export const requestGenerator = async (url, method = "GET", body = null) => {
  const headers = await generateHeader();
  return fetch(url, {
    method,
    headers,
    body: body ? JSON.stringify(body) : undefined
  });
};

export const parseJson = response => response.json();

export const checkStatus = response => {
  const { status } = response;
  if (status >= 300) {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
  return response;
};

/*
 * NEW CUSTOMER APIs
 */

/*
 * Customer account summary
 */
export const getCustomerAccountSummary = async () => {
  const userId = await getUserId();
  const url = `${REACT_APP_PROPEL_SERVICE_URL}/membersarea/customer-process/accountSummary/${userId}`;

  return requestGenerator(url)
    .then(checkStatus)
    .then(parseJson);
};

/*
 * Customer Login Information
 */
// get customer email
export const getCustomerEmail = async () => {
  const userId = await getUserId();
  const url = `${REACT_APP_PROPEL_SERVICE_URL}/membersarea/customer-process/loginInfo/${userId}`;

  return requestGenerator(url)
    .then(checkStatus)
    .then(parseJson);
};
// update customer email
export const patchCustomerEmail = async values => {
  const userId = await getUserId();
  const url = `${REACT_APP_PROPEL_SERVICE_URL}/membersarea/customer-process/loginInfo/${userId}`;

  return requestGenerator(url, "PATCH", values)
    .then(checkStatus)
    .then(parseJson);
};

/*
 * Customer Peronsal Information
 */
// get customer personal info
export const getCustomerPersonalInfo = async () => {
  const userId = await getUserId();
  const url = `${REACT_APP_PROPEL_SERVICE_URL}/membersarea/customer-process/personalInformation/${userId}`;

  return requestGenerator(url)
    .then(checkStatus)
    .then(parseJson);
};

// update customer personal info
export const patchCustomerPersonalInfo = async values => {
  const userId = await getUserId();
  const url = `${REACT_APP_PROPEL_SERVICE_URL}/membersarea/customer-process/personalInformation/${userId}`;

  return requestGenerator(url, "PATCH", values)
    .then(checkStatus)
    .then(parseJson);
};

/*
 * Customer Driver licence Information
 */
// get customer driver licence info
export const getCustomerDriverLicence = async () => {
  const userId = await getUserId();
  const url = `${REACT_APP_PROPEL_SERVICE_URL}/membersarea/customer-process/driverLicence/${userId}`;

  return requestGenerator(url)
    .then(checkStatus)
    .then(parseJson);
};

// update customer driver licence info (dlnumber, dlexpiry, dlcountry, dlprovince)
export const updateCustomerDriverLicence = async values => {
  const userId = await getUserId();
  const url = `${REACT_APP_PROPEL_SERVICE_URL}/membersarea/customer-process/driverLicence/${userId}`;

  return requestGenerator(url, "POST", values)
    .then(checkStatus)
    .then(parseJson);
};

// patch customer driver licence info (firstname, lastname, middlename and birthdate)
export const patchCustomerDriverLicence = async values => {
  const userId = await getUserId();
  const url = `${REACT_APP_PROPEL_SERVICE_URL}/membersarea/customer-process/driverLicence/${userId}`;

  return requestGenerator(url, "PATCH", values)
    .then(checkStatus)
    .then(parseJson);
};

/*
 * Customer Payment Information
 */
export const getCustomerPaymentInfo = async values => {
  const userId = await getUserId();
  const url = `${REACT_APP_PROPEL_SERVICE_URL}/membersarea/customer-process/paymentInformation/${userId}`;

  return requestGenerator(url)
    .then(checkStatus)
    .then(parseJson);
};

export const updateCustomerPaymentInfo = async values => {
  const userId = await getUserId();
  const url = `${REACT_APP_PROPEL_SERVICE_URL}/membersarea/customer-process/paymentInformation/${userId}`;

  return requestGenerator(url, "POST", values)
    .then(checkStatus)
    .then(parseJson);
};

export const patchCustomerPaymentInfo = async values => {
  const userId = await getUserId();
  const url = `${REACT_APP_PROPEL_SERVICE_URL}/membersarea/customer-process/paymentInformation/${userId}`;

  return requestGenerator(url, "PATCH", values)
    .then(checkStatus)
    .then(parseJson);
};

/*
 * Customer Membership
 */
export const getCustomerMembership = async values => {
  const userId = await getUserId();
  const url = `${REACT_APP_PROPEL_SERVICE_URL}/membersarea/customer-process/membership/${userId}`;

  return requestGenerator(url)
    .then(checkStatus)
    .then(parseJson);
};

// update membership number, year, and postal code
export const updateCustomerMembership = async values => {
  const userId = await getUserId();
  const url = `${REACT_APP_PROPEL_SERVICE_URL}/membersarea/customer-process/membership/${userId}`;

  return requestGenerator(url, "POST", values)
    .then(checkStatus)
    .then(parseJson);
};

// update marketing preferences and school info
export const patchCustomerMembership = async values => {
  const userId = await getUserId();
  const url = `${REACT_APP_PROPEL_SERVICE_URL}/membersarea/customer-process/membership/${userId}`;

  return requestGenerator(url, "PATCH", values)
    .then(checkStatus)
    .then(parseJson);
};

export const removeCustomerMembership = async memberNumber => {
  const userId = await getUserId();
  const url = `${REACT_APP_PROPEL_SERVICE_URL}/membersarea/customer-process/membership/${userId}/${memberNumber}`;

  return requestGenerator(url, "DELETE")
    .then(checkStatus)
    .then(parseJson);
};

/*
 * LEGACY CUSTOMER APIs
 */

export const searchCustomer = async () => {
  const userId = await getUserId();
  const url = `${REACT_APP_PROPEL_SERVICE_URL}/membersarea/customer/${userId}`;
  const headers = await generateHeader();
  try {
    const customerResp = await fetch(url, { headers });
    return await customerResp.json();
  } catch (error) {
    throw error;
  }
};

export const updateCustomerInfo = async values => {
  const userId = await getUserId();
  const url = `${REACT_APP_PROPEL_SERVICE_URL}/membersarea/customer/${userId}`;
  const headers = await generateHeader();
  try {
    const customerResp = await fetch(url, {
      method: "PATCH",
      headers,
      body: JSON.stringify(values)
    });
    const result = await customerResp.json();
    if (customerResp.status >= 300) throw new Error(result.error);
    return result;
  } catch (error) {
    throw error;
  }
};

export const getBillingBalances = async () => {
  const userId = await getUserId();
  const url = `${REACT_APP_PROPEL_SERVICE_URL}/membersarea/customers-billing/balances?customerId=${userId}`;
  const headers = await generateHeader();

  try {
    const customerResp = await fetch(url, { headers });
    return await customerResp.json();
  } catch (error) {
    throw error;
  }
};

/**
 *
 *
 * @param {*} { from, to, count = 50, page = 1}
 * @returns {Promise}
 */
export const getPayments = async ({ from, to, count = 50, page = 1 }) => {
  const customerId = await getUserId();
  const queryString = jsonToFormStr({ customerId, from, to, count, page });
  const url = `${REACT_APP_PROPEL_SERVICE_URL}/membersarea/customers-billing/payments?${queryString}`;
  const headers = await generateHeader();

  try {
    const payments = await fetch(url, { headers });
    return await payments.json();
  } catch (error) {
    throw error;
  }
};

//   FREE MINUTES NAMESPACE
//
//
const FREE_MINUTES = "membersarea/customer-freeminute";

export const getFreeMinutes = async () => {
  const customerId = await getUserId();
  const queryString = jsonToFormStr({ customerId });
  const url = `${REACT_APP_PROPEL_SERVICE_URL}/${FREE_MINUTES}?${queryString}`;
  const headers = await generateHeader();

  try {
    const freeMinutesResp = await fetch(url, { headers });
    return await freeMinutesResp.json();
  } catch (error) {
    throw error;
  }
};

export const getFreeMinutesSummary = async () => {
  const customerId = await getUserId();
  const queryString = jsonToFormStr({ customerId });
  const url = `${REACT_APP_PROPEL_SERVICE_URL}/${FREE_MINUTES}/summary?${queryString}`;
  const headers = await generateHeader();

  try {
    const freeMinutesResp = await fetch(url, { headers });
    return await freeMinutesResp.json();
  } catch (error) {
    throw error;
  }
};

export const getPaymentDetails = async paymentId => {
  const customerId = await getUserId();
  const queryString = jsonToFormStr({ customerId, paymentId });
  const url = `${REACT_APP_PROPEL_SERVICE_URL}/membersarea/customers-billing/paymentdetails?${queryString}`;
  const headers = await generateHeader();

  try {
    const paymentDetails = await fetch(url, { headers });
    return await paymentDetails.json();
  } catch (error) {
    throw error;
  }
};

export const getTransactionDetails = async transcalcId => {
  const queryString = jsonToFormStr({ transcalcId });
  const url = `${REACT_APP_PROPEL_SERVICE_URL}/membersarea/customers-billing/transactiondetails?${queryString}`;
  const headers = await generateHeader();

  try {
    const transactionDetails = await fetch(url, { headers });
    return await transactionDetails.json();
  } catch (error) {
    throw error;
  }
};

/*
 * Get Customer Monthly Statement List
 */
export const getMonthlyStatements = async (count = 15, page = 1) => {
  const userId = await getUserId();
  const url = `${REACT_APP_PROPEL_SERVICE_URL}/membersarea/customers-billing/monthlyStatementList?customerId=${userId}&count=${count}&page=${page}`;

  return requestGenerator(url)
    .then(checkStatus)
    .then(parseJson);
};

export const generateMonthlyStatement = async (month, year, languageCode) => {
  const userId = await getUserId();
  const url = `${REACT_APP_PROPEL_SERVICE_URL}/membersarea/customers-billing/genMonthlyStatement?customerId=${userId}&year=${year}&month=${month}&languageCode=${languageCode}`;

  return requestGenerator(url)
    .then(checkStatus)
    .then(parseJson);
};

export const postApplyPromoCode = async (promoCode, isRegistration = false) => {
  const customerId = await getUserId();
  const url = `${REACT_APP_PROPEL_SERVICE_URL}/membersarea/apply-promo`;
  const headers = await generateHeader();
  const body = {
    customerId,
    promoCode,
    isRegistration
  };

  try {
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers
    });
    return await resp.json();
  } catch (error) {
    throw error;
  }
};
