/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link } from "react-router-dom";
import cn from "classnames";
import { injectIntl } from "react-intl";

import "./navlinklist.scss";
import { Title } from "../../elements";
import { messages, constants } from "../../constants";
import { logout } from "../../actions/auth";
import * as selectors from "../../selectors";
import {DependOn} from "../index";
import {isIOS} from "react-device-detect";

class NavLinklist extends PureComponent {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    isLoggedIn: PropTypes.bool,
    logout: PropTypes.func
  };

  static defaultProps = {
    isLoggedIn: false,
    logout: () => {}
  };

  render() {
    const { intl, isLoggedIn, logout } = this.props;

    const linkList = [
      {
        title: intl.formatMessage(messages.link.home),
        link: "https://www.evo.ca",
        type: "external",
        condition: !isLoggedIn
      },
      // {
      //   title: intl.formatMessage(messages.link.findACar),
      //   link: "https://www.evo.ca/find-a-car",
      //   type: "external",
      //   condition: !isLoggedIn
      // },
      {
        title: intl.formatMessage(messages.link.findACar),
        link: constants.ROUTE_FIND_A_CAR,
        type: "internal",
        condition: isLoggedIn
      },
      {
        title: intl.formatMessage(messages.title.AccountDetails),
        link: constants.ROUTE_ACCOUNT,
        type: "internal",
        condition: isLoggedIn
      },
      {
        title: intl.formatMessage(messages.link.howItWorks),
        link: "https://www.evo.ca/how-it-works",
        type: "external",
        condition: !isLoggedIn
      },
      {
        title: intl.formatMessage(messages.link.ourCars),
        link: "https://www.evo.ca/our-cars",
        type: "external",
        condition: !isLoggedIn
      },
      {
        title: intl.formatMessage(messages.link.rates),
        link: "https://www.evo.ca/rates",
        type: "external",
        condition: !isLoggedIn
      },
      {
        title: intl.formatMessage(messages.link.getApp),
        link: "https://www.evo.ca/car-share-app",
        type: "external"
      },
      {
        title: intl.formatMessage(messages.link.more),
        type: "sublistHeader",
        condition: isLoggedIn
      }
    ];

    const subLinkList = [
      {
        title: intl.formatMessage(messages.link.parking),
        link: "https://www.evo.ca/parking#parking",
        type: "external",
        isSublistItem: true
      },
      {
        title: intl.formatMessage(messages.link.faq),
        link: "https://www.evo.ca/faq",
        type: "external",
        isSublistItem: true
      },
      {
        title: intl.formatMessage(messages.link.evoExtra),
        link: "https://www.evo.ca/extras",
        type: "external",
        isSublistItem: true
      },
      {
        title: intl.formatMessage(messages.link.communitySupport),
        link: "https://www.evo.ca/community-support",
        type: "external",
        isSublistItem: true
      },
      {
        title: intl.formatMessage(messages.link.aboutEvo),
        link: "https://www.evo.ca/about",
        type: "external",
        isSublistItem: true
      },
      {
        title: intl.formatMessage(messages.link.contact),
        link: "https://www.evo.ca/contact",
        type: "external",
        isSublistItem: true
      },
      {
        title: intl.formatMessage(messages.link.memberAgreement),
        link: "https://www.evo.ca/member-agreement",
        type: "external",
        isSublistItem: true
      },
      {
        title: intl.formatMessage(messages.link.news),
        link: "https://www.evo.ca/news",
        type: "external",
        isSublistItem: true
      }
    ];

    const actionLinkList = [
      {
        title: `${intl.formatMessage(messages.button.signout)}`,
        action: logout,
        type: "action",
        condition: isLoggedIn
      }
    ];

    return (
      <ul className="nav-linklist">
        {linkList.map(this.renderLink)}
        <ul>{subLinkList.map(this.renderLink)}</ul>
        {actionLinkList.map(this.renderLink)}
      </ul>
    );
  }
  renderLink = (
    { title, link, type, action, cls, condition = true, isSublistItem = false },
    idx
  ) => {
    const itemCSS = cn(
      isSublistItem ? `nav-linklist__sub-list__link` : "nav-linklist__link",
      cls ? `nav-linklist__link-${cls}` : ""
    );

    if (!condition) return null;
    switch (type) {
      case "sublistHeader":
        return (
          <Title key={idx} priority={4} className="nav-linklist__sub-list__title">
            {title}
          </Title>
        );
      case "action":
        // use `onClick={() => action()}` instead of `onClick={action}`
        // because object will be reused and all properties will be nullified
        // after the event callback has been invoked
        return (
          <Title key={idx} priority={4}>
            <a title={title} className={itemCSS} onClick={() => action()}>
              {title}
            </a>
          </Title>
        );
      case "internal":
        return (
          <Title key={idx} priority={4}>
            <Link to={link} className={itemCSS}>
              {title}
            </Link>
          </Title>
        );
      case "external":
      default:
        return (
          <Title key={idx} priority={4}>
            <DependOn active={isIOS}>
              <a href={link} className={itemCSS}>
                {title}
              </a>
            </DependOn>
            <DependOn active={isIOS===false}>
            <a href={link} target="_blank" rel="noopener noreferrer" className={itemCSS}>
              {title}
            </a>
            </DependOn>
          </Title>
        );
    }
  };
}

const mapStateToProps = state => ({
  isLoggedIn: selectors.isLoggedIn(state)
});

const mapDispatchToProps = {
  logout
};

const enhancer = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export const IntlInjectedNavlinkList = injectIntl(NavLinklist);

export default enhancer(NavLinklist);
