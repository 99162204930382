import React from "react";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";

import {Txt} from "../../../../elements";
import {Section, Grid, Col} from "../../../../components";
import {messages} from "../../../../constants";

import {FormSectionWrapper} from "../";
import {connect} from "react-redux";
import phoneIcon from "../../../../assets/img/evoApp.png";
import hubIcon from "../../../../assets/img/HUBLogo.jpg";
import "./ebikeConfirmation.scss";
const EbikeConfirmation = props => {
  window.scrollTo(0, 0);


  return (
    <FormSectionWrapper {...props}>


      <Section size="1-2" min="md" theme={["div"]}>
        <Grid>
          <Col>
            <Txt className="text--large">
              <div>{props.intl.formatMessage(messages.ebikeConfirmation.confirmationInbox)}</div>
            </Txt>
          </Col>
        </Grid>
      </Section>
      <Section size="1-2" min="md" theme={["div"]}>
        <Grid>
          <Col size="2-3">
            <Txt className="text--middle">
              <div>{props.intl.formatMessage(messages.ebikeConfirmation.confirmationSameEvoApp)}</div>
            </Txt>
          </Col>
          <Col size="1-3" theme={["right"]}>
            <a href='https://evo.ca/car-share-app' target='_blank' rel='noopener noreferrer'><img className="evoApp" src={phoneIcon} alt="EvoApp" /></a>
          </Col>
        </Grid>
      </Section>
      <hr className="hr--primary"/>
      <Section size="1-2" min="md" theme={["div"]}>
        <Grid>
          <Col size="1-3" theme={["left"]}>
            <img className="evoApp" src={hubIcon} alt="EvoApp" />
          </Col>
          <Col  size="2-3">
            <Txt className="text--middle">
              <div>{props.intl.formatMessage(messages.ebikeConfirmation.confirmationContent, {newLine: <br />, hubLink: <a className='bold primary' href='https://bikehub.b-cdn.net/sites/default/files/hub_cycling_x_bcaa_-_e-bike_online_v6_1.pdf' target='_blank' rel='noopener noreferrer'>HUB Cycling's E-Bike Safety Handbook</a>})}</div>
            </Txt>
          </Col>
        </Grid>
      </Section>
      <Section size="1-2" min="md" theme={["div"]}>
        <Grid>
          <Col>
            <Txt className="text--middle">
              <div>{props.intl.formatMessage(messages.ebikeConfirmation.confirmationInterestedDriving, {email: <a className="primary bold" href='mailto:info@evo.ca'>info@evo.ca</a>})}</div>
            </Txt>
          </Col>
        </Grid>
      </Section>
    </FormSectionWrapper>
  );
};


EbikeConfirmation.propTypes = {
  form: PropTypes.object,
  section: PropTypes.string,
  intl: PropTypes.object.isRequired,
};
const mapStateToProps = (state, {form}) => {

  return {
    form: state.form.registration
  };
};

export default connect(mapStateToProps)(injectIntl(EbikeConfirmation));

