import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import "./index.scss";

export default class TableHeader extends PureComponent {
  render() {
    const { collaspableColumns, columnNames } = this.props;
    const filteredColumnNames = columnNames.filter(cn => cn !== null);
    const renderHeader = filteredColumnNames.map((column, idx) => (
      <th key={idx} className={cn({ collapsColumn: collaspableColumns.includes(column.key) })}>
        {column.title}
      </th>
    ));
    return (
      <thead>
        <tr>{renderHeader}</tr>
      </thead>
    );
  }
}

TableHeader.defaultProps = {
  columnNames: [],
  collaspableColumns: []
};

TableHeader.propTypes = {
  columnNames: PropTypes.arrayOf(PropTypes.object),
  collaspableColumns: PropTypes.arrayOf(PropTypes.string)
};
