export const LOGIN_ERRORS = {
  USER_NOT_FOUND: "UserNotFoundException",
  USER_NOT_AUTHORIZED: "NotAuthorizedException",
  USER_NOT_CONFIRMED: "UserNotConfirmedException"
};

export const CHANGE_PASSWORD_ERRORS = {
  USER_NOT_AUTHORIZED: "NotAuthorizedException",
  LIMIT_EXCEEDED: "LimitExceededException"
};
