import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reset, destroy } from "redux-form";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { get, pick, isEmpty, omit } from "lodash";
import { diff} from 'deep-object-diff';

import { UpdateForms } from "../../containers/form";
import * as formSections from "../../containers/form/formSections";
import { messages, constants } from "../../constants";
import * as selectors from "../../selectors";
import { user as userActions, banner as bannerActions } from "../../actions";
import { Section, Grid, Col } from "../../components";
import { Txt } from "../../elements";
import { formatDate } from "../../util";

export class AccountDriversInfo extends PureComponent {
  state = {
    isLoading: true
  };

  async componentDidMount() {
    const { getCustomerDriverLicence, setAlerts, intl } = this.props;
    try {
      await getCustomerDriverLicence();
    } catch (e) {
      setAlerts([
        {
          level: constants.ALERT_LEVEL.error,
          message: intl.formatMessage(messages.error.genericError)
        }
      ]);
    }

    this.setState({ isLoading: false });
  }

  render() {
    const { isLoading } = this.state;
    const { intl, user, destroyForm } = this.props;

    if (isLoading) return null;
    const driverLicence = user.driverLicence ? this.mapFieldsBeToFe(user.driverLicence) : {};

    const DriversInfoUpdate = {
      title: intl.formatMessage(messages.title.DriversLicenseInfo),
      section: `driverLicence`,
      component: formSections.DriversLicenseInfoUpdate
    };

    return (
      <Fragment>
        <Section>
          <Grid>
            <Col>
              <Link
                to={constants.ROUTE_ACCOUNT}
                onClick={() => {
                  destroyForm("update");
                }}
              >
                <Txt theme={["upper"]}>
                  <i className="fa fa-chevron-left" />{" "}
                  {intl.formatMessage(messages.button.backtoAccountDetails)}
                </Txt>
              </Link>
            </Col>
          </Grid>
        </Section>
        <Section theme={[`div`]}>
          <Grid>
            <Col>
              <UpdateForms
                onSubmit={this._handleSubmit}
                sections={[DriversInfoUpdate]}
                initialValues={{ driverLicence }}
              />
            </Col>
          </Grid>
        </Section>
      </Fragment>
    );
  }

  /**
   *
   *
   * @memberof AccountDriversInfo
   */
  _handleSubmit = values => {
    const {intl, user, updateCustomerDriverLicence, patchCustomerDriverLicence} = this.props;

    const origDriver = pick(user.driverLicence, 'firstName', 'lastName', 'middleName', 'birthDate');
    const origDriverLicence = pick(user.driverLicence, 'dlNumber', 'dlExpiry','dlProvinceStateCode','dlCountryCode');

    const {driver, driverLicence} = this.mapFieldsFeToBe(values.driverLicence);
    const changedDriverValues = diff(origDriver, driver);
    let changedDriverLicence = diff(origDriverLicence, driverLicence);

    // BE passes a null value for the Province State Code and the input on screen returns an empty string
    if(origDriverLicence.dlProvinceStateCode === null && driverLicence.dlProvinceStateCode === ""){
      changedDriverLicence =  omit(changedDriverLicence, 'dlProvinceStateCode');
    }

    const promises = [];
    let patchValues = {};

    if(!isEmpty(changedDriverValues))
      patchValues = changedDriverValues;

    // if the driver licence number changes send a post with ALL driverlicence values,
    // Otherwise, add the remaining fields to the patch call (cannot patch the dlnumber)
    if (origDriverLicence.dlNumber !== driverLicence.dlNumber) {
      promises.push(updateCustomerDriverLicence(driverLicence));
    } else {
      if(get(changedDriverLicence, 'dlProvinceStateCode') )
      {
        changedDriverLicence['dlProvinceStateCode'] = driverLicence.dlProvinceStateCode;
        changedDriverLicence['dlCountryCode']= driverLicence.dlCountryCode;
      }

      if(!isEmpty(changedDriverLicence))
       patchValues = {...patchValues, ...changedDriverLicence};
    }

    if(!isEmpty(patchValues))
      promises.push(patchCustomerDriverLicence(patchValues));

    isEmpty(promises) ? this.props.resetForm():  Promise.all(promises)
      .then(() => {
        // set success alert message to banner
        this.props.setAlerts([
          {
            level: constants.ALERT_LEVEL.success,
            message: intl.formatMessage(messages.success.saveDriverLicence)
          }
        ]);
      })
      .catch(() => {
        // set success alert message to banner
        this.props.resetForm("update");
        this.props.setAlerts([
          {
            level: constants.ALERT_LEVEL.error,
            message: intl.formatMessage(messages.error.genericError)
          }
        ]);
      });
  };

  mapFieldsBeToFe = beFields => {
    const {
      firstName,
      middleName,
      lastName,
      birthDate,
      dlNumber,
      dlExpiry,
      dlProvinceStateCode,
      dlCountryCode
    } = beFields;
    const isCAUSA = dlCountryCode === "CAN" || dlCountryCode === "USA";
    return {
      firstName,
      middleName,
      lastName,
      birthDate: moment(birthDate).toDate(),
      number: dlNumber,
      country: dlCountryCode,
      province: isCAUSA ? dlProvinceStateCode : "", // ignore province if country is not canada nor usa
      expiryDate: moment(dlExpiry).toDate()
    };
  };

  mapFieldsFeToBe = beFields => {
    const {
      firstName,
      middleName,
      lastName,
      birthDate,
      number,
      country,
      province,
      expiryDate
    } = beFields;
    const isCAUSA = country === "CAN" || country === "USA";
    const provinceCode = province ? province : '';
    return {
      driver: {
        firstName,
        middleName,
        lastName,
        birthDate: formatDate(birthDate)
      },

      driverLicence: {
        dlNumber: number,
        dlExpiry: formatDate(expiryDate),
        dlProvinceStateCode: isCAUSA ? provinceCode : '',
        dlCountryCode: country
      }
    };
  };
}

AccountDriversInfo.propTypes = {
  intl: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  setAlerts: PropTypes.func
};

const mapStateToProps = state => ({
  user: selectors.getUser(state)
});

const mapDispatchToProps = {
  getCustomerDriverLicence: userActions.getCustomerDriverLicence,
  updateCustomerDriverLicence: userActions.updateCustomerDriverLicence,
  patchCustomerDriverLicence: userActions.patchCustomerDriverLicence,
  setAlerts: bannerActions.setAlerts,
  destroyForm: destroy,
  resetForm: reset
};

const enhancer = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhancer(AccountDriversInfo);
