import React, { PureComponent } from "react";
import { Field, formValueSelector } from "redux-form";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import { Input, Grid, Col, Section } from "../../../../components";
import { messages } from "../../../../constants";
import { validationBuilder, validations } from "../..";
import { Button } from "../../../../elements";

let rules = {};

class VerifyOtp extends PureComponent {

  constructor(props) {
    super(props);
    // for field level validations
    // TODO: find a better way to instantiate props when the component mounts
    Object.keys(validations).forEach(key => {
      rules[key] = spec => value => validationBuilder(props, validations[key], spec)(value);
    });

    const { intl } = props;
    this.rulesEvoAccountOtp = [
      rules.required(intl.formatMessage(messages.privateRegistration.OTPEmailInstructions)),
    ];
  }

  // function to get the selected field value
  getValue = name => {
    const { state, form, section } = this.props;
    const selector = formValueSelector(form);
    if (section) {
      return selector(state, `${section}.${name}`);
    }
    return selector(state, name);
  };

  // _resendOTP = async () => {
  //   console.log("handleSubmit", this.props.location.state.business_email);

  //   // this.setState({loading: true});
  //   // return new Promise(async (resolve, reject) => {
  //   await resendBusinessEmailOTP().then(async (res) => {
  // //   resolve();
  // //   }).catch((e) => {
  // //     this.setState({loading: false});
  // //     reject(e);
  // //   });
  // });
  // }

  render() {
    const { intl, resendOtp } = this.props;

    return (
      <Section size="1-2" min="md">
        <Grid>
          <Col>
            <Field
              name="otp"
              type="number"
              label={intl.formatMessage(messages.privateRegistration.OTPEmailInstructions)}
              labelClassName="text--large"
              radioFieldClassName="compact"
              // tooltip={tooltip}
              required={true}
              // tooltipArrow="top"
              component={Input}
              validate={this.rulesEvoAccountOtp}
              props={{ maxLength: "4" }}
            />
            <Button
              loading={false}
              theme={["link-no-underline", "compact"]}
              loadingTheme="inline"
              onClick={resendOtp}
            >
               <span>{intl.formatMessage(messages.privateRegistration.resendOTP)}</span>
            </Button>
            <br /><br />
          </Col>
        </Grid>
      </Section>
    );
  }
}

VerifyOtp.propTypes = {
  intl: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};

export default injectIntl(VerifyOtp);
