const settings = {
  worldcover: [
    //a rectangle that covers the whole world, from which we carve out the home area, coordinates are defined in inverse order (CCW)
    { lat: -85.1054596961173, lng: -180 },
    { lat: 85.1054596961173, lng: -180 },
    { lat: 85.1054596961173, lng: 180 },
    { lat: -85.1054596961173, lng: 180 },
    { lat: -85.1054596961173, lng: 0 }
  ],
  edgestyle: {
    path: "M 0,-0.5 0,0",
    strokeOpacity: 1,
    strokeColor: "#00bce4",
    scale: 3
  },
  edgeRepeat: "6px"
};

//draws home area on the map
export const drawHomeArea = (data, map, maps, isVisibleOnInit) => {
  let edges = [],
    combinedData = [];

  //inject a very big rectangle
  combinedData.push(settings.worldcover);

  //gmaps wants a single array, we need to join all features array
  data.forEach(fleet => {

    //display only the home area
    fleet.zones.forEach(zone => {
      //transform coordinates into G latlng objects to draw the edge as a dashed polyline
      const coordsObjArray = zone.latLongs.map(l => ({lat: l.latitude, lng: l.longitude})) || [];
      edges.push(coordsObjArray);
      combinedData.push(coordsObjArray);
    });
  });
  //draw home area polygon
  const homezoneArea = new maps.Polygon({
    paths: combinedData,
    useGeoJSON: true,
    fillColor: "#FFF",
    strokeOpacity: 0,
    fillOpacity: 0.5,
    map
  });

  homezoneArea.setVisible(isVisibleOnInit);

  //draw the edge of the homearea as a polyline, to support dashed border
  const homezoneEdges = [];
  edges.forEach(edge => {
    const newEdge = new maps.Polyline({
      map,
      path: edge,
      strokeOpacity: 0,
      icons: [
        {
          icon: settings.edgestyle,
          offset: "0",
          repeat: settings.edgeRepeat
        }
      ]
    });
    newEdge.setVisible(isVisibleOnInit);
    homezoneEdges.push(newEdge);
  });

  return {homezoneArea, homezoneEdges};
};
