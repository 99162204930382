import { defineMessages } from "react-intl";

const namespace = "warning";
const warning = defineMessages({
  userSuspended: {
    id: `${namespace}.userSuspended`,
    description: "User is suspended warning message",
    defaultMessage:
      'Your account has been suspended. You won’t be able to use Evo until this issue is resolved. Please <a href="https://www.evo.ca/contact" target="_blank">contact us</a> to resolve this issue.'
  },
  accountSuspended: {
    id: `${namespace}.accountSuspended`,
    description: "Account is suspended warning message",
    defaultMessage:
      "Your account has been suspended due to an outstanding balance of {outstandingBalance}. You won’t be able to use Evo until this issue is resolved. Please {paymentLink} and {contactLink} to unlock your account and hit the road again with Evo."
  },
  driverLicenceExpired: {
    id: `${namespace}.driverLicenceExpired`,
    description: "driver's licence is expired warning message",
    defaultMessage: "Your Driver’s Licence has expired. {dlLink} to hit the road again with Evo."
  },
  creditCardExpired: {
    id: `${namespace}.creditCardExpired`,
    description: "Credit card is expired warning message",
    defaultMessage: "Your credit card has expired. {paymentLink}."
  },
  pendingDrivingRecordApproval: {
    id: `${namespace}.pendingDrivingRecordApproval`,
    description: "User has pending driving record approval warning message",
    defaultMessage:
      "Your account has not been approved yet. We are either waiting for your driving record or your approval is in progress."
  },
  pendingDrivingRecordStatus: {
    id: `${namespace}.pendingDrivingRecordStatus`,
    description: "User has pending driving record status warning message",
    defaultMessage:
      'Your driving record did not meet our scoring criteria. Please <a href="https://www.evo.ca/contact" target="_blank">contact us</a> for more info.'
  },
  expiredCreditCard: {
    id: `${namespace}.expiredCreditCard`,
    description: "Credit card is expired warning message",
    defaultMessage:
      "Your credit card has expired. Update your credit card info to hit the road again with Evo."
  },
  mapAddressNotFound: {
    id: `${namespace}.mapAddressNotFound`,
    description: "Warning about input address can't be found.",
    defaultMessage: "We can't find this address. Make sure your search is spelled correctly."
  },
  mapUnknownError: {
    id: `${namespace}.mapUnknownError`,
    description: "Unkown map error.",
    defaultMessage: "We have trouble getting your location. Please try again later."
  },
  timeout: {
    id: `${namespace}.timeout`,
    description: "Map permission timeout.",
    defaultMessage: "Request timeout. Please try again"
  },
  positionUnavailable: {
    id: `${namespace}.positionUnavailable`,
    description: "Location information is unavailable.",
    defaultMessage: "Location information is unavailable."
  },
  permissionDenied: {
    id: `${namespace}.permissionDenied`,
    description: "Permission Denied",
    defaultMessage: "Could not detect location. Please check browser settings."
  }
});

export default warning;
